import React from "react";
import {
  assessphasewise,
  assessplannedmigration,
  assessplannedmigrationtable,
  assesstobecore,
  assesstobememory,
} from "../action/graphql/a2crover/A2CReducer";
import {
  rightSizingTable,
  corecomparisonchart,
  ramcomparisonchart,
  storagecomparisonchart,
  tobecorecpu,
  tobecoreram,
} from "../action/graphql/a2crover/A2CCostAnalysisREducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Spin, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import RightSizingEditablleTable from "./RightSizingEditablleTable";

function RightSizing() {
  const dispatch = useDispatch();
  let company = localStorage.getItem("CognitoIdentityServiceProvider.company");
  const [assessphase, setAssessphase] = useState([]);
  const [assessplan, setAssessplan] = useState([]);
  const [tablerecord, setTablerecord] = useState([]);
  const [assesscore, setAssesscore] = useState([]);
  const [assessmemory, setAssessmemory] = useState([]);
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const [corecomparison, setCoreComparison] = useState([]);
  const [ramcomparison, setRamComparison] = useState([]);
  const [storagecomparison, setStorageComparison] = useState([]);
  //api fro right sizing
  const [cpucore, setCpuCore] = useState([]);
  const [ramcore, setRamCore] = useState([]);
  const {
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  } = useSelector((store) => store.a2cRover);
  // console.log(selectedEnvironment);
  const inputs = {
    application: selectedApplication.toString(),
    company: company,
    data_center_name: selecteddc.toString(),
    db: selecteddb.toString(),
    environment: selectedEnvironment.toString(),
    os: selectedos.toString(),
  };
  useEffect(() => {
    dispatch(assessphasewise(inputs))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_phase_wise.map((datas) => {
          return {
            phase_level: datas.phase_level,
            resource_count: datas.resource_count,
          };
        });
        console.log(record);
        setAssessphase(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assessplannedmigration(inputs))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setIsShowSpinner(false);

        const record = data.migrate_resource_by_planned.map((datas) => {
          const date = new Date(datas.date);
          const monthNames = [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ];
          const formattedDate = `${
            monthNames[date.getMonth()]
          }-${date.getDate()}, ${date.getFullYear()}`;

          return {
            date: formattedDate,
            resource_count: datas.resource_count,
          };
        });
        setAssessplan(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assessplannedmigrationtable(inputs))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "vmtable");
        setIsShowSpinner(false);

        const record = data.assess_to_be_vm_table.map((datas) => {
          // const vm_type = parseInt(datas.vm_type)
          return {
            memory: datas.memory,
            resource_name: datas.resource_name,
            vcpu: datas.vcpu,
            vm_type: datas.vm_type,
          };
        });

        console.log(record);
        setTablerecord(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assesstobecore(inputs))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_to_be_core.map((datas) => {
          return {
            to_be_core: datas.to_be_core,
            application: datas.application,
          };
        });
        console.log(record);
        setAssesscore(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assesstobememory(inputs))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "asssssgrp");
        setIsShowSpinner(false);

        const record = data.assess_to_be_memory.map((datas) => {
          return {
            total_mem: datas.total_mem,
            application: datas.application,
          };
        });
        console.log(record);
        setAssessmemory(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  //right sizing bar chart
  //rightsizing bar chart

  // company = "bi_worldwide";

  useEffect(() => {
    const inputchart = {
      company: "bi_worldwide",
    };
    dispatch(corecomparisonchart({ inputchart }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "barchartss");
        setIsShowSpinner(false);

        const record = data.cost_analysis_as_is_to_be_core.map((datas) => {
          return {
            total_as_is_core: datas.total_as_is_core,
            total_to_be_core: datas.total_to_be_core,
          };
        });

        // const record = data.cost_analysis_as_is_to_be_core.map((datas) => {
        //   return {
        //     total_as_is_core: datas.total_as_is_core,
        //     total_to_be_core: datas.total_to_be_core,
        //   };
        // });

        console.log(record, "barchartdata");
        setCoreComparison(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let datass = corecomparison.map((data) => data.total_as_is_core);

  useEffect(() => {
    const inputchart = {
      company: "bi_worldwide",
    };
    dispatch(ramcomparisonchart({ inputchart }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "barchartram");
        setIsShowSpinner(false);

        const record = data.cost_analysis_as_is_to_be_ram.map((datas) => {
          // const vm_type = parseInt(datas.vm_type)
          return {
            total_as_is_ram: datas.total_as_is_ram,
            total_to_be_ram: datas.total_to_be_ram,
          };
        });

        console.log(record, "barchartdataram");
        setRamComparison(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    const inputchart = {
      company: "bi_worldwide",
    };
    dispatch(storagecomparisonchart({ inputchart }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "barchartstorage");
        setIsShowSpinner(false);

        const record = data.cost_analysis_as_is_to_be_storage.map((datas) => {
          // const vm_type = parseInt(datas.vm_type)
          return {
            total_as_is_storage: datas.total_as_is_storage,
            total_to_be_storage: datas.total_to_be_storage,
          };
        });

        console.log(record, "barchartdatastorage");
        setStorageComparison(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    const input1 = {
      company: "bi_worldwide",
    };
    dispatch(tobecorecpu({ input1 }))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.cost_analysis_application_wise_cpu.map((datas) => {
          return {
            application: datas.application,
            total_to_be_cpu: datas.total_to_be_cpu,
          };
        });
        console.log(record);
        setCpuCore(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const input1 = {
      company: "bi_worldwide",
    };
    dispatch(tobecoreram({ input1 }))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.cost_analysis_application_wise_ram.map((datas) => {
          return {
            application: datas.application,
            total_to_be_ram: datas.total_to_be_ram,
          };
        });
        console.log(record);
        setRamCore(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const phaseData = assessphase;
  let startValue = 0;
  let endValue = 0;
  const chartData_phase = [];
  phaseData.forEach((phase) => {
    const resourceCount = parseInt(phase.resource_count);
    endValue += resourceCount;
    chartData_phase.push({
      x: phase.phase_level,
      y: [startValue, endValue],
      fill: "#A7EE7C",
    });
    startValue = endValue + 1;
  });
  const result = endValue;
  chartData_phase.push({
    x: "Total",
    y: [0, result],
    fill: "#999999",
    tooltip: {
      content: `Total: ${result}`,
    },
  });

  const datephas = chartData_phase.map((item) => item.x);

  console.log(datephas);

  const chartOptions = {
    chart: {
      type: "treemap",
    },
    series: [
      {
        data: [
          {
            x: "",
            y: 218,
          },
          {
            x: "",
            y: 149,
          },
          {
            x: "",
            y: 184,
          },
          {
            x: "",
            y: 55,
          },
          {
            x: "",
            y: 84,
          },
          {
            x: "",
            y: 31,
          },
          {
            x: "",
            y: 70,
          },
          {
            x: "",
            y: 30,
          },
          {
            x: "",
            y: 44,
          },
          {
            x: "",
            y: 68,
          },
          {
            x: "",
            y: 28,
          },
          {
            x: "",
            y: 19,
          },
          {
            x: "",
            y: 29,
          },
        ],
      },
    ],
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };
  const columns = [
    {
      title: "Instance Type",
      dataIndex: "vm_type",
      key: "vm_type",
      key: "vcpu",
      align: "left",
    },
    {
      title: "Storage Type",
      dataIndex: "vcpu",
      key: "vcpu",
      align: "right",
    },
    {
      title: "Egress %",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
    {
      title: "Region",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
    {
      title: "Environment ",
      dataIndex: "environment",
      key: "environment",
      align: "left",
    },
    {
      title: "Application ",
      dataIndex: "application",
      key: "application",
      align: "left",
    },
    {
      title: "Source Resource Name",
      dataIndex: "resource_name",
      key: "resource_name",
      align: "left",
    },
    {
      title: "As-IS Core",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
    {
      title: "To-Be Core",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
    {
      title: "As-IS RAM",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
    {
      title: "To-Be RAM",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
    {
      title: "As-IS Storage",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
    {
      title: "To-Be Storage",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
    },
  ];

  const dates = assessplan.map((item) => item.date);
  // const labels_db = dbspread.map((item) => item.database);
  console.log(dates);
  const plannedchartoptions = {
    chart: {
      type: "area",
      height: 350,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    xaxis: {
      categories: dates,
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    yaxis: {
      title: {
        text: "Resource Count",
      },
      labels: {
        style: {
          colors: "white",
        },
      },
    },
  };

  chartOptions.dataLabels = {
    enabled: false,
  };

  chartOptions.stroke = {
    width: 2,

    curve: "smooth",
  };
  const options_phase = {
    chart: {
      type: "rangeBar",
      height: 250,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    xaxis: {
      type: "category",
      categories: datephas,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    tooltip: {
      enabled: false,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    noData: {
      text: "No data ",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#FFF",
        fontSize: "20px",
      },
    },
  };

  const series_phase = [
    {
      name: "Range",
      data: chartData_phase,
      dataLabels: {
        enabled: true,
      },
    },
  ];

  const resourceCounts = assessplan.map((item) =>
    parseInt(item.resource_count)
  );

  const plannedchartData = [
    {
      name: "Resource Count",
      data: resourceCounts,

      fill: {
        type: "gradient",

        gradient: {
          shadeIntensity: 0.3,

          opacityFrom: 0.7,

          opacityTo: 0.9,

          stops: [0, 100],
        },
      },

      stroke: {
        curve: "smooth",

        color: "#FFFF",
      },
    },
  ];

  // to be core
  const chartOptionscore = {
    chart: {
      type: "treemap",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    series: [
      // {
      //   data: assesscore.map((item) => ({
      //     x: item.application,
      //     y: parseFloat(item.to_be_core),
      //   })),
      // },

      {
        data: cpucore.map((item) => ({
          x: item.application,
          y: parseFloat(item.total_to_be_cpu),
        })),
      },
    ],
  };

  const chartOptionsmemory = {
    chart: {
      type: "treemap",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    series: [
      {
        data: ramcore.map((item) => ({
          x: item.application,
          y: parseFloat(item.total_to_be_ram),
        })),
      },
    ],
  };

  const barSeries_core = [
    {
      name: "As Is Core",
      data: corecomparison.map((data) => data.total_as_is_core),
    },
    {
      name: "To Be core",
      data: corecomparison.map((data) => data.total_to_be_core),
    },
  ];
  const baroptions_core = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Core"],
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    yaxis: {
      title: {
        // text: "$ (thousands)",
        style: {
          color: "#FFFFFF",
        },
      },
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        // formatter: function (val) {
        //   return "$ " + val + " thousands";
        // },
      },
    },
    legend: {
      labels: {
        colors: ["#FFFFFF"],
      },
    },
  };
  baroptions_core.legend.labels = {
    colors: "#FFFFFF",
  };
  baroptions_core.xaxis.labels.style = {
    colors: "#FFFFFF",
  };

  //baroptions_ram
  const barSeries_ram = [
    {
      name: "As is RAM",
      data: ramcomparison.map((data) => data.total_as_is_ram),
    },
    {
      name: "To Be RAM",
      data: ramcomparison.map((data) => data.total_to_be_ram),
    },
  ];
  const baroptions_ram = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["RAM"],
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    yaxis: {
      title: {
        // text: "$ (thousands)",
        style: {
          color: "#FFFFFF",
        },
      },
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {},
    legend: {
      labels: {
        colors: ["#FFFFFF"],
      },
    },
  };

  //baroptions_storage

  const barSeries_storage = [
    {
      name: "As is Storage",
      data: storagecomparison.map((data) => data.total_as_is_storage),
    },
    {
      name: "To Be Storage",
      data: storagecomparison.map((data) => data.total_to_be_storage),
    },
  ];
  const baroptions_storage = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Storage"],
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    yaxis: {
      title: {
        // text: "$ (thousands)",
        style: {
          color: "#FFFFFF",
        },
      },
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {},
    legend: {
      labels: {
        colors: ["#FFFFFF"],
      },
    },
  };

  baroptions_core.legend.labels = {
    colors: "#FFFFFF",
  };
  baroptions_core.xaxis.labels.style = {
    colors: "#FFFFFF",
  };
  baroptions_ram.legend.labels = {
    colors: "#FFFFFF",
  };
  baroptions_ram.xaxis.labels.style = {
    colors: "#FFFFFF",
  };

  return (
    <div>
      <div>
        <div className="">
          <div className="">
            <RightSizingEditablleTable />
            {/* <Table
              className="slm-table"
              columns={columns}
              dataSource={tablerecord}
              loading={{ indicator: <Spin />, spinning: isShowSpinner }}
              pagination={{ pageSize: 10 }}
              scroll={{ x: 2000 }}
            ></Table> */}
          </div>
          <div className="mt-2">
            <Row gutter={[16, 16]}>
              <Col lg={8} xxl={8} xl={8} md={8} sm={24}>
                <Card
                  title="Core Comparison"
                  bordered={false}
                  className="operate-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={baroptions_core}
                      series={barSeries_core}
                      type="bar"
                      height={350}
                    />
                  )}
                </Card>
              </Col>
              <Col lg={8} xxl={8} xl={8} md={8} sm={24}>
                <Card
                  title="RAM Comparison"
                  bordered={false}
                  className="operate-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={baroptions_ram}
                      series={barSeries_ram}
                      type="bar"
                      height={350}
                    />
                  )}
                </Card>
              </Col>
              <Col lg={8} xxl={8} xl={8} md={8} sm={24}>
                <Card
                  title="Storage Comparison"
                  bordered={false}
                  className="operate-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={baroptions_storage}
                      series={barSeries_storage}
                      type="bar"
                      height={350}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mt-2">
            <Row gutter={[16, 16]}>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card
                  title=" To-Be Core"
                  bordered={false}
                  className="operate-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={chartOptionscore}
                      series={chartOptionscore.series}
                      className="piechart"
                      type="treemap"
                      height={715}
                    />
                  )}
                </Card>
              </Col>

              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card
                  title="To-Be RAM"
                  bordered={false}
                  className="operate-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={chartOptionsmemory}
                      series={chartOptionsmemory.series}
                      className="piechart"
                      type="treemap"
                      height={715}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightSizing;
