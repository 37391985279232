import { Icon } from "@iconify/react";
import React from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import Assets from "../assets_a2c/Assets";
import { Link } from "react-router-dom";
import { color } from "echarts";

function A2CSidebar() {
  return (
    <div>
      <div
        className="a2c-rover-sidebar"
        style={{ display: "flex", height: "100%", minHeight: "400px" }}
      >
        <Sidebar
          width="200px"
          backgroundColor="#0f0f0f"
          rootStyles={{
            borderRight: "1px solid rgba(255, 255, 255, 0.20)",
            ["." + menuClasses.button]: {
              background: "#0f0f0f",
              color: "white",
              "&:hover": {
                backgroundColor: "#202020",
              },
            },
            ["." + menuClasses.label]: {
              color: "white",
            },
            ["." + menuClasses.icon]: {
              color: "white",
            },
          }}
          collapsedWidth="200px"
          collapsed={false}
          defaultCollapsed={false}
        >
          <Menu>
            <SubMenu
              icon={<Icon icon={"uis:chart"} />}
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
                ["." + menuClasses.SubMenuExpandIcon]: {
                  color: "white",
                },
                ["." + menuClasses.icon]: {
                  color: "white",
                },
              }}
              label="A2C Rover"
            >
              <MenuItem component={<Link to={"/assess"} />}> Assess</MenuItem>
              <MenuItem component={<Link to={"/cost-analysis"} />}>
                {" "}
                Cost Analysis
              </MenuItem>
              <MenuItem component={<Link to={"/migrate"} />}> Migrate</MenuItem>
            </SubMenu>
            <MenuItem
              icon={<Icon icon={"uis:chart"} />}
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
                ["." + menuClasses.icon]: {
                  color: "white",
                },
              }}
              component={<Link to={"/devops-requestor"} />}
            >
              {" "}
              Devops Express
            </MenuItem>
            <SubMenu
              icon={<Icon icon={"uis:chart"} />}
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
                ["." + menuClasses.SubMenuExpandIcon]: {
                  color: "white",
                },
                ["." + menuClasses.icon]: {
                  color: "white",
                },
              }}
              label="OpsVista"
            >
              <MenuItem component={<Link to={"/operate"} />}> Operate</MenuItem>
              <MenuItem component={<Link to={"/slm"} />}> SLM</MenuItem>
              <MenuItem component={<Link to={"/peems"} />}>
                {" "}
                Event Express
              </MenuItem>
              <MenuItem component={<Link to={"/cams"} />}>
                {" "}
                Smart Alert
              </MenuItem>
              <MenuItem component={<Link to={"/wardashboard"} />}>WAR</MenuItem>
              {/* <MenuItem component={<Link to={"/warnew"} />}>
                {" "}
                War
              </MenuItem> */}
            </SubMenu>

            <MenuItem
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
              }}
              component={<Link to="/costdemaze/quick_glance" />}
            >
              {" "}
              CostDeMaze
            </MenuItem>
            <MenuItem
              rootStyles={{
                ["." + menuClasses.button]: {
                  background: "#0f0f0f",
                  color: "white",
                  "&:hover": {
                    background: "#0f0f0f",
                  },
                },
                ["." + menuClasses.label]: {
                  color: "white",
                },
              }}
            >
              {" "}
              <a
                style={{ color: "white" }}
                // href="https://secops.1cloudhub.com/app/wazuh#/agents-preview/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(_source),filters:!(('$state':(isImplicit:!t,store:appState),meta:(alias:!n,disabled:!f,index:'wazuh-alerts-*',key:cluster.name,negate:!f,params:(query:'1cloudhub'),removable:!f,type:phrase),query:(match:(cluster.name:(query:'1cloudhub',type:phrase))))),index:'wazuh-alerts-*',interval:auto,query:(language:kuery,query:''),sort:!())"
                href="https://secops.1cloudhub.com/app/wazuh"
                target="_blank"
              >
                SkySentinal
              </a>
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </div>
  );
}

export default A2CSidebar;
