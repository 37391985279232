import { memo, useState } from "react";
import { Form, Input, Button, Row, Col, Select, message } from "antd";
import { Icon } from "@iconify/react";
import RoleDetails from "./RoleDetails";
import RoleForm from "../role/RoleForm";
import { roleDropdown } from "../../action/accountAction";
import { customerUpdate, insertTempUserDetails } from "../../action/userAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userDetailsUpdateStatus,
  onboardActionMethod,
} from "../../slice/userSlice";
import { useNavigate } from "react-router-dom";

const OfferingInfo = (props) => {
  const [form] = Form.useForm();
  const [modelopen, setModelOpen] = useState(false);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [defaultoffering, setDefaultOffering] = useState();
  const [rolelist, setRoleList] = useState();
  const [selectedrole, setSelecteRole] = useState();
  const [updatestats, setUpdateStatus] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    profileInfo,
    master_info,
    companyInfo,
    onboardAction,
    updateuserdetails,
  } = useSelector((state) => state.user);

  useEffect(() => {
    const company_name = updateuserdetails.company_name;
    //offering
    if (updateuserdetails.Offerings) {
      const offering = JSON.parse(updateuserdetails.Offerings);
      setDefaultOffering(offering);
    }
    if (
      updateuserdetails.role &&
      updateuserdetails.role.toLowerCase() !== "user" &&
      updateuserdetails.role.toLowerCase() !== "admin" &&
      updateuserdetails.role.toLowerCase() !== "master"
    ) {
      setSelecteRole(JSON.parse(updateuserdetails.role));
    }
    // console.log(updatestats);
    dispatch(roleDropdown({ company_name }))
      .unwrap()
      .then(({ data }) => {
        // console.log("checking");
        // console.log(data);
        const records = JSON.parse(data.usermanagementroledropdown);
        // console.log(records);
        var roles = [];
        const role_type = localStorage.getItem(
          "CognitoIdentityServiceProvider.offering"
        );
        records.data.map((data) => {
          if (role_type.toLowerCase() === "master") {
            if (data.role_name.toLowerCase() !== "master") {
              roles.push({ label: data.role_name, value: data.role_id });
            }
          } else if (
            role_type.toLowerCase() === "1ch_admin" ||
            role_type.toLowerCase() === "admin"
          ) {
            if (
              data.role_name.toLowerCase() !== "master" &&
              data.role_name.toLowerCase() !== "1ch_admin" &&
              data.role_name.toLowerCase() !== "sales"
            ) {
              roles.push({ label: data.role_name, value: data.role_id });
            }
          }
        });
        setRoleList(roles);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [updatestats]);

  const handleSubmit = (values) => {
    var offering_list;
    var role_list;

    //offering
    if (values.offering) {
      offering_list = JSON.stringify(values.offering);
    } else if (updateuserdetails.Offerings != null) {
      offering_list = updateuserdetails.Offerings;
    } else {
      message.error("Please Select Offering");
      return;
    }
    //role
    if (values.role) {
      role_list = JSON.stringify(values.role);
    } else if (updateuserdetails.role != null) {
      role_list = updateuserdetails.role;
    } else {
      message.error("Please Select Role");
      return;
    }

    if (props.type === "create") {
      var id = Math.random().toString(16).slice(2);

      console.log({
        ...profileInfo,
        ...companyInfo,
        time_zone: "india",
        Offerings: offering_list,
        role: role_list,
        id: id,
      });
      const originalString = id;
      const encodedString = btoa(originalString);
      // const decodedString = atob(encodedString);
      // console.log(encodedString);
      // console.log(decodedString);
      const input = {
        ...profileInfo,
        ...companyInfo,
        time_zone: "india",
        Offerings: offering_list,
        role: role_list,
        id: id,
      };
      const fullURL = window.location.href;
      const splittedText = fullURL.split("/user-mng");
      const registerURL = splittedText[0] + "/signup/" + encodedString;

      dispatch(insertTempUserDetails(input))
        .unwrap()
        .then((value) => {
          props.setURLDetails({
            status: true,
            url: registerURL,
          });
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
        });
    } else {
      dispatch(
        customerUpdate({
          ...profileInfo,
          ...companyInfo,
          ...master_info,
          time_zone: "india",
          Offerings: offering_list,
          role: role_list,
          // status: updateuserdetails.status,
          status: "active",
          user_id: updateuserdetails.user_id,
        })
      )
        .unwrap()
        .then((value) => {
          dispatch(userDetailsUpdateStatus("user_updated"));
          dispatch(onboardActionMethod("create"));
          navigate("/user-mng");
          message.success("User Details Updated Successfully");
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
        });
    }
  };

  const handleRoleChange = (value, event) => {
    // console.log(value);
    // console.log(event);
    setSelecteRole(value);
  };
  return (
    <>
      {modelopen && (
        <RoleDetails
          modelopen={modelopen}
          setModelOpen={setModelOpen}
          selectedrole={selectedrole}
          company_name={updateuserdetails.company_name}
        />
      )}
      <RoleForm
        drawerstatus={drawerstatus}
        setDrawerStatus={setDrawerStatus}
        formType="add"
        company_list={[updateuserdetails.company_name]}
        updatestats={updatestats}
        setUpdateStatus={setUpdateStatus}
      />

      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={[16, 16]}>
          <Col
            xxl={24}
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className="d-flex justify-content-end"
          >
            <Button
              className="float-right yes-btn"
              htmlType="button"
              onClick={() => setDrawerStatus(true)}
            >
              Add New Role
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="offering"
              label="Offering"
              // rules={[{ required: true, message: "" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select Offering"
                options={[
                  { label: "CostDeMaze", value: "costdemaze" },
                  { label: "SLM", value: "slm" },
                  { label: "Event Express", value: "event_express" },
                  { label: "Smart Alert", value: "smart_alert" },
                  { label: "DevOps", value: "devops" },
                  { label: "ChatBot", value: "chatbot" },
                ]}
                defaultValue={
                  updateuserdetails.Offerings &&
                  JSON.parse(updateuserdetails.Offerings)
                }
                // defaultValue={[]}
              />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}></Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="role"
              label="Role"
              // rules={[{ required: true, message: "" }]}
              className="details-form-view"
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please Select Role"
                options={rolelist}
                onChange={(value, event) => handleRoleChange(value, event)}
                defaultValue={
                  updateuserdetails.role &&
                  updateuserdetails.role.toLowerCase() !== "user" &&
                  updateuserdetails.role.toLowerCase() !== "admin" &&
                  updateuserdetails.role.toLowerCase() !== "master" &&
                  JSON.parse(updateuserdetails.role)
                }
                // defaultValue={[]}
              />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Icon
              className={
                "mt-2 ml-4 cursor-pointer " +
                (props.type === "create"
                  ? "form-info-icon-user-create"
                  : "form-info-icon")
              }
              icon="fluent:info-24-filled"
              onClick={() => setModelOpen(true)}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item className="create-user-but-d-flex">
              <Button
                htmlType="button"
                onClick={() => props.setCurrent(props.current - 1)}
              >
                Previous
              </Button>
              <Button type="primary" htmlType="submit" className="yes-button">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default memo(OfferingInfo);
