import { memo, useState, useCallback, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown, Button } from "antd";
import { Icon } from "@iconify/react";
import SpendVSBudgetPopup from "./SpendVSBudgetPopup";
import { useSelector } from "react-redux";

const SpendVSBudget = (props) => {
  const [open, setOpen] = useState(false);
  const [monthlist, setMonthList] = useState(0);
  const [selectedBar, setSelectedBar] = useState(null);
  const [chartdetails, setChartDetails] = useState({
    label: [],
    spendvalue: [],
    budgetvalue: [0, 0, 0],
    monthlist: [],
  });
  const { selectedTopdate } = useSelector((state) => state.constimize);

  useEffect(() => {
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month_list = [];
    var chart_new_format = [];
    var month_lable_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${(
          "" + date.getFullYear()
        ).substr()}`
      );
      chart_new_format.push(props.spend[i]);
      month_lable_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }

    setChartDetails({
      label: month_list,
      spendvalue: chart_new_format,
      budgetvalue: props.budget,
      monthlist: month_lable_list,
    });
  }, [props, selectedTopdate]);

  const changeMonth = (month) => {
    setOpen(true);
    setMonthList(month);
  };

  const items = [
    {
      label: <a onClick={() => changeMonth(6)}>6 Months</a>,
      key: "1",
    },
    {
      label: <a onClick={() => changeMonth(12)}>12 Months</a>,
      key: "2",
    },
  ];

  const series = [
    {
      name: "Budget",
      type: "column",
      data: chartdetails.budgetvalue,
      // data: chartdetails.budgetvalue.map((value, index) => ({
      //   y: value,
      //   className: index === selectedBar ? "selected" : "",
      // })),
    },
    {
      name: "Spend",
      type: "line",
      data: chartdetails.spendvalue,
    },
  ];
  // console.log(chartdetails.label)
  const highlightLabelIndex = "Jun-23";

  const options = {
    chart: {
      height: 350,
      type: "line",
      events: {
        dataPointSelection: function (event, chartContext, config) {
          props.setSpendDate(chartdetails.monthlist[config.dataPointIndex]);
          props.setDayChartStatus(true);
        },
      },
    },
    stroke: {
      width: [0, 4],
      curve: "straight",
    },
    tooltip: {
      enabled: true,
      intersect: false,
      shared: true,
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: {
      show: true,
      style: {
        colors: chartdetails.label.map((label, index) =>
          index === highlightLabelIndex ? "#FF5733" : "#333333"
        ),
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "white", // Set x-axis labels color to red
        },
      },
    },
    labels: chartdetails.label,

    // colors: ["#fcbb29", "#82ccdc"],
    // colors: ["#4cc0c0", "#fe1a67"],
    colors: ["#A7EE7C", "#FF6A2F"],
    // colors: [baseColor, secondaryColor, baseLightColor],
    legend: {
      labels: {
        colors: "white",
      },
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 7,
      },
    },
    yaxis: [
      {
        title: {
          text: "Budget",
          style: {
            fontSize: "12px",
            fontWeight: 500,
            color: "white",
          },
        },
        labels: {
          style: {
            colors: "white",
          },
          formatter: (num) => {
            if (num === 0) {
              return "0";
            } else if (num === Infinity) {
              return "0";
            } else {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            }
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Spend",
          style: {
            fontSize: "12px",
            fontWeight: 500,
            color: "white",
          },
        },
        labels: {
          style: {
            colors: "white", // Set y-axis labels color to red
          },
          formatter: (num) => {
            if (num === 0) {
              return "0";
            } else if (num === Infinity) {
              return "0";
            } else {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            }
          },
        },
      },
    ],
  };
  // console.log(series)

  return (
    <div style={{ position: "relative" }}>
      {open && (
        <SpendVSBudgetPopup
          open={open}
          setOpen={setOpen}
          monthlist={monthlist}
          setMonthList={setMonthList}
        />
      )}

      <h1 className="spendbudget-chart-title">Budget vs Spend </h1>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={210}
      />
      {/* <div className="highlighted-bar">{selectedBar !== null && `Selected: ${selectedBar}`}</div> */}
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        {/* <a onClick={(e) => e.preventDefault()} className="chart-filter">
          <Icon icon="material-symbols:filter-alt-outline-sharp" />
        </a> */}
        <a
          onClick={(e) => e.preventDefault()}
          className="chart-filter2"
          // className="header-filter-btn"
        >
          <Button className="header-filter-btn p-2">
            {" "}
            <Icon icon="material-symbols:filter-alt-outline-sharp" />
          </Button>
        </a>
      </Dropdown>
    </div>
  );
};

export default memo(SpendVSBudget);
