import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { headercustomerlogo } from "../.././action/header_logo";
import {
  getApplicationMonthlyReport,
  getApplicationWeeklyReport,
  getEnvironmentMonthlyReport,
  getEnvironmentWeeklyReport,
  getServiceMonthlyReport,
  getServiceWeeklyReport,
  getWeeklyReportConsumptionSavingInitiativeSummary,
  weeklychartforreport,
  weeklyreportMonthchart,
  weeklyreportchart,
  weeklyreportenvweekwisechart,
  weeklyreportweekwisechart,
  weeklyreportweekwisechartsummarytopincrease,
} from "../../action/accountAction";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import ProductionTable from "./ProductionTable";
import { useNavigate } from "react-router";
function ReportInsights() {
  const [reportmonth, setReportmonth] = useState([]); //integrated
  const [selectedRange, setSelectedRange] = useState(3);

  const [reportweek, setReportweek] = useState([]); //Intergrated
  const { RangePicker } = DatePicker;
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [company_logo, setCompanyLogo] = useState();
  const [reportweekwise, setReportweekwise] = useState([]);
  const [reportweekwiseappsuminc, setReportweekwiseappsuminc] = useState([]); //Value is coming

  const dispatch = useDispatch();
  const date = "2023-11-01";
  // const start_date = "2023-11-05";
  // const end_date = "2023-11-11";
  const currentDate = new Date(selectedTopdate);
  const oneWeekLater = new Date(currentDate);
  let now = new Date();
  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate())
  );
  let end = moment(start).add(1, "days").subtract(1, "seconds");
  let ranges = {
    // Today: [moment(start), moment(end)],
    // Yesterday: [
    //   moment(start).subtract(1, "days"),
    //   moment(end).subtract(1, "days"),
    // ],
    "3 Days": [moment(start).subtract(3, "days"), moment(end)],
    "5 Days": [moment(start).subtract(5, "days"), moment(end)],
    "1 Week": [moment(start).subtract(7, "days"), moment(end)],
    "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
    "3 Weeks": [moment(start).subtract(21, "days"), moment(end)],
    "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    "3 Months": [moment(start).subtract(3, "months"), moment(end)],
    "6 Months": [moment(start).subtract(6, "months"), moment(end)],
  };

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(start).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment(end).format("YYYY-MM-DD")
  );
  const [consumptionData, setConsumptionData] = useState([]);
  const [serviceReporChart, setServiceReportChart] = useState([]);
  useEffect(() => {
    if (radioValue == 1) {
      dispatch(
        weeklychartforreport({
          company_name,
          selectedStartDate,
          selectedEndDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log("weekly report>>", data);
          const reportmonth =
            data.weekly_report_consumption_week_wise_chart.map((datas) => {
              return {
                credit_spend: datas.credit_spend,
                period: datas.period,
                total_spend: datas.total_spend,
                with_credit: datas.with_credit,
              };
            });
          // console.log(reportmonth)
          setReportmonth(reportmonth);
        })
        .catch((err) => {
          console.log(err);
        });
      // environment summary
      dispatch(
        getEnvironmentWeeklyReport({
          company_name,
          selectedStartDate,
          selectedEndDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          const reportmonth =
            data.weekly_report_consumption_environment_week_wise_chart.map(
              (datas) => {
                return {
                  credit_spend: datas.credit_spend,
                  period: datas.period,
                  total_spend: datas.total_spend,
                  with_credit: datas.with_credit,
                  environment: datas.environment,
                };
              }
            );
          setReportweekwise(reportmonth);
        })

        .catch((err) => {
          console.log(err);
        });
      // application summary
      dispatch(
        getApplicationWeeklyReport({
          company_name,
          selectedStartDate,
          selectedEndDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          const reportmonth =
            data.weekly_report_consumption_application_week_wise_chart.map(
              (datas) => {
                return {
                  credit_spend: datas.credit_spend,
                  period: datas.period,
                  total_spend: datas.total_spend,
                  with_credit: datas.with_credit,
                  application: datas.application,
                };
              }
            );
          setReportweekwiseappsuminc(reportmonth);
        })

        .catch((err) => {
          console.log(err);
        });
      // service summary

      dispatch(
        getServiceWeeklyReport({
          company_name,
          selectedStartDate,
          selectedEndDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          const reportmonth =
            data.weekly_report_consumption_service_week_wise_chart.map(
              (datas) => {
                return {
                  service: datas.service,
                  period: datas.period,
                  total_spend: datas.total_spend,
                };
              }
            );
          setServiceReportChart(reportmonth);
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(
        weeklyreportMonthchart({
          company_name,
          selectedStartDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log("weekly report>>", data);
          const reportmonth =
            data.weekly_report_consumption_month_wise_chart.map((datas) => {
              return {
                credit_spend: datas.credit_spend,
                period: datas.period,
                total_spend: datas.total_spend,
                with_credit: datas.with_credit,
              };
            });
          // console.log(reportmonth)
          setReportmonth(reportmonth);
        })
        .catch((err) => {
          console.log(err);
        });
      // environment summary
      dispatch(
        getEnvironmentMonthlyReport({
          company_name,
          selectedStartDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          const reportmonth =
            data.weekly_report_consumption_environment_month_wise_chart.map(
              (datas) => {
                return {
                  credit_spend: datas.credit_spend,
                  period: datas.period,
                  total_spend: datas.total_spend,
                  with_credit: datas.with_credit,
                  environment: datas.environment,
                };
              }
            );
          setReportweekwise(reportmonth);
        })
        .catch((err) => {
          console.log(err);
        });
      // application summary
      dispatch(
        getApplicationMonthlyReport({
          company_name,
          selectedStartDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          const reportmonth =
            data.weekly_report_consumption_application_month_wise_chart.map(
              (datas) => {
                return {
                  credit_spend: datas.credit_spend,
                  period: datas.period,
                  total_spend: datas.total_spend,
                  with_credit: datas.with_credit,
                  application: datas.application,
                };
              }
            );
          setReportweekwiseappsuminc(reportmonth);
        })
        .catch((err) => {
          console.log(err);
        });
      // service summary
      dispatch(
        getServiceMonthlyReport({
          company_name,
          selectedStartDate,
          selectedRange,
        })
      )
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          const reportmonth =
            data.weekly_report_consumption_service_month_wise_chart.map(
              (datas) => {
                return {
                  service: datas.service,
                  period: datas.period,
                  total_spend: datas.total_spend,
                };
              }
            );
          setServiceReportChart(reportmonth);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // savings initiative
    // dispatch(
    //   getWeeklyReportConsumptionSavingInitiativeSummary({
    //     company_name,
    //     selectedStartDate,
    //   })
    // )
    //   .unwrap()
    //   .then(({ data }) => {
    //     console.log(data.weekly_report_consumption_savings_initiative_summary);
    //     setConsumptionData(
    //       data.weekly_report_consumption_savings_initiative_summary
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    dispatch(
      getWeeklyReportConsumptionSavingInitiativeSummary({
        company_name,
        selectedStartDate,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data.weekly_report_consumption_savings_initiative_summary);
        setConsumptionData(
          data.weekly_report_consumption_savings_initiative_summary
        );
        let savings_initiative_data =
          data.weekly_report_consumption_savings_initiative_summary;
        setYAxisData({
          actualCost: savings_initiative_data.map((items) => items.actual_cost),
          creditCost: savings_initiative_data.map((items) =>
            items.credit_cost === "-" ? "0" : items.credit_cost
          ),
          realisedSavings: savings_initiative_data.map((items) =>
            items.realised_savings === "-" ? "0" : items.realised_savings
          ),
          projectedSpend: savings_initiative_data.map(
            (items) => items.projected_spend
          ),
        });
        setXAxisData(savings_initiative_data.map((items) => items.month));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedStartDate, selectedEndDate, selectedRange]);
  const categories_service = [
    ...new Set(serviceReporChart.map((item) => item.service)),
  ];
  const seriesData_service = serviceReporChart.map((item) =>
    parseFloat(item.total_spend)
  );
  const groupedData = serviceReporChart.reduce((result, item) => {
    const { period, service, total_spend } = item;
    if (!result[period]) {
      result[period] = {};
    }
    if (!result[period][service]) {
      result[period][service] = parseFloat(total_spend);
    }
    return result;
  }, {});
  const series_service = Object.keys(groupedData).map((period) => ({
    name: period,
    data: categories_service.map(
      (service) => groupedData[period][service] || 0
    ),
  }));

  const options_service = {
    chart: {
      id: "threeweekspend",
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#FFFFFF"],
      },
      offsetY: -20,
    },
    //   dataLabels: {
    //     enabled: false
    //   },
    xaxis: {
      categories: categories_service,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
      title: {
        text: "Services",
        style: {
          fontSize: "1rem",
          color: "#FFFFFF",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
      // categories: keys,
    },

    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#FFFFFF",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },

      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    legend: {
      show: false,
      labels: {
        colors: "#FFFFFF",
      },
    },

    colors: ["#FF6A2F", "#A7EE7C", "#40A2B8"],
  };
  let seriesData = [];
  let keys = [];
  const monthdata = reportweek[0];
  if (monthdata && Object.keys(monthdata).length > 0) {
    keys = Object.keys(reportweek[0]);
    seriesData = Object.values(monthdata).map((value) => parseFloat(value));
    console.log(keys);
    console.log(seriesData);
  } else {
    console.log("no data available");
  }
  const series_weeks = [
    {
      name: "Series 1",
      data: seriesData && seriesData.reverse(),
    },
  ];

  const categories = reportweekwise.map((item) => item.environment);

  const uniquePeriods = [...new Set(reportweekwise.map((item) => item.period))];
  const options_Grouped = {
    chart: {
      id: "groupedbar",
      type: "bar",
      stacked: true,
      height: 430,
    },

    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        stacked: true,
        dataLabels: {
          align: "top",
          enabled: true,
          formatter: function (val) {
            return val.toLocaleString(); // Use toLocaleString for comma-separated values
          },
          total: {
            enabled: true,
            align: "right",
            style: {
              color: "#FFF",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#FFFFFF"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
      title: {
        text: "Environment",
        style: {
          fontSize: "1rem",
          color: "#FFFFFF",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },

    legend: {
      show: false,
      position: "bottom",
      labels: {
        colors: "#FFFFFF",
      },
    },
    title: {
      text: "",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },

    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#FFFFFF",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    colors: ["#FF6A2F", "#A7EE7C", "#40A2B8"],
  };
  const series_Grouped = [
    {
      name: "Total Spend",
      data: reportweekwise.map((item) => parseFloat(item.total_spend)),
    },
    {
      name: "Credit Spend",
      data: reportweekwise.map((item) => parseFloat(item.credit_spend)),
    },
    {
      name: "With Credit",
      data: reportweekwise.map((item) => parseFloat(item.with_credit)),
    },
  ];

  const categories_App = reportweekwiseappsuminc.map(
    (item) => item.application
  );
  const uniquePeriods_app = [
    ...new Set(reportweekwiseappsuminc.map((item) => item.period)),
  ];
  const options_WeeklySpend = {
    chart: {
      id: "weeklyspendapp",
      type: "bar",
      height: 250,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#FFFFFF"],
      },
      offsetY: -20,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: categories_App,
      title: {
        text: "Applications",
        style: {
          fontSize: "1rem",
          color: "#FFFFFF",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },

    legend: {
      show: false,
      position: "top",
      labels: {
        colors: "#FFFFFF",
      },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#FFFFFF",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    colors: ["#FF6A2F", "#A7EE7C", "#40A2B8"],
  };

  const series_WeeklySpend = [
    {
      name: "Total Spend",
      data: reportweekwiseappsuminc.map((item) => parseFloat(item.total_spend)),
    },
    {
      name: "Credit Spend",
      data: reportweekwiseappsuminc.map((item) =>
        parseFloat(item.credit_spend)
      ),
    },
    {
      name: "With Credit",
      data: reportweekwiseappsuminc.map((item) => parseFloat(item.with_credit)),
    },
  ];
  console.log(reportmonth);
  const xAxixForConsumption = reportmonth.map((item) => item.period);
  console.log("xaxis", xAxixForConsumption);

  const options = {
    chart: {
      id: "threemonthspend",
      type: "bar",
      height: 350,
      stacked: true,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        stacked: true,
        dataLabels: {
          align: "top",
          enabled: true,
          formatter: function (val) {
            return val.toLocaleString(); // Use toLocaleString for comma-separated values
          },
          total: {
            enabled: true,
            align: "right",
            style: {
              color: "#FFF",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: "12px",
        colors: ["#FFFFFF"],
      },
      formatter: function (val) {
        console.log(val);
        return val && val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },

    xaxis: {
      categories: xAxixForConsumption.reverse(),
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#FFFFFF",
      },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#FFFFFF",
          width: "1rem",
          fontWeight: "500",
        },
      },
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#FFFFFF",
      },
    },

    colors: ["#FF6A2F", "#A7EE7C", "#40A2B8"],
  };
  const with_credit = reportmonth.map((item) => parseFloat(item.with_credit));
  const credtSpend = reportmonth.map((item) =>
    Math.abs(parseFloat(item.credit_spend))
  );
  const series = [
    {
      name: "Spend after Credit",
      data: with_credit.reverse(),
    },
    {
      name: "Credits Spend",
      data: credtSpend.reverse(),
    },
  ];
  const [headingTitle, setHeadingTitle] = useState("3 Weeks");

  const [isShowWeekDropdown, setIsShowWeekDropdown] = useState(true);
  const [radioValue, setRadioValue] = useState(1);
  const onRadioChange = (e) => {
    console.log(e.target.value);
    let val = e.target.value;
    setRadioValue(val);
    if (val === 1) {
      setIsShowWeekDropdown(true);
    } else {
      setIsShowWeekDropdown(false);
    }
  };
  const selectOptionsWeek = [
    {
      label: "Current Week",
      value: "1",
    },
    {
      label: "Last 2 Weeks",
      value: "2",
    },
    {
      label: "Last 3 Weeks",
      value: "3",
    },
    {
      label: "Last 4 Weeks",
      value: "4",
    },
  ];
  const selectOptionsMonth = [
    {
      label: "Current Month",
      value: "1",
    },
    {
      label: "Last 3 Months",
      value: "3",
    },
    {
      label: "Last 6 Months",
      value: "6",
    },
  ];
  // const columns = [
  //   {
  //     title: "Month",
  //     dataIndex: "month",
  //     key: "key",
  //     align: "left",
  //   },
  //   {
  //     title: "Actual Cost",
  //     dataIndex: "actual_cost",
  //     key: "actual_cost",
  //     align: "right",
  //   },
  //   {
  //     title: "Credit Cost",
  //     dataIndex: "credit_cost",
  //     key: "credit_cost",
  //     align: "right",
  //   },
  //   {
  //     title: "Realised Savings",
  //     dataIndex: "realised_savings",
  //     key: "realised_savings",
  //     align: "right",
  //   },
  //   {
  //     title: "Projected Spend",
  //     dataIndex: "projected_spend",
  //     key: "projected_spend",
  //     align: "right",
  //   },
  // ];
  const monthsData = {};

  // Grouping data by months
  consumptionData.forEach((item) => {
    const {
      month,
      actual_cost,
      credit_cost,
      realised_savings,
      projected_spend,
    } = item;
    const [m, y] = month.split(" ");
    if (!monthsData[m]) monthsData[m] = {};
    monthsData[m][y] = {
      actual_cost: actual_cost !== "-" ? actual_cost : "-",
      credit_cost: credit_cost !== "-" ? credit_cost : "-",
      realised_savings: realised_savings !== "-" ? realised_savings : "-",
      projected_spend: projected_spend !== "-" ? projected_spend : "-",
    };
  });
  const months = Object.keys(monthsData).sort((a, b) => {
    const monthsOrder = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    return monthsOrder[a] - monthsOrder[b];
  });
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "left",
    },
    ...months.map((month) => ({
      title: month,
      dataIndex: month,
      key: month,
      render: (_, record) => record[month],
    })),
  ];
  const formattedData = [
    {
      category: "Actual Cost",
      ...months.reduce((acc, month) => {
        acc[month] = monthsData[month]
          ? monthsData[month]["2023"].actual_cost
          : "";
        return acc;
      }, {}),
    },
    {
      category: "Credit Cost",
      ...months.reduce((acc, month) => {
        acc[month] = monthsData[month]
          ? monthsData[month]["2023"].credit_cost
          : "";
        return acc;
      }, {}),
    },
    {
      category: "Projected Spend",
      ...months.reduce((acc, month) => {
        acc[month] = monthsData[month]
          ? monthsData[month]["2023"].projected_spend
          : "";
        return acc;
      }, {}),
    },
    {
      category: "Realised Savings ",
      ...months.reduce((acc, month) => {
        acc[month] = monthsData[month]
          ? monthsData[month]["2023"].realised_savings
          : "";
        return acc;
      }, {}),
    },
  ];
  const handleWeekChange = (e) => {
    console.log(e);
    setSelectedRange(e);
    if (e == 1) {
      setHeadingTitle("1 Week");
      setSelectedStartDate(
        moment(start).subtract(7, "days").format("YYYY-MM-DD")
      );
    } else if (e == 2) {
      setHeadingTitle("2 Weeks");
    } else if (e == 3) {
      setHeadingTitle("3 Weeks");
    } else if (e == 4) {
      setHeadingTitle("4 Weeks");
    }
  };
  const handleMonthChange = (e) => {
    setSelectedRange(e);
    if (e == 1) {
      setHeadingTitle("1 Month");
      setSelectedStartDate(moment(start).startOf("month").format("YYYY-MM-DD"));
    } else if (e == 3) {
      setHeadingTitle("3 Months");
    } else if (e == 6) {
      setHeadingTitle("6 Months");
    }
  };
  const navigate = useNavigate();
  const [yAxisData, setYAxisData] = useState({
    actualCost: [],
    creditCost: [],
    realisedSavings: [],
    projectedSpend: [],
  });
  const [xAxisData, setXAxisData] = useState([]);

  const areaoptions = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xAxisData,
      labels: {
        show: true,
        rotate: -70,
        trim: true,
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    legend: {
      labels: {
        colors: "#FFFFFF",
      },
    },
  };

  const areaseries = [
    {
      name: "Actual Cost",
      data: yAxisData.actualCost,
      type: "area",
    },
    {
      name: "Credit Cost",
      data: yAxisData.creditCost,
      type: "area",
    },
    {
      name: "Realised Savings",
      data: yAxisData.realisedSavings,
      type: "area",
    },
    {
      name: "Projected Spend",
      data: yAxisData.projectedSpend,
      type: "area",
    },
  ];
  return (
    <div>
      <div
        style={{ display: "flex", height: "100%" }}
        className="costimize_bgs"
      >
        <CostimizeSidebar />
        <div className="">
          {/* <MainHead title="Quick Glance" /> */}
          <div className="header-filter ">
            <div className="costimize-header-body">
              <Row className="mt-2">
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  className={"header-title-a2c-main"}
                >
                  Report
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                  <div className="header-logo-block">
                    {company_logo && (
                      <img
                        className="costimize_header_logo"
                        src={company_logo}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="costimize-body-screen report-screen-width padding-right">
            <div className="filter  ">
              <Form>
                {/* <Form.Item> */}
                <div className="">
                  <Row>
                    <Col xl={4} md={4} lg={4}>
                      <Radio.Group
                        className=""
                        onChange={onRadioChange}
                        value={radioValue}
                      >
                        <Radio value={1} className="me-5">
                          Week
                        </Radio>
                        <Radio value={2}> Month</Radio>
                      </Radio.Group>
                    </Col>
                    <Col xl={8} md={8} lg={8}>
                      {isShowWeekDropdown ? (
                        <Select
                          className="w-75"
                          defaultValue={{
                            label: "Last 3 Weeks",
                            value: "3",
                          }}
                          placeholder="Select Weeks"
                          options={selectOptionsWeek}
                          onChange={handleWeekChange}
                        />
                      ) : (
                        <Select
                          className="w-75"
                          placeholder="Select Months"
                          options={selectOptionsMonth}
                          onChange={handleMonthChange}
                        />
                      )}
                    </Col>
                    <Col xl={12} md={12} lg={12}>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="yes-button"
                          onClick={() => {
                            navigate("/costdemaze/report/pdf");
                          }}
                        >
                          Generate PDF
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* </Form.Item> */}
              </Form>
            </div>
            <div className="mt-3">
              <Row gutter={[16, 16]} className="">
                <Col lg={24} xxl={24} xl={24} md={24}>
                  <Card
                    title={headingTitle + " Spend ($)"}
                    className="operate-cards"
                  >
                    <ReactApexChart
                      options={options}
                      series={series}
                      height={400}
                      type="bar"
                    />
                  </Card>
                </Col>
              </Row>

              <h5 className=" color-orange p-0 pt-3">
                Environment Level Weekly Spend Summary
              </h5>
              <Row gutter={[16, 16]} className="">
                <Col span={24}>
                  <Card
                    title={headingTitle + " Spend by Environment ($)"}
                    className="operate-cards"
                  >
                    <div className="d-flex justify-content-center">
                      {uniquePeriods.reverse().map((period) => (
                        <span className="text-center">
                          <p className="number-title">{period}&nbsp;/&nbsp;</p>
                        </span>
                      ))}
                    </div>
                    <ReactApexChart
                      options={options_Grouped}
                      series={series_Grouped}
                      height={400}
                      type="bar"
                    />
                  </Card>
                </Col>
              </Row>
              <Row className="">
                <Col lg={24} xxl={24} xl={24} md={24}>
                  {/* <ProductionTable
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                  /> */}
                </Col>
              </Row>
              <h5 className=" color-orange p-0 pt-3">
                Applications Level Weekly Spend Summary
              </h5>
              <Row className="">
                <Col lg={24} xxl={24} xl={24} md={24}>
                  <Card
                    title={headingTitle + " Spend by Top 5 Application ($)"}
                    className="operate-cards"
                  >
                    <div className="d-flex justify-content-center">
                      {uniquePeriods_app.reverse().map((period) => (
                        <span className="text-center">
                          <p className="number-title">{period}&nbsp;/&nbsp;</p>
                        </span>
                      ))}
                    </div>
                    <ReactApexChart
                      options={options_WeeklySpend}
                      series={series_WeeklySpend}
                      height={400}
                      type="bar"
                    />
                  </Card>
                </Col>
              </Row>
              <h5 className=" color-orange p-0 pt-3">
                Services Level Weekly Spend Summary
              </h5>
              <Row className="">
                <Col lg={24} xxl={24} xl={24} md={24}>
                  <Card
                    title={headingTitle + " Spend by Top 5 Service ($)"}
                    className="operate-cards"
                  >
                    <ReactApexChart
                      options={options_service}
                      series={series_service}
                      height={400}
                      type="bar"
                    />
                  </Card>
                </Col>
              </Row>
              <div>
                <h5 className=" color-orange p-0 pt-3">Savings Initiative</h5>
                <Row className="">
                  <Col lg={24} xxl={24} xl={24} md={24}>
                    {/* <Table
                      className="slm-table"
                      columns={columns}
                      dataSource={formattedData}
                      pagination={false}
                    /> */}
                    <Card className="operate-cards">
                      <ReactApexChart
                        options={areaoptions}
                        series={areaseries}
                        type="area"
                        height={400}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportInsights;
