import React from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColumns: {
    width: "11.11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "11.11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  tableColleft: {
    width: "11.11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCellright: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 8,
    marginRight: 3,
  },
  tableCellleft: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  tableColright: {
    width: "11.11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
  },

  //   table: {
  //     display: "table",
  //     width: "auto",
  //     borderStyle: "solid",
  //     borderWidth: 1,
  //     borderRightWidth: 0,
  //     borderBottomWidth: 0,
  //     borderColor: "#eee",
  //     // height: "100%",
  //   },
  //   tableRow: {
  //     margin: "auto",
  //     flexDirection: "row",
  //   },
  //   tableCol: {
  //     width: "14%",
  //     borderStyle: "solid",
  //     borderWidth: 1,
  //     borderLeftWidth: 0,
  //     borderTopWidth: 0,
  //     borderColor: "#eee",
  //   },
  //   tableColumns: {
  //     width: "14%",
  //     borderStyle: "solid",
  //     borderWidth: 1,
  //     borderLeftWidth: 0,
  //     borderTopWidth: 0,
  //     borderColor: "#eee",
  //     backgroundColor: "#eee",
  //   },
  //   tableCell: {
  //     margin: "auto",
  //     marginTop: 5,
  //     fontSize: 10,
  //     padding: 5,
  //     paddingTop: 25,
  //     paddingBottom: 25,
  //   },
  //   tableCell1: {
  //     margin: "auto",
  //     marginTop: 5,
  //     fontSize: 10,
  //     padding: 5,
  //     paddingTop: 25,
  //     paddingBottom: 25,
  //   },
});
function ApplicationSummary({ appdetail }) {
  console.log(appdetail);
  const environments = [...new Set(appdetail.map((item) => item.environment))];
  console.log(environments);
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      {environments.map((environment, index) => (
        <View style={styles.section}>
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <Text style={{ color: "#FF6A2F", fontSize: 12 }}>
              {" "}
              Environment: {environment}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Application</Text>
              </View>
              {/* <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Environment</Text>
          </View> */}
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>VM Count</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>CPU</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Memory</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Storage</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>22/10-28/10</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>29/10-04/11</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>05/11-11/11</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>
                  Comparison of last two weeks
                </Text>
              </View>
            </View>

            {appdetail
              .filter((item) => item.environment === environment)
              .map((item, dataIndex) => (
                <View key={dataIndex} style={styles.tableRow}>
                  <View style={styles.tableColleft}>
                    <Text style={styles.tableCellleft}>{item.application}</Text>
                  </View>

                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>{item.vm_count}</Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>{item.cpu}</Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>{item.memory}</Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>{item.storage}</Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      ${formatNumberWithCommas(item.pre_previous_week)}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      ${formatNumberWithCommas(item.previous_week)}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      ${formatNumberWithCommas(item.current_week)}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      {item.difference >= 0
                        ? `$${formatNumberWithCommas(item.difference)}`
                        : `-$${formatNumberWithCommas(
                            item.difference.toString().slice(1)
                          )}`}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
      ))}
    </div>
  );
}

export default ApplicationSummary;
