import React,{useState} from "react";
import { Row, Col, Card, Table, Form } from "antd";
import SecurityChart from "./SecurityChart";
import SecurityTable from "./SecurityTable";

const Security = () => {
  const [counting,setcounting]=useState();
  console.log(counting)
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
          <Card className="slmcard">
           
            <SecurityChart counting={counting} /> 
          
          </Card>
        </Col>
        <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
          <SecurityTable counting={counting} setcounting={setcounting}/>
        </Col>
      </Row>
    </div>
  );
}

export default Security;
