import { JoinFull } from "@mui/icons-material";
import { Table } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CgDetailsMore } from "react-icons/cg";
import { Icon } from "@iconify/react";

const AnnualBudgetTable = (props) => {
  const columns = [
    {
      title: "Year",
      dataIndex: "budget_year",
      key: "budget_year",
      align: "right",
    },
    {
      title: "Jan",
      dataIndex: "budget_jan",
      key: "budget_jan",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Feb",
      dataIndex: "budget_feb",
      key: "budget_feb",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Mar",
      dataIndex: "budget_mar",
      key: "budget_mar",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Apr",
      dataIndex: "budget_apr",
      key: "budget_apr",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "May",
      dataIndex: "budget_may",
      key: "budget_may",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Jun",
      dataIndex: "budget_jun",
      key: "budget_jun",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Jul",
      dataIndex: "budget_jul",
      key: "budget_jul",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Aug",
      dataIndex: "budget_aug",
      key: "budget_aug",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Sep",
      dataIndex: "budget_sept",
      key: "budget_sept",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Oct",
      dataIndex: "budget_oct",
      key: "budget_oct",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Nov",
      dataIndex: "budget_nov",
      key: "budget_nov",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Dec",
      dataIndex: "budget_dec",
      key: "budget_dec",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Annual Total",
      dataIndex: "annual_total",
      key: "annual_total",
      align: "right",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        // if(index.type === 'data'){
        if (props.rolestatus && props.permission.budgetwrite) {
          return (
            <>
              <Icon
                icon="material-symbols:edit"
                onClick={() => props.editYearDetails(index)}
              />
            </>
          );
        } else {
          return <></>;
        }

        // }
      },
    },
  ];

  const tableProps = {};

  return (
    <>
      {props.rolestatus && props.permission.budgetwrite ? (
        <>
          <button
            className="table-add-button float-right"
            onClick={() => props.addYearDetails()}
          >
            {/* <Icon icon="ion:add-circle-outline" /> */}
            <Icon icon="material-symbols:add-circle-outline"></Icon>
            Add
          </button>
        </>
      ) : (
        ""
      )}

      <div className="costimize-table-width">
        <div className="annualBudgetTable">
          <Table
            {...tableProps}
            // className="budget-table"
            className="slm"
            columns={columns}
            dataSource={props.yearlistrecords}
            pagination={false}
            // rowClassName={(record, index) => {
            //     console.log(record);
            //     console.log(index);
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default AnnualBudgetTable;
