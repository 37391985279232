import React, { useState, useEffect } from "react";
import { Space, Table, Tag, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { weeklyreportenvweekwisechartsummary } from "../../action/accountAction";
import { Icon } from "@iconify/react";
import { Fragment } from "react";
import EnvironmentDeatiledreport from "./EnvironmentDeatiledreport";
function ProductionTable(props) {
  console.log(props, "stdate,enddate");
  const start_date = props.selectedDate;
  const end_date = props.endDate;
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  console.log(company_name, "company");
  const [reportweekwisesum, setReportweekwisesum] = useState([]);
  const [isShowSpinner, setShowSpinner] = useState(true);
  const [detailed, setDetailed] = useState(false);
  const input = {
    company_name: company_name,
    start_date: start_date,
    end_date: end_date,
  };
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(startDate.getTime() - oneWeek);
  const prevWeekEndDate = new Date(endDate.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(startDate.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(endDate.getTime() - 2 * oneWeek);

  const ThreeweeksBeforeStartDate = new Date(startDate.getTime() - 3 * oneWeek);
  const ThreeweekbeforeEndDate = new Date(endDate.getTime() - 3 * oneWeek);
  // Format the dates to strings in the desired format (DD/MM - DD/MM)
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };

  const prevthreeweekRange = `${formatDate(
    ThreeweeksBeforeStartDate
  )} - ${formatDate(ThreeweekbeforeEndDate)}`;

  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(startDate)}-${formatDate(endDate)}`;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(weeklyreportenvweekwisechartsummary(input))
      .unwrap()
      .then(({ data }) => {
        console.log("week wise summary>>>", data);
        setShowSpinner(false);
        const reportweeksum = data.weekly_report_env_week_wise_summary.map(
          (datas) => {
            return {
              current_week: datas.current_week,
              previous_week: datas.previous_week,
              pre_previous_week: datas.pre_previous_week,
              environment: datas.environment,
              no_of_application: datas.no_of_application,
              vm_count: datas.vm_count,
              cpu: datas.cpu,
              memory: datas.memory,
              storage: datas.storage,
              difference: datas.difference,
            };
          }
        );
        console.log(reportweeksum);
        setReportweekwisesum(reportweeksum);
      })
      .catch((err) => {
        console.log(err);
        message.info("no data has been found");
        setShowSpinner(false);
      });
  }, [start_date, end_date]);

  const totals = {
    environment: "Total",
    no_of_application: 0,
    vm_count: 0,
    cpu: 0,
    memory: 0,
    storage: 0,
    pre_previous_week: 0,
    previous_week: 0,
    current_week: 0,
    difference: 0,
  };

  reportweekwisesum.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (key !== "environment") {
        totals[key] += parseFloat(row[key] || 0); // Parse values to floats and sum them
      }
    });
  });
  let reverseOrder = reportweekwisesum.reverse();
  const dataWithTotals = [...reverseOrder, totals];
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // const goToEnviSection = () => {
  //   const enviSection = document.getElementById("envi");
  //   if (enviSection) {
  //     enviSection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "key",
      align: "left",
      render: (text) => (
        <a style={{ color: "#000", cursor: "pointer" }} href="#envi">
          {text}
        </a>
      ),
    },
    {
      title: "Application",
      dataIndex: "no_of_application",
      key: "no_of_application",
      align: "right",
    },
    {
      title: "VM Count",
      dataIndex: "vm_count",
      key: "vm_count",
      align: "right",
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      key: "cpu",
      align: "right",
    },
    {
      title: "Memory (GiB)",
      dataIndex: "memory",
      key: "memory",
      align: "right",
    },
    {
      title: "Storage (GiB)",
      dataIndex: "storage",
      key: "storage",
      align: "right",
    },
    {
      title: prevthreeweekRange,
      dataIndex: "",
      key: "",
      align: "right",
      render: (text) => <span>${formatNumberWithCommas(text)}</span>,
    },

    {
      title: prePrevWeekRange,
      dataIndex: "pre_previous_week",
      key: "pre_previous_week",
      align: "right",
      render: (text) => <span>${formatNumberWithCommas(text)}</span>,
    },
    {
      title: prevWeekRange,
      dataIndex: "previous_week",
      key: "previous_week",
      align: "right",
      render: (text) => <span>${formatNumberWithCommas(text)}</span>,
    },
    {
      title: currentWeekRange,
      dataIndex: "current_week",
      key: "current_week",
      align: "right",
      render: (text) => <span>${formatNumberWithCommas(text)}</span>,
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
      render: (text, record) => (
        <span>
          {record.difference >= 0 ? (
            <>
              ${record.difference}
              <Icon icon="mdi:arrow-drop-up" color="red" />
            </>
          ) : (
            <>
              -${record.difference.toString().slice(1)}
              <Icon icon="mdi:arrow-down-drop" color="green" />
            </>
          )}
        </span>
      ),
      align: "right",
    },
  ];
  const data = [];
  return (
    <div>
      {isShowSpinner ? (
        <div className=" d-flex justify-content-center align-items-center spinner-height">
          <Spin />
        </div>
      ) : (
        <Table
          className="weeklytable"
          columns={columns}
          // dataSource={reportweekwisesum}
          // style={{ height: "10vh" }}
          dataSource={dataWithTotals}
          pagination={false}
          rowClassName={(record) =>
            record.environment === "Total" ? "total-row" : ""
          }
        />
      )}
    </div>
  );
}

export default ProductionTable;
