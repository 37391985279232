import { Button, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ConfigHeaderPrefix } from "../../../../assets/icons/configuration_header_prefix.svg";
import {
  getDropDownValues,
  getOSOffer,
  getOSSKU,
  listRequestTable,
  listRequestTableforAll,
  saveUpdateConfigureForm,
} from "../../../action/graphql/devops/DevOpsAction";
import {
  getApprovalTableData,
  setConfigurationFormData,
  setbackButtonClickAction,
} from "../../../slice/devopsSlice";
import { loginUser } from "../../../action/userAction";

function ConfigureDetails(props) {
  const navigateFn = useNavigate();
  const [isShowAWS, setIsShowAWS] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const devopsStore = useSelector((store) => store.devops);
  const { configuration } = devopsStore;

  const [accountDetailsInfo, setAccountDetailsInfo] = useState(
    devopsStore.saveBasicDetailsData.accountInfo
  );
  const [basicDetailsInfo, setBasicDetailInfo] = useState(
    devopsStore.saveBasicDetailsData.basicDetailsInfo
  );
  const [accountConfig, setAccountConfig] = useState(
    devopsStore.configuration_records
  );
  // console.log(devopsStore.configuration_records);
  let parsedValue = JSON.parse(accountConfig);
  const [response, setResponse] = useState(parsedValue);
  const [ishowSpinner, setIsShowSpinner] = useState(false);
  const [formFieldDetails, setFormFieldDetails] = useState([
    {
      header: "",
      field_names: [
        {
          name: "",
          field_type: "",
          showDropDown: false,
          cloud_platform: "",
          field_value_string: "",
          optionsList: [],
          osOffer: [],
        },
      ],
    },
  ]);

  const [created_by_user, setCreatedByUser] = useState("");
  const getCreatedByUser = () => {
    // to get createdby user
    let user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
    let token = localStorage.getItem("_token");
    let created_user;
    const records = {
      user_id: user_id,
      _token: token,
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        console.log({ data });
        created_user = data.getUsers.email_id;
        setCreatedByUser(created_user);
      });
  };
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    getFormFields(response);
    getCreatedByUser();
  }, []);
  const [dropDownAPIData, setdropDownAPIData] = useState([]);

  const getFormFields = (response) => {
    var tempArray = [];
    var formFieldType = "";
    if (response) {
      console.log(response);
      Object.entries(JSON.parse(response)).map((key, index) => {
        let obj_names = {};
        obj_names["header"] = key[0];
        key[1].map((item1) => {
          let temparr_one = [];
          Object.values(item1).map((itm2, _index) => {
            var label_name = {};
            // if (Array.isArray(itm2)) {
            //   console.log("inside", itm2);

            // Object.values(itm2).map((val, index) => {
            //   console.log(val);
            //   label_name.name = val.label_name;
            //   label_name.field_type = val.field_value_type;
            //   label_name.cloud_platform =
            //     devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;
            // });
            // }

            label_name.name = itm2.label_name;
            label_name.field_type = itm2.field_value_type;
            label_name.cloud_platform =
              devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;
            console.log(itm2);
            //to get dropdown
            let str = itm2.field_value_type.split(/([^@!]+)/g);
            label_name.field_value_string = str[1];
            if (str[0] === "@") {
              label_name.showDropDown = true;
              let fieldType = str[1];
              let cloud_platform =
                devopsStore.saveBasicDetailsData.basicDetailsInfo
                  .cloud_platform;

              dispatch(getDropDownValues({ cloud_platform, fieldType }))
                .unwrap()
                .then(({ data }) => {
                  let tempArray = [];
                  let arryJson = [];
                  setdropDownAPIData(data);
                  if (data.devops_config_mapping.length > 0) {
                    arryJson = JSON.parse(
                      data.devops_config_mapping[0].value_list
                    );
                    arryJson = arryJson.slice(1, -1).split('","');
                    arryJson = arryJson.map((region, index) => ({
                      label: region,
                      value: region,
                    }));
                  }

                  label_name.optionsList = arryJson;
                });
            } else {
              label_name.showDropDown = false;
              label_name.optionsList = [];
              formFieldType = "showinput";
            }
            temparr_one.push(label_name);
          });
          obj_names["field_names"] = temparr_one;
        });

        tempArray.push(obj_names);

        setFormFieldDetails(tempArray);
        setIsShowSpinner(false);
      });
    }

    return true;
  };

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [isShowButtonLoader, setIsShowButtonLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const userRole = localStorage.getItem(
    "CognitoIdentityServiceProvider.offering"
  );

  const getPricingDetails = (
    basicDetailsInfo,
    accountDetailsInfo,
    values,
    accountConfig,
    jobName
  ) => {
    dispatch(
      saveUpdateConfigureForm({
        basicDetailsInfo,
        accountDetailsInfo,
        values,
        accountConfig,
        jobName,
      })
    )
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.devops_workflow);
        values.id = response.id;
        dispatch(setConfigurationFormData(values));
        setIsShowButtonLoader(false);
        dispatch(setbackButtonClickAction(false));
        props.setCurrent(props.current + 1);
      });
  };
  const handleSubmit = (values) => {
    setIsShowButtonLoader(true);
    values.created_by = created_by_user;
    values.status = "Draft";

    const jobName = devopsStore.jobName;

    if (devopsStore.requestAction === "addNew") {
      console.log("if");
      if (devopsStore.isFormBackButtonClicked) {
        values.event_type = "update-draft";
        values.id = devopsStore.saveBasicDetailsData.configDetailsInfo.id;
      } else {
        values.event_type = "insert-draft";
        values.id = "";
      }
    } else {
      values.event_type = "update-draft";
      console.log("else");
      if (devopsStore.requestorTableData.id) {
        values.id = devopsStore.requestorTableData.id;
      } else {
        values.id = devopsStore.saveBasicDetailsData.configDetailsInfo.id;
      }
    }
    // values.event_type = "get_pricing";
    values.created_by = created_by_user;
    values.status = "Draft";
    dispatch(
      saveUpdateConfigureForm({
        basicDetailsInfo,
        accountDetailsInfo,
        values,
        accountConfig,
        jobName,
      })
    )
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.devops_workflow);
        values.id = response.id;
        dispatch(setConfigurationFormData(values));
        setIsShowButtonLoader(false);
        dispatch(setbackButtonClickAction(false));
        props.setCurrent(props.current + 1);
      });
    values.event_type = "get_pricing";
    console.log("actionType", devopsStore.requestAction);
    console.log("isback button clicked?", devopsStore.isFormBackButtonClicked);
    console.log(
      "config details id",
      devopsStore.saveBasicDetailsData.configDetailsInfo.id
    );
    console.log(
      "config details id",
      devopsStore.saveBasicDetailsData.configDetailsInfo.id
    );
  };

  useEffect(() => {
    if (devopsStore.requestAction === "addNew") {
      if (
        devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform === ""
      ) {
        form.resetFields();
      } else {
        form.setFieldsValue(devopsStore.saveBasicDetailsData.configDetailsInfo);
      }
    } else if (devopsStore.requestAction === "edit") {
      try {
        let config_string = devopsStore.requestorTableData.configurations;
        console.log({ config_string });
        let cleanedString = config_string.replace(/\\\\/g, "\\");
        console.log(cleanedString);
        let formValues = JSON.parse(cleanedString);
        form.setFieldsValue(JSON.parse(formValues));
      } catch (err) {
        console.log(err);
        let config_string = devopsStore.requestorTableData.configurations;
        console.log({ config_string });

        let formValues = JSON.parse(config_string);
        // formValues = JSON.parse(formValues);
        form.setFieldsValue(JSON.parse(formValues));
      }
    }
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleAdditionalTags = () => {
    setIsModalOpen(true);
  };

  const removeSpaceAddUnderScore = (val) => {
    let str = val.split(" ").join("_");
    return str.toLowerCase();
  };
  const [osOfferOptions, setOSOfferOptions] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [osSKUOptions, setOSSKUOptions] = useState([]);
  const handleChangeOS = (e, val) => {
    console.log(e, val);

    let arryJson = [];
    let skuArr = [];
    form.setFieldsValue({ offer: "", sku: "" });
    setOSOfferOptions([]);
    let cloud_platform = basicDetailsInfo.cloud_platform;
    // console.log(cloud_platform, e);
    let selectedValue = e;
    let osSku = "all";
    setSelectedOffer(e);
    dispatch(getOSOffer({ cloud_platform, selectedValue, osSku }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        let osResponse = data.devops_cloud_mapping;
        const uniqueOffers = new Set();

        for (const item of osResponse) {
          uniqueOffers.add(item.offer);
        }
        const labelValueList = [];
        for (const offer of uniqueOffers) {
          labelValueList.push({ label: offer, value: offer });
        }
        setOSOfferOptions(labelValueList);
      });
  };

  const handleChangeOSOffer = (e, val) => {
    let arryJson = [];
    setOSSKUOptions([]);
    console.log(val);
    form.setFieldsValue({ sku: "" });
    let cloud_platform = basicDetailsInfo.cloud_platform;
    let selectedValue = e;
    dispatch(getOSSKU({ cloud_platform, selectedOffer, selectedValue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        let osResponse = data.devops_cloud_mapping;
        arryJson = osResponse.map((region, index) => ({
          label: region.sku,
          value: region.sku,
        }));
        setOSSKUOptions(arryJson);
      });
  };
  return (
    <>
      <div className="mt-5 configuration-details">
        <span className="heading-devops-config">
          {" "}
          Virtual Machine Configuration
        </span>
      </div>

      <>
        <Form onFinish={handleSubmit} form={form}>
          {ishowSpinner ? (
            <Spin />
          ) : (
            formFieldDetails.map((item) => {
              return (
                <>
                  <div className="sub-heading-devops-config ms-5">
                    <ConfigHeaderPrefix /> {item.header}
                  </div>
                  <br />
                  <div className="configuration-card-body px-5">
                    {/* {console.log("formFieldDetails ::", formFieldDetails)} */}
                    {item.field_names.map((item, index) => {
                      return (
                        <Row
                          gutter={[16, 16]}
                          className="d-flex justify-content-center"
                        >
                          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                            <Row gutter={[16, 16]}>
                              <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                                <span className="devops-form-names">
                                  {" "}
                                  {item.name}
                                </span>
                              </Col>
                              <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                                {item.showDropDown ? (
                                  item.name === "OS Publisher" ? (
                                    <>
                                      {" "}
                                      <Form.Item
                                        name={item.field_value_string}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={
                                            item.optionsList &&
                                            item.optionsList.length > 0 &&
                                            item.optionsList
                                          }
                                          onChange={(e) =>
                                            handleChangeOS(
                                              e,
                                              item.field_value_string
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  ) : item.name === "OS Offer" ? (
                                    <>
                                      {console.log(item.field_value_string)}{" "}
                                      <Form.Item
                                        name={item.field_value_string}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        {/* {console.log(item.osOffer)} */}
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={osOfferOptions}
                                          onChange={(e) =>
                                            handleChangeOSOffer(
                                              e,
                                              item.field_value_string
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  ) : item.name === "OS SKU" ? (
                                    <>
                                      {" "}
                                      {console.log(
                                        item.field_value_string
                                      )}{" "}
                                      <Form.Item
                                        name={item.field_value_string}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        {/* {console.log(item.osOffer)} */}
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={osSKUOptions}
                                          // onChange={handleChangeOS}
                                        />
                                      </Form.Item>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Form.Item
                                        name={item.field_value_string}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={
                                            item.optionsList &&
                                            item.optionsList.length > 0 &&
                                            item.optionsList
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  )
                                ) : item.name === "Admin Password" ? (
                                  <Form.Item
                                    name={removeSpaceAddUnderScore(item.name)}
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Input
                                      className="devops-antd-input"
                                      bordered={false}
                                      type="password"
                                      // placeholder={`Enter ` + item.name}
                                    />
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name={removeSpaceAddUnderScore(item.name)}
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Input
                                      className="devops-antd-input"
                                      bordered={false}
                                      // placeholder={`Enter ` + item.name}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                            </Row>
                            <br />
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                  <br />
                </>
              );
            })
          )}

          <Row gutter={[16, 16]} justify="end">
            <Col span={24}>
              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  htmlType="button"
                  className="form-back-button"
                  onClick={() => props.setCurrent(props.current - 1)}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="form-button save-form-button"
                >
                  {isShowButtonLoader ? (
                    <>
                      <div className="d-flex">
                        <div className="">
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    " Save"
                  )}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>

      <Modal
        title="Add Tag"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="additional-tag-modal"
        width={800}
      >
        <div className="job-schedule-modal">
          <div className="d-flex justify-content-center">
            <div className="mt-4">
              <Row gutter={[16, 16]} className="d-flex justify-content-center">
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form layout="vertical" onFinish={handleSubmit} form={form}>
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Key">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Key"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Value">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Value"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Resource">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        className="d-flex align-items-center"
                      >
                        <span className="color-red">Remove</span>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Key">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Key"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Value">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Value"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Resource">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        className="d-flex align-items-center"
                      >
                        <span className="color-red">Remove</span>
                      </Col>
                    </Row>
                    <br />
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfigureDetails;
