import React from "react";
import { memo, useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { capitalizeFirst } from "../../../custom_hook/CustomHook";
import {
  addUpdateForm,
  getDropDownValues,
  listDevopsAccount,
  listDevopscategory,
} from "../../../action/graphql/devops/DevOpsAction";
import {
  getConfigurationData,
  saveBasicDetails,
  setAccountInfo,
  setConfigurationRecords,
  setFormFielDetailsData,
  setJobName,
  setSaveBasicAction,
} from "../../../slice/devopsSlice";

function BasicDetails(props) {
  const navigateFn = useNavigate();
  const [tablerecords, setTableRecords] = useState([]);
  const [categoryrecords, setcategoryrecords] = useState([]);
  const [categoryoptions, setcategoryoptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectaccountOptions, setSelectaccountOptions] = useState([]);
  const [selectCloudoption, setselectCloudoption] = useState([]);
  const [selectCategoryoption, setselectCategoryoption] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const customer = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const devopsStore = useSelector((state) => state.devops);
  const getcloudDetails = () => {
    console.log("api called");
    dispatch(listDevopsAccount({ customer }))
      .unwrap()
      .then(({ data }) => {
        const records = data.list_platform_devops_accounts.map((datas) => {
          return {
            account_id: datas.account_id,
            account_name: datas.account_name,
            company: datas.company,
            cloud_platform: datas.cloud_platform,
            customer_approval_email: datas.customer_approval_email,
            external_id: datas.external_id,
            och_approval_email: datas.och_approval_email,
            status: datas.status,
          };
        });
        dispatch(setAccountInfo(records));
        setTableRecords(records);
        const options = records.reduce((acc, record) => {
          const foundOption = acc.find(
            (option) => option.value === record.cloud_platform
          );
          if (!foundOption) {
            acc.push({
              label: record.cloud_platform,
              value: record.cloud_platform,
            });
          }
          return acc;
        }, []);
        setSelectOptions(options);
      })
      .catch((err) => {});
  };
  const getCloudCategory = (cloud_platform) => {
    dispatch(listDevopscategory({ cloud_platform }))
      .unwrap()
      .then(({ data }) => {
        const records = data.devops_config.map((datas) => {
          return {
            build_category: datas.build_category,
            build_sub_category: datas.build_sub_category,
            cloud_platform: datas.cloud_platform,
            configurations: datas.configurations,
            logs: datas.logs,
            status: datas.status,
            jenkins_job_name: datas.jenkins_job_name,
          };
        });
        dispatch(getConfigurationData(records));
        setcategoryrecords(records);
        const options = records.reduce((acc, record) => {
          const foundOption = acc.find(
            (option) => option.value === record.build_category
          );
          if (!foundOption) {
            acc.push({
              label: record.build_category,
              value: record.build_category,
              config: record.configurations,
            });
          }
          return acc;
        }, []);
        setcategoryoptions(options);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getcloudDetails();
    if (devopsStore.requestAction === "addNew") {
      if (
        devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform === ""
      ) {
        form.resetFields();
        setTableRecords([]);
      } else {
        form.setFieldsValue(devopsStore.saveBasicDetailsData.basicDetailsInfo);
        const accountOptions = devopsStore.saveBasicDetailsData.accountInfo
          .filter(
            (record) =>
              record.cloud_platform ===
              devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform
          )
          .map((record) => ({
            label: record.account_name,
            value: record.account_name,
          }));

        setSelectaccountOptions(accountOptions);

        // for cloud category
        getCloudCategory(
          devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform
        );
        const accountOptions_one = devopsStore.configuration
          .filter(
            (record) =>
              record.build_category ===
              devopsStore.saveBasicDetailsData.basicDetailsInfo.service_name
          )
          .map((record) => ({
            label: record.build_sub_category,
            value: record.build_sub_category,
          }));

        setselectCategoryoption(accountOptions_one);
      }
    } else if (devopsStore.requestAction === "edit") {
      form.setFieldsValue(devopsStore.requestorTableData);
      console.log(devopsStore);
      dispatch(listDevopsAccount({ customer }))
        .unwrap()
        .then(({ data }) => {
          const records = data.list_platform_devops_accounts.map((datas) => {
            return {
              account_id: datas.account_id,
              account_name: datas.account_name,
              company: datas.company,
              cloud_platform: datas.cloud_platform,
              customer_approval_email: datas.customer_approval_email,
              external_id: datas.external_id,
              och_approval_email: datas.och_approval_email,
              status: datas.status,
            };
          });

          const accountOptions = records
            .filter(
              (record) =>
                record.cloud_platform ===
                devopsStore.requestorTableData.cloud_platform
            )
            .map((record) => ({
              label: record.account_name,
              value: record.account_name,
            }));

          setSelectaccountOptions(accountOptions);
        });

      // // // for cloud category
      let cloud_platform = devopsStore.requestorTableData.cloud_platform;
      dispatch(listDevopscategory({ cloud_platform }))
        .unwrap()
        .then(({ data }) => {
          const records = data.devops_config.map((datas) => {
            return {
              build_category: datas.build_category,
              build_sub_category: datas.build_sub_category,
              cloud_platform: datas.cloud_platform,
              configurations: datas.configurations,
              logs: datas.logs,
              status: datas.status,
              jenkins_job_name: datas.jenkins_job_name,
            };
          });
          setcategoryrecords(records);
          const accountOptions_one = records
            .filter(
              (record) =>
                record.build_category ===
                devopsStore.requestorTableData.service_name
            )
            .map((record) => ({
              label: record.build_sub_category,
              value: record.build_sub_category,
            }));

          setselectCategoryoption(accountOptions_one);
          const options = records.reduce((acc, record) => {
            const foundOption = acc.find(
              (option) => option.value === record.build_category
            );
            if (!foundOption) {
              acc.push({
                label: record.build_category,
                value: record.build_category,
                config: record.configurations,
              });
            }
            return acc;
          }, []);
          setcategoryoptions(options);
          const configuration_Records = records
            .filter(
              (record) =>
                record.build_sub_category ===
                devopsStore.requestorTableData.operation
            )
            .map((record) => ({
              record,
            }));
          dispatch(
            setConfigurationRecords(
              configuration_Records[0].record.configurations
            )
          );
          let parsedValue = JSON.parse(
            configuration_Records[0].record.configurations
          );

          dispatch(
            setJobName(configuration_Records[0].record.jenkins_job_name)
          );
        });
    } else if (devopsStore.saveBasicAction === "true") {
      form.setFieldsValue(devopsStore.saveBasicDetailsData.basicDetailsInfo);
    }
  }, [customer]);
  const handleCloudChange = (selectedCloud) => {
    setselectCloudoption(selectedCloud);
    const accountOptions = tablerecords
      .filter((record) => record.cloud_platform === selectedCloud)
      .map((record) => ({
        label: record.account_name,
        value: record.account_name,
      }));
    setSelectaccountOptions(accountOptions);
    getCloudCategory(selectedCloud);
    form.setFieldsValue({
      activity_type: "",
      operation: "",
      service_name: "",
      account_name: "",
    });
  };
  const handleAccountNameChange = () => {
    getCloudCategory(selectCloudoption);
  };

  const handleCategoryChange = (selectedCate) => {
    form.setFieldsValue({
      operation: "",
    });
    const accountOptions = categoryrecords
      .filter((record) => record.build_category === selectedCate)
      .map((record) => ({
        label: record.build_sub_category,
        value: record.build_sub_category,
      }));
    setselectCategoryoption(accountOptions);
  };
  const handleChangeConfiguration = (selectedData) => {
    console.log(selectedData);
    const configuration_Records = categoryrecords
      .filter((record) => record.build_sub_category === selectedData)
      .map((record) => ({
        record,
      }));
    dispatch(
      setConfigurationRecords(configuration_Records[0].record.configurations)
    );
    let parsedValue = JSON.parse(
      configuration_Records[0].record.configurations
    );

    dispatch(setJobName(configuration_Records[0].record.jenkins_job_name));
  };
  useEffect(() => {
    var records;
    form.setFieldsValue(records);
  }, []);
  const handleSubmit = (values) => {
    values["status"] = "draft";
    values["event_type"] = "insert";
    values["activity_type"] = "CR";
    dispatch(saveBasicDetails(values));
    dispatch(setSaveBasicAction(true));
    props.setCurrent(props.current + 1);
  };

  return (
    <>
      {" "}
      <Form onFinish={handleSubmit} form={form}>
        <div className="basic-details">
          <Row gutter={[16, 16]} className="d-flex justify-content-center">
            <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
              {/* <Row gutter={[16, 16]} className="d-flex justify-content-center"> */}
              {/* <Col> */}
              {/* <Form.Item name="cloud" label="Cloud"> */}
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="devops-form-names">Cloud</span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="cloud_platform"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      showSearch
                      className="devops-antd-select"
                      bordered={false}
                      placeholder="  Select Cloud"
                      options={selectOptions}
                      onChange={handleCloudChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="devops-form-names"> Account Name</span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="account_name"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      showSearch
                      className="devops-antd-select"
                      bordered={false}
                      placeholder="  Select Account Name"
                      options={selectaccountOptions}
                      onChange={handleAccountNameChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                  <span className="devops-form-names">
                    Select Launch Category
                  </span>
                </Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="service_name"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      showSearch
                      className="devops-antd-select"
                      bordered={false}
                      placeholder="  Select Launch Category"
                      options={categoryoptions}
                      onChange={handleCategoryChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="devops-form-names">
                    Select Configuration
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form.Item
                    name="operation"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      showSearch
                      className="devops-antd-select"
                      bordered={false}
                      placeholder="  Select Configuration"
                      options={selectCategoryoption}
                      onChange={handleChangeConfiguration}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <br />
        <Row gutter={[16, 16]} justify="end">
          <Col span={24}>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                className="form-back-button"
                htmlType="button"
                onClick={() => navigateFn("/devops-requestor")}
              >
                Back
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="form-button save-form-button"
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default BasicDetails;
