import React, { useEffect, useState } from "react";
import { Spin, Table, DatePicker, message, Select } from "antd";
import "../../slm.css";
import { Form, Row, Col, Button } from "antd";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addInitialRequestTime,
  deleteReqResponse,
  getReqResponseTime,
  updateInitialRequestTime,
} from "../../../action/graphql/slm/SLM_reducers";

const ResolutionTimeTable = ({
  tableData,
  showSpinner,
  initialResponseTimeTableAPI,
  tablerecords
}) => {
  console.log(tablerecords)
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  const [form] = Form.useForm();
  const monthFormat = "YYYY-MM-01";
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [dynamicMonthOne, setDynamicMonthOne] = useState();
  const [dynamicMonthTwo, setDynamicMonthTwo] = useState();
  const [dynamicMonthThree, setDynamicMonthThree] = useState();

  var dynamicMonthList = [];
  useEffect(() => {
    console.log({ monthList });
    fetchDynamicMonth();
  }, [dynamicMonthList, dynamicMonthOne, dynamicMonthTwo, dynamicMonthThree]);

  const [selectedRow, setSelectedRow] = useState([]);
  var monthList = [];

  useEffect(() => {}, [monthList, tableData]);
  const fetchDynamicMonth = () => {
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );

      monthList.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    monthList.map((item) => {
      let formattedDate = new Date(item);
      const options = {
        year: "numeric",
        month: "short",
      };

      let formattedDate_new = formattedDate.toLocaleDateString(
        "en-US",
        options
      );

      dynamicMonthList.push(formattedDate_new);
      setDynamicMonthOne(dynamicMonthList[0]);
      setDynamicMonthTwo(dynamicMonthList[1]);
      setDynamicMonthThree(dynamicMonthList[2]);
    });
  };
  const renderServiceLevel = (text, record) => {
    console.log(record)
    
     const priorityTypeMap = {
       P1: 'Critical',
       P2: 'Moderate',
       P3: 'Low',
     };
     const priorityType = record.priority_type.toUpperCase();
     return priorityTypeMap[priorityType];
   
   };
 
   const renderInitialResponseTime = (text, record) => {
 
     const initialResponseTimeMap = {
       P1: '15 mins',
       P2: '30 mins',
       P3: '30 mins',
     };
     const priorityType = record.priority_type.toUpperCase();
 
     return initialResponseTimeMap[priorityType];
 
   };
   const  rendertarget = (text, record) => {
 
     const initialtarget = {
       P1: '95%',
       P2: '95%',
       P3: '95%',
     };
     const priorityType = record.priority_type.toUpperCase();
 
     return initialtarget[priorityType];
 
     
   };
  // const columns = [
  //   {
  //     title: "Service Level",
  //     // dataIndex: "service_level",
  //     dataIndex: 'priority_type',
  //     render: renderServiceLevel,
  //     key: "service_level",
  //     //   fixed : "left"
  //   },
  //   {
  //     title: "Response Time",
  //     // dataIndex: "resolution_time",
  //     dataIndex: 'priority_type',
  //     render: renderInitialResponseTime,
  //     key: "resolution_time",
  //   },
  //   {
  //     title: "Target",
  //     // dataIndex: "target",
  //     dataIndex: 'priority_type',
  //     render: rendertarget,
  //     key: "target",
  //   },
  //   {
  //     title: dynamicMonthOne,
  //     dataIndex: "dynamicMonthOne",
  //     key: "dynamicMonthOne",
  //   },

  //   {
  //     title: dynamicMonthTwo,
  //     dataIndex: "dynamicMonthTwo",
  //     key: "dynamicMonthTwo",
  //   },
  //   {
  //     title: dynamicMonthThree,
  //     dataIndex: "dynamicMonthThree",
  //     key: "dynamicMonthThree",
  //   },
  //   // {
  //   //   title: "Action",
  //   //   dataIndex: "action",
  //   //   key: "action",
  //   //   render: (record, data) => {
  //   //     return (
  //   //       <>
  //   //         <div
  //   //           onClick={() => handleEditClick(data)}
  //   //           style={{ cursor: "pointer" }}
  //   //         >
  //   //           <Icon icon="material-symbols:edit" />
  //   //         </div>
  //   //       </>
  //   //     );
  //   //   },
  //   // },
  // ];
  const [percentageP1Completed, setPercentageP1Completed] = useState(0);
const [percentageP2Completed, setPercentageP2Completed] = useState(0);
const [percentageP3Completed, setPercentageP3Completed] = useState(0);
  const priorityData = {};
  const dataSource = [
    {
      key: '1',
      priority_type: 'Critical',
      response:'15min',
      target: '95%',
      monthone:`${percentageP1Completed}%`,
    },
    {
      key: '2',
      priority_type: 'Moderate',
      response:'30min',
      target: '95%',
      monthone:`${percentageP2Completed}%`,
    },
    {
      key: '3',
      priority_type: 'Low',
      response:'30min',
      target: '95%',
      monthone:`${percentageP3Completed}%`,
    },
  ];
  const columns=[
    {
          title: 'Service Level',
          dataIndex: 'priority_type',
          key:'priority_type'
        },
        {
          title:'Initial Response Time',
          dataIndex: 'response',
          key:'response'
        },
        {
          title: 'Target',
          dataIndex: 'target',
          key:'target'
        },
        {
          title: dynamicMonthOne,
          dataIndex: 'monthone',
          key:'monthone'
        },
        {
          title: dynamicMonthTwo,
          dataIndex: '',
          key:''
        },
        {
          title: dynamicMonthThree,
          dataIndex: '',
          key:''
        },
    
  ] 

tablerecords.forEach((record) => {
  const { priority_type, ticket_status } = record;

  if (!priorityData[priority_type]) {
   
    priorityData[priority_type] = {
      total: 0,
      completed: 0,
      failed: 0,
      postCompletion: 0,
    };
  }
  priorityData[priority_type].total++;
  if (ticket_status === "completed") {
    priorityData[priority_type].completed++;
  } else if (ticket_status === "Failed") {
    priorityData[priority_type].failed++;
  } else if (ticket_status === "Post Completion") {
    priorityData[priority_type].postCompletion++;
  }
});
  useEffect(()=>{
    Object.keys(priorityData).forEach((priorityType) => {
      const { total, completed } = priorityData[priorityType];
      const percentageCompleted = (completed / total) * 100;
      const formattedPercentage = percentageCompleted.toFixed(2);
    
      if (priorityType === "p1") {
        setPercentageP1Completed(formattedPercentage);
      } else if (priorityType === "p2") {
        setPercentageP2Completed(formattedPercentage);
      } else if (priorityType === "p3") {
        setPercentageP3Completed(formattedPercentage);
      }
    });
   
  },[tablerecords])
 
  const data = tableData;

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    var month_list = [];

    console.log({ values });
    let report_date = values.report_month;
    report_date = `${new Date(report_date).getFullYear()}-${
      new Date(report_date).getMonth() + 1
    }-01`;
    values["report_month"] = report_date;
    values["company_name"] = companyName;

    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    // console.log(form.getFieldValue("action_item_id"));
    if (editStatus) {
      values["sla_id"] = form.getFieldValue("action_item_id");

      dispatch(updateInitialRequestTime({ values }))
        .unwrap()
        .then(({ data }) => {
          setDrawerStatus(false);
          initialResponseTimeTableAPI();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(addInitialRequestTime({ values }))
        .unwrap()
        .then(({ data }) => {
          setDrawerStatus(false);
          initialResponseTimeTableAPI();
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const handleEditClick = (record) => {
    console.log({ record });
    setDrawerStatus(true);
    setDeleteStatus(true);
    setEditStatus(true);
    setSelectedRow(record);
    form.setFieldsValue(record);
  };

  const rowDelete = () => {
    const id = selectedRow.action_item_id;
    dispatch(deleteReqResponse({ id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        initialResponseTimeTableAPI();
        setDrawerStatus(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);

  const tableProps = {};
  return (
    <>
   
      <div className="mt-5">
        <h6 className="head-color"> Resolution Time</h6>
      </div>
      <div>
        
      </div>
      <div className="initial_response_timetable">
        <Table
          {...tableProps}
          className="slm"
          style={{ overflow: "auto", height: "19rem" }}
          loading={{ indicator: <Spin />, spinning: showSpinner }}
          columns={columns}
          dataSource={dataSource} 
          // dataSource={tablerecords}
          pagination={false}
        />
      </div>
    </>
  );
};

export default ResolutionTimeTable;
// import React, { useEffect, useState } from "react";
// import { Spin, Table, DatePicker, message, Select } from "antd";
// import "../../slm.css";
// import { Form, Row, Col, Button } from "antd";
// import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
// import { Icon } from "@iconify/react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addInitialRequestTime,
//   deleteReqResponse,
//   getReqResponseTime,
//   updateInitialRequestTime,
// } from "../../../action/graphql/slm/SLM_reducers";

// const ResolutionTimeTable = ({
//   tableData,
//   showSpinner,
//   initialResponseTimeTableAPI,
//   tablerecords
// }) => {
//   console.log(tablerecords)
//   const [drawerStatus, setDrawerStatus] = useState(false);
//   const [deletestatus, setDeleteStatus] = useState(false);
//   const [editStatus, setEditStatus] = useState(false);

//   const [form] = Form.useForm();
//   const monthFormat = "YYYY-MM-01";
//   const companyName = localStorage.getItem(
//     "CognitoIdentityServiceProvider.company"
//   );
//   const { selectedTopdate } = useSelector((state) => state.constimize);
//   const [dynamicMonthOne, setDynamicMonthOne] = useState();
//   const [dynamicMonthTwo, setDynamicMonthTwo] = useState();
//   const [dynamicMonthThree, setDynamicMonthThree] = useState();

//   var dynamicMonthList = [];
//   useEffect(() => {
//     console.log({ monthList });
//     fetchDynamicMonth();
//   }, [dynamicMonthList, dynamicMonthOne, dynamicMonthTwo, dynamicMonthThree]);

//   const [selectedRow, setSelectedRow] = useState([]);
//   var monthList = [];

//   useEffect(() => {}, [monthList, tableData]);
//   const fetchDynamicMonth = () => {
//     let currentDate = new Date(selectedTopdate);
//     let lastThreeMonthsDate = new Date(selectedTopdate);
//     lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
//     for (let i = 0; i < 3; i++) {
//       let date = new Date(
//         lastThreeMonthsDate.getFullYear(),
//         lastThreeMonthsDate.getMonth() + i,
//         1
//       );

//       monthList.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
//     }
//     monthList.map((item) => {
//       let formattedDate = new Date(item);
//       const options = {
//         year: "numeric",
//         month: "short",
//       };

//       let formattedDate_new = formattedDate.toLocaleDateString(
//         "en-US",
//         options
//       );

//       dynamicMonthList.push(formattedDate_new);
//       setDynamicMonthOne(dynamicMonthList[0]);
//       setDynamicMonthTwo(dynamicMonthList[1]);
//       setDynamicMonthThree(dynamicMonthList[2]);
//     });
//   };
//   const renderServiceLevel = (text, record) => {
//     console.log(record)
    
//      const priorityTypeMap = {
//        P1: 'Critical',
//        P2: 'Moderate',
//        P3: 'Low',
//      };
//      const priorityType = record.priority_type.toUpperCase();
//      return priorityTypeMap[priorityType];
   
//    };
 
//    const renderInitialResponseTime = (text, record) => {
 
//      const initialResponseTimeMap = {
//        P1: '15 mins',
//        P2: '30 mins',
//        P3: '30 mins',
//      };
//      const priorityType = record.priority_type.toUpperCase();
 
//      return initialResponseTimeMap[priorityType];
 
//    };
//    const  rendertarget = (text, record) => {
 
//      const initialtarget = {
//        P1: '95%',
//        P2: '95%',
//        P3: '95%',
//      };
//      const priorityType = record.priority_type.toUpperCase();
 
//      return initialtarget[priorityType];
 
     
//    };
//   const columns = [
//     {
//       title: "Service Level",
//       // dataIndex: "service_level",
//       dataIndex: 'priority_type',
//       render: renderServiceLevel,
//       key: "service_level",
//       //   fixed : "left"
//     },
//     {
//       title: "Response Time",
//       // dataIndex: "resolution_time",
//       dataIndex: 'priority_type',
//       render: renderInitialResponseTime,
//       key: "resolution_time",
//     },
//     {
//       title: "Target",
//       // dataIndex: "target",
//       dataIndex: 'priority_type',
//       render: rendertarget,
//       key: "target",
//     },
//     {
//       title: dynamicMonthOne,
//       dataIndex: "dynamicMonthOne",
//       key: "dynamicMonthOne",
//     },

//     {
//       title: dynamicMonthTwo,
//       dataIndex: "dynamicMonthTwo",
//       key: "dynamicMonthTwo",
//     },
//     {
//       title: dynamicMonthThree,
//       dataIndex: "dynamicMonthThree",
//       key: "dynamicMonthThree",
//     },
//     // {
//     //   title: "Action",
//     //   dataIndex: "action",
//     //   key: "action",
//     //   render: (record, data) => {
//     //     return (
//     //       <>
//     //         <div
//     //           onClick={() => handleEditClick(data)}
//     //           style={{ cursor: "pointer" }}
//     //         >
//     //           <Icon icon="material-symbols:edit" />
//     //         </div>
//     //       </>
//     //     );
//     //   },
//     // },
//   ];
 
//   const data = tableData;

//   const changeDrawerStatus = () => {
//     setDrawerStatus(false);
//   };
//   const dispatch = useDispatch();
//   const handleSubmit = (values) => {
//     var month_list = [];

//     console.log({ values });
//     let report_date = values.report_month;
//     report_date = `${new Date(report_date).getFullYear()}-${
//       new Date(report_date).getMonth() + 1
//     }-01`;
//     values["report_month"] = report_date;
//     values["company_name"] = companyName;

//     let currentDate = new Date(selectedTopdate);
//     let lastThreeMonthsDate = new Date(selectedTopdate);
//     lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
//     for (let i = 0; i < 3; i++) {
//       let date = new Date(
//         lastThreeMonthsDate.getFullYear(),
//         lastThreeMonthsDate.getMonth() + i,
//         1
//       );
//       month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
//     }
//     // console.log(form.getFieldValue("action_item_id"));
//     if (editStatus) {
//       values["sla_id"] = form.getFieldValue("action_item_id");

//       dispatch(updateInitialRequestTime({ values }))
//         .unwrap()
//         .then(({ data }) => {
//           setDrawerStatus(false);
//           initialResponseTimeTableAPI();
//         })
//         .catch((err) => {
//           console.log(err.message);
//         });
//     } else {
//       dispatch(addInitialRequestTime({ values }))
//         .unwrap()
//         .then(({ data }) => {
//           setDrawerStatus(false);
//           initialResponseTimeTableAPI();
//         })
//         .catch((err) => {
//           console.log(err.message);
//         });
//     }
//   };
//   const handleEditClick = (record) => {
//     console.log({ record });
//     setDrawerStatus(true);
//     setDeleteStatus(true);
//     setEditStatus(true);
//     setSelectedRow(record);
//     form.setFieldsValue(record);
//   };

//   const rowDelete = () => {
//     const id = selectedRow.action_item_id;
//     dispatch(deleteReqResponse({ id, companyName }))
//       .unwrap()
//       .then((data) => {
//         message.success("Deleted");
//         initialResponseTimeTableAPI();
//         setDrawerStatus(false);
//       })
//       .catch((err) => {
//         console.log(err.message);
//       });
//   };
//   const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);

//   const tableProps = {};
//   return (
//     <>
//       {/* <DrawerPlat
//         drawerstatus={drawerStatus}
//         changeDrawerStatus={changeDrawerStatus}
//         deletestatus={deletestatus}
//         title="Resolution Time"
//         rowDelete={rowDelete}
//         editStatus={editStatus}
//         setDrawerDeleteStatus={setDrawerDeleteStatus}
//       >
//         <Form layout="vertical" onFinish={handleSubmit} form={form}>
//           <Row gutter={[16, 16]}>
//             <Col span={12}>
//               <Form.Item
//                 name="service_level"
//                 label="Service Level"
//                 rules={[
//                   {
//                     required: true,
//                     message: "",
//                   },
//                 ]}
//               >
//                 <Select
//                   showSearch
//                   style={{
//                     width: "100%",
//                   }}
//                   placeholder="Select"
//                   optionFilterProp="children"
//                   filterOption={(input, option) =>
//                     (option?.label ?? "")
//                       .toLowerCase()
//                       .includes(input.toLowerCase())
//                   }
//                   options={[
//                     {
//                       value: "critical",
//                       label: "Critical",
//                     },
//                     {
//                       value: "high",
//                       label: "High",
//                     },
//                     {
//                       value: "medium",
//                       label: "Medium",
//                     },
//                     {
//                       value: "low",
//                       label: "Low",
//                     },
//                   ]}
//                 />
//               </Form.Item>
//               <Form.Item
//                 name="initial_response_time"
//                 label="Initial Response Time"
//               >
//                 <input
//                   type="text"
//                   className="form-control"
//                   defaultValue={"15mins"}
//                   readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={12}>
//               <Form.Item name="target" label="Target">
//                 <input
//                   type="text"
//                   className="form-control"
//                   defaultValue={"95%"}
//                   readOnly
//                 />
//               </Form.Item>
//               <Form.Item
//                 name="report_month"
//                 label="Month"
//                 rules={[
//                   {
//                     required: true,
//                     message: "",
//                   },
//                 ]}
//               >
//                 <DatePicker
//                   format={monthFormat}
//                   className="antd-date-picker w-100 form-control"
//                   picker="month"
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={[16, 16]}>
//             <Col span={12}>
//               <Form.Item name="completed_target" label="Completed Target%">
//                 <input type="text" className="form-control" />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Row>
//             <Col
//               xxl={12}
//               xl={12}
//               lg={12}
//               md={24}
//               sm={24}
//               xs={24}
//               className="p-1"
//             >
//               <Form.Item>
//                 <Button
//                   htmlType="button"
//                   onClick={() => {
//                     setDrawerStatus(false);
//                   }}
//                 >
//                   Cancel
//                 </Button>
//               </Form.Item>
//             </Col>
//             <Col
//               xxl={12}
//               xl={12}
//               lg={12}
//               md={24}
//               sm={24}
//               xs={24}
//               className="p-1"
//             >
//               <Form.Item>
//                 <Button
//                   type="primary"
//                   htmlType="submit"
//                   style={{
//                     background: "#1b5f9d",
//                     color: "#fff",
//                     float: "right",
//                   }}
//                 >
//                   Submit
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//         </Form>
//       </DrawerPlat> */}
//       <div className="mt-5">
//         <h6> Resolution Time</h6>
//       </div>
//       <div>
//         {/* <button
//           className="table-add-button float-right"
//           onClick={() => setDrawerStatus(true)}
//         >
//         // <Icon icon="ion:add-circle-outline" /> 
//           <Icon icon="material-symbols:add-circle-outline" />
//           Add
//         </button> */}
//       </div>
//       <div className="initial_response_timetable">
//         <Table
//           {...tableProps}
//           className="slm"
//           style={{ overflow: "auto", height: "19rem" }}
//           loading={{ indicator: <Spin />, spinning: showSpinner }}
//           columns={columns}
//           // dataSource={data}
//           dataSource={tablerecords}
//           pagination={false}
//         />
//       </div>
//     </>
//   );
// };

// export default ResolutionTimeTable;





