import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButtonV2 from "../../../properties/accordion/ToggleButtonV2";
import { Icon } from "@iconify/react";
import { numberFormat } from "../../../custom_hook/CustomHook";
import {
  getAssetSpendVMCloudRecords,
  getK8SpendCloudRecords,
} from "../../../action/costimizeV2Active";
import awsicon from "../../../../assets/images/aws.svg";
import AssetSpendListApplication from "../AssetSpendListApplication";
import { memo } from "react";
import K8_Application from "./K8_Application";

const K8_SpendCloud = (props) => {
  const dispatch = useDispatch();
  //   console.log(props);
  const [allcloudlist, setAllCloudList] = useState([]);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [allenvironmentlist, setAllenvironmentCloudList] = useState([]);
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var first_month = month_list[0];
    var second_month = month_list[1];
    var thired_month = month_list[2];

    var apivalue = {
      environment: props.environmentdetails.title,
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedCloud.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
    };

    dispatch(getK8SpendCloudRecords({ companyName, selectedTopdate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        if (data.newfunctionquery37.length > 0) {
          // console.log(data.newfunctionquery31);
          const enviroment = data.newfunctionquery37?.map((v) => v.enviroment);
          // var enviromentlist = new Set([...enviroment]);
          // console.log(enviroment)
          // setAllenvironmentCloudList(enviromentlist)
          const cloud = data.newfunctionquery37?.map((v) => v.cloud);

          var cloudlist = new Set([...cloud]);
          cloudlist = [...cloudlist];
          // console.log(cloudlist)

          const cloud_all_list = [];
          cloudlist.map((data_map) => {
            const cloud_data_list = data.newfunctionquery37?.filter((datas) => {
              return datas.cloud.toLowerCase() === data_map.toLowerCase();
            });
            const return_environment = getEnvironmentList(
              data_map,
              cloud_data_list,
              first_month,
              second_month,
              thired_month
            );
            cloud_all_list.push(return_environment);
          });
          setAllCloudList(cloud_all_list);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    companyName,
    selectedTopdate,
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    props,
  ]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    const totalcount = data_list.reduce(
      (a, v) => (a = +a + +v.resourcename),
      0
    );

    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: "-",
      size: "-",
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),

      count: totalcount,
    };
  };
  // console.log(allcloudlist.title)
  var coludname = allcloudlist.map((env) => env.title);
  // console.log(coludname)
  // console.log(allcloudlist)
  // console.log(allenvironmentlist)
  const handleClick = () => {
    // setOpenStatus(!openstatus);
    setIsRowClicked(!isRowClicked);
  };
  useEffect(() => {}, [isRowClicked]);
  const [isShowApplication, setIsShowApplication] = useState(false);

  const handleOnClick = () => {
    setIsShowApplication(!isShowApplication);
  };
  return (
    <>
      <>
        {allcloudlist.map((cloudlist, indexc) => {
          const ran_num = Math.random();
          return (
            <>
              <div className="p-3">
                <input
                  className="cd-accordion__input"
                  type="checkbox"
                  name={"sub-group-level-k8" + indexc + ran_num}
                  id={"sub-group-level-k8" + indexc + ran_num}
                  // defaultChecked
                />
                <label
                  className="cd-accordion__label"
                  htmlFor={"sub-group-level-k8" + indexc + ran_num}
                  onClick={() => handleOnClick()}
                >
                  <span className="accordion-full-width">
                    <ToggleButtonV2 status={true} newclass="cloud-list-icon">
                      <Row className="plat-table-assets-spend-thead">
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="asset-left-title cloud-list-title asset-spend-title-vs"
                        >
                          {cloudlist.title.toLowerCase() === "aws" ? (
                            <>
                              {/* <Icon
                              icon="logos:aws"
                              class="asset-spend-cloud-list-icon"
                            /> */}
                              <img src={awsicon} /> AWS({cloudlist.count})
                            </>
                          ) : cloudlist.title.toLowerCase() === "azure" ? (
                            <>
                              <Icon icon="vscode-icons:file-type-azure" />
                              Azure({cloudlist.count})
                            </>
                          ) : cloudlist.title.toLowerCase() === "oracle" ? (
                            <>
                              <Icon
                                icon="simple-icons:oracle"
                                style={{ color: "#FF0000" }}
                              />{" "}
                              Oracle({cloudlist.count})
                            </>
                          ) : (
                            <>
                              <Icon icon="skill-icons:gcp-light" /> GCP(
                              {cloudlist.count})
                            </>
                          )}
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="asset-spend-values-vs justify-content-center"
                        >
                          ${numberFormat(cloudlist.spend)}
                          <Icon
                            className={
                              "me-1 " +
                              (cloudlist.spend > 0
                                ? "text-danger-icon-custom"
                                : "text-success-icon-custom")
                            }
                            icon={
                              cloudlist.spend > 0
                                ? "material-symbols:arrow-drop-up"
                                : "material-symbols:arrow-drop-down"
                            }
                            style={{ fontSize: "20px" }}
                          />
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="asset-spend-values-vs justify-content-center"
                        >
                          {cloudlist.name}
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className="asset-spend-values-vs justify-content-center"
                        >
                          -
                        </Col>
                      </Row>
                      <div className="asset-spend-border-bottom-vs"></div>
                    </ToggleButtonV2>
                  </span>
                </label>
                {/* application list */}
                {isShowApplication && (
                  <K8_Application
                    environment={props.environmentdetails.title}
                    cloud={cloudlist.title}
                    application={props.application}
                    resname={props.resname}
                  />
                )}
              </div>
            </>
          );
        })}
      </>
    </>
  );
};

export default memo(K8_SpendCloud);
