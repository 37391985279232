import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Row, Col } from "react-bootstrap";
import { message, Form, Input, Select, DatePicker, Button ,Modal} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst } from "../../custom_hook/CustomHook";
import {
  addApplicationBudget,
  updateApplicationBudget,
  deleteApplicationBudget,
} from "../../action/costimizev2DashboardAction";
import "../../../components/costimize_v2/costimize_v2.css";
const YearBudgetDetailsScreen = (props) => {
  const [form] = Form.useForm();
  const jan_value = useRef();
  const feb_value = useRef();
  const mar_value = useRef();
  const apr_value = useRef();
  const may_value = useRef();
  const jun_value = useRef();
  const jul_value = useRef();
  const aug_value = useRef();
  const sep_value = useRef();
  const oct_value = useRef();
  const nov_value = useRef();
  const dec_value = useRef();
  const dispatch = useDispatch();
  const [buttonloading, setButtonLoading] = useState(false);
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  console.log(companyName)

  const { environmentList, applicationList, serviceList, accountList } =
    useSelector((state) => state.constimize);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [inputdisable, setInputDisable] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    var records = {
      annual_total: "",
      year: "",
      jan: "",
      feb: "",
      mar: "",
      apr: "",
      may: "",
      jun: "",
      jul: "",
      aug: "",
      sep: "",
      oct: "",
      nov: "",
      dec: "",
      account: "",
      application: "",
      environment: "",
    };
    var input_disable_status = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];

    if (props.budgetyearrecords) {
      records = {
        annual_total: props.budgetyearrecords.annual_total,
        year: props.budgetyearrecords.budget_year,
        jan: props.budgetyearrecords.budget_jan,
        feb: props.budgetyearrecords.budget_feb,
        mar: props.budgetyearrecords.budget_mar,
        apr: props.budgetyearrecords.budget_apr,
        may: props.budgetyearrecords.budget_may,
        jun: props.budgetyearrecords.budget_jun,
        jul: props.budgetyearrecords.budget_jul,
        aug: props.budgetyearrecords.budget_aug,
        sep: props.budgetyearrecords.budget_sept,
        oct: props.budgetyearrecords.budget_oct,
        nov: props.budgetyearrecords.budget_nov,
        dec: props.budgetyearrecords.budget_dec,
        account: props.budgetyearrecords.account_name,
        application: props.budgetyearrecords.application,
        environment: props.budgetyearrecords.environment,
      };
      //input disable
      const current_date = new Date();
      if (
        new Date(current_date).getFullYear() >
        Number(props.budgetyearrecords.budget_year)
      ) {
        input_disable_status = [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
      } else {
        if (
          new Date(current_date).getFullYear() ==
          Number(props.budgetyearrecords.budget_year)
        ) {
          const val_list = [];
          for (let index = 0; index < 12; index++) {
            if (new Date(current_date).getMonth() + 1 < +index + +1) {
              val_list.push(false);
            } else {
              val_list.push(true);
            }
            input_disable_status = val_list;
          }
        } else {
          input_disable_status = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
        }
      }
    }
    setInputDisable(input_disable_status);
    form.setFieldsValue(records);

    // console.log(new Date(current_date).getFullYear());
    // console.log(props.budgetyearrecords.budget_year);
    // console.log(current_date);
  }, [props.budgetyearrecords]);

  const handleSubmit = (values) => {
    console.log(values)
    setButtonLoading(true);
    const inputs = {
      budget_amount:
        values.jan +
        "," +
        values.feb +
        "," +
        values.mar +
        "," +
        values.apr +
        "," +
        values.may +
        "," +
        values.jun +
        "," +
        values.jul +
        "," +
        values.aug +
        "," +
        values.sep +
        "," +
        values.oct +
        "," +
        values.nov +
        "," +
        values.dec,
      budget_month:
        "January,February,March,April,May,June,July,August,September,October,November,December",
      budget_year: values.year,
      company_name: companyName,
      account: values.account,
      application: values.application,
      environment: values.environment,
    };
    console.log(inputs)

    if (props.yeardrawerdeletestatus) {
      const key = props.budgetyearrecords.budget_id;
      dispatch(updateApplicationBudget({ inputs, key }))
        .unwrap()
        .then((data) => {
          message.success("Updated Successfully Done");
          props.yearsetDrawerStatus(false);
          setButtonLoading(false);
          props.setBudgetApplicationUpdate(!props.budgetapplicationupdate);
        })
        .catch((err) => {
          setButtonLoading(false);
          message.error(err);
          console.log(err.message);
        });
    } else {
      dispatch(addApplicationBudget({ inputs }))
        .unwrap()
        .then((data) => {
          console.log(data)
          message.success("Added Successfully Done");
          props.yearsetDrawerStatus(false);
          setButtonLoading(false);
          props.setBudgetApplicationUpdate(!props.budgetapplicationupdate);
        })
        .catch((err) => {
          setButtonLoading(false);
          message.error(err);
          console.log(err.message);
        });
    }
  };

  const checkAnnualTotal = () => {
    var total =
      +jan_value.current.input.value +
      +feb_value.current.input.value +
      +mar_value.current.input.value +
      +apr_value.current.input.value +
      +may_value.current.input.value +
      +jun_value.current.input.value +
      +jul_value.current.input.value +
      +aug_value.current.input.value +
      +sep_value.current.input.value +
      +oct_value.current.input.value +
      +nov_value.current.input.value +
      +dec_value.current.input.value;
    form.setFieldsValue({
      annual_total: total,
    });
  };

  const rowDelete = () => {
    setConfirmLoading(true);
    const budget_id = props.budgetyearrecords.budget_id;
    dispatch(deleteApplicationBudget({ budget_id, companyName }))
      .unwrap()
      .then((data) => {
        // console.log(data);
        props.yearsetDrawerStatus(false);
        setDrawerDeleteStatus(false);
        setConfirmLoading(false);
        message.success("Deleted");
        props.setBudgetApplicationUpdate(!props.budgetapplicationupdate);
      })
      .catch((err) => {
        console.log(err.message);
        message.error(err);
      });
  };

  const checkInputDisable = () => {
    console.log("checking");
    return false;
  };

  return (
    <>
      <Modal
      className="costmodal"
        open={props.yeardrawerstatus}
        onCancel={props.yearchangeDrawerStatus}
        title="Application Budget"
        footer={null}
        deletestatus={props.yeardrawerdeletestatus}
        rowDelete={rowDelete}
        setConfirmLoading={setConfirmLoading}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
      >
        <Form
        className="formcss"
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={initialValues}
          form={form}
        >
          <Row>
            <Col>
              <Form.Item
                name="year"
                label="Year"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  className="my-select"
                  style={{
                    width: "100%",
                    
                  }}
                  dropdownStyle={{ backgroundColor: '#2E2E2E'}} 
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "2023",
                      label: "2023",
                    },
                    {
                      value: "2022",
                      label: "2022",
                    },
                    {
                      value: "2021",
                      label: "2021",
                    },
                    {
                      value: "2020",
                      label: "2020",
                    },
                    {
                      value: "2019",
                      label: "2019",
                    },
                  ]}
                // disabled={props.yeardrawerdeletestatus}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="environment"
                label="Environment"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={environmentList.map((list) => {
                    return {
                      value: list,
                      label: capitalizeFirst(list),
                    };
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="application"
                label="Application"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={applicationList.map((list) => {
                    return {
                      value: list,
                      label: capitalizeFirst(list),
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="account"
                label="Account"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={accountList.map((list) => {
                    return {
                      value: list,
                      label: capitalizeFirst(list),
                    };
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="jan"
                label="Jan"
                rules={[
                  {
                    required: !inputdisable[0],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={jan_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[0]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="feb"
                label="Feb"
                rules={[
                  {
                    required: !inputdisable[1],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={feb_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[1]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="mar"
                label="Mar"
                rules={[
                  {
                    required: !inputdisable[2],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={mar_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[2]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="apr"
                label="Apr"
                rules={[
                  {
                    required: !inputdisable[3],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={apr_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[3]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="may"
                label="May"
                rules={[
                  {
                    required: !inputdisable[4],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={may_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[4]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="jun"
                label="Jun"
                rules={[
                  {
                    required: !inputdisable[5],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={jun_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[5]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="jul"
                label="Jul"
                rules={[
                  {
                    required: !inputdisable[6],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={jul_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[6]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="aug"
                label="Aug"
                rules={[
                  {
                    required: !inputdisable[7],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={aug_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[7]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="sep"
                label="Sep"
                rules={[
                  {
                    required: !inputdisable[8],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={sep_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[8]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="oct"
                label="Oct"
                rules={[
                  {
                    required: !inputdisable[9],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={oct_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[9]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="nov"
                label="Nov"
                rules={[
                  {
                    required: !inputdisable[10],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={nov_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[10]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="dec"
                label="Dec"
                rules={[
                  {
                    required: !inputdisable[11],
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={dec_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[11]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="annual_total"
                label="Annual Total"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button
                htmlType="button"
                className="cancel-btn"
                onClick={() => props.yearsetDrawerStatus(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-btn"
                // style={{ background: "#FF6A2F", color: "#fff", float: "right" }}
                loading={buttonloading}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default YearBudgetDetailsScreen;
