import React, { useState } from "react";
import {
  MDBInput,
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import LinearProgress from "@mui/material/LinearProgress";

import { forgotPassword, submitForgotPassword } from "./amplify/auth-config";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import { message, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ValuesOfCorrectTypeRule } from "graphql";

function ForgotPassword(props) {
  const [formValue, setFormValue] = useState({
    emailid: "",
    otpvalue: "",
    newpassword: "",
    repeatpassword: "",
  });
  const [loader, setLoader] = useState(false);
  const [otpscreen, setOTPScreenStatus] = useState(false);
  const [close, setCloseAsset] = useState(false);
  const [successInfo, setSuccessInfo] = useState(false);
  const [errorcontent, setErrorContent] = useState("");
  const [buttonloading, setButtonloading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigateFn = useNavigate();

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();

  const submit = () => {
    setButtonloading(true);
    const { emailid } = formValue;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailid)) {
      setErrorContent("Please enter a valid email address.");

      if (submitted) {
        setCloseAsset(true);
      }

      return;
    }

    // setLoader(true);
    if (emailid) {
      setLoader(true);
      dispatch(forgotPassword({ emailid }))
        .unwrap()
        .then((data) => {
          setCloseAsset(true);
          setLoader(false);
          setButtonloading(false);
          setSuccessInfo(true);
          setErrorContent("OTP Sent");
          setOTPScreenStatus(true);
        })
        .catch((err) => {
          setCloseAsset(true);
          setLoader(false);
          setButtonloading(false);
          setSuccessInfo(false);
          setErrorContent(err);
        });
    }
    setSubmitted(true);
    setLoader(false);
    setButtonloading(false);
  };

  const submitOTP = () => {
    if (otpvalue && newpassword && repeatpassword) {
      if (newpassword === repeatpassword) {
        setLoader(true);
        setButtonloading(true);
        dispatch(submitForgotPassword({ emailid, otpvalue, newpassword }))
          .unwrap()
          .then((data) => {
            setLoader(false);
            setButtonloading(false);
            message.success("Password changed successfully done");
            navigateFn("/signin");
          })
          .catch((err) => {
            setCloseAsset(true);
            setLoader(false);
            setButtonloading(false);
            setSuccessInfo(false);
            setErrorContent(err);
          });
      } else {
        setCloseAsset(true);
        setLoader(false);
        setButtonloading(false);
        setSuccessInfo(false);
        setErrorContent("Confirm password doesn't match.");
      }
    }
  };

  const { emailid, otpvalue, newpassword, repeatpassword } = formValue;
  return (
    <div>
      {close ? (
        <Alert
          onClose={() => setCloseAsset(false)}
          severity={successInfo ? "success" : "error"}
        >
          {submitted && successInfo ? errorcontent : null}
        </Alert>
      ) : null}
      {otpscreen ? (
        <MDBValidation>
          <div className="loginForm">
            <div className="userName mb-3">
              <MDBValidationItem
                className="mb-3 pb-1"
                feedback="Please enter your OTP."
                invalid
              >
                <label className="plat-form-label">OTP</label>
                <MDBInput
                  id="form1"
                  name="otpvalue"
                  type="number"
                  onChange={onChange}
                  value={otpvalue}
                  required
                />
              </MDBValidationItem>
            </div>
            <div className="userName mb-3">
              <MDBValidationItem
                className="mb-3 pb-1"
                feedback="Please enter your New Password."
                invalid
              >
                <label className="plat-form-label">New Password</label>
                <MDBInput
                  id="form1"
                  name="newpassword"
                  type="password"
                  onChange={onChange}
                  value={newpassword}
                  required
                />
              </MDBValidationItem>
            </div>
            <div className="userName mb-3">
              <MDBValidationItem
                className="mb-3 pb-1"
                feedback="Please enter your Confirm Password."
                invalid
              >
                <label className="plat-form-label">Confirm Password</label>
                <MDBInput
                  id="form1"
                  name="repeatpassword"
                  type="password"
                  onChange={onChange}
                  value={repeatpassword}
                  required
                />
              </MDBValidationItem>
            </div>
            <div className="row mb-4">
              <div className="text-center">
                <p className="mb-3">
                  <a
                    className="sign-page-link plat_cursor_pointer"
                    href
                    onClick={() => props.setForgotPasswordStatus(false)}
                  >
                    {" "}
                    Back to Sign in{" "}
                  </a>
                  <a
                    style={{ marginLeft: "10px" }}
                    className="sign-page-link plat_cursor_pointer"
                    href
                    onClick={submit}
                  >
                    {" "}
                    Resend OTP{" "}
                  </a>
                </p>
              </div>
              <div className="plat-loing-btn-body">
                {/* {loader ? <LinearProgress color="secondary" /> : null} */}
                <MDBBtn
                  disabled={loader}
                  type="submit"
                  className="me-1 plat-login-button"
                  onClick={submitOTP}
                  id="submit-signin"
                >
                  {" "}
                  submit
                </MDBBtn>
              </div>
            </div>
          </div>
        </MDBValidation>
      ) : (
        <MDBValidation>
          <div className="loginForm">
            <div className="userName mb-3">
              <MDBValidationItem
                className="mb-3 pb-1"
                feedback="Please enter the email."
                invalid
              >
                <label className="plat-form-label">Email ID</label>
                <MDBInput
                  label="Email"
                  id="form1"
                  name="emailid"
                  type="email"
                  onChange={onChange}
                  value={emailid}
                  required
                />
              </MDBValidationItem>
            </div>
            <div className="row mb-4">
              <div className="text-center">
                <p className="mb-3">
                  <a
                    className="sign-page-link plat_cursor_pointer"
                    href
                    onClick={() => props.setForgotPasswordStatus(false)}
                  >
                    {" "}
                    Back to Sign in{" "}
                  </a>
                </p>
              </div>
              <div className="plat-loing-btn-body">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="me-1 plat-login-button"
                  loading={buttonloading}
                  id="submit-signin-2"
                  onClick={submit}
                >
                  {" "}
                  Send OTP
                </Button>
              </div>
            </div>
          </div>
        </MDBValidation>
      )}
    </div>
  );
}
export default ForgotPassword;
