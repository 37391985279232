import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetSpendVMCloudRecords } from "../../action/costimizeV2Active";
import { Col, Row } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import { numberFormat, capitalizeFirst } from "../../custom_hook/CustomHook";
import awsicon from "../../../assets/images/aws.svg";
import AssetTrendChart from "./AssetTrendChart";
import AssetSpendListApplication from "./AssetSpendListApplication";

const AssetSpendListCloud = (props) => {
  const dispatch = useDispatch();
  // console.log(props)
  const [allcloudlist, setAllCloudList] = useState([]);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [allenvironmentlist, setAllenvironmentCloudList] = useState([]);
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  useEffect(() => {
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var first_month = month_list[0];
    var second_month = month_list[1];
    var thired_month = month_list[2];

    var apivalue = {
      environment: props.environmentdetails.title,
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      // first_month: month_list[0],
      // second_month: month_list[1],
      // thired_month: month_list[2],
    };

    dispatch(
      getAssetSpendVMCloudRecords({ companyName, selectedTopdate, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        if (data.newfunctionquery31.length > 0) {
          // console.log(data.newfunctionquery31);
          const enviroment = data.newfunctionquery31?.map((v) => v.enviroment);
          // var enviromentlist = new Set([...enviroment]);
          // console.log(enviroment)
          // setAllenvironmentCloudList(enviromentlist)
          const cloud = data.newfunctionquery31?.map((v) => v.cloud);

          var cloudlist = new Set([...cloud]);
          cloudlist = [...cloudlist];
          // console.log(cloudlist)

          const cloud_all_list = [];
          cloudlist.map((data_map) => {
            const cloud_data_list = data.newfunctionquery31?.filter((datas) => {
              return datas.cloud.toLowerCase() === data_map.toLowerCase();
            });
            const return_environment = getEnvironmentList(
              data_map,
              cloud_data_list,
              first_month,
              second_month,
              thired_month
            );
            cloud_all_list.push(return_environment);
          });
          setAllCloudList(cloud_all_list);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    companyName,
    selectedTopdate,
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    props,
  ]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    const totalcount = data_list.reduce((a, v) => (a = +a + +v.count), 0);
    //ram
    const ram_list = data_list.map((data) => {
      if (data) {
        const ram_value = data.ram?.split("GiB");
        if (ram_value?.length > 0) {
          return Number(ram_value[0].trim());
        } else {
          return 0;
        }
      }
    });

    const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
    const core = data_list.reduce((a, v) => (a = +a + +v.core), 0);
    var storage = data_list.reduce((a, v) => (a = +a + +v.storage), 0);
    if (storage > 1000) {
      storage = storage / 1000;
      storage = `${storage} TB`;
    } else {
      storage = `${storage} GiB`;
    }
    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: "-",
      size: "-",
      ram: ram === 0 ? "-" : Math.round(ram),
      core: core === 0 ? "-" : Math.round(core),
      storage: storage,
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      spend_trend: chart,
      count: totalcount,
    };
  };
  // console.log(allcloudlist.title)
  var coludname = allcloudlist.map((env) => env.title);
  // console.log(coludname)
  // console.log(allcloudlist)
  // console.log(allenvironmentlist)
  const handleClick = () => {
    // setOpenStatus(!openstatus);
    setIsRowClicked(!isRowClicked);
  };
  useEffect(() => {}, [isRowClicked]);
  return (
    <>
      {allcloudlist.map((cloudlist, indexc) => {
        const ran_num = Math.random();
        return (
          <ul className="cd-accordion__sub cd-accordion__sub--l2">
            <li className="cd-accordion__item cd-accordion__item--has-children">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name={"sub-group-level-" + indexc + ran_num}
                id={"sub-group-level-" + indexc + ran_num}
                // defaultChecked
              />
              <label
                className="cd-accordion__label"
                htmlFor={"sub-group-level-" + indexc + ran_num}
                onClick={handleClick}
              >
                <span className="accordion-full-width">
                  <ToggleButtonV2 status={true} newclass="cloud-list-icon">
                    <Row className="plat-table-assets-spend-thead">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-left-title cloud-list-title asset-spend-title-vs"
                      >
                        {cloudlist.title.toLowerCase() === "aws" ? (
                          <>
                            {/* <Icon
                              icon="logos:aws"
                              class="asset-spend-cloud-list-icon"
                            /> */}
                            <img src={awsicon} /> AWS({cloudlist.count})
                          </>
                        ) : cloudlist.title.toLowerCase() === "azure" ? (
                          <>
                            <Icon icon="vscode-icons:file-type-azure" />
                            Azure({cloudlist.count})
                          </>
                        ) : cloudlist.title.toLowerCase() === "oracle" ? (
                          <>
                            <Icon
                              icon="simple-icons:oracle"
                              style={{ color: "#FF0000" }}
                            />{" "}
                            Oracle({cloudlist.count})
                          </>
                        ) : (
                          <>
                            <Icon icon="skill-icons:gcp-light" /> GCP(
                            {cloudlist.count})
                          </>
                        )}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        ${numberFormat(cloudlist.spend)}
                        <Icon
                          className={
                            "me-1 " +
                            (cloudlist.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            cloudlist.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {cloudlist.name}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        -
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {cloudlist.ram}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {cloudlist.core}
                      </Col>
                      {/* {companyName === "fivestar" ||
                                      (companyName === "cmacgm" && ( */}
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {cloudlist.storage}
                      </Col>
                      {/* ))} */}
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        ${numberFormat(cloudlist.savings_opportunity)}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs plat-chart-center"
                      >
                        <AssetTrendChart
                          records={cloudlist.spend_trend}
                          cloud={cloudlist.title}
                          titles={props.environmentdetails.title}
                          application={props.application}
                          resname={props.resname}
                        />
                      </Col>
                    </Row>
                    <div className="asset-spend-border-bottom-vs"></div>
                  </ToggleButtonV2>
                </span>
              </label>
              {/* application list */}
              {isRowClicked && (
                <AssetSpendListApplication
                  environment={props.environmentdetails.title}
                  cloud={cloudlist.title}
                  application={props.application}
                  resname={props.resname}
                />
              )}
            </li>
          </ul>
        );
      })}
    </>
  );
};
export default memo(AssetSpendListCloud);
