import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import { numberFormat, capitalizeFirst } from "../../custom_hook/CustomHook";
import AssetTrendChart from "./AssetTrendChart";
import AssetSpendListCloud from "./AssetSpendListCloud";

const AssetSpendListEnvironment = (props) => {
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [allenvironmentlist, setAllEnvironmentList] = useState([]);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [openstatus, setOpenStatus] = useState(false);
  useEffect(() => {
    // console.log(props.allenvironmentlist)
    if (props.allenvironmentlist?.length > 0) {
      let currentDate = new Date(selectedTopdate);
      let lastThreeMonthsDate = new Date(selectedTopdate);
      lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
      var month_list = [];
      for (let i = 0; i < 3; i++) {
        let date = new Date(
          lastThreeMonthsDate.getFullYear(),
          lastThreeMonthsDate.getMonth() + i,
          1
        );
        month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      }

      var first_month = month_list[0];
      var second_month = month_list[1];
      var thired_month = month_list[2];

      const enviroment = props.allenvironmentlist?.map((v) => v.environment);
      var enviromentlist = new Set([...enviroment]);
      enviromentlist = [...enviromentlist];
      //environment list
      const environment_all_list = [];
      enviromentlist.map((data_map) => {
        const environment_data_list = props.allenvironmentlist?.filter(
          (datas) => {
            return datas.environment.toLowerCase() === data_map.toLowerCase();
          }
        );
        const return_environment = getEnvironmentList(
          data_map,
          environment_data_list,
          first_month,
          second_month,
          thired_month
        );
        environment_all_list.push(return_environment);
        setAllEnvironmentList(environment_all_list);
        // console.log(environment_all_list)
      });
    }
  }, [props]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    const totalcount = data_list.reduce((a, v) => (a = +a + +v.count), 0);

    //ram
    const ram_list = data_list.map((data) => {
      if (data) {
        const ram_value = data.ram?.split("GiB");
        if (ram_value?.length > 0) {
          return Number(ram_value[0].trim());
        } else {
          return 0;
        }
      }
    });

    const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
    const core = data_list.reduce((a, v) => (a = +a + +v.core), 0);
    var storage = data_list.reduce((a, v) => (a = +a + +v.storage), 0);
    if (storage > 1000) {
      storage = storage / 1000;
      storage = `${storage} TB`;
    } else {
      storage = `${storage} GiB`;
    }
    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: "-",
      size: "-",
      ram: ram === 0 ? "-" : Math.round(ram),
      core: core === 0 ? "-" : Math.round(core),
      storage: storage,
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      spend_trend: chart,
      count: totalcount,
    };
  };
  console.log(allenvironmentlist);
  var titles = allenvironmentlist.map((env) => env.title);
  // console.log(titles)
  var cloud = "all";
  var application = "all";
  var resname = "all";
  // var apivalue = {
  //   environment: props.environmentdetails.title,
  //   account: selectedAccount.toString(),
  //   cloud: selectedCloud.toString(),
  //   application: selectedApplication.toString(),
  //   services: selectedservices.toString(),
  //   os: selectedos.toString(),
  //   infra: selectedinfra.toString(),
  //   db: selecteddb.toString(),
  //   company_name: companyName,
  //   // first_month: month_list[0],
  //   // second_month: month_list[1],
  //   // thired_month: month_list[2],
  // };
  const handleClick = () => {
    setOpenStatus(!openstatus);
    setIsRowClicked(!isRowClicked);
  };
  console.log(allenvironmentlist);
  useEffect(() => {}, [isRowClicked]);
  return (
    <>
      {allenvironmentlist.map((environmentlist, index) => (
        <>
          <ul className="cd-accordion__sub cd-accordion__sub--l1">
            <li className="cd-accordion__item cd-accordion__item--has-children">
              <input
                className="cd-accordion__input"
                type="checkbox"
                id={"sub-group-" + index}
                // defaultChecked
              />
              <label
                className="cd-accordion__label"
                htmlFor={"sub-group-" + index}
                // onClick={() => {
                //   setOpenStatus(!openstatus);
                // }}
                onClick={handleClick}
              >
                <span className="accordion-full-width">
                  <ToggleButtonV2
                    status={true}
                    newclass="environment-list-icon"
                  >
                    <Row className="plat-table-assets-spend-thead">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className=" asset-left-title environment-list-title asset-spend-title-vs"
                      >
                        {/* <ToggleButton
                          status={false}
                          newclass="environment-list-icon"
                          clickoption={true}
                        /> */}
                        {capitalizeFirst(environmentlist.title)}(
                        {environmentlist.count})
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        ${numberFormat(environmentlist.spend)}
                        <Icon
                          className={
                            "me-1 " +
                            (environmentlist.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            environmentlist.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {environmentlist.name}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {environmentlist.size}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {environmentlist.ram}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {environmentlist.core}
                      </Col>
                      {/* {companyName === "fivestar" ||
                                (companyName === "cmacgm" && ( */}
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {environmentlist.storage}
                      </Col>
                      {/* ))} */}
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        $ {numberFormat(environmentlist.savings_opportunity)}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs plat-chart-center"
                      >
                        {console.log(environmentlist.spend_trend)}
                        <AssetTrendChart
                          records={environmentlist.spend_trend}
                          titles={environmentlist.title}
                          cloud={cloud}
                          application={application}
                          resname={resname}
                        />
                      </Col>
                    </Row>
                    <div className="asset-spend-border-bottom-vs"></div>
                  </ToggleButtonV2>
                </span>
              </label>
              {/* cloud list */}
              {/* {openstatus && ( */}
              {isRowClicked && (
                <AssetSpendListCloud
                  environmentdetails={environmentlist}
                  cloud={cloud}
                  application={application}
                  resname={resname}
                />
              )}
              {/* )} */}
            </li>
            <div>
              <Row className="assent-spend-footer">
                <Col span={24}></Col>
              </Row>
            </div>
          </ul>
        </>
      ))}
    </>
  );
};

export default memo(AssetSpendListEnvironment);
