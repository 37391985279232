import React from "react";
import { Table } from "antd";
import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
function MicroChart({ data }) {
  const isAllZero =
    parseFloat(data.pre_previous_week) === 0 &&
    parseFloat(data.previous_week) === 0 &&
    parseFloat(data.current_week) === 0;

  if (isAllZero) {
    return null; // Return null or a placeholder if all values are zero
  }
  const formatNumberWithCommas = (number) => {
    const formattedNumber = Math.round(number).toLocaleString(); // Format number with commas
    return `$${formattedNumber}`; // Add $ symbol
  };
  const chartOptions = {
    chart: {
      type: "line",

      height: 10,
      sparkline: {
        enabled: true, // Enable sparkline to shrink the chart
      },
    },
    series: [
      {
        name: "Spend",
        data: [
          parseFloat(data.pre_previous_week) || 0,
          parseFloat(data.previous_week) || 0,
          parseFloat(data.current_week) || 0,
        ],
      },
    ],
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,
      x: {
        show: false,
      },
      y: {
        formatter: (value) => formatNumberWithCommas(value), // Format tooltip value
      },
    },
    stroke: {
      width: 2,
      colors: ["#FF6A2F"],
    },
    markers: {
      size: 1,
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ["#000"], // Set data label color (replace 'blue' with your desired color)
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        height="30"
        width="100"
      />
    </div>
  );
}
function ApplicationLevelDeatiledView({
  applicationweekly,
  selectedDate,
  endDate,
}) {
  console.log(applicationweekly);
  const startDate = new Date(selectedDate);
  const end_Date = new Date(endDate);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(startDate.getTime() - oneWeek);
  const prevWeekEndDate = new Date(end_Date.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(startDate.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(end_Date.getTime() - 2 * oneWeek);

  // Format the dates to strings in the desired format (DD/MM - DD/MM)
  // const formatDate = (date) => {
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   return `${day}/${month}`;
  // };
  const threeweeks = new Date(startDate.getTime() - 3 * oneWeek);
  const threeweekend = new Date(end_Date.getTime() - 3 * oneWeek);
  // Format the dates to strings in the desired format (DD/MM - DD/MM)
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };
  const threeweek = `${formatDate(threeweeks)} - ${formatDate(threeweekend)}`;
  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(startDate)}-${formatDate(end_Date)}`;
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      align: "left",
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      align: "left",
    },
    {
      title: "VM Count",
      dataIndex: "vm_count",
      key: "vm_count",
      align: "right",
      render: (text, record, index) => {
        if (record.vm_count && record.vm_count !== "0") {
          return record.vm_count;
        } else {
          return "-";
        }
      },
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      key: "cpu",
      align: "right",
      render: (text, record, index) => {
        if (record.cpu && record.cpu !== "0") {
          return record.cpu;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Memory (GiB)",
      dataIndex: "memory",
      key: "memory",
      align: "right",
      render: (text, record, index) => {
        if (record.memory && record.memory !== "0") {
          return record.memory;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Storage (GiB)",
      dataIndex: "storage",
      key: "storage",
      align: "right",
      render: (text, record, index) => {
        if (record.storage && record.storage !== "0") {
          return record.storage;
        } else {
          return "-";
        }
      },
    },
    {
      title: threeweek,
      dataIndex: "pre_previous_week",
      key: "pre_previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.pre_previous_week && record.pre_previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: prePrevWeekRange,
      dataIndex: "pre_previous_week",
      key: "pre_previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.pre_previous_week && record.pre_previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: prevWeekRange,
      dataIndex: "previous_week",
      key: "previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.previous_week && record.previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: currentWeekRange,
      dataIndex: "current_week",
      key: "current_week",
      align: "right",
      render: (text, record, index) => {
        if (record.current_week && record.current_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
      align: "right",
      render: (text, record) => (
        <span>
          {record.difference > 0 ? (
            <>
              ${record.difference}
              <Icon icon="mdi:arrow-drop-up" color="red" />
            </>
          ) : record.difference < 0 ? (
            <>
              -${record.difference.toString().slice(1)}
              <Icon icon="mdi:arrow-down-drop" color="green" />
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      title: "Spend Trend",
      dataIndex: "spendtrend",
      key: "spentrend",
      render: (_, record) => <MicroChart data={record} />,
    },
  ];
  const rowClassName = (record) => {
    if (parseInt(record.difference, 10) > 100) {
      return "highlight-row";
    }
    return "";
  };

  const applications = [
    ...new Set(applicationweekly.map((item) => item.application)),
  ];
  // console.log(applications);
  return (
    <div>
      {applications.map((application) => (
        <div key={application}>
          <a href="#app">
            {" "}
            <h4 className="subtableheading">
              Application: &nbsp; {application}
            </h4>
          </a>
          <Table
            className="weeklytable"
            columns={columns}
            dataSource={applicationweekly.filter(
              (item) => item.application === application
            )}
            pagination={false}
            rowClassName={rowClassName}
          />
        </div>
      ))}
    </div>
  );
}

export default ApplicationLevelDeatiledView;
