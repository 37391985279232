import { createAsyncThunk } from "@reduxjs/toolkit";
import { useErrorNavigation } from "../../../custom_hook/CustomHook";
import {
  RIGHTSIZINGQUERY,
  CORE_COMPARISON,
  RAM_COMPARISON,
  STORAGE_COMPARISON,
  TCO_TABLE_COSTANALYSIS,
  TO_BE_CORE_CPU,
  TO_BE_CORE_RAM,
  TCO_TABLE_SUMMARY,
  UPDATE_VM,
  VM_DROPDOWN,
  STORAGE_DROPDOWN,
  EGRESS_DROPDOWN,
} from "./A2CRoverQueries";
import client_a2c from "../../client/A2C_Client";

export const rightSizingTable = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: RIGHTSIZINGQUERY,
        variables: {
          company: data.input1.company,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const corecomparisonchart = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: CORE_COMPARISON,
        variables: {
          company: data.inputchart.company,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const ramcomparisonchart = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: RAM_COMPARISON,
        variables: {
          company: data.inputchart.company,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const storagecomparisonchart = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: STORAGE_COMPARISON,
        variables: {
          company: data.inputchart.company,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const tcoanalysisrightsizing = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: TCO_TABLE_COSTANALYSIS,
        variables: {
          company: data.company,
          os_license: data.os_license,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const tobecorecpu = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: TO_BE_CORE_CPU,
        variables: {
          company: data.input1.company,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const tobecoreram = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: TO_BE_CORE_RAM,
        variables: {
          company: data.input1.company,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const summarytco = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: TCO_TABLE_SUMMARY,
        variables: {
          company: data.company,
          os_license: data.os_license,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatevmedit = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_a2c.query({
        query: UPDATE_VM,
        variables: {
          company: data.company,
          curr_vm_type: data.curr_vm_type,
          new_vm_type: data.new_vm_type,
          region_curr: data.region_curr,
        },
      });
      return aasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const dropdownvminstance = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const vmlist = await client_a2c.query({
        query: VM_DROPDOWN,
        variables: {
          company: data.input1.company,
          region: data.input1.region,
        },
      });
      return vmlist;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const dropdownstorage = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const storage = await client_a2c.query({
        query: STORAGE_DROPDOWN,
        variables: {
          company: data.company,
          region: data.region,
        },
      });
      return storage;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const egresstable = createAsyncThunk(
  "rightsizing/chart",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const egress = await client_a2c.query({
        query: EGRESS_DROPDOWN,
        variables: {
          company: data.company,
          egress_new: data.egress_new,
          resource_name_curr: data.resource_name_curr,
        },
      });
      return egress;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
