import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Row, Col } from "react-bootstrap";
import { message, Form, Input, Select, DatePicker, Button,Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  addAnnualBudget,
  deleteAnnualBudget,
  updateAnnualBudget,
} from "../../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from "react-redux";

const YearDetailsScreen = (props) => {
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  console.log(companyName)
  const [buttonloading, setButtonLoading] = useState(false);
  const [yearlist, setYearList] = useState([]);
  const [inputdisable, setInputDisable] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [form] = Form.useForm();
  const jan_value = useRef();
  const feb_value = useRef();
  const mar_value = useRef();
  const apr_value = useRef();
  const may_value = useRef();
  const jun_value = useRef();
  const jul_value = useRef();
  const aug_value = useRef();
  const sep_value = useRef();
  const oct_value = useRef();
  const nov_value = useRef();
  const dec_value = useRef();
  const dispatch = useDispatch();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    var records = {
      annual_total: "",
      year: "",
      jan: "",
      feb: "",
      mar: "",
      apr: "",
      may: "",
      jun: "",
      jul: "",
      aug: "",
      sep: "",
      oct: "",
      nov: "",
      dec: "",
    };
    if (props.budgetyearrecords) {
      records = {
        annual_total: props.budgetyearrecords.annual_total,
        year: props.budgetyearrecords.budget_year,
        jan: props.budgetyearrecords.budget_jan,
        feb: props.budgetyearrecords.budget_feb,
        mar: props.budgetyearrecords.budget_mar,
        apr: props.budgetyearrecords.budget_apr,
        may: props.budgetyearrecords.budget_may,
        jun: props.budgetyearrecords.budget_jun,
        jul: props.budgetyearrecords.budget_jul,
        aug: props.budgetyearrecords.budget_aug,
        sep: props.budgetyearrecords.budget_sept,
        oct: props.budgetyearrecords.budget_oct,
        nov: props.budgetyearrecords.budget_nov,
        dec: props.budgetyearrecords.budget_dec,
      };
      const current_date = new Date();
      if (
        new Date(current_date).getFullYear() >
        Number(props.budgetyearrecords.budget_year)
      ) {
        setInputDisable([
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ]);
      } else {
        if (
          new Date(current_date).getFullYear() ==
          Number(props.budgetyearrecords.budget_year)
        ) {
          const val_list = [];
          for (let index = 0; index < 12; index++) {
            const check_month = +index + +1;
            if (new Date(current_date).getMonth() + 1 <= check_month) {
              val_list.push(false);
            } else {
              val_list.push(true);
            }
            setInputDisable(val_list);
          }
        } else {
          setInputDisable([
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ]);
        }
      }
    } else {
      setInputDisable([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ]);
    }
    form.setFieldsValue(records);

    //year list
    const current_year = new Date().getFullYear();
    const yearlist = [];
    for (let index = 0; index < 10; index++) {
      yearlist.push(new Date().getFullYear() + index);
    }
    const yearstatus = props.yearlistrecords.map((data) => {
      const index = yearlist.indexOf(Number(data.budget_year));
      if (index > -1) {
        yearlist.splice(index, 1);
      }
    });
    const yearrecordslist = [];
    yearlist.map((date) => {
      yearrecordslist.push({
        value: date,
        label: date,
      });
    });
    setYearList(yearrecordslist);
  }, [props.budgetyearrecords]);

  const handleSubmit = (values) => {
    setButtonLoading(true);
    const inputs = {
      budget_amount:
        values.jan +
        "," +
        values.feb +
        "," +
        values.mar +
        "," +
        values.apr +
        "," +
        values.may +
        "," +
        values.jun +
        "," +
        values.jul +
        "," +
        values.aug +
        "," +
        values.sep +
        "," +
        values.oct +
        "," +
        values.nov +
        "," +
        values.dec,
      budget_month:
        "January,February,March,April,May,June,July,August,September,October,November,December",
      budget_year: values.year,
      company_name: companyName,
    };
    console.log(inputs);
    if (props.yeardrawerdeletestatus) {
      const budget_id = props.budgetyearrecords.budget_id;
      dispatch(updateAnnualBudget({ inputs, budget_id }))
        .unwrap()
        .then((data) => {
          message.success("Updated Successfully Done");
          props.yearsetDrawerStatus(false);
          setButtonLoading(false);
          props.setBudgetYearUpdate(!props.budgetyearupdate);
        })
        .catch((err) => {
          console.log(err.message);
          setButtonLoading(false);
        });
    } else {
      // console.log(inputs);
      dispatch(addAnnualBudget({ inputs }))
        .unwrap()
        .then((data) => {
        console.log(data);
          message.success("Added Successfully Done");
          props.yearsetDrawerStatus(false);
          setButtonLoading(false);
          props.setBudgetYearUpdate(!props.budgetyearupdate);
        })
        .catch((err) => {
          console.log(err.message);
          setButtonLoading(false);
        });
    }
  };
  const checkAnnualTotal = () => {
    var total =
      +jan_value.current.input.value +
      +feb_value.current.input.value +
      +mar_value.current.input.value +
      +apr_value.current.input.value +
      +may_value.current.input.value +
      +jun_value.current.input.value +
      +jul_value.current.input.value +
      +aug_value.current.input.value +
      +sep_value.current.input.value +
      +oct_value.current.input.value +
      +nov_value.current.input.value +
      +dec_value.current.input.value;
    form.setFieldsValue({
      annual_total: total,
    });
  };
  const rowDelete = () => {
    setConfirmLoading(true);
    const budget_id = props.budgetyearrecords.budget_id;
    const company_name = companyName;
    dispatch(deleteAnnualBudget({ budget_id, company_name }))
      .unwrap()
      .then((data) => {
        console.log(data);
        props.yearsetDrawerStatus(false);
        setDrawerDeleteStatus(false);
        setConfirmLoading(false);
        message.success("Deleted");
        props.setBudgetYearUpdate(!props.budgetyearupdate);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <>
      <Modal
      className="costmodal"
        open={props.yeardrawerstatus}
        onCancel={props.yearchangeDrawerStatus}
        title="Budget Planning"
        footer={null}
        deletestatus={props.yeardrawerdeletestatus}
        onFinish={handleSubmit}
        rowDelete={rowDelete}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        setConfirmLoading={setConfirmLoading}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={initialValues}
          form={form}
        >
          <Row>
            <Col>
              <Form.Item
                name="year"
                label="Year"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  disabled={props.yeardrawerdeletestatus}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={yearlist}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="jan"
                label="Jan"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={jan_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[0]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="feb"
                label="Feb"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={feb_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[1]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="mar"
                label="Mar"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={mar_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[2]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="apr"
                label="Apr"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={apr_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[3]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="may"
                label="May"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={may_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[4]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="jun"
                label="Jun"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={jun_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[5]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="jul"
                label="Jul"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={jul_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[6]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="aug"
                label="Aug"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={aug_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[7]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="sep"
                label="Sep"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={sep_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[8]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="oct"
                label="Oct"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={oct_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[9]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="nov"
                label="Nov"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={nov_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[10]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="dec"
                label="Dec"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  ref={dec_value}
                  onChange={checkAnnualTotal}
                  disabled={inputdisable[11]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="annual_total"
                label="Annual Total"
                rules={[
                  {
                    required: true,
                    message: "",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button
                htmlType="button"
                className="cancel-btn"
                onClick={() => props.yearsetDrawerStatus(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-btn"
                // style={{ background: "#1b5f9d", color: "#fff", float: "right" }}
                loading={buttonloading}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default YearDetailsScreen;
