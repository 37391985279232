import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetSpendVMResorcesRecords } from "../../action/costimizeV2Active";
import { Col, Row, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import { numberFormat, capitalizeFirst } from "../../custom_hook/CustomHook";
import AssetTrendChart from "./AssetTrendChart";
import DetailsView from "./DetailsView";

const AssetSpendListRes = (props) => {
  console.log(props, "res");
  const [allresorceslist, setAllResorcesList] = useState([]);
  const [detailsviewstatus, setDetailsViewStatus] = useState(false);
  const [popupdetails, setPopupDetails] = useState([]);
  const dispatch = useDispatch();
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  useEffect(() => {
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var first_month = month_list[0];
    var second_month = month_list[1];
    var thired_month = month_list[2];

    var apivalue = {
      environment: props.environment,
      account: selectedAccount.toString(),
      cloud: props.cloud,
      application: props.application,
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      first_month: month_list[0],
      second_month: month_list[1],
      thired_month: month_list[2],
    };

    dispatch(
      getAssetSpendVMResorcesRecords({
        companyName,
        selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(">>>>", data);
        if (data.newfunctionquery33.length > 0) {
          const application = data.newfunctionquery33?.map(
            (v) => v.resourcename
          );
          // console.log(application)
          var applicationlist = new Set([...application]);
          applicationlist = [...applicationlist];
          const cloud_all_list = [];
          applicationlist.map((data_map) => {
            const cloud_data_list = data.newfunctionquery33?.filter((datas) => {
              return (
                datas.resourcename.toLowerCase() === data_map.toLowerCase()
              );
            });
            const return_environment = getEnvironmentList(
              data_map,
              cloud_data_list,
              first_month,
              second_month,
              thired_month
            );
            // console.log(return_environment);
            cloud_all_list.push(return_environment);
          });
          // console.log(cloud_all_list);
          setAllResorcesList(cloud_all_list);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    //ram
    const ram_list = data_list.map((data) => {
      if (data) {
        const ram_value = data.ram?.split("GiB");
        if (ram_value?.length > 0) {
          return Number(ram_value[0].trim());
        } else {
          return 0;
        }
      }
    });

    const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
    const core = data_list.reduce((a, v) => (a = +a + +v.core), 0);
    var storage = data_list.reduce((a, v) => (a = +a + +v.storage), 0);
    if (storage > 1000) {
      storage = storage / 1000;
      storage = `${storage} TB`;
    } else {
      storage = `${storage} GiB`;
    }
    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: data_list[0].resourcename,
      size: data_list[0].instancetype ? data_list[0].instancetype : "-",
      //   size: data_list[0].resourcename,
      ram: ram === 0 ? "-" : Math.round(ram),
      core: core === 0 ? "-" : Math.round(core),
      storage: storage,
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      spend_trend: chart,
      count: data_list.length,
      index: data_list[0],
      resourceid: data_list[0].resourceid,
    };
  };

  const openPopupDetails = (data) => {
    setPopupDetails(data);
    setDetailsViewStatus(true);
  };

  // console.log(allresorceslist)
  return (
    <>
      {detailsviewstatus && (
        <DetailsView
          detailsviewstatus={detailsviewstatus}
          setDetailsViewStatus={setDetailsViewStatus}
          details={popupdetails}
        />
      )}
      {allresorceslist.map((resourcelist) => (
        <ul className="cd-accordion__sub cd-accordion__sub--l3">
          <li className="cd-accordion__item cd-accordion__item--has-children">
            <a
              className="cd-accordion__label cd-accordion__label--icon-img"
              href="javascript:void(0)"
            >
              <span className="accordion-full-width">
                <Row className="plat-table-assets-spend-thead">
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="asset-left-title cloud-list-title asset-spend-title-vs"
                  ></Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="asset-spend-values-vs plat-text-right"
                  >
                    ${resourcelist.spend}
                    <Icon
                      className={
                        "me-1 " +
                        (resourcelist.spend > 0
                          ? "text-danger-icon-custom"
                          : "text-success-icon-custom")
                      }
                      icon={
                        resourcelist.spend > 0
                          ? "material-symbols:arrow-drop-up"
                          : "material-symbols:arrow-drop-down"
                      }
                      style={{
                        fontSize: "20px",
                      }}
                    />
                  </Col>
                  <Col
                    xxl={3}
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    className="asset-spend-values-vs justify-content-center"
                  >
                    {/* {console.log(resourcelist.title)}
                    {console.log(resourcelist.name,"name")} */}

                    <span onClick={() => openPopupDetails(resourcelist.index)}>
                      {resourcelist.name.length > 10 ? (
                        <>
                          <Tooltip placement="bottom" title={resourcelist.name}>
                            {resourcelist.name.substring(0, 10)}
                            ...
                          </Tooltip>
                        </>
                      ) : (
                        resourcelist.name
                      )}
                    </span>
                  </Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="asset-spend-values-vs justify-content-center"
                  >
                    {/* {resourcelist.size} */}
                    {resourcelist.size.length > 10 ? (
                      <>
                        <Tooltip placement="bottom" title={resourcelist.size}>
                          {resourcelist.size.substring(0, 10)}
                          ...
                        </Tooltip>
                      </>
                    ) : (
                      resourcelist.size
                    )}
                  </Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="asset-spend-values-vs plat-text-right"
                  >
                    {resourcelist.ram}
                  </Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="asset-spend-values-vs plat-text-right"
                  >
                    {resourcelist.core}
                  </Col>
                  {/* {companyName === "fivestar" ||
                                                (companyName === "cmacgm" && ( */}
                  <Col
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="asset-spend-values-vs plat-text-right"
                  >
                    {resourcelist.storage}
                  </Col>
                  {/* ))} */}
                  <Col
                    xxl={3}
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    className="asset-spend-values-vs plat-text-right"
                  >
                    ${numberFormat(resourcelist.savings_opportunity)}
                  </Col>
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    className="asset-spend-values-vs plat-chart-center"
                  >
                    <AssetTrendChart
                      records={resourcelist.spend_trend}
                      cloud={props.cloud}
                      titles={props.environment}
                      application={props.application}
                      resname={resourcelist.title}
                      resourceid={resourcelist.resourceid}
                    />
                  </Col>
                </Row>
                <div className="asset-spend-border-bottom-vs"></div>
              </span>
            </a>
          </li>
        </ul>
      ))}
    </>
  );
};

export default memo(AssetSpendListRes);
