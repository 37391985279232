import React, { useEffect } from "react";
import { Space, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
    // height: "100%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColumns: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // padding: 25,
    // paddingTop: 15,
    // paddingLeft: 15,
    // paddingRight: 15,
  },
  tableCol: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // paddingTop: 15,
    // paddingLeft: 15,
    // paddingRight: 15,
    // padding: 25,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  tableColleft: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCellright: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 8,
    marginRight: 3,
  },
  tableCellleft: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  tableColright: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
  },
  tableColleft: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCellright: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 8,
    marginRight: 3,
  },
  tableCellleft: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  tableColright: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
  },
});

function WeeklySpendByServiceLevel({ tableWeeklySpend }) {
  // console.log("spendss", tableWeeklySpend);
  useEffect(() => {
    console.log("spendss", tableWeeklySpend);
  }, []);

  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Service</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>No of Applications</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>No of Environment</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>22/10-28/10</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>29/10-04/11</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>05/11-11/11</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Comparison of last two weeks</Text>
          </View>
        </View>

        {tableWeeklySpend.map((item) => {
          return (
            <View style={styles.tableRow}>
              <View style={styles.tableColleft}>
                <Text style={styles.tableCellleft}>{item.service}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {item.no_of_application}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {item.no_of_environment}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.pre_previous_week)}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.previous_week)}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.current_week)}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  {item.difference >= 0
                    ? `$${formatNumberWithCommas(item.difference)}`
                    : `-$${formatNumberWithCommas(
                        item.difference.toString().slice(1)
                      )}`}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </div>
  );
}

export default WeeklySpendByServiceLevel;
