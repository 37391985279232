import { Button } from "antd";
import React from "react";
import arrow from "../../assets/images/landing/Arrow Icon.svg";
import headertext from "../../assets/images/landing/Rectangle (1).png";
import header from "../../assets/images/landing/Rectangle.png";
import google from "../../assets/images/landing/image 280.png";
import apple from "../../assets/images/landing/image 281.png";
import underline from "../../assets/images/landing/shape.png";
import assetImg from "../../assets/images/landing/Landing-Main-2.svg";
import bannerImg from "../../assets/images/landing/Landing-Main.png";
import bottomimg from "../../assets/images/landing/Landing-Page3.png";
import "./landingPage.css";
// import { Icon } from "@iconify/react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();
  const handleClickSignin = () => {
    console.log("sign in");
    navigate("/signin");
  };
  const handlecontactclick = () => {
    window.location.href = "https://www.1cloudhub.com/contact-us/";
  };
  return (
    <>
      <div className="banner-bg ">
        <div className="d-flex justify-content-between align-items-center py-5 ">
          <div className="signin-home-1">
            <img height={20} src={header} alt="Capture" /> &nbsp;
            <img height={20} src={headertext} alt="Capture_text" /> &nbsp;
          </div>
          <div className="signin-home">
            <Button className="sign-in-button " onClick={handleClickSignin}>
              {" "}
              SIGN IN{" "}
            </Button>
            <Button
              className="sign-up-button "
              onClick={() => navigate("/signup")}
              id="sign-up-id"
            >
              {" "}
              Sign Up{" "}
            </Button>
          </div>
        </div>

        <div className="d-flex justify-content-center ">
          <div className="sub-para">
            <h3 className="secondary-text">
              Making your multi cloud operation<br></br>
              more{" "}
              <span className="banner-text">
                visible, efficient and simple.
              </span>
            </h3>
            <div>
              <h2 className="secondary-text">CloudShifu.Ai</h2>
              <img
                height={10}
                width={200}
                src={underline}
                alt="Capture"
                className="line-img"
              />{" "}
              &nbsp;
            </div>

            <br></br>
            <a href="https://www.1cloudhub.com/contact-us/" target="_blank">
              <Button className="sign-up-button">
                Try for free{" "}
                <Icon
                  icon="ph:arrow-right-light"
                  class="table-coloum-chart-icon"
                />
              </Button>
            </a>
          </div>
          <div>
            <img
              className=" banner-img"
              height={"700"}
              src={bannerImg}
              alt="bannerImg"
            />
          </div>
        </div>
      </div>

      <div className="d-flex  justify-content-center align-items-center">
        <div className="sub-para">
          <h3 className="banner-text">Asset Management</h3>
          <p className="secondary-text">
            <span className="secondary-text-2"> Cloud Shifu </span>
            is your comprehensive platform that provides a <br></br>
            <span className="banner-text">360 degree view </span>
            of your infrastructure’s assets, covering all
            <br></br>
            aspects of multi cloud asset management.
          </p>

          <h3 className="banner-text">SecOps</h3>
          <p className="secondary-text">
            With SecOps capabilities, you can track security events,<br></br>{" "}
            detect and respond to threats, and maintain compliance <br></br>with
            industry standards.
          </p>
        </div>

        <div className="sub-para">
          <img className="img-fluid" src={assetImg} alt="assetImg" />
        </div>
      </div>
      <div className="d-flex   mb-5">
        <div>
          <img src={bottomimg} height={500} alt="assetImg" />
        </div>
        <div className="sub-para justify-content-center ">
          <h2 className="secondary-text-bottom">
            Ready to take <br></br> your{" "}
            <span className="banner-text">Business</span> <br></br>to the next
            level?
          </h2>
          <br></br>
          &nbsp;{" "}
          <a href="https://www.1cloudhub.com/contact-us/" target="_blank">
            <Button className="secondary-text-bottom">
              {" "}
              GET CONSULATION{" "}
              <img src={arrow} alt="assetImg" className="arrow-icon" />
            </Button>
          </a>
        </div>
      </div>
      <br />
      {/* <div className="d-flex footer-text" >
                <div className="signin-home-1">
                    <img height={20} src={header} alt="Capture" /> &nbsp;
                    <img height={20} src={headertext} alt="Capture_text" /> &nbsp;
                </div>
                <div>
                    <p>Contact us</p><br></br>
                    <p>Singapore </p><br></br>
                    <p>India</p><br></br>
                    <p>United States</p><br></br>
                    <p>Philippines</p><br></br>
                    <p>Malaysia</p><br></br>
                </div>
                <div>
                    <p>Categories</p><br></br>
                    <p>Our Offering </p><br></br>
                    <p>Blogs</p><br></br>
                    <p>Case Studies</p><br></br>
                    <p>FAQs</p><br></br>
                    <p>Privacy Policy</p><br></br>
                </div>
                <div>
                    <p>About </p><br></br>
                    <p>Our Story </p><br></br>
                    <p>Leadership</p><br></br>
                    <p>Media</p><br></br>
                    <p>Careers</p><br></br>
                </div>
                <div><p>test</p></div>

            </div> */}
      <div class="footer">
        <div class="footer-column">
          <div className="signin-home-1">
            <img height={20} src={header} alt="Capture" /> &nbsp;
            <img height={20} src={headertext} alt="Capture_text" /> &nbsp;
          </div>
          <br></br>
          <div>
            <img height={20} src={google} alt="Capture" /> &nbsp;
            <img height={20} src={apple} alt="Capture_text" /> &nbsp;
          </div>
        </div>
        <div class="footer-column">
          <div class="footer-heading">Contact us</div>
          <p class="footer-body">Singapore</p>
          <p class="footer-body">India</p>
          <p class="footer-body">United States</p>
          <p class="footer-body">Philippines</p>
          <p class="footer-body">Malaysia</p>
        </div>
        <div class="footer-column">
          <div class="footer-heading">Categories</div>
          <p class="footer-body">Our Offering</p>
          <p class="footer-body">Blogs</p>
          <p class="footer-body">Case Studies</p>
          <p class="footer-body">FAQs</p>
          <p class="footer-body">Privacy Policy</p>
        </div>
        <div class="footer-column">
          <div class="footer-heading">About</div>
          <p class="footer-body">Our Story</p>
          <p class="footer-body">Leadership</p>
          <p class="footer-body">Media</p>
          <p class="footer-body">Careers</p>
        </div>

        <div class="footer-column">
          <div class="footer-heading">Follow Us</div>
          <div class="social-icons">
            <span class="social-icon">
              <Icon icon="il:instagram" class="soc-icon" />
            </span>{" "}
            <span class="social-icon">
              <Icon icon="ic:baseline-facebook" class="soc-icon" />
            </span>{" "}
            <span class="social-icon">
              <Icon icon="mdi:youtube" class="soc-icon" />
            </span>{" "}
            <span class="social-icon">
              <Icon icon="mdi:twitter" class="soc-icon" />
            </span>
          </div>
        </div>
      </div>
      <div class="footer">© CloudShifu.Ai 2023. All Rights Reserved</div>
    </>
  );
}
export default LandingPage;
