import React, { useState ,useEffect} from "react";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import { Progress, Row, Col, Tabs, Button } from "antd";
import "./../a2c_rover/a2c_rover.css";
import Security from "./Security";
import CostOptmization from "./CostOptmization";
import PerrformanceEfficiency from "./PerrformanceEfficiency";
import OperationalExcellence from "./OperationalExcellence";
import Sustainabality from "./Sustainabality";
import { useNavigate, } from "react-router-dom";
import RealiablityWAR from "./RealiablityWAR";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
function Realiability() {
  const navigate = useNavigate();
  let [file, setFile] = useState(0);
  let [notapplicable,setNotApplicable] = useState(0);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  

const handledashboard= () => {
navigate('/wardashboard');
};

useEffect(() => {
  updateLastUpdatedTime();

}, []);

const updateLastUpdatedTime = () => {
  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;
  setLastUpdatedTime(formattedDate);
};



  const items = [
    {
      key: "1",
      label: "Reliability",
      children: <RealiablityWAR file={file} setFile={setFile} notapplicable={notapplicable} setNotApplicable={setNotApplicable}/>,
    },
    {
      key: "2",
      label: "Security",
      children: <Security file={file} setFile={setFile} notapplicable={notapplicable} setNotApplicable={setNotApplicable} />,
    },
    {
      key: "3",
      label: "Cost Optimization",
      children: <CostOptmization file={file} setFile={setFile} notapplicable={notapplicable} setNotApplicable={setNotApplicable}/>,
    },
    {
      key: "4",
      label: "Performance Efficiency",
      children: <PerrformanceEfficiency file={file} setFile={setFile} notapplicable={notapplicable} setNotApplicable={setNotApplicable}/>,
    },
    {
      key: "5",
      label: "Operational Excellence",
      children: <OperationalExcellence file={file} setFile={setFile} notapplicable={notapplicable} setNotApplicable={setNotApplicable}/>,
    },
    {
      key: "6",
      label: "Sustainability",
      children: <Sustainabality file={file} setFile={setFile} notapplicable={notapplicable} setNotApplicable={setNotApplicable}/>,
    },
  ];

const {selectedWorkload} = useSelector((state) => state.a2cRover);


  const totalprogress = 6;

  return (
    <>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">Well Architected Review</div>
                <span className="d-flex align-items-end">
                  {/* <A2cFilter /> */}
                </span>
              </div>
              <div className="">
                <div className="mb-3">
                  <Row justify={"space-between"}>
                    <Col>
                      {" "}
                      <p className="text-white">
                      
                </p>
                    </Col>
                    <Col>
                      <Button
                        className="buttonclass  "
                        icon={<Icon icon="uil:upload" />}
                      >
                        Export Report
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        className="savebutton "
                        icon={<Icon icon="uil:exit" />}
                        onClick={handledashboard}
                      >
                        Exit Assessment
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Row gutter={[16, 16]} justify={"space-between"}>
                  {/* <br /> */}
                  <Col>
                    <h4 className="text-white">
                    {selectedWorkload}
                    </h4>
                  </Col>
                  <Col>
                  <p className="text-sm white-color">Last Updated on {lastUpdatedTime}</p>
                  </Col>
                </Row>
                <div className="border-line mt-3">
                  <Row
                    className="pt-2"
                    // justify={"center"}
                    align={"top"}
                    gutter={[16, 16]}
                  >
                    <Col lg={1}
                      md={1}
                      sm={1}></Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-start"
                    >
                      <div className="">
                        <div className="color-orange">
                          War Score &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-2">
                        <Progress style={{color:"white"}} size="small" strokeColor={"#ff6a2f"} circleTextColor={"#FFF"}
                              className="war-progress titletab" type="circle" percent={40} />
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-start"
                    >
                      <div className="">
                        <div className="color-orange">
                          Unanswered &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                         42&nbsp;
                          <Icon
                            icon={"material-symbols:info-outline"}
                            style={{ color: "green" }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          High &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          14&nbsp;
                          <Icon
                            icon={"ph:warning-bold"}
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          Medium &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          16&nbsp;
                          <Icon
                            icon={"ph:warning-bold"}
                            style={{ color: "orange" }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          Low &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          --&nbsp;
                          {/* <Icon
                    icon={"material-symbols:info-outline"}
                    style={{ color: "green" }}
                  /> */}
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          Not Applicable &nbsp;
                          <Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white mt-4">
                          {notapplicable}&nbsp;
                          {/* <Icon
                    icon={"material-symbols:info-outline"}
                    style={{ color: "green" }}
                  /> */}
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={5}
                      md={5}
                      sm={5}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="">
                        <div className="color-orange">
                          Assessment Progress
                          &nbsp;<Icon icon={"material-symbols:info-outline"} />
                        </div>
                        <div className="text-center text-lg text-normal text-white">
                          <div
                            className="progress-score mt-4"
                            style={{
                              width: 200,
                            }}
                          >
                            <Progress
                              percent={30}
                              size="small"
                              style={{color:"white"}}
                              className="war-progress titletab"
                            />
                            {/* <p className="progress-percentage text-sm">30%</p> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Tabs
                    className="cams-tabs"
                    defaultActiveKey="1"
                    items={items}
                  />
                </Row>
              </div>

              <br />
              {/* Graph */}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Realiability;
