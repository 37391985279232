import React, { useState, useEffect } from "react";
import { Table, Spin, Card } from "antd";

const ServiceRequestTable = (props) => {
  const tablerecords = props.tablerecords;
  console.log(tablerecords);
  const [showSpinner, setShowSpinner] = useState(true);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (!Array.isArray(tablerecords)) {
      setTableData([]);
      setShowSpinner(false);
      return;
    }
    const filteredRecords = tablerecords.filter(
      (record) =>
        record.request_category === "Service Request" ||
        record.request_category === "Change Request"
    );
    const requestCounts = {};
    const completedCounts = {};
    const failedCounts = {};
    const cancelledCounts = {};
    const postCompletionCounts = {};
    filteredRecords.forEach((record) => {
      const { request_category, ticket_status, change_request_count } = record;

      if (!requestCounts[request_category]) {
        requestCounts[request_category] = 0;
        completedCounts[request_category] = 0;
        failedCounts[request_category] = 0;
        cancelledCounts[request_category] = 0;
        postCompletionCounts[request_category] = 0;
      }
      requestCounts[request_category]++;

      if (ticket_status === "completed") {
        completedCounts[request_category]++;
      } else if (ticket_status === "Failed") {
        failedCounts[request_category]++;
      } else if (ticket_status === "Post Completion") {
        postCompletionCounts[request_category]++;
      } else if (ticket_status === "cancelled") {
        cancelledCounts[request_category]++;
      }
    });

    const data = Object.keys(requestCounts).map((request_category) => {
      const totalRequests = requestCounts[request_category];
      const completedRequests = completedCounts[request_category];
      const failedRequests = failedCounts[request_category];
      const cancelledRequests = cancelledCounts[request_category];
      const postCompletionRequests = postCompletionCounts[request_category];

      const completionPercentage =
        totalRequests > 0
          ? ((completedRequests / totalRequests) * 100).toFixed(2)
          : "-";
      const failedPercentage =
        totalRequests > 0
          ? ((failedRequests / totalRequests) * 100).toFixed(2)
          : "-";
      const cancelledPercentage =
        totalRequests > 0
          ? ((cancelledRequests / totalRequests) * 100).toFixed(2)
          : "-";

      const postCompletionPercentage =
        totalRequests > 0
          ? ((postCompletionRequests / totalRequests) * 100).toFixed(2)
          : "-";

      return {
        request: getRequestLabel(request_category),
        count: totalRequests,
        completedOnTime:
          completionPercentage !== "0.00" ? `${completionPercentage}%` : "-",
        failed: failedPercentage !== "0.00" ? `${failedPercentage}%` : "-",
        cancelled:
          cancelledPercentage !== "0.00" ? `${cancelledPercentage}%` : "-",
        postCompletion:
          postCompletionPercentage !== "0.00"
            ? `${postCompletionPercentage}%`
            : "None",
      };
    });

    setTableData(data);
    setShowSpinner(false);
  }, [props, tablerecords]);

  const getRequestLabel = (request_category) => {
    switch (request_category) {
      case "Change Request":
        return "CR";
      case "Service Request":
        return "SR";
      default:
        return request_category;
      // return null;
    }
  };

  const columns = [
    {
      title: "Request",
      dataIndex: "request",
      key: "request",
      render: (text) => <span>{text}</span>,
    },
    // {
    //   title: "Count",
    //   dataIndex: "count",
    //   key: "count",
    // },
    {
      title: "Completed on Time",
      dataIndex: "completedOnTime",
      key: "completedOnTime",
    },
    {
      title: "Failed",
      dataIndex: "failed",
      key: "failed",
    },
    {
      title: "Cancelled",
      dataIndex: "cancelled",
      key: "cancelled",
    },
    {
      title: "Post completion",
      dataIndex: "postCompletion",
      key: "postCompletion",
    },
  ];

  return (
    <>
   <Card className="reso">
      
        <Table
          className="slm"
          style={{ overflow: "auto" }}
          loading={{ indicator: <Spin />, spinning: showSpinner }}
          columns={columns}
          dataSource={tableData}
          pagination={true}
        />
    </Card>
    </>
  );
};

export default ServiceRequestTable;
