import QuickView from "../QuickView";
import Tapmenu from "../Tapmenu";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "antd";
import "./budgetplanning.css";
import ReactApexChart from "react-apexcharts";
import ListBudgetPlanning from "./ListBudgetPlanning";
import { useEffect, useState } from "react";
import EditBudgetPlanning from "./EditBudgetPlanning";
import BudgetYearList from "./BudgetYearList";
import BudgetAllList from "./BudgetAllList";
import { MDBIcon } from "mdb-react-ui-kit";
import YearDetailsScreen from "./YearDetailsScreen";
import YearBudgetDetailsScreen from "./YearBudgetDetailsScreen";
import YearBudgetChart from "./YearBudgetChart";
import PlannedvsActual from "./PlannedvsActual";
import CustimizeFooter from "../CustimizeFooter";
import {
  getProjectedSpend,
  getAllSummaryDashboard,
} from "../../action/costimizedashboardAction";
import {
  listAnnualBudget,
  listApplicationBudget,
} from "../../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { numberFormat } from "../../custom_hook/CustomHook";
import { Icon } from "@iconify/react";
import { listCloudBudget } from "../../action/costimizeV2Active";

const BudgetPlanning = () => {
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedinfra,
    selecteddb,
    selectedservices,
    selectedos,
    selectedTopdate,
  } = useSelector((state) => state.constimize);
  const [addrecordsstatus, addRecordsStatus] = useState(false);
  const [projected, setProjected] = useState(0);
  const [yeardrawerstatus, yearsetDrawerStatus] = useState(false);
  const [yeardrawerdeletestatus, yearsetDrawerDeleteStatus] = useState(false);
  const [yearbudgetdrawerstatus, setYearBudgetDrawerStatus] = useState(false);
  const [monthallocated, setMonthAllocated] = useState(0);
  const [budgetyearrecords, setBudgetYearRecords] = useState();
  const [budgetChartHeight, setBudgetChartHeight] = useState(350);

  const [chartvalue, setChartValue] = useState({
    options: {},
    series: [],
  });
  const [chartcal, setChartCal] = useState({
    spend: 0,
    budget: 0,
    balance: 0,
  });
  const [actualtablerecords, setActualTableRecords] = useState();
  const [annualplannedlist, setAnnualPlannedList] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const dispatch = useDispatch();

  const [yearlistrecords, setYearListRecords] = useState([]);
  const [budgetyearupdate, setBudgetYearUpdate] = useState(false);
  const [applicationbudgettable, setApplicationBudgetTable] = useState([]);
  const [budgetapplicationupdate, setBudgetApplicationUpdate] = useState(false);
  const [budgetapplicationdata, setBudgetApplicationData] = useState(false);

  useEffect(() => {
    //chart height
    var windowWidth = Number(window.innerWidth);
    switch (true) {
      case windowWidth <= 1080 && windowWidth >= 1025:
        setBudgetChartHeight(320);
        break;
      case windowWidth <= 1024 && windowWidth >= 769:
        setBudgetChartHeight(300);
        break;
      case windowWidth <= 768 && windowWidth >= 427:
        setBudgetChartHeight(270);
        break;
      default:
        setBudgetChartHeight(350);
    }
    //  console.log("in");
    //  console.log(windowWidth);

    var projected_date = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-01`;
    var project_value = 0;
    var allocated_buget = 0;
    var month_spend = 0;
    var chartlength;
    var chartcurrentdate = new Date();
    if (selectedTopdate.split(",").length == 3) {
      chartlength = 3;
    } else if (selectedTopdate.split(",").length == 6) {
      chartlength = 6;
    } else if (selectedTopdate.split(",").length == 12) {
      chartlength = 12;
    } else {
      chartlength = 1;
      chartcurrentdate = new Date(selectedTopdate);
    }

    // projected_date = projected_date.toString();
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };
    //projected
    dispatch(getProjectedSpend({ companyName, projected_date, apivalue }))
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery8) {
          setProjected(Number(data.newfunctionquery8[0].projected_spend));
          project_value = Number(data.newfunctionquery8[0].projected_spend);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    //actual spend
    var temp_chartdata = [];
    var temp_chartmonth = [];

    var actual_table_records = [];
    var current_year = new Date().getFullYear();
    for (let index = 0; index < 12; index++) {
      const month_2_current = new Date(
        chartcurrentdate.getFullYear(),
        chartcurrentdate.getMonth() - index,
        1
      );
      const selectedTopdate_new = `${current_year}-${+index + +1}-01`;
      actual_table_records.push(selectedTopdate_new);
    }
    var selectedTopdate_new = actual_table_records.toString();

    dispatch(
      getAllSummaryDashboard({ companyName, selectedTopdate_new, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        setActualTableRecords(data.newfunctionquery3);
      })
      .catch((err) => {
        console.log(err.message);
      });

    for (let index = 0; index < chartlength; index++) {
      const month_2_current = new Date(
        chartcurrentdate.getFullYear(),
        chartcurrentdate.getMonth() - index,
        1
      );
      const selectedTopdate_new = `${month_2_current.getFullYear()}-${
        month_2_current.getMonth() + 1
      }-01`;
      temp_chartmonth.push(selectedTopdate_new);
    }
    var selectedTopdate_new = temp_chartmonth.toString();
    dispatch(
      getAllSummaryDashboard({ companyName, selectedTopdate_new, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        //chart
        if (data.newfunctionquery3.length > 0) {
          month_spend = data.newfunctionquery3[0].spend;
        }
        var total_budget_value;
        if (monthallocated === 0) {
          total_budget_value = project_value;
        } else {
          total_budget_value = monthallocated;
        }
        setChartCal({
          spend: Number(month_spend),
          budget: Number(total_budget_value),
          balance: Number(total_budget_value - month_spend),
        });
        //chart
        var chart_percentage;
        if (
          Number.isInteger(Math.round((month_spend * 100) / total_budget_value))
        ) {
          chart_percentage = Math.round(
            (month_spend * 100) / total_budget_value
          );
        } else {
          chart_percentage = 0;
        }
        const options = {
          chart: {
            type: "radialBar",
            offsetY: -38,
            offsetX: 0,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              offsetX: 0,
              track: {
                background: "#c1e6aa",
                strokeWidth: "100%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: false,
                  top: 2,
                  left: 0,
                  color: "#999",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "22px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            colors: ["#8cb273"],
            type: "solid",
          },
          title: {
            text: "Budget Overview",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 31,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#263238",
            },
          },
        };
        const series = [chart_percentage];
        setChartValue({
          options: options,
          series: series,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });
  }, [monthallocated, selectedTopdate]);


  useEffect(() => {
    //annual budget

    const cloud = selectedCloud.toString();
    dispatch(listAnnualBudget({ companyName, cloud }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setYearListRecords(data.list_annual_budget);
        data.list_annual_budget.map((data) => {
          if (Number(data.budget_year) == new Date().getFullYear()) {
            var allocated_buget = 0;

            setAnnualPlannedList([
              data.budget_jan,
              data.budget_feb,
              data.budget_mar,
              data.budget_apr,
              data.budget_may,
              data.budget_jun,
              data.budget_jul,
              data.budget_aug,
              data.budget_sept,
              data.budget_oct,
              data.budget_nov,
              data.budget_dec,
            ]);

            //condition
            const current_month = new Date().getMonth();
            if (current_month === 1) {
              allocated_buget = data.budget_jan;
            } else if (current_month === 2) {
              allocated_buget = data.budget_feb;
            } else if (current_month === 3) {
              allocated_buget = data.budget_mar;
            } else if (current_month === 4) {
              allocated_buget = data.budget_apr;
            } else if (current_month === 5) {
              allocated_buget = data.budget_may;
            } else if (current_month === 6) {
              allocated_buget = data.budget_jun;
            } else if (current_month === 7) {
              allocated_buget = data.budget_jul;
            } else if (current_month === 8) {
              allocated_buget = data.budget_aug;
            } else if (current_month === 9) {
              allocated_buget = data.budget_sept;
            } else if (current_month === 10) {
              allocated_buget = data.budget_oct;
            } else if (current_month === 11) {
              allocated_buget = data.budget_nov;
            } else {
              allocated_buget = data.budget_dec;
            }
            setMonthAllocated(Number(allocated_buget));
          }
        });
      })
      .catch((err) => {
        console.log(err.message);
      });

  }, [budgetyearupdate]);

  useEffect(() => {
    //application budget
    var budget_year;
    if (selectedTopdate.split(",").length > 1) {
      const datelist = selectedTopdate.split(",").map((data) => {
        return new Date(data).getFullYear();
      });
      const uniqueArr = new Set([...datelist]);
      budget_year = [...uniqueArr].toString();
    } else {
      budget_year = new Date(selectedTopdate).getFullYear();
    }
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      budget_year: budget_year,
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
    };
    dispatch(listApplicationBudget({ input }))
      .unwrap()
      .then(({ data }) => {
        setApplicationBudgetTable(data.list_application_budget);
        setBudgetApplicationData(!budgetapplicationdata);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    budgetapplicationupdate,
    selectedCloud,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    selectedTopdate,
  ]);

  const yearchangeDrawerStatus = () => {
    yearsetDrawerStatus(false);
  };

  const editYearDetails = (data) => {
    setBudgetYearRecords(data);
    yearsetDrawerStatus(true);
    yearsetDrawerDeleteStatus(true);
  };

  const addYearDetails = () => {
    setBudgetYearRecords();
    yearsetDrawerStatus(true);
    yearsetDrawerDeleteStatus(false);
  };

  const changeyearBudgetDrawerStatus = () => {
    setYearBudgetDrawerStatus(false);
  };

  const editYearBudgetDetails = (data) => {
    setBudgetYearRecords(data);
    setYearBudgetDrawerStatus(true);
    yearsetDrawerDeleteStatus(true);
  };
  const addYearBudgetDetails = () => {
    setBudgetYearRecords();
    setYearBudgetDrawerStatus(true);
    yearsetDrawerDeleteStatus(false);
  };

  return (
    <>
      <YearDetailsScreen
        yeardrawerstatus={yeardrawerstatus}
        yearchangeDrawerStatus={yearchangeDrawerStatus}
        yearsetDrawerStatus={yearsetDrawerStatus}
        yeardrawerdeletestatus={yeardrawerdeletestatus}
        yearlistrecords={yearlistrecords}
        setYearListRecords={setYearListRecords}
        budgetyearrecords={budgetyearrecords}
        setBudgetYearUpdate={setBudgetYearUpdate}
        budgetyearupdate={budgetyearupdate}
      />
      <YearBudgetDetailsScreen
        yeardrawerstatus={yearbudgetdrawerstatus}
        yearchangeDrawerStatus={changeyearBudgetDrawerStatus}
        yearsetDrawerStatus={setYearBudgetDrawerStatus}
        yeardrawerdeletestatus={yeardrawerdeletestatus}
        budgetyearrecords={budgetyearrecords}
        budgetapplicationupdate={budgetapplicationupdate}
        setBudgetApplicationUpdate={setBudgetApplicationUpdate}
      />
      <div className="plat-dashboard-body">
        <div className="plat-dashboard-quickview">
          <QuickView />
        </div>

        <div className="plat-dashboard-tabs">
          <Tapmenu
            excalname="savings models"
            type="savingsmodel"
            osstatus={false}
            dbstatus={false}
            title="Budget Planning"
          />
          <div className="plat-full-asset-spend-cover">
            <Row>
              <Col lg={2} md={4} className="budget-projected-col">
                <Card className="budget-projected-box">
                  <h1 className="budget-allocated-content">
                    Allocated Budget <br />
                    for the current month
                  </h1>
                  <h1 className="budget-box-cneter-icon">
                    <Icon icon="uil:usd-square" />
                  </h1>
                  <h1 className="budget-allocated-value">
                    ${numberFormat(monthallocated)}
                  </h1>
                </Card>
              </Col>
              <Col lg={2} md={4} className="budget-projected-col">
                <Card className="budget-projected-box">
                  <h1 className="budget-allocated-content">
                    Projected for the current month
                  </h1>
                  <h1 className="budget-box-cneter-icon">
                    <Icon icon="ic:outline-balance" />
                  </h1>
                  <h1 className="budget-allocated-value">
                    ${numberFormat(projected)}
                    {/* <MDBIcon className={"me-1 text-success"}  fas icon="caret-down"  style={{ fontSize: '12px' }} />
                                        <label className={"labelsmallest poppins-font text-success"}>40%</label> */}
                  </h1>
                  {/* <h1 className='budget-allocated-witget-content'>27% exceeding budget</h1> */}
                </Card>
              </Col>
              <Col lg={3} md={4} className="budget-projected-cost-col">
                <div className="budget-projected-cost-box">
                  <Row>
                    <Col>
                      {/* <h1 className='budget-cost-title'>Budget Overview</h1> */}
                      <ReactApexChart
                        options={chartvalue.options}
                        series={chartvalue.series}
                        type="radialBar"
                        height={budgetChartHeight}
                      />
                      <div className="chart-down-value">
                        <Row>
                          <Col>
                            <h1 className="budget-cost-value">
                              ${numberFormat(chartcal.spend)}
                            </h1>
                            <h1 className="budget-cost-value">Spend</h1>
                          </Col>
                          <Col>
                            <h1 className="budget-cost-value">
                              ${numberFormat(chartcal.budget)}
                            </h1>
                            <h1 className="budget-cost-value">Budget</h1>
                          </Col>
                          <Col>
                            {chartcal.balance > 0 ? (
                              <h1 className="budget-cost-value">
                                ${numberFormat(chartcal.balance)}
                              </h1>
                            ) : (
                              <h1
                                className="budget-cost-value"
                                style={{ color: "rgb(190, 30, 45)" }}
                              >
                                ${numberFormat(chartcal.balance)}
                              </h1>
                            )}
                            <h1 className="budget-cost-value">Balance</h1>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={5} md={12}>
                <YearBudgetChart
                  actualtablerecords={actualtablerecords}
                  yearlistrecords={yearlistrecords}
                />
              </Col>
            </Row>
            <Row className="budget-mt">
              <Col>
                <PlannedvsActual
                  actualtablerecords={actualtablerecords}
                  yearlistrecords={yearlistrecords}
                />
                {/* <BudgetYearList 
                                    editYearDetails = {editYearDetails}
                                /> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <BudgetAllList
                  editYearBudgetDetails={editYearBudgetDetails}
                  addYearBudgetDetails={addYearBudgetDetails}
                  applicationbudgettable={applicationbudgettable}
                  budgetapplicationupdate={budgetapplicationupdate}
                  budgetapplicationdata={budgetapplicationdata}
                  annualplannedlist={annualplannedlist}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col lg={7}>
                            {
                                addrecordsstatus ?
                                    <>
                                        <div onClick={() => addRecordsStatus(false)}>
                                            <Icon  icon="material-symbols:arrow-back" class="plat-saving-add-icon"/>
                                        </div>
                                        <EditBudgetPlanning />
                                    </>
                                    
                                :
                                    <>
                                        <div onClick={() => addRecordsStatus(true)}>
                                            <Icon icon="material-symbols:edit" class="plat-saving-add-icon"/>
                                        </div>
                                        <ListBudgetPlanning />
                                    </>
                            }
                            </Col> */}
              <Col>
                <BudgetYearList
                  editYearDetails={editYearDetails}
                  addYearDetails={addYearDetails}
                  yearlistrecords={yearlistrecords}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <CustimizeFooter />
    </>
  );
};

export default BudgetPlanning;
