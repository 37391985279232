import { memo, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Spin,
  DatePicker,
  message,
  Select,
  Switch,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import SpendDayChart from "./SpendDayChart";
import SpendDayResorcesChart from "./SpendDayResorcesChart";
import { useState } from "react";
import { Icon } from "@iconify/react";
import SpendDayInstanceChart from "./SpendDayInstanceChart";
import moment from "moment";
import {
  listSpendbyDate,
  countResourcesSpendDate,
} from "../../action/costimizeV2Active";
import SpendDayServiceChart from "./SpendDayServiceChart";

const SpendServiceChartBody = (props) => {
 
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dispatch = useDispatch();
  const [carttype, setCartType] = useState("datelist");
  const [resourcesdate, setResourcesDate] = useState(new Date());
  const [selectedresources, setSelectedResources] = useState();
  const [selectedservice, setSelectedService] = useState();
  const [preloader, setPreloader] = useState(false);
  const [allspendstatus, setAllSpendStatus] = useState(false);
  const [daychartrecords, setDayChartRecords] = useState([]);
  const [dates,setDates]=useState(false);
  const [chartfldate, setChartFLDate] = useState({
    first_date: "",
    last_date: "",
  });
  const [totalpagecount, setTotalPageCount] = useState(1);
  const [pagecount, setPageCount] = useState(1);
  const [resourcesview, setResourceView] = useState([]);
  const [spenddate, setSpendDate] =useState(selectedTopdate)
 
  // const [selectDate, setSelectDate] = useState(props.spenddate);
  const[selectDate,setSelectDate] = useState(selectedTopdate)
  const[selectmonth,setSelectMonth] = useState(selectedTopdate)
 
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate().toString().padStart(2, "0");  
    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day}`;
  
    return formattedDate;
  };
  
  
  const formattedDate = formatDate(selectedTopdate);

const handlePreviousMonth = () => {

      const previousMonth = new Date(selectedTopdate);
      previousMonth.setMonth(selectmonth.getMonth() - 1);
      props.setSpendDate(`${previousMonth.getFullYear()}-${previousMonth.getMonth() + 1}-01`)

    
    };

  const handleNextMonth = () => {
    const nextMonth = new Date(selectedTopdate);
    nextMonth.setMonth(selectmonth.getMonth() + 1);
    props.setSpendDate(`${nextMonth.getFullYear()}-${nextMonth.getMonth() + 1}-01`)
  };
 

  useEffect(() => {
  // console.log("testclick")
  // console.log(props.spenddate)
  
    setCartType("datelist");
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date:selectedTopdate,
       
    };
    setPreloader(true);
    dispatch(listSpendbyDate({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        if (data.daywise_spend.length > 0) {
          const total = data.daywise_spend.length;
          setChartFLDate({
            first_date: data.daywise_spend[0].date,
            last_date: data.daywise_spend[total - 1].date,
          });
          setDayChartRecords(data.daywise_spend);

        }
        else{
          setDayChartRecords([]);
          props.setDayChartStatus(true)
           message.info('No records found.');
        }
        setPreloader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
  
    // props.spenddate,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment
    
  ]);
 
  const onChange = (date, dateString) => {
    if (dateString) {
      // sendResorceschartRecords(dateString);
      setResourcesDate(dateString);
      setCartType("servicelist");
    }
  };
  const resourcesChartDate = (selectmonth) => {

    setResourcesDate(selectmonth);
    setCartType("servicelist");
  };

  const sendResorceschartRecords = (data, service) => {
    setPreloader(true);
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: service,
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: data,
    };
    dispatch(countResourcesSpendDate({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        setTotalPageCount(
          Math.round(data.daywise_spend_resorce_pagesize[0].total_pages)
        );
        setPreloader(false);
      })
      .catch((err) => {
        setPreloader(false);
        console.log(err);
      });
  };
  const onChangeResorces = (event) => {
    setCartType("instancelist");
    setSelectedResources(event);
  };
  const spendChartHandleChange = (event) => {
    if (event) {
      setAllSpendStatus(true);
    } else {
      setAllSpendStatus(false);
    }
  };

  function disabledDate(current) {
    const date = new Date(selectedTopdate);
    // Get the current year and month
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    if (chartfldate.first_date !== "") {
      var startDate = moment(getPreviousDay(new Date(chartfldate.first_date)));
      var endDate = moment(getNextDay(new Date(chartfldate.last_date)));
    } else {
      var startDate = moment(
        getPreviousDay(
          new Date(`${year}-${month}-${new Date(year, month - 1, 1).getDate()}`)
        )
      );
      if (
        new Date(selectedTopdate).getMonth() + 1 ===
        new Date().getMonth() + 1
      ) {
        var endDate = moment(getNextDay(new Date()));
      } else {
        var endDate = moment(
          getNextDay(
            new Date(`${year}-${month}-${new Date(year, month, 0).getDate()}`)
          )
        );
      }
    }
    return !(
      current.isAfter(startDate, "day") && current.isBefore(endDate, "day")
    );
  }
  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return previous;
    
  }
  function getNextDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() + 1);
    return previous;
  }

  useEffect(() => {
    setSelectDate(new Date(selectedTopdate));  
    setSelectMonth(new Date(selectedTopdate));

  }, [selectedTopdate,props.spenddate]);

   const isCurrentMonth =  formattedDate=== selectedTopdate ;
  return (
    <>
      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card className="plat-card-1">
          <Spin spinning={preloader} wrapperClassName="costimize-loader">
            {carttype === "datelist" ? (
              <>
                <Row gutter={[16, 16]} className="mt-1">
                  <Col xxl={10} xl={10} lg={10} md={24} sm={24} xs={24}>
                    <div className="d-flex justify-content-start">
                      <div>
                        <Icon
                          // icon="material-symbols:close"
                          className="spend-day-back-btu"
                          onClick={() => props.setDayChartStatus(true)}
                        />
                      </div>
                      <div>
                     
                        <h1 className="spend-day-chart-title">
                          {/* Usage Trend by Day */}
                          
                          {/* Date trend for the month of {selectDate} */}
                          Date trend for the month of {" "}
                          <Icon icon="raphael:arrowleft" onClick={handlePreviousMonth} /> 
                          {selectmonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                           {/* <Icon icon="raphael:arrowright" onClick={handleNextMonth}/> */}
                            {!isCurrentMonth && <Icon icon="raphael:arrowright" onClick={handleNextMonth}/>}  
                        </h1>
                    
                      </div>
                    </div>
                  </Col>
                  <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
                    <Row>
                      <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                    
                      </Col>
                      <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                        <div className="d-flex justify-content-end ">
                          <h1 className="spend-day-chart-content">
                            By Total{" "}
                            <Switch
                              className="costdemaze_switch"
                              defaultChecked={allspendstatus}
                              onChange={spendChartHandleChange}
                            />{" "}
                            By Service
                          </h1>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {allspendstatus ? (
                
                  <SpendDayServiceChart
                      spenddate={props.spenddate}
                      preloader={preloader}
                      setPreloader={setPreloader}
                      resourcesChartDate={resourcesChartDate}
                      // formattedDate={formattedDate}
                      selectDate={selectDate}
                      selectmonth={selectmonth}
                    />
                ) : (
                  <>
                  <SpendDayChart
                    records={daychartrecords}
                    resourcesChartDate={resourcesChartDate}
                    spenddate={props.spenddate}
                    selectDate={selectDate}
                    // selectmonth={selectmonth}
                    prostatus={props.setDayChartStatus}
                    setSpendDate={props.setSpendDate}
                    selectmonth={selectmonth}

                  />

                    
                  </>
                )}
              </>
            ) : carttype === "resorceslist" ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-between">
                    <div>
                      <Icon
                        icon="ic:round-arrow-back-ios"
                        className="spend-day-back-btu"
                        onClick={() => setCartType("servicelist")}
                      />
                    </div>
                    <div>
                      <h1 className="spend-day-chart-title">
                        {selectedservice} Resources By{" "}
                        {monthNamesShort[new Date(resourcesdate).getMonth()]}{" "}
                        {new Date(resourcesdate).getDate()}
                      </h1>
                    </div>
                  </div>
                 
                </div>
                <SpendDayResorcesChart
                  records={resourcesview}
                  pagecount={pagecount}
                  resourcesdate={resourcesdate}
                  setPreloader={setPreloader}
                  preloader={preloader}
                  selectedservice={selectedservice}
                />
                <div className="d-flex justify-content-between">
                  <div>
                    {pagecount >= 2 && (
                      <>
                        <button
                          className="table-add-button"
                          onClick={() => setPageCount(pagecount - 1)}
                        >
                          <Icon icon="ooui:previous-ltr" /> Previous
                        </button>
                      </>
                    )}
                  </div>
                  <div>
                    {totalpagecount > 1 && (
                      <>
                        <button
                          className="table-add-button"
                          onClick={() => setPageCount(+pagecount + +1)}
                        >
                          Next <Icon icon="ooui:next-ltr" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : carttype === "servicelist" ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-between">
                    <div>
                      <Icon
                        icon="ic:round-arrow-back-ios"
                        className="spend-day-back-btu"
                        onClick={() => setCartType("datelist")}
                      />
                    </div>
                    <div>
                      <h1 className="spend-day-chart-title">
                        Services By Day -{" "}
                        {monthNamesShort[new Date(resourcesdate).getMonth()]}{" "}
                        {new Date(resourcesdate).getDate()}
                      </h1>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Icon
                      icon="iconamoon:information-circle-fill"
                      style={{ marginTop: "7px", fontSize: "19px", color:"#848484" }}
                    />
                    <h1
                      className="spend-day-chart-content"
                      style={{ marginRight: "10px", marginLeft: "3px" }}
                    >
                      Click Service and View Resources
                    </h1>
                  </div>
                </div>
                <SpendDayInstanceChart
                  records={resourcesview}
                  resourcesdate={resourcesdate}
                  setPreloader={setPreloader}
                  preloader={preloader}
                  setSelectedService={setSelectedService}
                  setCartType={setCartType}
                  sendResorceschartRecords={sendResorceschartRecords}
                />
              </>
            ) : (
              <></>
            )}
          </Spin>
        </Card>
      </Col>
    </>
  );
};
export default memo(SpendServiceChartBody);

