import { Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import Chart from "react-google-charts";
import {
  assesscloudreadiness,
  assessmigration,
  assessmigrationcomp,
  assessis,
  assesstocore,
  assesswaf,
  applicationModernizationASSIS,
  applicationModernizationtobe,
  assesadvisoryscore,
} from "../../components/action/graphql/a2crover/A2CReducer";
import "./../a2c_rover/a2c_rover.css";
function AnalysisPerspective() {
  const dispatch = useDispatch();
  const [modernizationtype, setModernization] = useState([]);
  const [migration, setmigration] = useState([]);
  const [migrationcomplex, setmigrationcomplex] = useState([]);
  const [assessischart, setassessischart] = useState([]);
  const [assesstobecore, setAssesstobecore] = useState([]);
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const {
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
    selectedAccount,
    selectedou,
  } = useSelector((store) => store.a2cRover);

  console.log(selectedApplication, "selectedappp");
  // console.log(selectedEnvironment);
  const input = {
    application: selectedApplication.toString(),
    company: company,
    data_center_name: selecteddc.toString(),
    db: selecteddb.toString(),
    environment: selectedEnvironment.toString(),
    os: selectedos.toString(),
    account_name: selectedAccount.toString(),
    organiation_unit: selectedou.toString(),
  };

  const input_cloudreadiness = {
    application: selectedApplication.toString(),
    company: company,
    data_center_name: selecteddc.toString(),
    environment: selectedEnvironment.toString(),
  };
  useEffect(() => {
    console.log(selectedos);
    dispatch(assesscloudreadiness(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.assess_cloud_mordenization.map((datas) => {
          return {
            modernization_type: datas.modernization_type,
            resource_count: datas.resource_count,
          };
        });
        setModernization(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  const [waf, setWAF] = useState([]);
  console.log("testt");
  useEffect(() => {
    dispatch(assesswaf(input))
      .unwrap()
      .then(({ data }) => {
        console.log("waf>>>", data.operate_waf_spread[0]);
        setIsShowSpinner(false);
        setWAF(data.operate_waf_spread[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
    selectedou,
  ]);

  const waf_series = [];
  const waf_label = [];
  for (let key in waf) {
    if (waf.hasOwnProperty(key)) {
      if (waf[key] !== "operate_waf_count") {
        console.log("waf[key]", waf[key]);
        waf_series.push(waf[key]);
        waf_label.push(key);
      }
    }
  }
  const numeric_waf_series = waf_series.map((value) => parseInt(value, 10));
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      labels: {
        colors: "#FFFFF",
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: waf_label, // Add labels here
    series: numeric_waf_series,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };
  useEffect(() => {
    dispatch(assessmigration(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_migration_methodology.map((datas) => {
          return {
            migration_methodology: datas.migration_methodology,
            resource_count: datas.resource_count,
          };
        });
        setmigration(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  useEffect(() => {
    dispatch(assessmigrationcomp(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_migration_complexity.map((datas) => {
          return {
            complexity_level: datas.complexity_level,
            application_count: datas.application_count,
          };
        });
        setmigrationcomplex(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  useEffect(() => {
    dispatch(assessis(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assessvm_appwise.map((datas) => {
          return {
            application: datas.application,
            data_center_name: datas.data_center_name,
            vm_count: datas.vm_count,
          };
        });
        setassessischart(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  const isassess = [["From", "To", "Server"]];

  assessischart.forEach((record, index) => {
    isassess.push([
      record.application,
      record.data_center_name,
      parseInt(record.vm_count),
    ]);
  });
  useEffect(() => {
    dispatch(assesstocore(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_to_be_vm_appwise.map((datas) => {
          return {
            application: datas.application,
            data_center_name: datas.data_center_name,
            vm_count: datas.vm_count,
            region: datas.region,
          };
        });
        setAssesstobecore(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  const coreassess = [["From", "To", "Server"]];

  assesstobecore.forEach((record, index) => {
    coreassess.push([
      record.application,
      record.region,
      parseInt(record.vm_count),
    ]);
  });

  const k8asses = [["From", "To", "Server"]];
  const options_sankey_assesis = {
    sankey: {
      node: {
        label: {
          color: "white",
        },
      },
    },
  };
  const [k8asseschart, setk8asseschart] = useState([]);
  useEffect(() => {
    dispatch(applicationModernizationASSIS(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_vm_k8s_appwise.map((datas) => {
          return {
            infra: datas.infra,
            application: datas.application,
            vm_count: datas.vm_count,
          };
        });
        setk8asseschart(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  k8asseschart.forEach((record, index) => {
    k8asses.push([record.application, record.infra, parseInt(record.vm_count)]);
  });

  const k8assestobe = [["From", "To", "Server"]];
  const [assesToBeChart, setAssesTobeChart] = useState([]);

  const options_sankey_app_tobe = {
    sankey: {
      node: {
        label: {
          color: "white",
        },
      },
    },
  };

  useEffect(() => {
    dispatch(applicationModernizationtobe(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_to_be_vm_k8s_appwise.map((datas) => {
          return {
            infra: datas.infra,
            application: datas.application,

            vm_count: datas.vm_count,
          };
        });
        setAssesTobeChart(record);
        console.log(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  assesToBeChart.forEach((record, index) => {
    k8assestobe.push([
      record.application,
      record.infra,
      parseInt(record.vm_count),
    ]);
  });
  const [assesAdvisory, setAssesAdvisory] = useState([]);
  useEffect(() => {
    dispatch(assesadvisoryscore(input_cloudreadiness))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        console.log(data, "advisory score data");
        setAssesAdvisory(data.assess_waf_methodology[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  const advisory_labels = [];
  const advisory_series = [];
  for (let key in waf) {
    if (assesAdvisory.hasOwnProperty(key)) {
      if (assesAdvisory[key] !== "asses_advisory") {
        advisory_series.push(assesAdvisory[key]);
        advisory_labels.push(key);
      }
    }
  }
  console.log(advisory_labels, "lables");
  console.log(advisory_series, "series");
  const numeric_advisory_series = advisory_series.map((value) =>
    parseInt(value, 10)
  );
  const advisory_options = {
    chart: {
      type: "donut",
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: advisory_labels, // Add labels here
    series: numeric_advisory_series,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };
  advisory_options.legend.labels = {
    colors: "#FFFFFF",
  };
  options.legend.labels = {
    colors: "#FFFFFF",
  };

  const labels_cloud = modernizationtype.map((item) => item.modernization_type);
  const value_cloud = modernizationtype.map((item) => item.resource_count);
  const numericValues_cloud = value_cloud.map((value) => parseInt(value, 10));
  const series_cloud = numericValues_cloud;
  const options_cloud = {
    chart: {
      type: "donut",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    stroke: {
      show: false,
    },
    labels: labels_cloud,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };
  options_cloud.legend.labels = {
    colors: "#FFFFFF",
  };
  const labels_mig = migration.map((item) => item.migration_methodology);
  const values = migration.map((item) => item.resource_count);
  const numericValues = values.map((value) => parseInt(value, 10));
  const series_mig = numericValues;
  const options_mig = {
    chart: {
      type: "pie",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    stroke: {
      show: false,
    },

    labels: labels_mig,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
  };
  options_mig.legend.labels = {
    colors: "#FFFFFF",
  };
  const chartData_mig = {
    labels: labels_mig,
    series: series_mig,
  };
  const labels_com = migrationcomplex.map((item) => item.complexity_level);
  const values_com = migrationcomplex.map((item) => item.application_count);
  const numericValues_com = values_com.map((value) => parseInt(value, 10));
  const series_com = numericValues_com;
  const options_com = {
    chart: {
      type: "pie",
    },
    stroke: {
      show: false,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    labels: labels_com,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
  };
  options_com.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  const chartData_com = {
    labels: labels_com,
    series: series_com,
  };
  const options_sankey = {
    sankey: {
      node: {
        label: {
          color: "white",
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };
  return (
    <div>
      {" "}
      <>
        <Row gutter={[16, 16]}>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
            <Row>
              <Card
                title="Cloud Readiness Assessment(%)"
                className="operate-cards"
              >
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={options_cloud}
                    series={series_cloud}
                    className="piechart"
                    type="donut"
                    height={300}
                  />
                )}
              </Card>
            </Row>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
            <Row>
              <Card
                title="Advisory Score - All Controls (%)"
                className="operate-cards"
              >
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    // options={advisory_options}
                    // series={advisory_options.series}
                    className="piechart"
                    options={options}
                    series={numeric_waf_series}
                    type="donut"
                    height={300}
                  />
                )}
              </Card>
            </Row>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
            <Row>
              <Card title="Migration Complexity(%)" className="operate-cards">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={options_com}
                    series={chartData_com.series}
                    className="piechart"
                    type="pie"
                    height={300}
                  />
                )}
              </Card>
            </Row>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
            <Row>
              <Card title="Migration Methodology(%)" className="operate-cards">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={options_mig}
                    series={chartData_mig.series}
                    className="piechart"
                    type="pie"
                    height={300}
                  />
                )}
              </Card>
            </Row>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Card title="As-Is" className="operate-cards ">
              <div className="d-flex justify-content-center">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <Chart
                    chartType="Sankey"
                    // width="350px"
                    height="300px"
                    data={isassess}
                    options={options_sankey}
                  />
                )}
              </div>
            </Card>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Card title="To-Be" className="operate-cards">
              <div className="d-flex justify-content-center">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <Chart
                    chartType="Sankey"
                    // width="600px"
                    height="300px"
                    data={coreassess}
                    options={options_sankey}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>

        <Card
          className="operate-cards "
          title="Application Moderniszation - Top 15"
        >
          <Row gutter={[16, 16]} type="flex" justify={"center"}>
            <Col span={12}>
              <p className="color-orange text-center">As-Is</p>
              <div className="p-3">
                {assesToBeChart.length === 0 ? (
                  <div
                    className="chart-no-data"
                    style={{
                      textAlign: "center",
                      marginTop: "15%",
                      color: "#FFF",
                    }}
                  >
                    No Data Available
                  </div>
                ) : (
                  <Chart
                    chartType="Sankey"
                    // width="90%"
                    height="400px"
                    data={k8asses}
                    options={options_sankey_assesis}
                  />
                )}
              </div>
            </Col>
            <Col span={12}>
              <p className="color-orange text-center">To-Be</p>
              <div className="p-3">
                <Chart
                  chartType="Sankey"
                  // width="400px"
                  height="400px"
                  data={k8assestobe}
                  options={options_sankey_app_tobe}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </>
    </div>
  );
}

export default AnalysisPerspective;
