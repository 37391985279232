import React from "react";
import { Space, Table, Tag } from "antd";
import { Icon } from "@iconify/react";

import { colors } from "@mui/material";
function OverallSummaryUI({ spendoverall, stdate }) {
  console.log(spendoverall, "overall");
  console.log(stdate, "startdate");

  const dateObj = new Date(stdate); // Creating a Date object from the string

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentMonthIndex = dateObj.getMonth();
  console.log(currentMonthIndex, "currentmntindex"); // Get the current month index
  const currentMonth = months[currentMonthIndex]; // Get the name of the current month

  // Calculate previous month
  const previousMonthIndex =
    currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
  const previousMonth = months[previousMonthIndex]; // Get the name of the previous month

  console.log("Current Month:", currentMonth); // Output: Current Month: Nov
  console.log("Previous Month:", previousMonth);

  const spend = spendoverall.map((item) => item.previous_month_spend);
  const handleClick = (id) => {
    // Logic to handle navigation to another component based on ID
    // console.log(`Navigating to component with ID: ${id}`);
    // Perform your navigation logic here...
  };

  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = [
    {
      title: "Projected Spend for " + currentMonth + " $",
      dataIndex: "projected_spend",
      key: "projected_spend",
      align: "right",
      render: (text) => (
        // <
        //   className="atagcolor"
        //   href={`#${record.key}`}
        //   onClick={() => handleClick(record.key)}
        // >
        <span>${formatNumberWithCommas(text)}</span>
        // <span>{text.toLocaleString("en-US")}</span>
        // </a>
      ),
    },
    {
      title: "Spend for the month $",
      dataIndex: "current_month_spend",
      key: "current_month_spend",
      align: "right",
      render: (text, record) => (
        <span>
          ${formatNumberWithCommas(text)}{" "}
          {record.difference >= 0 ? (
            <Icon icon="mdi:arrow-drop-up" color="red" />
          ) : (
            <Icon icon="mdi:arrow-down-drop" color="green" />
          )}
          <span className="text-sm text-left subscript">
            ${formatNumberWithCommas(record.difference)}
            <span style={{ color: "red" }}>*</span> <br></br>
          </span>
          {/* <span className="mt-2 text-sm text-left">
            {" "}
            *-
          </span> */}
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="weeklytable"
        columns={columns}
        dataSource={spendoverall}
        pagination={false}
      />
      {/* <br></br> */}
      <p className="subtext">
        <span style={{ color: "red" }}>*</span> - Difference Shown as of &nbsp;
        {previousMonth} &nbsp;Spend ${formatNumberWithCommas(spend)}
      </p>
    </div>
  );
}

export default OverallSummaryUI;
