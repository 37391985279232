import React from "react";
import { Table, Button, Row, Col, Form, DatePicker, Input,Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   listservicerequesttrend,
// } from "../../../action/graphql/slm/SLM_reducers";
const { TextArea } = Input;
const ServiceRequestTrendTable = () => {
  const [tablerecords, setTablerecords] = useState();
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [oldrecords, setOldRecords] = useState({});
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [selectedMonth, setSelectedMonth] = useState(selectedTopdate);
  //const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const monthFormat = "YYYY-MM-01";
  const [form] = Form.useForm();
  // useEffect(() => {
  //   const date = selectedMonth;
  //   dispatch(listservicerequesttrend({ companyName, date }))
  //     .unwrap()
  //     .then(({ data }) => {
  //       const records = data.newfunctionquery28.map((datas) => {
  //         return {
  //           company: datas.company,
  //           date: datas.date,
  //           action: (
  //             <Icon
  //               icon="material-symbols:edit"
  //               onClick={() => handleEditClick(datas)}
  //             />
  //           ),
  //           type: "data",
  //         };
  //       });
  //       setTablerecords(records);
  //     });
  // }, [updatestatus, selectedMonth]);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "No of Service Request",
      dataIndex: "no_of_service_request",
    },
  ];
  //const data=[]
  const data = [
    {
      key: "1",
      date: "May 01,2023",
      no_of_service_request: "10",
    },
    {
      key: "2",
      date: "May 01,2023",
      no_of_service_request: "20",
    },
    {
      key: "3",
      date: "May 01,2023",
      no_of_service_request: "15",
    },
    {
      key: "4",
      date: "May 01,2023",
      no_of_service_request: "05",
    },
  ];
  const addRow = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    console.log("Add");
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const handleSubmit = (value) => {
    setDrawerStatus(false);
    setDeleteStatus(true);
    const input = {
      date: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-01`,
      no_of_service_request: value.no_of_service_request,
    };
    console.log(input);
  };
  const handleEditClick = (datas) => {
    setDeleteStatus(true);
  };

  return (
    <>
      <div>
        <Modal
        className="slmmodal"
          open={drawerStatus}
          onCancel={changeDrawerStatus}
          deletestatus={deletestatus}
          footer={null}
          // rowDelete={rowDelete}
          setDrawerDeleteStatus={setDrawerDeleteStatus}
          title="Service Request Trends"
        >
          <Form layout="vertical" onFinish={handleSubmit} form={form}>
            <Row>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={24}
                sm={24}
                xs={24}
                className="p-1"
              >
                <Form.Item
                  name="date"
                  label="Date"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <DatePicker
                    className="datepicker"
                    format={monthFormat}
                    picker="month"
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={24}
                sm={24}
                xs={24}
                className="p-1"
              >
                <Form.Item
                  name="no_of_service_request"
                  label="No of Service Request"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={24}
                sm={24}
                xs={24}
                className="p-1"
              >
                <Form.Item>
                  <Button
                    htmlType="button"
                    onClick={() => setDrawerStatus(false)}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={24}
                sm={24}
                xs={24}
                className="p-1"
              >
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      background: "#1b5f9d",
                      color: "#fff",
                      float: "right",
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Button
          className="table-add-button float-right"
          icon={<PlusCircleOutlined />}
          onClick={addRow}
        >
          ADD
        </Button>
        <Table
          className="patch-table slm"
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default ServiceRequestTrendTable;
