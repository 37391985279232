import { Spin, Table } from "antd";
import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";

function TicketInformationTable({ camsAllDetailsData }) {
  // useEffect(()=>{
  //   console.log("inside");
  //   console.log({camsAllDetailsData});

  //   camsAllDetailsData.forEach(data => {
  //     console.log({data});
  //   })
  // },[camsAllDetailsData])

  const columns = [
    {
      title: "Alert Type",
      key: "alert_type",
      dataIndex: "alert_type",
      sorter: (a, b) => (a.alert_type > b.alert_type ? -1 : 1),
      className: "devops-table-header",
      align: "center",
      defaultSortOrder: "ascend",
    },
    {
      title: "From Address",
      dataIndex: "from_address",
      key: "from_address",
      sorter: (a, b) => (a.from_address > b.from_address ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Server Name",
      dataIndex: "server_name",
      key: "server_name",
      sorter: (a, b) => (a.server_name > b.server_name ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Severity Level",
      dataIndex: "severity",
      key: "severity",
      sorter: (a, b) => (a.severity > b.severity ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      sorter: (a, b) => (a.subject > b.subject ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "To Address",
      dataIndex: "to_address",
      key: "to_address",
      sorter: (a, b) => (a.to_address > b.to_address ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => (a.date > b.date ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      sorter: (a, b) => (a.time > b.time ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
  ];

  return (
    <div>
      <div className="alert-detail-table">
        <Table
          className="slm-table"
          columns={columns}
          dataSource={camsAllDetailsData}
          pagination={{ pageSize: 5 }}
          showSizeChanger={false}
          scroll={{ x: 2000 }}
        />
      </div>
    </div>
  );
}

export default TicketInformationTable;
