import React, { memo, useState, useEffect } from "react";
import { Dropdown, Input, DatePicker } from "antd";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedTopDateMethod,
  setFilterButtonStatus,
} from "../../slice/costimizeSlice";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerReact from "react-datepicker";

const FilterDropdownDate = (props) => {
  const current = new Date();
  const dispatch = useDispatch();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const dufaltdateword = `${
    monthNames[current.getMonth()]
  } ${current.getFullYear()}`;

  const [dateword, setDateWord] = useState(dufaltdateword);
  const [selectedstaticdate, setSelectedStaticDate] = useState(
    `${current.getFullYear()}-${current.getMonth() + 1}-01`
  );
  const [selecteddynamicdate, setSelectedDynamicDate] = useState(
    `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
  );
  const [startDate, setStartDate] = useState(current);

  const menuStyle = {
    boxShadow: "none",
  };
  const items = [];
  // const onChange = (date, dateString) => {
  //   const formattedDate = moment(dateString).format("YYYY/MM/DD");
  //   const currentnew = new Date(formattedDate);
  //   setSelectedStaticDate(
  //     `${currentnew.getFullYear()}-${currentnew.getMonth() + 1}-01`
  //   );
  //   setDateWord(
  //     `${monthNames[currentnew.getMonth()]} ${currentnew.getFullYear()}`
  //   );
  //   setSelectedDynamicDate(
  //     `${currentnew.getFullYear()}-${
  //       currentnew.getMonth() + 1
  //     }-${currentnew.getDate()}`
  //   );
  // };

  const dateChange = () => {
    if (
      new Date(startDate).getMonth() + 1 === new Date().getMonth() + 1 &&
      new Date(startDate).getFullYear() === new Date().getFullYear()
    ) {
      dispatch(setFilterButtonStatus(false));
    } else {
      dispatch(setFilterButtonStatus(true));
    }

    const currentnew = new Date(startDate);
    setSelectedStaticDate(
      `${currentnew.getFullYear()}-${currentnew.getMonth() + 1}-01`
    );
    setDateWord(
      `${monthNames[currentnew.getMonth()]} ${currentnew.getFullYear()}`
    );
    setSelectedDynamicDate(
      `${currentnew.getFullYear()}-${
        currentnew.getMonth() + 1
      }-${currentnew.getDate()}`
    );
  };
  useEffect(() => {
    dateChange();
  }, [startDate]);

  useEffect(() => {
    dateChange();
  }, [startDate]);

  useEffect(() => {
    const currentnew = new Date(selectedTopdate);
    setStartDate(currentnew);
    setSelectedStaticDate(
      `${currentnew.getFullYear()}-${currentnew.getMonth() + 1}-01`
    );
    setDateWord(
      `${monthNames[currentnew.getMonth()]} ${currentnew.getFullYear()}`
    );
    setSelectedDynamicDate(
      `${currentnew.getFullYear()}-${
        currentnew.getMonth() + 1
      }-${currentnew.getDate()}`
    );
  }, [selectedTopdate]);

  function disabledDate(current) {
    return current && current > moment().endOf("month");
  }

  useEffect(() => {
    dispatch(selectedTopDateMethod(selectedstaticdate));
  }, [selectedstaticdate]);
  if (
    window.location.pathname !== "/migrate" &&
    window.location.pathname !== "/assess" &&
    window.location.pathname !== "/operate" &&
    window.location.pathname !== "/slm"
  ) {
    return (
      <>
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          overlayClassName="plat-filter-menu-list"
          dropdownRender={(menu) => (
            <div className="filter-menu-dropdown-render">
              {/* {React.cloneElement(menu, {
                        style: menuStyle,
                      })} */}
              <div
                className="menu-filter-search"
                style={{
                  paddingTop: 5,
                  paddingRight: 5,
                  paddingBottom: 15,
                  paddingLeft: 5,
                }}
              >
                <lable style={{ paddingLeft: 0 }}>Month</lable>
                {/* <DatePicker
                style={{ width: "95%", margin: 3 }}
                onChange={onChange}
                picker="month"
                disabledDate={disabledDate}
                mode="month"
              /> */}
                <span className="plat-single-date ">
                  <DatePickerReact
                    showIcon
                    selected={startDate}
                    // className="form-control"
                    isClearable
                    inline
                    onChange={(date) => {
                      setStartDate(date);
                      console.log(date, "date");
                      // var element = document.getElementById("data-show");
                      // element.classList.remove("show");
                      // var element2 = document.getElementById("date-main-show");
                      // element2.classList.remove("show");
                      // $(".top-menu-date").attr("aria-expanded","false");
                      // document.getElementsByClassName('dropdown-button-dark-example1-date').setAttribute('aria-expanded', 'false');
                    }}
                    dateFormat="MMMM/yyyy"
                    showMonthYearPicker
                    maxDate={new Date()}
                  />
                  {/* <Icon className="plat-icon-cal" icon="uil:calender" /> */}
                </span>
              </div>
            </div>
          )}
        >
          <a onClick={(e) => e.preventDefault()}>
            <div className="header-menu-filter-btn">
              <div>{dateword}</div>
              <div>
                <Icon
                  icon="material-symbols:keyboard-arrow-down-rounded"
                  style={{ fontSize: "21px" }}
                />
              </div>
            </div>
          </a>
        </Dropdown>
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          overlayClassName="plat-filter-menu-list"
          dropdownRender={(menu) => (
            <div className="filter-menu-dropdown-render">
              <div
                className="menu-filter-search"
                style={{
                  paddingTop: 5,
                  paddingRight: 5,
                  paddingBottom: 15,
                  paddingLeft: 5,
                }}
              >
                <lable style={{ paddingLeft: 0 }}>Week</lable>

                <span className="plat-single-date ">
                  <DatePicker
                    showIcon
                    selected={startDate}
                    isClearable
                    inline
                    onChange={(date) => {
                      console.log(date, "date");
                    }}
                    picker="week"
                  />
                </span>
              </div>
            </div>
          )}
        >
          <a onClick={(e) => e.preventDefault()}>
            <div className="header-menu-filter-btn">
              <div>{dateword}</div>
              <div>
                <Icon
                  icon="material-symbols:keyboard-arrow-down-rounded"
                  style={{ fontSize: "21px" }}
                />
              </div>
            </div>
          </a>
        </Dropdown>
      </>
    );
  }
};

export default memo(FilterDropdownDate);
