import React, { useState, useEffect } from "react";
import "./../a2c_rover/a2c_rover.css";
import { Row, Col, Button, Card, Switch, } from "antd";
import warQuestion from "./Realiablitywarjson.json";
import { Input } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate, } from "react-router-dom";
import { getwarassessment,getwarassesmentupdate,getwarassesmentlist} from "../action/graphql/a2crover/A2CReducer";
import { useDispatch,useSelector} from "react-redux";
const { TextArea } = Input;


function RealiablityWAR(props) {
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedResponses, setSelectedResponses] = useState([]);
  const [iddata,setIDData] = useState([])
  const {selectedTopdate} = useSelector((store) => store.a2cRover);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [count, setCount] = useState(0);

  const [selectedOptions, setSelectedOptions] = useState(() => {
    const storedOptions = localStorage.getItem('selectedOptions');
    return storedOptions ? JSON.parse(storedOptions) : [];
  });
  
  const [answers, setAnswers] = useState(() => {
    const storedAnswers = localStorage.getItem('answers');
    return storedAnswers ? JSON.parse(storedAnswers) : Array(warQuestion.data.length).fill({ answers: [] });
  });

  const handleSwitchChange = (checked) => {
    setIsSwitchChecked(checked);
    localStorage.setItem('isSwitchChecked', checked);
  };
  const type = localStorage.getItem('reliability');

  const navigate = useNavigate();

  
  const dispatch = useDispatch();

  var customer = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );



  const openQuestion = (index) => {
    setShowOptions(true);
    const opts = warQuestion.data[index].option;
    setOptions(opts);
    setSelectedQuestion(warQuestion.data[index]);
    setCurrentIndex(index);
    setSelectedOptions(answers[index].answers || []); 
  };


const handleCheckboxChange = (option) => {
  const updatedOptions = selectedOptions.includes(option)
    ? selectedOptions.filter((selected) => selected !== option)
    : [...selectedOptions, option];

  setSelectedOptions(updatedOptions);

  // Save to local storage
  localStorage.setItem('selectedOptions', JSON.stringify(updatedOptions));

};

useEffect(() => {
  props.setFile(0);
  openQuestion(0);

  const storedSwitchChecked = localStorage.getItem('isSwitchChecked');
  setIsSwitchChecked(storedSwitchChecked === 'true');

  setCount(props.notapplicable || 0);

}, [props.notapplicable]);

  const nonEmptyArrayCount = answers.filter(
    (answer) => Array.isArray(answer.answers) && answer.answers.length > 0
  ).length;
  localStorage.setItem('nonEmptyArrayCount', nonEmptyArrayCount.toString());
  
 

const saveOptions = () => {
  const updatedAnswers = [...answers];
  updatedAnswers[currentIndex] = {
    question: selectedQuestion.question,
    answers: selectedOptions,
  };
  setAnswers(updatedAnswers);
  localStorage.setItem('answers', JSON.stringify(updatedAnswers));

if (isSwitchChecked) {
  props.setNotApplicable(props.notapplicable + 1);
  console.log(count + 1,"countchecked")
}

  const formattedQuestion = selectedQuestion.question;
  const optionIndexes = selectedOptions.map(option => options.indexOf(option));

  
const input = {
  workload_name: "war workload test",
  updated_date: selectedTopdate,
  question: formattedQuestion,
  piller_type: "reliability", 
  customer: customer,
  created_date: selectedTopdate,
  answer_options:  optionIndexes, 
};

dispatch(getwarassessment({ input }))
    .unwrap()
    .then(({ data }) => {
      // console.log(data, "assessmentqus");
        const wardata = data.war_assessment.map((item) => item.id);
    })
    .catch((err) => {
      console.log(err);
    })
    setFormSubmitted(true);

dispatch(getwarassesmentlist({ customer }))
          .unwrap()
          .then(({ data }) => {
              console.log(data, "warassessmentlist");
              const questionsValue = data.get_war_assessment.map((datas) => {
              });      
              setIDData(questionsValue)
              // console.log(questionsValue,"listid")
        
          })
          .catch((err) => {
              console.log(err);
          });

          console.log()



    const assesupdate = {
      id: iddata,
      workload_name: "war workload test",
      updated_date: selectedTopdate,
      question: formattedQuestion,
      piller_type: "reliability",
      customer: customer,
      answer_options: optionIndexes,
    };
    
    dispatch(getwarassesmentupdate({ assesupdate }))
    .unwrap()
    .then(({ data }) => {
      // console.log(data, "assesupdate1");
    })
    .catch((err) => {
      console.log(err);
    });

  if (nonEmptyArrayCount === warQuestion.data.length - 1) {
    props.setFile(props.file + 1);
    setSelectedResponses(updatedAnswers);
  }
};

  const questions = warQuestion.data.map((item, index) => (
    <div
      data-index={index}
      onClick={() => openQuestion(index)}
      key={index}
      className={`question ${currentIndex === index ? "active" : ""}`}
      style={{ cursor: "pointer" }}
    >
      <span style={{ color: "#ff6a2f" }}>Q{index + 1}.</span> {item.question}
      {index !== warQuestion.data.length - 1 && <hr />}
    </div>
  ));

  const isNextDisabled = currentIndex === warQuestion.data.length - 1;

  return (
    <div>
      <Row className="mt-2">
        <h4 className="titletab">Reliability</h4>
        <Button className="buttonclass">
          {nonEmptyArrayCount}/{warQuestion.data.length}
        </Button>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xl={10} lg={12} md={12}>
          <Card className="operate-cards mt-4 scroll-container">
          <Row >
  <h5 className="white-color font-of-qus">{questions}</h5>
</Row>

          </Card>
        </Col>
        <Col xl={1} lg={1} md={1}></Col>
        <Col xl={11} lg={11} md={11}>
          {showOptions && (
            <Card className="perform-cards answer-card mt-4 scroll-container" >
              <div>
                <h4 className="headingh4 mb-2">Question {currentIndex + 1} </h4>
                <span className="white-color">{selectedQuestion.question}
                </span><br></br>
                <Row><Col>
                <Switch
        className="war_switch"
        checked={isSwitchChecked}
        onChange={handleSwitchChange}
      />{" "}</Col><Col><p className="color-orange ms-2">This Question is Not Applicable</p></Col></Row>
               
               
                 <ul className="whitecolor">
                  {options.map((opt, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        className="questionopt"
                        onChange={() => handleCheckboxChange(opt)}
                        checked={selectedOptions.includes(opt)}
                      />{" "}
                      {opt}
                    </li>
                  ))}
                </ul>
                <Row>
                  <Col span={24}>
                    <TextArea
                      rows={4}
                      className="textareacls whitecolor"
                      placeholder="Message"
                    />
                  </Col>
                </Row>
                <br></br>
                <Row gutter={[16, 16]} style={{ display: "flex", justifyContent: "center" }}>
             
                   <Col>
                  
                      <Button
                        className="buttonclass "
                        onClick={() => openQuestion(currentIndex - 1)}
                        disabled={isNextDisabled}
                      >
                        BACK
                      </Button>
                   
                  </Col>
                 
                  <Col>
                   
                      <Button
                        className="savebutton"
                        onClick={saveOptions}
                        // disabled={isSaveDisabled}
                      >
                        SAVE
                      </Button>
                  
                 
                     
                   
                  </Col>
                    <Col>
                    {currentIndex !== warQuestion.data.length - 1 && (
                      <Button
                        className="buttonclass ms-1"
                        onClick={() => openQuestion(currentIndex + 1)}
                        disabled={isNextDisabled}
                      >
                        NEXT
                      </Button>
                    )}
                  </Col>
                </Row>
              

              </div>
            </Card>
          )}
           {/* {formSubmitted && (
  <div>
    <h4>Selected Responses:</h4>
    {answers.map((answer, index) => (
      <div key={index}>
        <p>Q{index + 1}: {answer.answers ? answer.answers.join(', ') : 'Not answered'}</p>
      </div>
    ))}
  </div>
)} */}

        </Col>
      </Row>
    </div>
  );
}

export default RealiablityWAR;
