import { memo, useState } from "react";
import { Col, Row, Spin, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import AssetTrendChart from "./AssetTrendChart";
import DetailsView from "./DetailsView";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetSpendVMRecords } from "../../action/costimizeV2Active";
import {
  titleCase,
  numberFormat,
  capitalizeFirst,
} from "../../custom_hook/CustomHook";
import awsicon from "../../../assets/images/aws.svg";
import { filterAssetandSpendList } from "../../action/costimizedashboardAction";

const AssetSpendListDB = (props) => {
  const {
    applicationList,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedTopdate,
    selectedos,
    selectedservices,
    environmentList,
    dummydatastatus,
  } = useSelector((state) => state.constimize);
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  const [countchart, setCountChart] = useState([]);
  const [spendchart, setSpendChart] = useState([]);
  const [tablerecords, setTableRecords] = useState([]);

  const [searchapplication, setSearchApplication] = useState("");

  const [barchartvalue, setBarChartValue] = useState([]);
  const [countchartcategories, setCountChartCategories] = useState([]);
  const [countchartvm, setChartVm] = useState([]);
  const [countchartdbaas, setChartDbass] = useState([]);
  const [countchartothers, setChartOthers] = useState([]);
  const [barcharttype, setBarChartType] = useState("vm");
  //chart status
  const [countstatus, setCountStatus] = useState(false);
  const [spendstatus, setSpendStatus] = useState(false);
  const [vmexpanddetails, setVmExpandDetails] = useState({
    application: "",
    environment: "",
    cloud: "",
  });

  const [applicationviewstatus, setApplicationViewStatus] = useState(false);
  const [vmviewstatus, setVmViewStatus] = useState(false);
  const [preloader, setPreloader] = useState(true);
  //accourdion records
  const [allenvironmentdetails, setAllEnvironmentDetails] = useState([
    { type: "VM", count: 0, spend: 0 },
    { type: "DB", count: 0, spend: 0 },
    { type: "Others", count: 0, spend: 0 },
  ]);
  const [listenvironmentdetails, setListEnvironmentDetails] = useState([]);
  const [assetspenddata, setAssetSpendData] = useState([]);

  const getCloudList = (list) => {
    var cloudlist = ["aws", "azure", "gcp"];
    var envtemp = [];
    cloudlist.map((envdata, index) => {
      // console.log(list);
      const listenvironmentfillter = list.filter((datas) => {
        return datas.all_cloud?.toLowerCase() === envdata?.toLowerCase();
      });

      // console.log("listenvironmentfillter");
      // console.log(listenvironmentfillter);
      var applicationlist = getApplicationList(listenvironmentfillter);
      //  console.log("applicationlist");
      // console.log(applicationlist);
      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([envdata, envvalue, [applicationlist]]);
      }
    });
    // console.log("envtemp");
    // console.log(envtemp);
    return envtemp;
  };

  const getApplicationList = (list) => {
    // console.log(list);

    var cloudlist = applicationList;
    var envtemp = [];
    cloudlist.map((envdata, index) => {
      // console.log(envdata);
      const listenvironmentfillter = list.filter((datas) => {
        // console.log("envdata");
        // console.log(envdata);
        return datas.applications?.toLowerCase() === envdata?.toLowerCase();
      });
      var servicelist = getServiceList(listenvironmentfillter);
      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([capitalizeFirst(envdata), envvalue, [servicelist]]);
      }
    });
    return envtemp.sort((a, b) =>
      a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1
    );
  };

  const getServiceList = (list) => {
    const service_list = list?.map((v) => v.services);
    const service_set = new Set([...service_list]);
    const service = [...service_set];

    var cloudlist = service;
    var envtemp = [];
    cloudlist?.map((envdata, index) => {
      const listenvironmentfillter = list.filter((datas) => {
        // console.log("envdata");
        // console.log(envdata);
        return datas.services?.toLowerCase() === envdata?.toLowerCase();
      });
      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([capitalizeFirst(envdata), envvalue]);
      }
    });
    return envtemp.sort((a, b) =>
      a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1
    );
  };

  //asset and spend details

  useEffect(() => {
    setPreloader(true);
    // console.log(preloader);
    const apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: "DB",
      db: selecteddb.toString(),
    };

    // console.log(apivalue);
    dispatch(
      filterAssetandSpendList({ companyName, selectedTopdate, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setAssetSpendData(data.newfunctionquery);
        //all environment
        //vm
        const allenvironmentvmfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "VM";
          }
        );
        var allenvironmentvm = { type: "VM", count: 0, spend: 0 };
        if (allenvironmentvmfiltered?.length > 0) {
          allenvironmentvm = {
            type: "VM",
            count: allenvironmentvmfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentvmfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }
        //db
        const allenvironmentdbfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "DB";
          }
        );
        var allenvironmentdb = { type: "DB", count: 0, spend: 0 };
        if (allenvironmentdbfiltered?.length > 0) {
          allenvironmentdb = {
            type: "DB",
            count: allenvironmentdbfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentdbfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }
        //others
        const allenvironmentothersfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "Others";
          }
        );
        var allenvironmentothers = { type: "Others", count: 0, spend: 0 };
        if (allenvironmentothersfiltered?.length > 0) {
          allenvironmentothers = {
            type: "Others",
            count: allenvironmentothersfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentothersfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }

        const allenvspend =
          props.type === "db" ? allenvironmentdb : allenvironmentothers;
        setAllEnvironmentDetails(allenvspend);

        var envtemp = [];
        //environment list
        environmentList.map((envdata, index) => {
          const listenvironmentfillter = data.newfunctionquery?.filter(
            (datas) => {
              return (
                datas.environments?.toLowerCase() === envdata?.toLowerCase()
              );
            }
          );
          var allcloudlist = getCloudList(listenvironmentfillter);
          //vm
          const environmentvmfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "VM";
            }
          );
          var environmentvm = { type: "VM", count: 0, spend: 0 };
          if (environmentvmfiltered.length > 0) {
            environmentvm = {
              type: "VM",
              count: environmentvmfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentvmfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }

          //db
          const environmentdbfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "DB";
            }
          );
          var environmentdb = { type: "DB", count: 0, spend: 0 };
          if (environmentdbfiltered.length > 0) {
            environmentdb = {
              type: "DB",
              count: environmentdbfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentdbfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }
          //others
          const environmentothersfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "Others";
            }
          );
          var environmentothers = { type: "Others", count: 0, spend: 0 };
          if (environmentothersfiltered.length > 0) {
            environmentothers = {
              type: "Others",
              count: environmentothersfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentothersfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }
          const envvlaue =
            props.type === "db" ? environmentdb : environmentothers;
          envtemp.push([capitalizeFirst(envdata), envvlaue, [allcloudlist]]);
        });
        // console.log("env");
        // console.log(envtemp);
        setListEnvironmentDetails(envtemp);

        var appcountlist = [["type", "VM", "DBaaS", "Others"]];
        var appspendlist = [];
        var tablenewrecords = [];
        var categories = [];
        var chartvm = [];
        var chartdbaas = [];
        var chartothers = [];
        var barchartvalue = [];
        applicationList.map((appdata, index) => {
          const listenvironmentfillter = data.newfunctionquery.filter(
            (datas) => {
              return datas.applications === appdata;
            }
          );

          //vm
          const allenvironmentvmfiltered = listenvironmentfillter.filter(
            (datas) => {
              return datas.infras === "VM";
            }
          );
          const vmcount = allenvironmentvmfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );
          const vmspend = allenvironmentvmfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          //db
          const allenvironmentdbfiltered = listenvironmentfillter.filter(
            (datas) => {
              return datas.infras === "DB";
            }
          );
          const dbcount = allenvironmentdbfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );
          const dbspend = allenvironmentdbfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          //others
          const allenvironmentothersfiltered = listenvironmentfillter.filter(
            (datas) => {
              return datas.infras === "Others";
            }
          );
          const otherscount = allenvironmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );
          const othersspend = allenvironmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          const totalspend = +vmspend + +dbspend + +othersspend;
          const totalcount = +vmcount + +dbcount + +otherscount;
          // console.log(titleCase(appdata));
          appcountlist.push([
            titleCase(appdata),
            vmcount,
            dbcount,
            otherscount,
          ]);
          categories.push(titleCase(appdata));
          chartvm.push(vmcount);
          chartdbaas.push(dbcount);
          chartothers.push(otherscount);
          appspendlist.push({
            vm: vmspend,
            others: othersspend,
            db: dbspend,
            name: titleCase(appdata),
          });

          tablenewrecords.push({
            "Application Title": titleCase(appdata),
            "VM Count": vmcount,
            "VM Spend": vmspend,
            "DBaaS Count": dbcount,
            "DBaaS Spend": dbspend,
            "Others Count": otherscount,
            "Others Spend": othersspend,
          });
          barchartvalue.push({
            categories: titleCase(appdata),
            vmcount: vmcount,
            dbcount: dbcount,
            otherscount: otherscount,
          });
        });

        // console.log(tablenewrecords);
        // console.log("table");

        setBarChartValue(barchartvalue);
        setCountChartCategories(categories);
        setChartVm(chartvm);
        setChartDbass(chartdbaas);
        setChartOthers(chartothers);

        setCountChart(appcountlist);
        setSpendChart(appspendlist);
        setTableRecords(tablenewrecords);
        setPreloader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });
  }, [
    applicationList,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedTopdate,
    selectedos,
    selectedservices,
    environmentList,
    props,
  ]);

  const barChartView = (type) => {
    setBarChartType(type);
  };
  const viewAssetAndSpendVmDetails = (data) => {
    setVmViewStatus(true);
    setVmExpandDetails({
      application: data.application,
      cloud: data.cloud,
      environment: data.environment,
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Spin spinning={preloader} wrapperClassName="costimize-loader">
          <ul className="cd-accordion--animated cd-accordion--animated-vs">
            <div>
              <Row
                className={
                  props.classType == "slm"
                    ? "assent-spend-head slm-bg"
                    : "assent-spend-head"
                }
              >
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="color-orange plat-text-center"
                >
                  Environment
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="color-orange plat-text-center"
                >
                  Spend
                </Col>
              </Row>
            </div>
            <li className="cd-accordion__item cd-accordion__item--has-children ">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name="group-db-1"
                id="group-db-1"
                // defaultChecked
              />
              <label
                className="cd-accordion__label plat-asset-spend-body-vs "
                htmlFor="group-db-1"
                style={{ margin: "10px 0px" }}
              >
                <span className="accordion-full-width ">
                  <ToggleButtonV2
                    status={true}
                    newclass="all-environment-icon "
                  >
                    <Row className="plat-table-assets-spend-thead p-2">
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="asset-spend-title-vs "
                      >
                        All Environment(
                        {allenvironmentdetails.count})
                      </Col>
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        ${numberFormat(allenvironmentdetails.spend)}
                        <Icon
                          className={
                            "me-1 " +
                            (allenvironmentdetails.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            allenvironmentdetails.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                    </Row>
                  </ToggleButtonV2>
                  <div className="asset-spend-border-bottom-vs"></div>
                </span>
              </label>
              {/* environment list */}
              {listenvironmentdetails.map((environmentlist, index) => (
                <>
                  <ul className="cd-accordion__sub cd-accordion__sub--l1">
                    <li className="cd-accordion__item cd-accordion__item--has-children">
                      <input
                        className="cd-accordion__input"
                        type="checkbox"
                        id={"sub-group-db" + index}
                        // defaultChecked
                      />
                      <label
                        className="cd-accordion__label"
                        htmlFor={"sub-group-db" + index}
                      >
                        <span className="accordion-full-width">
                          <ToggleButtonV2
                            status={true}
                            newclass="environment-list-icon"
                          >
                            <Row className="plat-table-assets-spend-thead">
                              <Col
                                xxl={12}
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className=" asset-left-title environment-list-title asset-spend-title-vs"
                              >
                                {capitalizeFirst(environmentlist[0])}(
                                {environmentlist[1].count})
                              </Col>
                              <Col
                                xxl={12}
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="asset-spend-values-vs justify-content-center"
                              >
                                ${numberFormat(environmentlist[1].spend)}
                                <Icon
                                  className={
                                    "me-1 " +
                                    (environmentlist[1].spend > 0
                                      ? "text-danger-icon-custom"
                                      : "text-success-icon-custom")
                                  }
                                  icon={
                                    environmentlist[1].spend > 0
                                      ? "material-symbols:arrow-drop-up"
                                      : "material-symbols:arrow-drop-down"
                                  }
                                  style={{ fontSize: "20px" }}
                                />
                              </Col>
                            </Row>
                            <div className="asset-spend-border-bottom-vs"></div>
                          </ToggleButtonV2>
                        </span>
                      </label>
                      {/* cloud list */}
                      {environmentlist[2][0].map((cloudlist) => {
                        const ran_num = Math.random();
                        return (
                          <ul className="cd-accordion__sub cd-accordion__sub--l2">
                            <li className="cd-accordion__item cd-accordion__item--has-children">
                              <input
                                className="cd-accordion__input"
                                type="checkbox"
                                name={"sub-group-level-db" + index + ran_num}
                                id={"sub-group-level-db" + index + ran_num}
                                // defaultChecked
                              />
                              <label
                                className="cd-accordion__label"
                                htmlFor={"sub-group-level-db" + index + ran_num}
                              >
                                <span className="accordion-full-width">
                                  <ToggleButtonV2
                                    status={true}
                                    newclass="cloud-list-icon"
                                  >
                                    <Row className="plat-table-assets-spend-thead">
                                      <Col
                                        xxl={12}
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="asset-left-title cloud-list-title asset-spend-title-vs"
                                      >
                                        {cloudlist[0].toLowerCase() ===
                                        "aws" ? (
                                          <>
                                            {/* <Icon
                                              icon="logos:aws"
                                              class="asset-spend-cloud-list-icon"
                                              style={{ marginRight: "4px" }}
                                            /> */}
                                            <img src={awsicon} /> AWS(
                                            {cloudlist[1].count})
                                          </>
                                        ) : cloudlist[0].toLowerCase() ===
                                          "azure" ? (
                                          <>
                                            <Icon
                                              icon="vscode-icons:file-type-azure"
                                              style={{ marginRight: "4px" }}
                                            />{" "}
                                            Azure({cloudlist[1].count})
                                          </>
                                        ) : cloudlist[0].toLowerCase() ===
                                          "oracle" ? (
                                          <>
                                            <Icon
                                              icon="simple-icons:oracle"
                                              style={{
                                                color: "#FF0000",
                                                marginRight: "4px",
                                              }}
                                            />{" "}
                                            Oracle({cloudlist[1].count})
                                          </>
                                        ) : (
                                          <>
                                            <Icon
                                              icon="skill-icons:gcp-light"
                                              style={{ marginRight: "4px" }}
                                            />{" "}
                                            GCP({cloudlist[1].count})
                                          </>
                                        )}
                                      </Col>
                                      <Col
                                        xxl={12}
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="asset-spend-values-vs justify-content-center"
                                      >
                                        ${numberFormat(cloudlist[1].spend)}
                                        <Icon
                                          className={
                                            "me-1 " +
                                            (cloudlist[1].spend > 0
                                              ? "text-danger-icon-custom"
                                              : "text-success-icon-custom")
                                          }
                                          icon={
                                            cloudlist[1].spend > 0
                                              ? "material-symbols:arrow-drop-up"
                                              : "material-symbols:arrow-drop-down"
                                          }
                                          style={{ fontSize: "20px" }}
                                        />
                                      </Col>
                                    </Row>
                                    <div className="asset-spend-border-bottom-vs"></div>
                                  </ToggleButtonV2>
                                </span>
                              </label>
                              {/* application list */}
                              {cloudlist[2][0].map(
                                (applicationlist, indexa) => {
                                  const ran_num_app = Math.random();
                                  return (
                                    <ul className="cd-accordion__sub cd-accordion__sub--l3">
                                      <li className="cd-accordion__item cd-accordion__item--has-children">
                                        <input
                                          className="cd-accordion__input"
                                          type="checkbox"
                                          name={
                                            "sub-group-level3-db" +
                                            index +
                                            "" +
                                            ran_num_app
                                          }
                                          id={
                                            "sub-group-level3-db" +
                                            index +
                                            "" +
                                            ran_num_app
                                          }
                                          // defaultChecked
                                        />
                                        <label
                                          className="cd-accordion__label"
                                          htmlFor={
                                            "sub-group-level3-db" +
                                            index +
                                            "" +
                                            ran_num_app
                                          }
                                        >
                                          <span className="accordion-full-width">
                                            <ToggleButtonV2
                                              status={true}
                                              newclass="application-list-icon"
                                            >
                                              <Row className="plat-table-assets-spend-thead">
                                                <Col
                                                  xxl={12}
                                                  xl={12}
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  className="asset-left-title cloud-list-title asset-spend-title-vs"
                                                >
                                                  {capitalizeFirst(
                                                    applicationlist[0]
                                                  )}
                                                  ({applicationlist[1].count})
                                                </Col>
                                                <Col
                                                  xxl={12}
                                                  xl={12}
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  className="asset-spend-values-vs justify-content-center"
                                                >
                                                  $
                                                  {numberFormat(
                                                    applicationlist[1].spend
                                                  )}
                                                  {/* {applicationlist[2][0][0]} */}
                                                  <Icon
                                                    className={
                                                      "me-1 " +
                                                      (applicationlist[1]
                                                        .spend > 0
                                                        ? "text-danger-icon-custom"
                                                        : "text-success-icon-custom")
                                                    }
                                                    icon={
                                                      applicationlist[1].spend >
                                                      0
                                                        ? "material-symbols:arrow-drop-up"
                                                        : "material-symbols:arrow-drop-down"
                                                    }
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="asset-spend-border-bottom-vs"></div>
                                            </ToggleButtonV2>
                                          </span>
                                        </label>

                                        {applicationlist[2][0]?.map(
                                          (servicelist) => (
                                            <ul className="cd-accordion__sub cd-accordion__sub--l3">
                                              <li className="cd-accordion__item cd-accordion__item--has-children">
                                                <a
                                                  className="cd-accordion__label cd-accordion__label--icon-img"
                                                  href="javascript:void(0)"
                                                >
                                                  <span className="accordion-full-width">
                                                    <Row className="plat-table-assets-spend-thead">
                                                      <Col
                                                        xxl={12}
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className="asset-left-title cloud-list-title asset-spend-title-vs"
                                                      >
                                                        {capitalizeFirst(
                                                          servicelist[0]
                                                        )}
                                                        ({servicelist[1].count})
                                                      </Col>
                                                      <Col
                                                        xxl={12}
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className="asset-spend-values-vs justify-content-center"
                                                      >
                                                        $
                                                        {numberFormat(
                                                          servicelist[1].spend
                                                        )}
                                                        <Icon
                                                          className={
                                                            "me-1 " +
                                                            (servicelist[1]
                                                              .spend > 0
                                                              ? "text-danger-icon-custom"
                                                              : "text-success-icon-custom")
                                                          }
                                                          icon={
                                                            servicelist[1]
                                                              .spend > 0
                                                              ? "material-symbols:arrow-drop-up"
                                                              : "material-symbols:arrow-drop-down"
                                                          }
                                                          style={{
                                                            fontSize: "20px",
                                                          }}
                                                        />
                                                      </Col>
                                                    </Row>
                                                    <div className="asset-spend-border-bottom-vs"></div>
                                                  </span>
                                                </a>
                                              </li>
                                            </ul>
                                          )
                                        )}
                                      </li>
                                    </ul>
                                  );
                                }
                              )}
                            </li>
                          </ul>
                        );
                      })}
                    </li>
                    <div>
                      <Row className="assent-spend-footer">
                        <Col span={24}></Col>
                      </Row>
                    </div>
                  </ul>
                </>
              ))}
            </li>
          </ul>
        </Spin>
      </div>
    </>
  );
};

export default memo(AssetSpendListDB);
