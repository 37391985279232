import React from "react";
import { Space, Table, Tag, Row, Col } from "antd";
import { Icon } from "@iconify/react";
function ApplicationLevelSummaryUI({
  applicationlevelspendless,
  selectedDate,
  endDate,
}) {
  console.log(applicationlevelspendless);
  const startDate = new Date(selectedDate);
  const end_Date = new Date(endDate);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(startDate.getTime() - oneWeek);
  const prevWeekEndDate = new Date(end_Date.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(startDate.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(end_Date.getTime() - 2 * oneWeek);
  const threeweeks = new Date(startDate.getTime() - 3 * oneWeek);
  const threeweekend = new Date(end_Date.getTime() - 3 * oneWeek);

  // Format the dates to strings in the desired format (DD/MM - DD/MM)
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };
  const threeweekstart = `${formatDate(threeweeks)} - ${formatDate(
    threeweekend
  )}`;

  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(startDate)}-${formatDate(end_Date)}`;
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const columns = [
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      //
    },
    {
      title: threeweekstart,
      dataIndex: "pre_previous_week",
      key: "pre_previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.pre_previous_week && record.pre_previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: prePrevWeekRange,
      dataIndex: "pre_previous_week",
      key: "pre_previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.pre_previous_week && record.pre_previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
      //
    },
    {
      title: prevWeekRange,
      dataIndex: "previous_week",
      key: "previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.previous_week && record.previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: currentWeekRange,
      dataIndex: "current_week",
      key: "current_week",
      align: "right",
      render: (text, record, index) => {
        if (record.current_week && record.current_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
      render: (text, record) => (
        <span>
          {record.difference > 0 ? (
            <>
              ${record.difference}
              <Icon icon="mdi:arrow-drop-up" color="red" />
            </>
          ) : record.difference < 0 ? (
            <>
              -${record.difference.toString().slice(1)}
              <Icon icon="mdi:arrow-down-drop" color="green" />
            </>
          ) : (
            "-"
          )}
        </span>
      ),
      align: "right",
    },
  ];
  const environments = [
    ...new Set(applicationlevelspendless.map((item) => item.environment)),
  ];
  return (
    <div>
      <Row gutter={[16, 16]}>
        {environments.map((environment) => (
          <Col span={12}>
            <div key={environment}>
              {" "}
              <h4 className="subtableheading">
                Environment: &nbsp; {environment}
              </h4>
              <Table
                className="weeklytable"
                columns={columns}
                dataSource={applicationlevelspendless.filter(
                  (item) => item.environment === environment
                )}
                pagination={false}
              />
            </div>
          </Col>
        ))}
        {/* <Table
        className="weeklytable"
        columns={columns}
        dataSource={applicationlevelspendless}
        pagination={false}
      /> */}
      </Row>
    </div>
  );
}

export default ApplicationLevelSummaryUI;
