import { memo, useEffect } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { companyInfoMethod, updateUsersMethod } from "../../slice/userSlice";
const { TextArea } = Input;

const CompanyInfo = (props) => {
  const [form] = Form.useForm();
  const { companyInfo, onboardAction, updateuserdetails } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  useEffect(() => {
    var records;
    // console.log(onboardAction);
    // console.log(updateuserdetails);
    // console.log(companyInfo);
    if (onboardAction === "create") {
      records = {
        company_name: "",
        company_headquaters: "",
        address_information: "",
        state: "",
        country: "",
        company_mail_id: "",
        company_contact_no: "",
      };
    } else {
      records = {
        company_name: updateuserdetails.company_name
          ? updateuserdetails.company_name
          : "",
        company_headquaters: updateuserdetails.company_headquaters
          ? updateuserdetails.company_headquaters
          : "",
        address_information: updateuserdetails.address_information
          ? updateuserdetails.address_information
          : "",
        state: updateuserdetails.state ? updateuserdetails.state : "",
        country: updateuserdetails.country ? updateuserdetails.country : "",
        company_mail_id: updateuserdetails.company_mail_id
          ? updateuserdetails.company_mail_id
          : "",
        company_contact_no: updateuserdetails.company_contact_no
          ? updateuserdetails.company_contact_no
          : "",
      };
    }
    form.setFieldsValue(records);
  }, []);
  const handleSubmit = (values) => {
    props.setCurrent(props.current + 1);
    dispatch(
      companyInfoMethod({
        ...companyInfo,
        company_name: values.company_name,
        company_headquaters: values.company_headquaters,
        address_information: values.address_information,
        state: values.state,
        country: values.country,
        company_mail_id: values.company_mail_id,
        company_contact_no: values.company_contact_no,
      })
    );
    dispatch(
      updateUsersMethod({
        ...updateuserdetails,
        company_name: values.company_name,
        company_headquaters: values.company_headquaters,
        address_information: values.address_information,
        state: values.state,
        country: values.country,
        company_mail_id: values.company_mail_id,
        company_contact_no: values.company_contact_no,
      })
    );
  };
  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="company_name"
              label="Company Name"
              rules={[{ required: true, message: "" }]}
            >
              <Input
                className="background-antd-color "
                disabled={props.display_status}
              />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="company_headquaters"
              label="Headquarters"
              rules={[{ required: true, message: "" }]}
            >
              <Input className="background-antd-color " />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="address_information"
              label="Address Information"
              rules={[{ required: true, message: "" }]}
            >
              <Input className="background-antd-color " />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "" }]}
            >
              <Input className="background-antd-color " />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "" }]}
            >
              <Input className="background-antd-color " />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="company_mail_id"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                {
                  required: true,
                  message: " ",
                },
              ]}
            >
              <Input className="background-antd-color " />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="company_contact_no"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "",
                },
                // {
                //   pattern: /^[0-9]{10}$/,
                //   message: "Please enter a valid 10-digit phone number",
                // },
              ]}
            >
              <Input className="background-antd-color " />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item className="create-user-but-d-flex">
              <Button
                htmlType="button"
                onClick={() => props.setCurrent(props.current - 1)}
              >
                Previous
              </Button>
              <Button type="primary" htmlType="submit" className="yes-btn">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default memo(CompanyInfo);
