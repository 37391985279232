import { useEffect, useState } from "react";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
const SpendDayChart = (props) => {
  const [chart, setChart] = useState({
    value: [],
    categories: [],
  });
  const { selectedTopdate } = useSelector((state) => state.constimize);
  // const [spenddate, setSpendDate] =useState(selectedTopdate)

  useEffect(() => {
    if (props.records.length > 0) {
      const today1 = new Date(selectedTopdate);

      const today = new Date();
      const currentMonth = today1.getMonth();
      const currentYear = today1.getFullYear();

      let currentDateCount;
      if (today.getMonth() === today1.getMonth()) {
        currentDateCount = today.getDate() - 1;
      } else {
        currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();
      }

      const date_lable_list = [];
      const value = Array(currentDateCount).fill(0);

      for (let index = 0; index < currentDateCount; index++) {
        date_lable_list.push(
          `${today1.getFullYear()}-${today1.getMonth() + 1}-${index + 1}`
        );
      }

      props.records.forEach((data) => {
        const spendDate = new Date(data.date);

        const dayOfMonth = spendDate.getDate();
        const index = dayOfMonth - 1;

        value[index] += data.spend;
      });

      setChart({
        value: value,
        categories: date_lable_list,
      });
    } else {
      setChart({
        value: [],
        categories: [],
      });
    }
  }, [props.spenddate, props.records, props.selectmonth]);

  // useEffect(() => {
  //   console.log(props.spenddate)
  //   if (props.records.length > 0) {
  //     var value = [];
  //     var categories = [];
  //     const today = new Date(props.spenddate);

  //     const currentMonth = today.getMonth();
  //     const currentYear = today.getFullYear();
  //     const currentDateCount = new Date(
  //       currentYear,
  //       currentMonth + 1,
  //       0
  //     ).getDate();
  //     console.log(currentDateCount)
  //     var date_lable_list = [];
  //     var value = [];
  //     for (let index = 0; index < currentDateCount; index++) {
  //       date_lable_list.push(
  //         `${new Date(props.spenddate).getFullYear()}-${
  //           new Date(props.spenddate).getMonth() + 1
  //         }-${+index + +1}`
  //       );
  //     }
  //     console.log(date_lable_list);
  //     props.records.map((data) => {
  //       value.push(data.spend);
  //       categories.push(data.date);
  //     });
  //     setChart({
  //       value: value,
  //       categories: date_lable_list,
  //     });
  //   }
  //   else{
  //     var value1 = [];
  //     setChart({
  //       value: value1,
  //       categories: date_lable_list,
  //     });
  //   }
  // }, [props.spenddate]);
  const series = [
    {
      name: "Usage Trend",
      data: chart.value,
    },
  ];
  function getOrdinalSuffix(day) {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  }
  // console.log(chart);
  const options = {
    chart: {
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: function (event, chartContext, config) {
          props.resourcesChartDate(chart.categories[config.dataPointIndex]);
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chart.categories,

      labels: {
        style: {
          colors: "white",
        },

        formatter: (num, ed, de) => {
          const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          if (num.toString().length < 2) {
            return [];
          }
          const date = new Date(num);

          const day = date.getDate();

          const month = monthNamesShort[date.getMonth()];
          const current_month_text = `${day}${getOrdinalSuffix(day)}  ${month}`;
          return current_month_text;
        },
      },
    },

    colors: ["#FF6A2F"],

    yaxis: {
      labels: {
        style: {
          colors: "white",
        },
        formatter: (record) => {
          const num = (Math.round(record * 100) / 100).toFixed(2);
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </>
  );
};
export default memo(SpendDayChart);
