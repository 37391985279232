import { Dropdown, Input, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import React, { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { searchMenuList } from "../../custom_hook/CustomHook";
import awsicon from "../../../assets/images/aws.svg";
import { white } from "material-ui/styles/colors";

const FilterDropdownList = (props) => {
  // console.log("filter props");
  // console.log(props);
  const [itemlist, setItemList] = useState([]);
  const menuStyle = {
    boxShadow: "none",
  };
  useEffect(() => {
    var keyvalue = 0;
    const array = [
      {
        label: (
          <>
            <input
              type="checkbox"
              id={props.type}
              className="plat-item-checkbox"
              name={props.type}
              value="all"
              onChange={handleValueChange}
              checked={
                props.selectedvalue.indexOf("all") !== -1 ? "checked" : ""
              }
            />
            <label htmlFor={props.type}></label>
            <span className="plat-menu-list">All {props.type} </span>
          </>
        ),
        key: keyvalue,
      },
    ];
    const items = props.list.map((data) => {
      // console.log(data);
      keyvalue++;
      return {
        label: (
          <>
            <input
              type="checkbox"
              id={data}
              className="plat-item-checkbox"
              name={data}
              value={data}
              onChange={handleValueChange}
              checked={
                props.selectedvalue.indexOf(data) !== -1 ? "checked" : ""
              }
            />
            <label htmlFor={data}></label>
            <span className="plat-menu-list">
              {/* {data}  */}
              {data.toLowerCase() === "aws" ? (
                <>
                  <img src={awsicon} />
                  {/* <Icon icon="logos:aws" class="asset-spend-cloud-list-icon" />{" "} */}
                  AWS
                </>
              ) : data.toLowerCase() === "azure" ? (
                <>
                  <Icon icon="vscode-icons:file-type-azure" /> Azure
                </>
              ) : data.toLowerCase() === "gcp" ? (
                <>
                  <Icon icon="skill-icons:gcp-light" /> GCP
                </>
              ) : data.toLowerCase() === "oracle" ? (
                <>
                  <Icon
                    icon="simple-icons:oracle"
                    style={{ color: "#FF0000" }}
                  />{" "}
                  Oracle
                </>
              ) : (
                <>
                  {data.length > 13 ? (
                    <>
                      <Tooltip placement="bottom" title={data}>
                        {data.substring(0, 12)}
                        ...
                      </Tooltip>
                    </>
                  ) : (
                    <>{data}</>
                  )}
                </>
              )}
            </span>
          </>
        ),
        key: keyvalue,
      };
    });
    const newitemlist = array.concat(items);
    setItemList(newitemlist);
  }, [props]);
  const handleValueChange = (e) => {
    console.log(e.target);
    const { value, checked } = e.target;
    console.log(value, checked);
    console.log(props);
    props.handleChange(props.type, value, checked);
  };
  const items = itemlist;

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        overlayClassName="plat-filter-menu-list"
        dropdownRender={(menu) => (
          <div className="filter-menu-dropdown-render">
            <div
              className="menu-filter-search"
              style={{
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 8,
                paddingLeft: 0,
              }}
            >
              {!props.searchOption ? (
                <Input
                  className="filter-search-input"
                  placeholder="search here"
                  style={{ width: "95%", margin: 3, background: "white" }}
                  prefix={<Icon icon="ic:baseline-search" />}
                  onKeyUp={(e) => {
                    searchMenuList(e, props.statelist, props.reduxlist);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            {React.cloneElement(menu, {
              style: menuStyle,
            })}
          </div>
        )}
      >
        <a onClick={(e) => e.preventDefault()}>
          <div className="header-menu-filter-btn">
            <div>
              {props.selectedvalue.indexOf("all") !== -1 ? (
                <>{props.title}</>
              ) : (
                <>
                  {props.type}({props.selectedvalue.length})
                </>
              )}
            </div>
            <div>
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded"
                style={{ fontSize: "21px" }}
              />
            </div>
          </div>
        </a>
      </Dropdown>
    </>
  );
};

export default memo(FilterDropdownList);
