import { createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useErrorNavigation } from "../../../custom_hook/CustomHook";
import {
  GET_INCIDENT_TRENDS,
  GET_REQ_RESPONSE_TIME,
  SERVICE_REQUEST_TIME,
  ADD_SERVICE_REQUEST_TIME,
  ADD_INCIDENT_TREND,
  UPDATE_SERVICE_REQUEST_TIME,
  GET_SECURITY_HUB,
  ADD_SECURITY_HUB,
  GET_CHANGE_REQUEST,
  UPDATE_ACTION_ITEM,
  GET_PROJECT_STATUS,
  ADD_ACTION_ITEM,
  GET_ACTION_ITEM,
  UPDATE_PATCH_ITEM,
  DELETE_PATCH_ITEM,
  ADD_PATCH_ITEM,
  LIST_COMPLIANCE,
  ADD_INITIAL_REQUEST_TIME,
  UPDATE_INITIAL_REQUEST_TIME,
  UPDATE_INCIDENT_TREND,
  DELETE_INCIDENT_TREND,
  DELETE_REQ_RES_TIME,
  DELETE_SERVICE_REQUEST_TIME,
  DELETE_KEY_ACTIVITES,
  UPDATE_KEY_ACTIVITES,
  ADD_KEY_ACTIVITES,
  ADD_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,
  DELETE_PROJECT_STATUS,
  LIST_KEY_ACTIVITES,
  DELETE_ACTION_ITEM,
  ADD_CHANGE_REQUEST,
  UPDATE_CHANGE_REQUEST,
  DELETE_CHANGE_REQUEST,
  SLM_COMPLIANCE_INSERT,
  SLM_COMPLIANCE_LIST,
  SLM_COMPLIANCE_UPDATE,
  SLM_COMPLIANCE_DELETE,
  SLM_ASSETS_COUNT,
  SLM_QUICK_GLANCE_COUNT,
  SLM_QUICK_INCIDENT_COUNT,
  UPDATE_SECURITY_HUB,
  DELETE_SECURITY_HUB,
  INCIDENT_TRENDS_GRAPH,
  SERVICE_TREND_GRAPH,
  CHANGE_TREND_GRAPH,
  SLM_COMPLIANCE_GET_PLANNED,
  SLM_COMPLIANCE_GET_STATUS,
  TRUST_ADVISOR,

} from "./SLM_graphqlQuery";
import client_new from "../../client/dashboardapplonewClinet";

export const listIncidentTrends = createAsyncThunk(
  // action type string
  "dashboard/slm/incident/trends",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log({ data });
      const listIncident = await client_new.query({
        query: GET_INCIDENT_TRENDS,
        variables: {
          company_name: data.companyName,
          report_month: data.selectedTopdate,
        },
      });
      return listIncident;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getReqResponseTime = createAsyncThunk(
  // action type string
  "dashboard/slm/incident/reqresponse/time",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log({ data });
      const reqresponseTime = await client_new.query({
        query: GET_REQ_RESPONSE_TIME,
        variables: {
          company_name: data.companyName,
          report_month: data.selectedTopdate,
        },
      });
      console.log({ reqresponseTime });
      return reqresponseTime;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceRequestData = createAsyncThunk(
  "",
  async (data, { rejectWithValue }) => {
    try {
      const reqresponseTime = await client_new.mutate({
        mutation: SERVICE_REQUEST_TIME,
        variables: {
          company_name: data.companyName,
          report_month: data.selectedTopdate,
        },
      });
      return reqresponseTime;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addServiceRequestTime = createAsyncThunk(
  // action type string
  "slm/servicerequest/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const add_planned = await client_new.mutate({
        mutation: ADD_SERVICE_REQUEST_TIME,
        variables: {
          request_type: data.input.request,
          company_name: data.input.company_name,
          completed_on_time: data.input.completed_on_time,
          request_status: data.input.request_status,
          report_month: data.input.report_month,
        },
      });
      return add_planned;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addIncidentTrend = createAsyncThunk(
  // action type string
  "slm/incidentTrends/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const add_incident = await client_new.mutate({
        mutation: ADD_INCIDENT_TREND,
        variables: {
          company_name: data.input.company_name,
          priority_type: data.input.priority_type,
          ticket_id: data.input.ticket_id,
          report_month: data.input.report_month,
        },
      });
      return add_incident;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateIncidentTrend = createAsyncThunk(
  // action type string
  "slm/incidentTrends/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log({ data }, "in redux");
      const update_incident = await client_new.mutate({
        mutation: UPDATE_INCIDENT_TREND,
        variables: {
          company_name: data.editInput.company_name,
          priority_type: data.editInput.priority_type,
          ticket_id: data.editInput.ticket_id,
          report_month: data.editInput.report_month,
        },
      });
      return update_incident;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteIncidentTrend = createAsyncThunk(
  // action type string
  "slm/incidentTrends/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log({ data }, "in redux");
      const delete_incident = await client_new.mutate({
        mutation: DELETE_INCIDENT_TREND,
        variables: {
          company_name: data.input.company_name,
          ticket_id: data.input.ticket_id,
        },
      });
      return delete_incident;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateServiceRequestTime = createAsyncThunk(
  // action type string
  "slm/servicerequest/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const add_planned = await client_new.mutate({
        mutation: UPDATE_SERVICE_REQUEST_TIME,
        variables: {
          request_type: data.input.request,
          company_name: data.input.company_name,
          completed_on_time: data.input.completed_on_time,
          request_status: data.input.request_status,
          report_month: data.input.report_month,
          sr_id: data.sr_request_id,
        },
      });
      return add_planned;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSecurityHub = createAsyncThunk(
  "",
  async (data, { rejectWithValue }) => {
    try {
      const reqresponseTime = await client_new.mutate({
        mutation: GET_SECURITY_HUB,
        variables: {
          company_name: data.companyName,
          report_month: data.selectedTopdate,
        },
      });
      return reqresponseTime;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//
export const addSecurityHub = createAsyncThunk(
  "",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const reqresponseTime = await client_new.mutate({
        mutation: ADD_SECURITY_HUB,
        variables: {
          company_name: data.input.company_name,
          report_month: data.input.report_month,
          security_current_status:data.input.security_current_status, 
          standard:data.input.standard,
        },
      });
      return reqresponseTime;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//
export const updateSecurityHub = createAsyncThunk(
  "slm/updatepatchmanageItem/update",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const update_change_request = await client_new.mutate({
        mutation: UPDATE_SECURITY_HUB,
        variables: {
          company_name: data.input.company_name,
          report_month: data.input.report_month,
          security_current_status:data.input.security_current_status, 
          standard:data.input.standard,
          security_id: data.security_id,
        },
      });
      console.log(data);

      return update_change_request;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteSecurityHub = createAsyncThunk(
  "slm/updatepatchmanageItem/delete",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const delete_change_request = await client_new.mutate({
        mutation: DELETE_SECURITY_HUB,
        variables: {
          security_id: data.security_id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return delete_change_request;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addChangeRequest = createAsyncThunk(
  "slm/plannedandactivity/add", // callback function

  async (data, { rejectWithValue }) => {
    try {
      const add_change_request = await client_new.mutate({
        mutation: ADD_CHANGE_REQUEST,
        variables: {
          category: data.input.category,
          company_name: data.input.company_name,
          change_request: data.input.change_request,
          change_request_type: data.input.change_request_type,
          report_month: data.input.report_month,
        },
      });

      return add_change_request;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error); // return custom error message from API if any

      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateChangeRequest = createAsyncThunk(
  "slm/updatepatchmanageItem/update",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const update_change_request = await client_new.mutate({
        mutation: UPDATE_CHANGE_REQUEST,
        variables: {
          category: data.input.category,
          company_name: data.input.company_name,
          change_request: data.input.change_request,
          change_request_type: data.input.change_request_type,
          report_month: data.input.report_month,
          cr_id: data.cr_id,
        },
      });
      console.log(data);

      return update_change_request;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//deleteChangeRequest
export const deleteChangeRequest = createAsyncThunk(
  "slm/updatepatchmanageItem/delete",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const delete_change_request = await client_new.mutate({
        mutation: DELETE_CHANGE_REQUEST,
        variables: {
          cr_id: data.cr_id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return delete_change_request;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getChangeRequest = createAsyncThunk(
  // action type string
  "dashboard/slm/changeRequest",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log({ data });
      const change_request_time = await client_new.mutate({
        mutation: GET_CHANGE_REQUEST,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return change_request_time;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listcompliance = createAsyncThunk(
  "slm/listcompliance/update",

  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const list_compliance = await client_new.mutate({
        mutation: LIST_COMPLIANCE,
        variables: {
          date: data.selectedTopdate,
          company_name: data.companyName,
        },
      });
      // console.log(data);

      return list_compliance;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const AddPatchmanageItem = createAsyncThunk(
  "slm/AddPatchmanageItem/add",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const add_patch_item = await client_new.mutate({
        mutation: ADD_PATCH_ITEM,
        variables: {
          company_name: data.input.company_name,
          environment: data.input.environment,
          report_month: data.input.report_month,
          os: data.input.os,
          patch_id: data.input.patch_id,
          patch_status: data.input.patch_status,
        },
      });

      return add_patch_item;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
 
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updatePatchmanageItem = createAsyncThunk(
  "slm/updatepatchmanageItem/update",

  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const update_patch_item = await client_new.mutate({
        mutation: UPDATE_PATCH_ITEM,
        variables: {
          company_name: data.input.company_name,
          environment: data.input.environment,
          report_month: data.input.report_month,
          patch_status: data.input.patch_status,
          os: data.input.os,
          patch_id: data.patch_id,
        },
      });
      console.log(data);

      return update_patch_item;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deletePatchItem = createAsyncThunk(
  "slm/updatepatchmanageItem/delete",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const deletePatchManagementItem = await client_new.mutate({
        mutation: DELETE_PATCH_ITEM,
        variables: {
          patch_id: data.patch_id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return deletePatchManagementItem;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// slm incident trends
export const addInitialRequestTime = createAsyncThunk(
  "slm/addInitalRequestTime/add",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const add_initial_req_time = await client_new.mutate({
        mutation: ADD_INITIAL_REQUEST_TIME,
        variables: {
          company_name: data.values.company_name,
          target: data.values.target,
          report_month: data.values.report_month,
          service_level: data.values.service_level,
          initial_request_time: data.values.initial_request_time,
        },
      });

      return add_initial_req_time;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateInitialRequestTime = createAsyncThunk(
  "slm/updateInitialRequestTime/update",

  async (data, { rejectWithValue }) => {
    try {
      const update_initial_req_time = await client_new.mutate({
        mutation: UPDATE_INITIAL_REQUEST_TIME,
        variables: {
          company_name: data.values.company_name,
          target: data.values.target,
          report_month: data.values.report_month,
          service_level: data.values.service_level,
          initial_request_time: data.values.initial_request_time,
          resolution_time: data.values.resolution_time,
          sla_id: data.values.sla_id,
        },
      });

      return update_initial_req_time;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// delete item
export const deleteReqResponse = createAsyncThunk(
  "slm/req_res_time/delete",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const del_req_res_time = await client_new.mutate({
        mutation: DELETE_REQ_RES_TIME,
        variables: {
          sla_id: data.id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return del_req_res_time;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteServiceRequestTime = createAsyncThunk(
  // action type string
  "slm/servicerequest/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const delete_requesttime = await client_new.mutate({
        mutation: DELETE_SERVICE_REQUEST_TIME,
        variables: {
          sr_id: data.sr_request_id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return delete_requesttime;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getActionItemTableData = createAsyncThunk(
  // action type string
  "slm/actionitem/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_actionItem = await client_new.mutate({
        mutation: GET_ACTION_ITEM,
        variables: {
          company_name: data.companyName,
          report_month: data.selectedTopdate,
        },
      });
      console.log("AddcreateActionItemlist");

      return list_actionItem;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const addActionItem = createAsyncThunk(
  "slm/plannedandactivity/add", // callback function

  async (data, { rejectWithValue }) => {
    try {
      const add_ActionItem = await client_new.mutate({
        mutation: ADD_ACTION_ITEM,
        variables: {
          category: data.input.category,
          company_name: data.input.company_name,
          owner: data.input.owner,
          points_discussed: data.input.points_discussed,
          status: data.input.status,
          remarks: data.input.remarks,
          usagedate: data.input.usagedate,
          report_month: data.input.report_month,
        },
      });

      return add_ActionItem;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error); // return custom error message from API if any

      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateActionItem = createAsyncThunk(
  // action type string

  "slm/activitytracker/update", // callback function

  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const update_activity = await client_new.mutate({
        mutation: UPDATE_ACTION_ITEM,

        variables: {
          category: data.input.category,
          company_name: data.input.company_name,
          owner: data.input.owner,
          points_discussed: data.input.points_discussed,
          remarks: data.input.remarks,
          status: data.input.status,
          report_month: data.input.report_month,
          usagedate: data.input.usagedate,
          action_item_id: data.action_item_id,
        },
      });

      return update_activity;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error); // return custom error message from API if any

      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteActionItem = createAsyncThunk(
  // action type string
  "slm/actionitem/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const delete_actionItem = await client_new.mutate({
        mutation: DELETE_ACTION_ITEM,
        variables: {
          action_item_id: data.action_item_id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return delete_actionItem;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getProjectStatusData = createAsyncThunk(
  // action type string
  "slm/projectstatus/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_projectstatus = await client_new.mutate({
        mutation: GET_PROJECT_STATUS,
        variables: {
          date: data.selectedTopdate,
          company_name: data.companyName,
        },
      });
      console.log("listProjectStatus");

      return list_projectstatus;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const AddcreateProjectstatus = createAsyncThunk(
  "slm/projectstatus/add",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const add_projectstatus = await client_new.mutate({
        mutation: ADD_PROJECT_STATUS,
        variables: {
          company_name: data.input.company_name,
          next_milestone: data.input.next_milestone,
          progress: data.input.progress,
          project_name: data.input.project_name,
          project_phase: data.input.project_phase,
          project_target_date: data.input.project_target_date,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          start_date: data.input.start_date,
          status: data.input.status,
          status_id: data.input.status_id,
        },
      });
      console.log("AddcreateProjectstatus");

      return add_projectstatus;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateaddActionProjectstatus = createAsyncThunk(
  // action type string
  "slm/projectstatus/update",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data.input.report_date);
    console.log(data);
    try {
      const updateActionItem = await client_new.mutate({
        mutation: UPDATE_PROJECT_STATUS,
        variables: {
          company_name: data.input.company_name,
          next_milestone: data.input.next_milestone,
          progress: data.input.progress,
          project_name: data.input.project_name,
          project_phase: data.input.project_phase,
          project_target_date: data.input.project_target_date,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          start_date: data.input.start_date,
          status: data.input.status,
          status_id: data.status_id,
        },
      });
      console.log(data);
      console.log("updatelistProjectStatus");
      return updateActionItem;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteProjectstatus = createAsyncThunk(
  // action type string
  "slm/projectstatus/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const delete_projectstatus = await client_new.mutate({
        mutation: DELETE_PROJECT_STATUS,
        variables: {
          status_id: data.status_id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return delete_projectstatus;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const listKeyActivies = createAsyncThunk(
  // action type string
  "slm/keyactivites/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_keyactivies = await client_new.mutate({
        mutation: LIST_KEY_ACTIVITES,
        variables: {
          date: data.date,
          company_name: data.companyName,
        },
      });
      console.log("listKeyActivies");

      return list_keyactivies;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const AddKeyActivities = createAsyncThunk(
  "slm/keyactivites/add",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const add_keyactivities = await client_new.mutate({
        mutation: ADD_KEY_ACTIVITES,
        variables: {
          company_name: data.input.company_name,
          action_token: data.input.action_token,
          application: data.input.application,
          category: data.input.category,
          cost_impact: data.input.cost_impact,
          description: data.input.description,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          severity: data.input.severity,
          key_id: data.input.key_id,
        },
      });
      console.log("AddcreateProjectstatus");

      return add_keyactivities;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateKeyActivities = createAsyncThunk(
  // action type string
  "slm/keyactivites/update",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const updateKeyActivities = await client_new.mutate({
        mutation: UPDATE_KEY_ACTIVITES,
        variables: {
          company_name: data.input.company_name,
          action_token: data.input.action_token,
          application: data.input.application,
          category: data.input.category,
          cost_impact: data.input.cost_impact,
          description: data.input.description,
          report_date: data.input.report_date,
          report_month: data.input.report_month,
          severity: data.input.severity,
          key_id: data.key_id,
        },
      });
      console.log(data);
      console.log("updatelistProjectStatus");
      return updateKeyActivities;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteKeyActivities = createAsyncThunk(
  // action type string
  "slm/keyactivites/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const deleteKeyActivities = await client_new.mutate({
        mutation: DELETE_KEY_ACTIVITES,
        variables: {
          key_id: data.key_id,
          company_name: data.companyName,
        },
      });
      console.log(data);
      return deleteKeyActivities;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const addcompliance = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const add_compliance_ticket = await client_new.mutate({
        mutation: SLM_COMPLIANCE_INSERT,
        variables: {
          customer: data.input.company_name,
          application: data.input.application,
          compliants: data.input.compliants,
          cr_type: data.input.cr_type,
          environment: data.input.environment,
          non_complaints: data.input.non_complaints,
          operating_system: data.input.operating_system,
          priority_type: data.input.priority_type,
          request_category: data.input.request_category,
          request_description: data.input.request_description,
          ticket_id: data.input.ticket_id,
          ticket_status: data.input.ticket_status,
          month: data.input.month,
        },
      });
      return add_compliance_ticket ;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listCompliance = createAsyncThunk(
  // action type string
  "slm/compliance/list",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data)
    try {
      const add_compliance_ticket_list = await client_new.mutate({
        mutation:SLM_COMPLIANCE_LIST,
        variables: {
          // report_month: data.selectedTopdate,
          report_month: data.report_month,
          company_name: data.company,
        },
      });
   

      return add_compliance_ticket_list;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCompliance = createAsyncThunk(
  // action type string
  "slm/keyactivites/update",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const updatelistCompliance = await client_new.mutate({
        mutation: SLM_COMPLIANCE_UPDATE,
        variables: {
          customer: data.input.company_name,
          application: data.input.application,
          compliants: data.input.compliants,
          cr_type: data.input.cr_type,
          environment: data.input.environment,
          non_complaints: data.input.non_complaints,
          operating_system: data.input.operating_system,
          priority_type: data.input.priority_type,
          request_category: data.input.request_category,
          request_description: data.input.request_description,
          ticket_id: data.input.ticket_id,
          ticket_status: data.input.ticket_status,
          month: data.input.month,
        },
      });
      console.log(data);

      return updatelistCompliance;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//SLM_COMPLIANCE_DELETE
export const deleteComplaince = createAsyncThunk(

  "slm/keyactivites/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const deletelistCompliance = await client_new.mutate({
        mutation: SLM_COMPLIANCE_DELETE,
        variables: {
          ticket_id_id: data.ticket_id_id,
          company_name: data.company,
        },
      });
      console.log(data);
      return deletelistCompliance;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assetsmanagecount = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const add_compliance_ticket = await client_new.mutate({
        mutation: SLM_ASSETS_COUNT,
        variables: {
          
          account_name:data.apivalue.account_name,
          application:data.apivalue.application,
          cloud:data.apivalue.cloud, 
          company_name:data.companyName, 
          db:data.apivalue.db,
          environment:data.apivalue.environment,
          infra:data.apivalue.infra, 
          month:data.apivalue.month, 
          os: data.apivalue.os,
          service:data.apivalue.service,
        },
      });
      return add_compliance_ticket ;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const slmquickglancecount = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const quickglanceCount = await client_new.mutate({
        mutation:SLM_QUICK_GLANCE_COUNT,
        variables: {
          company_name: data.companyName,
          month: data.month,
        },
      });
      return quickglanceCount ;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const slmquickglanceincidentcount = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data)
    try {
      const quickglanceincidentCount = await client_new.mutate({
        mutation:SLM_QUICK_INCIDENT_COUNT,
        variables: {
          company_name: data.companyName,
          month: data.month,
        },
      });
      return quickglanceincidentCount ;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const incident_chart = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const incidentchart = await client_new.mutate({
        mutation:INCIDENT_TRENDS_GRAPH,
        variables: {
          company_name: data.companyName,
          report_month: data.lastThreeMonthsDates,
        },
      });
      return incidentchart ;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//SERVICE_TREND_GRAPH
export const service_chart = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const servicechart = await client_new.mutate({
        mutation:SERVICE_TREND_GRAPH,
        variables: {
          company_name: data.companyName,
          report_month: data.lastThreeMonthsDates,
        },
      });
      return servicechart;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const change_chart = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const servicechart = await client_new.mutate({
        mutation:  CHANGE_TREND_GRAPH,
        variables: {
          company_name: data.companyName,
          report_month: data.lastThreeMonthsDates,
        },
      });
      return servicechart;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listPlannedCompliance = createAsyncThunk(
  "slm/compliance/planned",
  async (data, { rejectWithValue }) => {
    // console.log(data)
    try {
      // console.log("dataaaa",data)
      const get_compliance_ticket_planned = await client_new.mutate({
        mutation:SLM_COMPLIANCE_GET_PLANNED,
        variables: {
          report_month: data.date,
          company_name: data.companyName,
        },
      });
      return get_compliance_ticket_planned;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const slm_get_status = createAsyncThunk(
  // action type string
  "slm/compliance/add",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data,"status updated")
    try {
      const slm_compliance_status = await client_new.mutate({
        mutation:  SLM_COMPLIANCE_GET_STATUS,
        variables: {
          company_name: data.companyName,
          report_month: data.date,
        },
      });
      return slm_compliance_status;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const trustadvicer = createAsyncThunk(
  "slm/trustadvicer/update",

  async (data, { rejectWithValue }) => {
    console.log(data,"trustadviser");
    try {
      const trustedadvisor = await client_new.mutate({
        mutation: TRUST_ADVISOR,
        variables: {
          account_name:data.apivalue.account_name,
          company: data.companyName,
        },
      });
      return trustedadvisor;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);