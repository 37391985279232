import { gql } from "@apollo/client";

const CREATE_USER_INFO = gql`
  mutation createUserInfo($createuserconfiginput: CreateUserConfigInput!) {
    createUserConfig(input: $createuserconfiginput) {
      email
      first_name
      last_name
      contact_no
      company_name
      password
      status
    }
  }
`;
const ACCOUNT_ONBOARDING = gql`
  query MyQuery(
    $usage_account_id: String!
    $account_name: String!
    $bucket_name: String!
    $prefix_path: String!
    $report_name: String!
    $environment: String!
    $cloud_platform: String!
    $customer: String!
    $ec2_regions_list: String!
    $environment_tag: String!
    $application_name_column: String!
    $payer_account_id: String!
    $auto_tag_update: String!
    $event_type: String!
    $external_id: String!
    $iam_arn_role: String!
    $user_id: String!
  ) {
    getTask(
      account_name: $account_name
      bucket_name: $bucket_name
      external_id: $external_id
      iam_arn_role: $iam_arn_role
      prefix_path: $prefix_path
      report_name: $report_name
      application_name_column: $application_name_column
      auto_tag_update: $auto_tag_update
      cloud_platform: $cloud_platform
      ec2_regions_list: $ec2_regions_list
      customer: $customer
      environment_tag: $environment_tag
      environment: $environment
      payer_account_id: $payer_account_id
      usage_account_id: $usage_account_id
      event_type: $event_type
      user_id: $user_id
      files: ""
    )
  }
`;

const ACCOUNT_ONBOARDING_INSERT = gql`
  query MyQuery(
    $usage_account_id: String!
    $account_name: String!
    $bucket_name: String!
    $prefix_path: String!
    $report_name: String!
    $environment: String!
    $cloud_platform: String!
    $customer: String!
    $ec2_regions_list: String!
    $environment_tag: String!
    $application_name_column: String!
    $payer_account_id: String!
    $auto_tag_update: String!
    $event_type: String!
    $external_id: String!
    $iam_arn_role: String!
    $files: String!
    $user_id: String!
  ) {
    getTask(
      account_name: $account_name
      bucket_name: $bucket_name
      external_id: $external_id
      iam_arn_role: $iam_arn_role
      prefix_path: $prefix_path
      report_name: $report_name
      application_name_column: $application_name_column
      auto_tag_update: $auto_tag_update
      cloud_platform: $cloud_platform
      ec2_regions_list: $ec2_regions_list
      customer: $customer
      environment_tag: $environment_tag
      environment: $environment
      payer_account_id: $payer_account_id
      usage_account_id: $usage_account_id
      event_type: $event_type
      files: $files
      user_id: $user_id
    )
  }
`;

// const ACCOUNT_ONBOARDING = gql`query MyQuery($account_id: String!, $account_name: String!, $bucket_name: String!, $iam_arn_role: String!, $ec2_region_list: String!, $report_name: String!, $payer_account_id: String!, $prefix_path: String!, $environment_tag: String!, $environment: String!, $application_tag: String!, $external_id: String!,){
//   getTask(account_name: $account_name, bucket_name: $bucket_name, external_id: $external_id, iam_arn_role: $iam_arn_role, prefix_path: $prefix_path, report_name: $report_name, application_name_column: $application_tag, auto_tag_update: "yes", cloud_platform: "aws", ec2_regions_list: $ec2_region_list, customer: "war", environment_tag: $environment_tag, environment:$environment, payer_account_id: $payer_account_id, usage_account_id: $account_id, event_type: "validate", files: "")
// }`;

const CREATE_ACCOUNT = gql`
  mutation createAccountConfig(
    $createaccountconfiginput: CreateAccountConfigInput!
  ) {
    createAccountConfig(input: $createaccountconfiginput) {
      account_id
      account_name
      bucket_name
      iam_arn_role
      report_name
      environment
      prefix_path
      cloud_type
      company_name
      ec2_region_list
      environment_tag
      application_tag
      payer_account_id
      auto_tag_update
    }
  }
`;

const LOGIN_USER_OLD = gql`
  query MyQuery($user_id: String!) {
    getPlatformUsers(user_id: $user_id) {
      company_name
      email_id
      first_name
      last_name
      mobile_number
      status
      user_id
      role
      Offerings
    }
  }
`;

const LOGIN_USER = gql`
  query MyQuery($user_id: String!) {
    getUsers(user_id: $user_id, event_type: "get-users") {
      email_id
      user_id
      azure_user_id
      first_name
      last_name
      mobile_number
      company_name
      company_headquaters
      company_mail_id
      company_contact_no
      address_information
      state
      country
      time_zone
      master_email
      master_first_name
      master_last_name
      master_contact_no
      Offerings
      role
      status
    }
  }
`;
// const LOGIN_USER = gql`query MyQuery($email:String!, $password: String!)
// { listUserConfigs(filter:
//   {email: {contains: $email},
//   password: {contains: $password}
// }) {
//   items {
//     email
//     first_name
//     last_name
//     Role
//     company_name
//     status
//     company_logo
//     Offerings
//   }
// }}`;

const GET_USER_DETAILS = gql`
  query getUserConfig($email: String!, $password: String!) {
    getUserConfig(email: $email, password: $password) {
      email
      password
      first_name
      last_name
      contact_no
      company_name
      company_mail_id
      company_headquaters
      company_contact_no
      address_information
      state
      country
      master_email
      master_first_name
      master_last_name
      master_contact_no
      status
      Offerings
    }
  }
`;

const LIST_USER_INFO = gql`
  query listUserConfigs {
    listUserConfigs {
      items {
        email
        password
        first_name
        last_name
        contact_no
        company_name
        company_mail_id
        company_headquaters
        company_contact_no
        address_information
        state
        country
        master_email
        master_first_name
        master_last_name
        master_contact_no
        status
      }
    }
  }
`;

const CREATE_CUSTOMER_ONBOARDING = gql`
  mutation createUserConfig(
    $email_id: String!
    $first_name: String!
    $last_name: String!
    $company_name: String!
    $company_headquaters: String!
    $company_mail_id: String!
    $company_contact_no: String!
    $address_information: String!
    $state: String!
    $country: String!
    $time_zone: String!
    $master_email: String!
    $master_first_name: String!
    $master_last_name: String!
    $master_contact_no: String!
    $Offerings: String!
    $Role: String!
    $status: String!
    $user_id: String!
  ) {
    updatePlatformUsers(
      input: {
        email_id: $email_id
        first_name: $first_name
        last_name: $last_name
        company_name: $company_name
        company_headquaters: $company_headquaters
        company_mail_id: $company_mail_id
        company_contact_no: $company_contact_no
        address_information: $address_information
        state: $state
        country: $country
        time_zone: $time_zone
        master_email: $master_email
        master_first_name: $master_first_name
        master_last_name: $master_last_name
        master_contact_no: $master_contact_no
        Offerings: $Offerings
        role: $role
        status: $status
        user_id: $user_id
      }
    ) {
      first_name
      last_name
      company_name
      company_headquaters
      company_mail_id
      company_contact_no
      address_information
      state
      country
      master_email
      master_first_name
      master_last_name
      master_contact_no
      time_zone
    }
  }
`;

const UPDATE_CUSTOMER_ONBOARDING = gql`
  mutation MyMutation(
    $email_id: String!
    $first_name: String!
    $last_name: String!
    $company_name: String!
    $company_headquaters: String!
    $company_mail_id: String!
    $company_contact_no: String!
    $address_information: String!
    $state: String!
    $country: String!
    $time_zone: String!
    $master_email: String!
    $master_first_name: String!
    $master_last_name: String!
    $master_contact_no: String!
    $Offerings: String!
    $status: String!
    $user_id: String!
    $role: String!
  ) {
    updatePlatformUsers(
      input: {
        email_id: $email_id
        first_name: $first_name
        last_name: $last_name
        company_name: $company_name
        company_headquaters: $company_headquaters
        company_mail_id: $company_mail_id
        company_contact_no: $company_contact_no
        address_information: $address_information
        state: $state
        country: $country
        time_zone: $time_zone
        master_email: $master_email
        master_first_name: $master_first_name
        master_last_name: $master_last_name
        master_contact_no: $master_contact_no
        Offerings: $Offerings
        status: $status
        user_id: $user_id
        role: $role
      }
    ) {
      email_id
      first_name
      last_name
      company_name
      company_mail_id
      company_headquaters
      company_contact_no
      address_information
      state
      country
      time_zone
      master_email
      master_first_name
      master_last_name
      master_contact_no
      status
      Offerings
      role
    }
  }
`;

const ACCOUNT_LIST = gql`
  query listAccountConfigs {
    listAccountConfigs {
      items {
        account_id
        account_name
        bucket_name
        iam_arn_role
        prefix_path
        report_name
        environment
        status
        cloud_type
        company_name
        ec2_region_list
        environment_tag
        application_tag
        payer_account_id
        auto_tag_update
      }
    }
  }
`;

const ACCOUNT_LIST_NEW = gql`
  query listAccountConfigs {
    listAccountConfigs {
      items {
        account_id
        account_name
        bucket_name
        iam_arn_role
        prefix_path
        report_name
        environment
        status
        cloud_type
        company_name
      }
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation deleteAccountConfig($account_id: String!, $account_name: String!) {
    deleteAccountConfig(
      input: { account_id: $account_id, account_name: $account_name }
    ) {
      account_id
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUserConfig($user_id: String!) {
    deletePlatformUsers(input: { user_id: $user_id }) {
      company_name
      email_id
      first_name
      last_name
      mobile_number
      role
      status
      user_id
    }
  }
`;

const EDITUSER = gql`
  query MyQuery($user_id: String!) {
    getPlatformUsers(user_id: $user_id) {
      Offerings
      role
      address_information
      company_contact_no
      company_headquaters
      company_mail_id
      company_name
      country
      email_id
      first_name
      last_name
      master_contact_no
      master_email
      master_first_name
      master_last_name
      mobile_number
      state
      status
      time_zone
      user_id
      company_headquaters
    }
  }
`;

const UPDATE_USER_STATUS = gql`
  mutation MyMutation(
    $emailid: String!
    $status: String!
    $company_name: String!
    $user_id: String!
    $role: String!
  ) {
    updateUserStatus(
      input: {
        emailid: $emailid
        status: $status
        company_name: $company_name
        user_id: $user_id
        role: $role
      }
    ) {
      email_id
      first_name
      last_name
      company_name
      status
      user_id
    }
  }
`;

const CHANGE_USER_PASSWORD = gql`
  mutation MyMutation($email: String!, $password: String!) {
    updateUserConfig(input: { email: $email, password: $password }) {
      email
    }
  }
`;

const USERMANAGER_LIST_OLD = gql`
  query MyQuery {
    listUserConfigs {
      items {
        user_id
        email_id
        first_name
        last_name
        company_name
        mobile_number
        status
        role
      }
    }
  }
`;

const USERMANAGER_LIST = gql`
  query MyQuery {
    listPlatformUsers {
      items {
        company_name
        email_id
        first_name
        last_name
        mobile_number
        status
        user_id
        role
        Offerings
      }
    }
  }
`;

const ACCOUNT_UPDATE = gql`
  mutation MyMutation(
    $account_id: String!
    $account_name: String!
    $bucket_name: String!
    $environment: String!
    $iam_arn_role: String!
    $prefix_path: String!
    $report_name: String!
    $ec2_region_list: String!
    $environment_tag: String!
    $application_tag: String!
    $payer_account_id: String!
    $auto_tag_update: String!
  ) {
    updateAccountConfig(
      input: {
        account_id: $account_id
        account_name: $account_name
        bucket_name: $bucket_name
        environment: $environment
        iam_arn_role: $iam_arn_role
        prefix_path: $prefix_path
        report_name: $report_name
        ec2_region_list: $ec2_region_list
        environment_tag: $environment_tag
        application_tag: $application_tag
        payer_account_id: $payer_account_id
        auto_tag_update: $auto_tag_update
      }
    ) {
      account_id
      account_name
      cloud_type
      bucket_name
      environment
      iam_arn_role
      prefix_path
      report_name
      status
      ec2_region_list
      environment_tag
      application_tag
      payer_account_id
      auto_tag_update
    }
  }
`;

const ACCOUNT_STATUS_UPDATE = gql`
  mutation MyMutation(
    $account_id: String!
    $account_name: String!
    $status: String!
  ) {
    updateAccountConfig(
      input: {
        account_id: $account_id
        account_name: $account_name
        status: $status
      }
    ) {
      account_id
      account_name
      status
    }
  }
`;

const ACCOUNT_ENVIRIONMENT_LIST = gql`
  query MyQuery {
    listAccountConfigs {
      items {
        environment
      }
    }
  }
`;

const TOKEN_VERIFIED = gql`
  query MyQuery($token: String!) {
    tokenvalidate(token: $token)
  }
`;

const GET_USER_ROLE = gql`
  query MyQuery2($report_month: String!, $role_name: String!) {
    userManagementGet(
      customer: "fivestar"
      report_month: $report_month
      role_name: $role_name
      cloud: "AWS"
    )
  }
`;

const ROLE_DROPDOWN = gql`
  query MyMutation($company: String!) {
    usermanagementroledropdown(event_type: "role_dropdown", customer: $company)
  }
`;

const ROLE_GET_BY_ID = gql`
  query MyQuery($company: String!, $role_id: String!) {
    usermanagementroledetails(
      event_type: "role_details"
      role_id: $role_id
      customer: $company
    )
  }
`;

const ROLE_GET_BY_ID_OLD = gql`
  query MyQuery {
    usermanagementroledetails(
      event_type: "role_details"
      role_id: "69"
      customer: "fivestar"
    )
  }
`;

const ROLE_INSERT = gql`
  query MyQuery(
    $customer: String!
    $role_category: String!
    $role_name: String!
    $user_id: String!
    $event_type: String!
    $offering: String!
    $resources: String!
  ) {
    usermanagementinsert(
      customer: $customer
      resources: $resources
      role_category: $role_category
      role_name: $role_name
      user_id: $user_id
      event_type: $event_type
      offering: $offering
    )
  }
`;

const ALL_ROLE_LIST = gql`
  query MyMutation($company_name: String!) {
    usermanagementallroles(event_type: "role_list", customer: $company_name)
  }
`;

const DELETE_ROLE = gql`
  query MyQuery($customer: String!, $role_id: String!, $user_id: String!) {
    usermanagementdelete(
      customer: $customer
      event_type: "delete"
      role_id: $role_id
      user_id: $user_id
    )
  }
`;

const UPDATE_ROLE = gql`
  query MyQuery($input_new: String!, $input_old: String!, $user_id: String!) {
    usermanagementupdate(
      event_type: "update"
      resources_new: $input_new
      resources_old: $input_old
      user_id: $user_id
    )
  }
`;
const DOCUMENT_POLICY = gql`
  query MyQuery($cloud: String!, $event_type: String!) {
    getDoc(cloud: $cloud, event_type: $event_type)
  }
`;

const COMPANY_DISTINICT_NAME = gql`
  query MyQuery($cloud: String!, $event_type: String!) {
    getDoc(cloud: $cloud, event_type: $event_type)
  }
`;
const COMPANY_BASED_ACCOUNT = gql`
  query MyQuery($company: String!, $event_type: String!) {
    getAccountDetails(company: $company, event_type: $event_type)
  }
`;
const GET_USER_ACCOUNT_NEW = gql`
  query MyQuery($company: String!, $event_type: String!) {
    getAccountDetails(company: $company, event_type: $event_type)
  }
`;
const GET_CUSTOMER_LOGO = gql`
  query MyQuery($company: String!) {
    getCustomerLogo(company: $company) {
      platform_logo
      company
      png_logo
    }
  }
`;

const INSERT_TEMP_USER_DETAILS = gql`
  mutation MyMutation(
    $company_contact_no: String!
    $company_headquaters: String!
    $company_mail_id: String!
    $compnay_name: String!
    $contact_number: String!
    $country: String!
    $email_id: String!
    $first_name: String!
    $last_name: String!
    $offering: String!
    $role: String!
    $state: String!
    $temp_user_id: String!
    $time_zone: String!
    $address_information: String!
  ) {
    createPlatformTempUser(
      input: {
        company_contact_no: $company_contact_no
        company_headquaters: $company_headquaters
        company_mail_id: $company_mail_id
        compnay_name: $compnay_name
        contact_number: $contact_number
        country: $country
        email_id: $email_id
        first_name: $first_name
        last_name: $last_name
        offering: $offering
        role: $role
        state: $state
        temp_user_id: $temp_user_id
        time_zone: $time_zone
        address_information: $address_information
      }
    ) {
      temp_user_id
    }
  }
`;

const GET_TEMP_USER_DETAILS = gql`
  query MyQuery($temp_user_id: String!) {
    getPlatformTempUser(temp_user_id: $temp_user_id) {
      address_information
      company_contact_no
      company_headquaters
      company_mail_id
      compnay_name
      contact_number
      country
      email_id
      first_name
      last_name
      offering
      role
      state
      temp_user_id
      time_zone
    }
  }
`;

const CHECK_AZURE_AD_EMAIL = gql`
  query MyQuery($email: String!) {
    azure_ad_validation(emailid: $email)
  }
`;
// const WEEKLYREPORT_MONTH = gql`
//   query MyQuery($company_name: String!, $date: String!) {
//     weekly_report_month_wise_chart(company_name: $company_name, date: $date) {
//       month
//kamalika
const WEEKLYREPORT = gql`
  query MyQuery($company_name: String!, $date: String!) {
    weekly_report_month_wise_chart(company_name: $company_name, date: $date) {
      month
      total_spend
      credit_spend
      with_credit
      aws_consumption
    }
  }
`;
const WEEKLYREPORTFORREPORT = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
    $range: String!
  ) {
    weekly_report_consumption_week_wise_chart(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
      range: $range
    ) {
      period
      total_spend
      credit_spend
      with_credit
    }
  }
`;
//kamalika
const WEEKLYREPORT_WEEK = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_week_wise_chart(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      current_week
      previous_week
      pre_previous_week
    }
  }
`;
//kamalika
const WEEKLYREPORT_ENV_WEEKWISE = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_env_week_wise_chart(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      environment
      pre_previous_week
      previous_week
      current_week
      t_previous_week
    }
  }
`;
//kamalika
const WEEKLYREPORT_ENV_WEEKWISE_SUMMARY = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_env_week_wise_summary(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      environment
      no_of_application
      vm_count
      cpu
      memory
      storage
      pre_previous_week
      previous_week
      current_week
      difference
    }
  }
`;
//kamalika
const WEEKLYREPORT_WEEKWISE_SUMMARY = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_app_week_wise_summary(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      application
      no_of_environment
      vm_count
      cpu
      memory
      storage
      pre_previous_week
      previous_week
      current_week
      difference
    }
  }
`;
//kamalika
const WEEKLYREPORT_WEEKWISE_SUMMARY_TOP = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_app_week_wise_top5_decrease(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      application
      environment
      pre_previous_week
      previous_week
      current_week
      difference
    }
  }
`;
//kamalika
const WEEKLYREPORT_WEEKWISE_SUMMARY_TOP_INCREASE = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_app_week_wise_top5_increase(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      application
      environment
      pre_previous_week
      previous_week
      current_week
      difference
    }
  }
`;
//kamalika
const WEEKLYREPORT_WEEKWISE_SUMMARY_SERVICE = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_service_week_wise_summary(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      service
      no_of_environment
      no_of_application
      pre_previous_week
      previous_week
      current_week
      difference
      region
    }
  }
`;
//kamalika
const WEEKLYREPORT_WEEKWISE_APP_SERIVCE = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_service_application_week_detailed_summary(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      environment
      application
      vm_count
      cpu
      memory
      storage
      pre_previous_week
      previous_week
      current_week
      difference
    }
  }
`;
//kamalika
const ENVIRONMENT_DETAILES = gql`
  query myQury(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_service_environment_week_detailed_summary(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      environment
      application
      vm_count
      cpu
      memory
      storage
      pre_previous_week
      previous_week
      current_week
      difference
    }
  }
`;
//kamalika
const ENVIRONMENT_WEEKLY_REPORT = gql`
  query myQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
    $range: String!
  ) {
    weekly_report_consumption_environment_week_wise_chart(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
      range: $range
    ) {
      period
      environment
      total_spend
      credit_spend
      with_credit
    }
  }
`;
//kamalika
const WEEKLY_DETAILED_SERVICE_REPORT = gql`
  query myQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_service_week_detailed_summary(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      service
      environment
      application
      region
      pre_previous_week
      previous_week
      current_week
      difference
    }
  }
`;
//kamalika
const OVERALL_SUMMARY = gql`
  query myQuery($company_name: String!, $date: String!) {
    weekly_report_overall_summary(company_name: $company_name, date: $date) {
      previous_month_spend
      current_month_spend
      projected_spend
      difference
    }
  }
`;
//kamalika
const APPLICATIONLEVELCHART = gql`
  query myQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    weekly_report_app_week_wise_top5_increase_chart(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
    ) {
      application
      environment
      pre_previous_week
      previous_week
      current_week
    }
  }
`;
//kamalika
const WEEKLYREPORTCONSUMPTION = gql`
  query myQuery($company_name: String!, $month: String!) {
    weekly_report_consumption_savings_initiative_summary(
      company_name: $company_name
      month: $month
    ) {
      month
      actual_cost
      credit_cost
      realised_savings
      projected_spend
    }
  }
`;
const WEEKLYREPORT_MONTH = gql`
  query myQuery($company_name: String!, $month: String!, $range: String!) {
    weekly_report_consumption_month_wise_chart(
      company_name: $company_name
      month: $month
      range: $range
    ) {
      period
      total_spend
      credit_spend
      with_credit
    }
  }
`;
//kamalika
const ENVIRONMENT_MONTHLY_REPORT = gql`
  query MyQuery($company_name: String!, $month: String!, $range: String!) {
    weekly_report_consumption_environment_month_wise_chart(
      company_name: $company_name
      month: $month
      range: $range
    ) {
      period
      environment
      total_spend
      credit_spend
      with_credit
    }
  }
`;
//kamalika
const APPLICATION_WEEKLY_REPORT = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
    $range: String!
  ) {
    weekly_report_consumption_application_week_wise_chart(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
      range: $range
    ) {
      period
      application
      total_spend
      credit_spend
      with_credit
    }
  }
`;
//kamalika
const APPLICATION_MONTHLY_REPORT = gql`
  query MyQuery($company_name: String!, $month: String!, $range: String!) {
    weekly_report_consumption_application_month_wise_chart(
      company_name: $company_name
      month: $month
      range: $range
    ) {
      period
      application
      total_spend
      credit_spend
      with_credit
    }
  }
`;
//kamalika
const SERVICE_WEEKLY_REPORT = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
    $range: String!
  ) {
    weekly_report_consumption_service_week_wise_chart(
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
      range: $range
    ) {
      period
      service
      total_spend
    }
  }
`;
//kamalika
const SERVICE_MONTHLY_REPORT = gql`
  query MyQuery($company_name: String!, $month: String!, $range: String!) {
    weekly_report_consumption_service_month_wise_chart(
      company_name: $company_name
      month: $month
      range: $range
    ) {
      period
      service
      total_spend
    }
  }
`;
//kamalika
const SERVICE_TOTAL_SPEND = gql`
  query MyQuery(
    $company_name: String!
    $end_date: String!
    $start_date: String!
  ) {
    weekly_report_consumption_service_top_5_summary(
      company_name: $company_name
      end_date: $end_date
      start_date: $start_date
    ) {
      service
      total_spend
    }
  }
`;
//kamalika
const APPLICATION_SUMMARY = gql`
  query MyQuery(
    $company_name: String!
    $end_date: String!
    $start_date: String!
  ) {
    weekly_report_consumption_application_top_5_summary(
      company_name: $company_name
      end_date: $end_date
      start_date: $start_date
    ) {
      application
      total_spend
    }
  }
`;
export {
  CREATE_USER_INFO,
  LOGIN_USER,
  LIST_USER_INFO,
  CREATE_CUSTOMER_ONBOARDING,
  ACCOUNT_LIST,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  DELETE_USER,
  GET_USER_DETAILS,
  UPDATE_CUSTOMER_ONBOARDING,
  UPDATE_USER_STATUS,
  USERMANAGER_LIST,
  ACCOUNT_UPDATE,
  CHANGE_USER_PASSWORD,
  ACCOUNT_STATUS_UPDATE,
  ACCOUNT_LIST_NEW,
  ACCOUNT_ENVIRIONMENT_LIST,
  ACCOUNT_ONBOARDING,
  ACCOUNT_ONBOARDING_INSERT,
  TOKEN_VERIFIED,
  GET_USER_ROLE,
  EDITUSER,
  ROLE_DROPDOWN,
  ROLE_GET_BY_ID,
  ROLE_INSERT,
  ALL_ROLE_LIST,
  DELETE_ROLE,
  UPDATE_ROLE,
  DOCUMENT_POLICY,
  COMPANY_DISTINICT_NAME,
  COMPANY_BASED_ACCOUNT,
  GET_USER_ACCOUNT_NEW,
  GET_CUSTOMER_LOGO,
  INSERT_TEMP_USER_DETAILS,
  GET_TEMP_USER_DETAILS,
  CHECK_AZURE_AD_EMAIL,
  WEEKLYREPORT,
  WEEKLYREPORT_WEEK,
  WEEKLYREPORT_ENV_WEEKWISE,
  WEEKLYREPORT_ENV_WEEKWISE_SUMMARY,
  WEEKLYREPORT_WEEKWISE_SUMMARY,
  WEEKLYREPORT_WEEKWISE_SUMMARY_TOP,
  WEEKLYREPORT_WEEKWISE_SUMMARY_TOP_INCREASE,
  WEEKLYREPORT_WEEKWISE_SUMMARY_SERVICE,
  WEEKLYREPORT_WEEKWISE_APP_SERIVCE,
  ENVIRONMENT_WEEKLY_REPORT,
  WEEKLY_DETAILED_SERVICE_REPORT,
  OVERALL_SUMMARY,
  APPLICATIONLEVELCHART,
  WEEKLYREPORTCONSUMPTION,
  WEEKLYREPORT_MONTH,
  ENVIRONMENT_MONTHLY_REPORT,
  APPLICATION_WEEKLY_REPORT,
  APPLICATION_MONTHLY_REPORT,
  SERVICE_WEEKLY_REPORT,
  SERVICE_MONTHLY_REPORT,
  ENVIRONMENT_DETAILES,
  SERVICE_TOTAL_SPEND,
  APPLICATION_SUMMARY,
  WEEKLYREPORTFORREPORT,
};
