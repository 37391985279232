import { memo, useState } from "react";
import { Col, Row, Spin, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import AssetTrendChart from "./AssetTrendChart";
import DetailsView from "./DetailsView";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssetSpendVMRecords,
  getAssetSpendVMEnvironmentRecords,
} from "../../action/costimizeV2Active";
import { numberFormat, capitalizeFirst } from "../../custom_hook/CustomHook";
import AssetSpendListEnvironment from "./AssetSpendListEnvironment";

const AssetSpendList = (props) => {
  const dispatch = useDispatch();
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [environmentdetails, setEnvironmentDetails] = useState({
    title: "All Environment",
    spend: "-",
    name: "-",
    size: "-",
    ram: "-",
    core: "-",
    storage: "-",
    savings_opportunity: "-",
    spend_trend: "",
    count: 0,
  });
  const [allenvironmentlist, setAllEnvironmentList] = useState([]);
  //loader
  const [loader, setLoader] = useState(false);

  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    environmentList,
    applicationList,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  useEffect(() => {}, [
    companyName,
    selectedTopdate,
    environmentList,
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    isRowClicked,
  ]);

  useEffect(() => {
    // console.log("checking");
    // console.log(props);
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      first_month: month_list[0],
      second_month: month_list[1],
      thired_month: month_list[2],
    };

    // dispatch(getAssetSpendVMRecords({ companyName, selectedTopdate, apivalue }))
    //   .unwrap()
    //   .then(({ data }) => {
    //     console.log(data);
    //     if (data.newfunctionquery30.length > 0) {
    //       props.setRecords(data.newfunctionquery30);
    //       var first_month = month_list[0];
    //       var second_month = month_list[1];
    //       var thired_month = month_list[2];
    //       //enviroment list
    //       const enviroment = data.newfunctionquery30.map((v) =>
    //         v.environment.toLowerCase()
    //       );
    //       var enviromentlist = new Set([...enviroment]);
    //       enviromentlist = [...enviromentlist];
    //       //cloud list
    //       const cloud = data.newfunctionquery30.map((v) =>
    //         v.cloud.toLowerCase()
    //       );
    //       var cloudlist = new Set([...cloud]);
    //       cloudlist = [...cloudlist];
    //       //application list
    //       const application = data.newfunctionquery30.map((v) =>
    //         v.application.toLowerCase()
    //       );
    //       var applicationlist = new Set([...application]);
    //       applicationlist = [...applicationlist];
    //       props.setApplicationList(applicationlist);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });

    //environment list
    setLoader(true);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      first_month: month_list[0],
      second_month: month_list[1],
      thired_month: month_list[2],
    };

    dispatch(
      getAssetSpendVMEnvironmentRecords({
        companyName,
        selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        // console.log(data.newfunctionquery30, "#####");
        if (data.newfunctionquery30.length > 0) {
          setAllEnvironmentList(data.newfunctionquery30);
          var first_month = month_list[0];
          var second_month = month_list[1];
          var thired_month = month_list[2];
          //enviroment list
          const enviroment = data.newfunctionquery30.map((v) =>
            v.environment?.toLowerCase()
          );
          var enviromentlist = new Set([...enviroment]);
          enviromentlist = [...enviromentlist];
          const spendtotal = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          //count
          const totalcount = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );

          //ram
          const ram_list = data.newfunctionquery30.map((data) => {
            if (data) {
              const ram_value = data.ram?.split("GiB");
              if (ram_value?.length > 0) {
                return Number(ram_value[0].trim());
              } else {
                return 0;
              }
            }
          });
          const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
          const core = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.core),
            0
          );
          var storage = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.storage),
            0
          );
          if (storage > 1000) {
            storage = storage / 1000;
            storage = `${storage} TB`;
          } else {
            storage = `${storage} GiB`;
          }
          const savings_opportunity = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.savings_opportunity),
            0
          );
          //chart
          const third_month_spend = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.third_month_spend),
            0
          );
          const second_month_spend = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.second_month_spend),
            0
          );
          const first_month_spend = data.newfunctionquery30.reduce(
            (a, v) => (a = +a + +v.first_month_spend),
            0
          );
          const chart = {
            chartlable: [first_month, second_month, thired_month],
            chartvalue: [
              Math.round(third_month_spend),
              Math.round(second_month_spend),
              Math.round(first_month_spend),
            ],
          };
          //environment total
          setEnvironmentDetails({
            title: "All Environment",
            spend: Math.round(spendtotal),
            name: "-",
            size: "-",
            ram: ram === 0 ? "-" : Math.round(ram),
            core: core === 0 ? "-" : Math.round(core),
            storage: storage,
            savings_opportunity: !savings_opportunity
              ? 0
              : Math.round(savings_opportunity),
            spend_trend: chart,
            count: totalcount,
          });
        }
        // console.log("in");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoader(false);
      });
  }, [
    companyName,
    selectedTopdate,
    environmentList,
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    props,
    // resname
  ]);
  // console.log(environmentdetails);
  var titles = "all";
  var cloud = "all";
  var application = "all";
  var resname = "all";

  return (
    <>
      <div>
        <Spin spinning={loader} wrapperClassName="costimize-loader">
          <ul className="cd-accordion--animated cd-accordion--animated-vs slmtable">
            <div className="costimize-asset-spend-scroll ">
              <Row
                className={
                  props.classType === "slm"
                    ? "assent-spend-head slm-bg"
                    : "assent-spend-head"
                }
              >
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className=" color-orange plat-text-center"
                >
                  Environment
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className=" color-orange plat-text-right"
                >
                  Spend
                </Col>
                <Col
                  xxl={3}
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  className=" color-orange plat-text-center"
                >
                  Name
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="color-orange plat-text-center"
                >
                  Instance Type
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="color-orange plat-text-right"
                >
                  RAM(GiB)
                </Col>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="color-orange plat-text-right"
                >
                  Core
                </Col>
                {/* {companyName === "fivestar" ||
                  (companyName === "cmacgm" && ( */}
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="color-orange plat-text-right"
                >
                  Storage(GiB)
                </Col>
                {/* ))} */}

                <Col
                  xxl={3}
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  className="color-orange plat-text-right"
                >
                  Savings Opportunity
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="color-orange plat-text-center"
                >
                  Spend Trends
                </Col>
              </Row>
            </div>
            <li className="cd-accordion__item cd-accordion__item--has-children">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name="group-1"
                id="group-1"
                // defaultChecked={props.viewStatus}
              />

              <label
                className="cd-accordion__label plat-asset-spend-body-vs"
                htmlFor="group-1"
                onClick={() => setIsRowClicked(!isRowClicked)}
              >
                <span className="accordion-full-width">
                  <ToggleButtonV2 status={true} newclass="all-environment-icon">
                    <Row className="plat-table-assets-spend-thead ">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-title-vs slmtable"
                      >
                        {capitalizeFirst(environmentdetails.title)}(
                        {environmentdetails.count})
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {/* ${numberFormat(environmentdetails.spend)} */}
                        {numberFormat(environmentdetails.spend) == 0 ? (
                          "-"
                        ) : (
                          <>
                            $ {numberFormat(environmentdetails.spend)}{" "}
                            <Icon
                              className={
                                "me-1 " +
                                (environmentdetails.spend > 0
                                  ? "text-danger-icon-custom"
                                  : "text-success-icon-custom")
                              }
                              icon={
                                environmentdetails.spend > 0
                                  ? "material-symbols:arrow-drop-up"
                                  : "material-symbols:arrow-drop-down"
                              }
                              style={{ fontSize: "20px" }}
                            />
                          </>
                        )}
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        -
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        -
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {environmentdetails.ram}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {environmentdetails.core}
                      </Col>
                      {/* {companyName === "fivestar" ||
                        (companyName === "cmacgm" && ( */}
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {environmentdetails.storage}
                      </Col>
                      {/* ))} */}
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {numberFormat(environmentdetails.savings_opportunity) ==
                        0
                          ? "-"
                          : numberFormat(
                              environmentdetails.savings_opportunity
                            )}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs plat-chart-center"
                      >
                        <AssetTrendChart
                          records={environmentdetails.spend_trend}
                          titles={titles}
                          cloud={cloud}
                          application={application}
                          resname={resname}
                        />
                      </Col>
                    </Row>
                  </ToggleButtonV2>
                  <div className="asset-spend-border-bottom-vs"></div>
                </span>
              </label>
              {/* {console.log(isRowClicked)} */}
              {/* environment list */}
              {isRowClicked && (
                <AssetSpendListEnvironment
                  allenvironmentlist={allenvironmentlist}
                />
              )}
            </li>
          </ul>
        </Spin>
      </div>
    </>
  );
};

export default memo(AssetSpendList);
