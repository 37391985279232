import { Button, Col, Form, Input, Row, Table, Tabs, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "../../../components/costimize_v2/costimize_v2.css";
import AssetSpendList from "../../costimize_v2/assetandspend/AssetSpendList";
import MainHead from "../../costimize_v2/layout/MainHead";
import SLMSidebar from "../SLMSidebar";
import "../slm.css";
import AssetSpendListDB from "../../costimize_v2/assetandspend/AssetSpendListDB";
import AssetSpendListOthers from "../../costimize_v2/assetandspend/AssetSpendListOthers";
import AssetSpendTopListChart from "../../costimize_v2/assetandspend/AssetSpendTopListChart";
import { Icon } from "@iconify/react";
import rpo_icon from "../../../assets/svg/rpo_icon.svg";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";

function SLM_AssetsSpend() {
  // const items = [
  //   {
  //     key: "1",
  //     label: `VM`,
  //     children: </>,
  //   },
  //   {
  //     key: "2",
  //     label: `DBaaS`,
  //     children: "DBAS",
  //   },
  //   {
  //     key: "3",
  //     label: "Others",
  //     children: "Others",
  //   },
  // ];

  const [topchartstatus, setTopChartStatus] = useState(false);
  const [records, setRecords] = useState([]);
  const [applicationlist, setApplicationList] = useState([]);
  const [infrochart, setInfraChartDetails] = useState({
    categories: [],
    vmlist: [],
    dblist: [],
    others: [],
  });
  const [spendchart, setSpendChartDetails] = useState({
    categories: [],
    value: [],
  });
  const [chartspend, setChartAllSpend] = useState([]);
  const [chartcount, setChartAllCount] = useState([]);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [drawerstatusform, setDrawerStatusform] = useState(false);
  const [drawerdeletestatusform, setDrawerDeleteStatusform] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [form] = Form.useForm();
  const [isModalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    // console.log(records);
    // console.log(applicationlist);
    var records_list_count = [];
    var reacords_list_spend = [];
    applicationlist.map((application) => {
      // if(application.toLowerCase() === )
      const list_records = records?.filter((datas) => {
        return datas.application === application.toLowerCase();
      });
      // console.log(list_records);
      const spendtotal = list_records.reduce((a, v) => (a = +a + +v.spend), 0);
      // console.log(spendtotal);

      records_list_count.push({
        label: application,
        value: list_records.length,
      });
      reacords_list_spend.push({
        label: application,
        value: Math.round(spendtotal),
      });
    });
    const records_list = records_list_count.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    const records_list_spend = reacords_list_spend.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    // console.log(records_list_spend);
    var cate_list = [];
    var vmcount = [];
    records_list.map((data, index) => {
      if (index < 5) {
        cate_list.push(data.label);
        vmcount.push(data.value);
      }
    });
    setInfraChartDetails({
      categories: cate_list,
      vmlist: vmcount,
      dblist: [0, 0, 0, 0, 0],
      others: [0, 0, 0, 0, 0],
    });

    var spend_cate_list = [];
    var spend_list = [];
    records_list_spend.map((data, index) => {
      if (index < 5) {
        spend_cate_list.push(data.label);
        spend_list.push(data.value);
      }
    });
    setSpendChartDetails({ categories: spend_cate_list, value: spend_list });
    // console.log({ categories: spend_cate_list, value: spend_list });

    // console.log({
    //   categories: cate_list,
    //   vmlist: vmcount,
    //   dblist: [0, 0, 0, 0, 0],
    // others: [0, 0, 0, 0, 0],
    // });
  }, [records]);
  const items = [
    {
      key: "1",
      label: `VM`,
      children: (
        <div className="assetspendslm">
        <AssetSpendList
       
          type="vm"
          classType="slm"
          setRecords={setRecords}
          setApplicationList={setApplicationList}
        />
        </div>
      ),
    },
    {
      key: "2",
      label: `DBaas`,
      children: (
        <div className="assetspendslm">
        <AssetSpendListDB
          classType="slm"
          setChartAllSpend={setChartAllSpend}
          setChartAllCount={setChartAllCount}
          type="db"
        />
        </div>
      ),
    },
    {
      key: "3",
      label: `Others`,
      children: (
        <div className="assetspendslm">
        <AssetSpendListOthers
          classType="slm"
          setChartAllSpend={setChartAllSpend}
          setChartAllCount={setChartAllCount}
          type="others"
        />
        </div>
      ),
    },
  ];

  const assetTable = () => {
    console.log("click");
    // setDrawerStatus(true);
    setModalVisible(true);
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const changeDrawerStatusform = () => {
    setDrawerStatusform(false);
  };
  const handleSubmit = () => {
    console.log("submit");
    setDrawerStatusform(false);
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  const handleAddClick = () => {
    console.log("add");
    setDrawerStatusform(true);
  };
  const data = [
    {
      key: "1",
      application_name: "Board Meeting Software",
      instance_name: "Board Meeting",
      rto: "30 Minutes",
      rpo: "24 Hours",
    },
    {
      key: "2",
      application_name: "Common Service",
      instance_name: "Gitlab ",
      rto: "30 Minutes",
      rpo: "24 Hours",
    },
    {
      key: "3",
      application_name: "Active Directory",
      instance_name: "Gitlab",
      rto: "30 Minutes",
      rpo: "24 Hours",
    },
    {
      key: "4",
      application_name: "Secondary Active Directory",
      instance_name: " Gitlab",
      rto: "30 Minutes",
      rpo: "24 Hours",
    },
  ];
  const columns = [
    {
      title: "Application Name",
      dataIndex: "application_name",
    },
    {
      title: "Instance Name",
      dataIndex: "instance_name",
    },
    {
      title: "RTO",
      dataIndex: "rto",
    },
    {
      title: "RPO",
      dataIndex: "rpo",
    },
  ];

  const item = [
    {
      key: "1",
      label: `Production`,
      children: (
        <>
          <div>
            <span className="rto">RTO & RPO (Production)</span>
            <button
              className="table-add-button float-right"
              onClick={() => handleAddClick()}
            >
              <Icon icon="material-symbols:add-circle-outline" />
              Add
            </button>
          </div>
          <Table className="slm" columns={columns} dataSource={data} />
        </>
      ),
    },
    {
      key: "2",
      label: `Non-Production`,
      children: (
        <>
          <div>
            <span className="rto">RTO & RPO (Non-Production)</span>
            <button
              className="table-add-button float-right"
              onClick={() => handleAddClick()}
            >
              <Icon icon="material-symbols:add-circle-outline" />
              Add
            </button>
          </div>
          <Table className="slm" columns={columns} dataSource={data} />
        </>
      ),
    },
  ];
  return (
    <>
    
      <Modal
        //drawerstatus={drawerstatus}
        className="slmmodal"
        open={isModalVisible}
        changeDrawerStatus={changeDrawerStatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        deletestatus={deletestatus}
        //rowDelete={rowDelete}
        title="RTO & RPO"
        onCancel={handleCancel}
        footer={null}
       
      >
        
        <Tabs className="slmpnp-tabs" defaultActiveKey="1" items={item} />
      </Modal>
      
      <Modal
      className="slmmodal"
        open={drawerstatusform}
        onCancel={changeDrawerStatusform}
        setDrawerDeleteStatus={setDrawerDeleteStatusform}
        title="RTO & RPO"
        footer={null}

      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="application_name" label=" Application Name">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="instance_name" label="Instance Name">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="rto" label="RTO">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="rpo" label=" RPO">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {topchartstatus && (
        <AssetSpendTopListChart
          topchartstatus={topchartstatus}
          setTopChartStatus={setTopChartStatus}
          infrochart={infrochart}
          spendchart={spendchart}
          chartspend={chartspend}
          chartcount={chartcount}
        />
      )}
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <SLMSidebar />
        <div className="quickglancetitle">
          <MainHead type="slm" title="Assets/Spend" />
        </div>
        <div className="slm-body-screen ">
          {/* <Tabs className="cams-tabs" defaultActiveKey="1" items={items} /> */}
          <div className="icons">
            <Row style={{ display: "block" }}>
              <Col span={24} className="spend-asset-body">
                <div className="spend-searchslm">
                  <div  className="plat-icon-bgslmdot">   {/* <AssetSpendExport /> */}
                  <Icon icon="bi:three-dots" style={{ fontSize: "20px" }} /> 
                </div>
                  <div
                    className="plat-icon-bgslm"
                    onClick={() => setTopChartStatus(true)}
                  >
                    <Icon
                      icon="mdi:chart-bar-stacked"
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                  <div className="plat-icon-bgslm" onClick={() => assetTable()}>
                    <img src={rpo_icon} alt="rpo" />
                  </div>
                  {<div className="forminput">
                    <Form.Item name="search">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      placeholder="search by app"
                      prefix={<Icon icon="material-symbols-light:search" color="white" />}
                    />
                  </Form.Item>
                  </div>}
                </div>
                <div className="spend-tab">
                  <Tabs
                    type="line"
                    defaultActiveKey="1"
                    items={items}
                    className="slm-tabs"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default SLM_AssetsSpend;
