import "../costimize_v2.css";
import React, { memo, useEffect } from "react";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import MainHead from "../layout/MainHead";
import BudgetvsSpend from "./BudgetvsSpend";
import CostSavings from "./CostSavings";
import Compliance from "./Compliance";
import KeyEvent from "./KeyEvent";
import TopFiveApplication from "./TopFiveApplication";
import TopFiveApplicationSpenLess from "./TopFiveApplicationSpenLess";
import { useState } from "react";
import { checkRolePermission } from "../../custom_hook/CustomHook";
import ChatBot from "../ChartBot/ChatBot";
const QuickGlanceLayout = () => {
  const [budgetstatus, setBudgetStatus] = useState(true);
  const [permission, setPermission] = useState({
    budget: checkRolePermission("budget_spend")
      ? checkRolePermission("budget_spend").access
      : 0,
    costsavings: checkRolePermission("cost_savings")
      ? checkRolePermission("cost_savings").access
      : 0,
    compliance: checkRolePermission("compliance")
      ? checkRolePermission("compliance").access
      : 0,
  });
  // console.log("test");
  return (
    <>
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <CostimizeSidebar />
        <MainHead title="Quick Glance" />
        <div className="costimize-body-screen">
          {permission.budget ? <BudgetvsSpend /> : ""}
          {permission.costsavings ? <CostSavings /> : ""}
          {permission.compliance ? <Compliance /> : ""}
          {/* {<TopFiveApplication />} */}
          {/* {<TopFiveApplicationSpenLess />} */}
          {/* <ChartBot /> */}
          {/* <KeyEvent /> */}
        </div>
      </div>
    </>
  );
};

export default memo(QuickGlanceLayout);
