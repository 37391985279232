import { Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import HeaderSection from "./HeaderSection";
import "./cams.css";
import SummaryView from "./SummaryView";
import AlertDetails from "./AlertDetails";
import CamsReport from "./CamsReport";
import { useDispatch } from "react-redux";
import { getCustomersReducer } from "../action/graphql/cams/cams_reducers";
import A2CSidebar from "../a2c_rover/A2CSidebar";

function Index() {
  const [defaultCustomer, setDefaultCustomer] = useState("all");
  const name = localStorage.getItem("CognitoIdentityServiceProvider.company");
  const dispatch = useDispatch();
  const [customerOptions, setCustomerOptions] = useState([
    {
      label: "All",
      value: "all",
    },
    {
      label: "CMACGM",
      value: "cmacgm",
    },
    {
      label: "Fivestar",
      value: "Fivestar",
    },
    {
      label: "TVS",
      value: "TVS",
    },
  ]);
  const getCustomersFn = () => {
    dispatch(getCustomersReducer())
      .unwrap()
      .then(({ data }) => {
        let tempArr = [{ label: "All", value: "all" }];
        data.cams_customer &&
          data.cams_customer.forEach((customer) => {
            let temp = {};
            temp.label = customer.customer;
            temp.value = customer.customer;
            tempArr.push(temp);
          });
        setCustomerOptions(tempArr);
      });
  };
  useEffect(() => {
    getCustomersFn();
  }, []);

  useEffect(() => {}, [defaultCustomer]);

  const handleChange = (e) => {
    setDefaultCustomer(e);
  };
  const onChange = (key) => {};
  const items =
    name === "Internal1CH"
      ? [
          {
            key: "1",
            label: `Summary view`,
            children: <SummaryView customer={defaultCustomer} />,
          },
          {
            key: "2",
            label: `Alert Details`,
            children: <AlertDetails customer={defaultCustomer} />,
          },
          {
            key: "3",
            label: `Reports`,
            children: <CamsReport customer={defaultCustomer} />,
          },
        ]
      : [
          {
            key: "1",
            label: `Summary view`,
            children: <SummaryView customer={defaultCustomer} />,
          },
          {
            key: "2",
            label: `Alert Details`,
            children: <AlertDetails customer={defaultCustomer} />,
          },
        ];
  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <A2CSidebar />
      <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
        {" "}
        <div className="cams-content">
          <div className="container-fluid">
            <div className="header-filter d-flex justify-content-between">
              <div className="header-title-a2c-main">Smart Alert</div>
              <div className="cams-global-filter">
                <div className="header-filter">
                  {/* <HeaderSection title="Smart Alert(SA)" /> */}
                  <div>
                    {name === "Internal1CH" && (
                      <Select
                        className="customer-select"
                        defaultValue={defaultCustomer}
                        onChange={(e) => handleChange(e)}
                        options={customerOptions}
                      />
                    )}
                  </div>
                </div>
              </div>
              <span className="d-flex align-items-end">
                {/* <CostimizeFilter /> */}
              </span>
            </div>

            <div className="cams-content">
              <div className="container-fluid">
                {/* Tabs */}
                <Tabs
                  className="cams-tabs"
                  defaultActiveKey="1"
                  items={items}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Index;
