import React, { memo, useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Spin,
  message,
  Tooltip,
  Button,
  Modal,
  Table,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Icon } from "@iconify/react";
import SpendVSBudget from "./SpendVSBudget";
import { useDispatch, useSelector } from "react-redux";
import TopApplicationChart from "./TopApplicationChart";
import {
  listAnnualBudget,
  getForecastSpend,
  getCreditAmount,
  getSpendDetails,
  getForecastSpendDetails,
} from "../../action/costimizeV2Active";
import { numberFormat } from "../../custom_hook/CustomHook";
import SpendDetailsView from "./SpendDetailsView";
import { useNavigate } from "react-router-dom";
import SpendServiceChartBody from "./SpendServiceChartBody";
const { Column, ColumnGroup } = Table;

dayjs.extend(customParseFormat);
const BudgetvsSpend = () => {
  const dispatch = useDispatch();
  const [forecastdetailsrecord, setForecastdetailrecord] = useState([]);
  const navigate = useNavigate();
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const current_month_text = `${monthNamesShort[new Date().getMonth()]}`;
  //state
  const [budgetdetails, setBudgetDetails] = useState({
    overall_budget: 0,
    last_month_budget: 0,
    month_budget: 0,
    status: "=",
    chartvalue: [0, 0, 0],
  });
  const [forecastdetails, setForecastDetails] = useState({
    forcast_value: 0,
    status: "=",
    diff_value: 0,
  });
  const [daychartstatus, setDayChartStatus] = useState(false);
  const [creditamount, setCreditAmount] = useState(0);
  const [spenddetails, setSpendDetails] = useState({
    withcredit: 0,
    withoutcredit: 0,
    chartvalue: [0, 0, 0],
  });
  const [spenddate, setSpendDate] = useState(selectedTopdate);

  // const [spenddate, setSpendDate] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`);
  const [spenddetailsstatus, setSpendDetailsStatus] = useState(false);
  //loader status
  const [lbudgetstatus, setLBudgetStatus] = useState(true);
  const [lforecaststatus, setLForecastStatus] = useState(true);
  const [lcreditstatus, setLCreditStatus] = useState(true);
  const [lspendstatus, setLSpendStatus] = useState(true);
  // budget
  useEffect(() => {
    const cloud = selectedCloud.toString();
    setLBudgetStatus(true);
    setLForecastStatus(true);
    dispatch(listAnnualBudget({ companyName, cloud }))
      .unwrap()
      .then(({ data }) => {
        data.list_annual_budget.map((data) => {
          if (Number(data.budget_year) == new Date().getFullYear()) {
            var allocated_buget = 0;
            var last_month_allocated = 0;
            const current_month = new Date(selectedTopdate).getMonth() + 1;
            const last_month = new Date(selectedTopdate).getMonth();

            //current month
            allocated_buget = getBudgetAllocatedAmount(current_month, data);
            //last month
            last_month_allocated = getBudgetAllocatedAmount(last_month, data);

            var status_value = "";
            var diff_value = "";
            if (Number(allocated_buget) === Number(last_month_allocated)) {
              status_value = "=";
              diff_value = 0;
            } else {
              if (allocated_buget > last_month_allocated) {
                status_value = "-";
                diff_value = Math.round(
                  (allocated_buget * 100) / last_month_allocated - 100
                );
              } else {
                status_value = "+";
                diff_value = Math.round(
                  (allocated_buget * 100) / last_month_allocated - 100
                );
              }
            }

            let currentDate = new Date(selectedTopdate);
            let lastThreeMonthsDate = new Date(selectedTopdate);
            lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
            const datevalue = [];
            for (let i = 0; i < 3; i++) {
              let date = new Date(
                lastThreeMonthsDate.getFullYear(),
                lastThreeMonthsDate.getMonth() + i,
                1
              );
              datevalue.push(getBudgetAllocatedAmount(last_month, data));
            }
            setBudgetDetails({
              overall_budget: Number(allocated_buget),
              last_month_budget: Number(last_month_allocated),
              month_budget: Math.abs(diff_value),
              status: status_value,
              chartvalue: datevalue,
            });
          }
        });
        setLBudgetStatus(false);
      })
      .catch((err) => {
        setLBudgetStatus(false);
        setLForecastStatus(false);
      });
  }, [selectedCloud, selectedTopdate]);
  const getBudgetAllocatedAmount = (current_month, data) => {
    var allocated_buget;
    if (current_month === 1) {
      allocated_buget = data.budget_jan;
    } else if (current_month === 2) {
      allocated_buget = data.budget_feb;
    } else if (current_month === 3) {
      allocated_buget = data.budget_mar;
    } else if (current_month === 4) {
      allocated_buget = data.budget_apr;
    } else if (current_month === 5) {
      allocated_buget = data.budget_may;
    } else if (current_month === 6) {
      allocated_buget = data.budget_jun;
    } else if (current_month === 7) {
      allocated_buget = data.budget_jul;
    } else if (current_month === 8) {
      allocated_buget = data.budget_aug;
    } else if (current_month === 9) {
      allocated_buget = data.budget_sept;
    } else if (current_month === 10) {
      allocated_buget = data.budget_oct;
    } else if (current_month === 11) {
      allocated_buget = data.budget_nov;
    } else {
      allocated_buget = data.budget_dec;
    }
    return allocated_buget;
  };
  // forecast
  useEffect(() => {
    setLForecastStatus(true);
    var apivalue = {
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
    };
    var projected_date = [
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
    ];
    projected_date = projected_date.toString();
    dispatch(getForecastSpend({ companyName, projected_date, apivalue }))
      .unwrap()
      .then(({ data }) => {
        var tempprojectspend = data.newfunctionquery8?.reduce(
          (a, v) => (a = +Number(a) + +Number(v.projected_spend)),
          0
        );
        var status_value = "";
        var diff_value = "";
        const budget_amount = budgetdetails.overall_budget;
        if (Number(budget_amount) === Number(tempprojectspend)) {
          status_value = "=";
          diff_value = 0;
        } else {
          if (budget_amount > tempprojectspend) {
            status_value = "+";
            diff_value = Math.round(
              (budget_amount * 100) / tempprojectspend - 100
            );
          } else {
            status_value = "-";
            // diff_value = Math.round(
            //   (budget_amount * 100) / tempprojectspend - 100
            // );
            diff_value = Math.round(
              (tempprojectspend * 100) / budget_amount - 100
            );
          }
        }
        if (diff_value === Infinity) {
          diff_value = 0;
        }
        setForecastDetails({
          forcast_value: Math.round(tempprojectspend),
          status: status_value,
          diff_value: Math.abs(diff_value),
        });
        setLForecastStatus(false);
      })
      .catch((err) => {
        setLForecastStatus(false);
      });
    // getForecastSpendDetails
    dispatch(getForecastSpendDetails({ companyName, projected_date, apivalue }))
      .unwrap()
      .then(({ data }) => {
        const record = data.projected_spend_detailed.map((datas) => {
          return {
            account_names: datas.account_names,
            clouds: datas.clouds,
            customer: datas.customer,
            month: datas.month,
            days: datas.days,
            remaining_days: datas.remaining_days,
            average_dailly_spend_for_last_7_days: parseFloat(
              datas.average_dailly_spend_for_last_7_days
            ).toFixed(2),
            actuals_spent_for_the_past_days_of_the_month: parseFloat(
              datas.actuals_spent_for_the_past_days_of_the_month
            ).toFixed(2),
            estimated_spend_for_remaining_days_of_the_month: parseFloat(
              datas.estimated_spend_for_remaining_days_of_the_month
            ).toFixed(2),
            // parseFloat(datas.estimated_spend_for_remaining_days_of_the_month).toFixed(2),
            total_estimates_spend_pre_tax_and_support: parseFloat(
              datas.total_estimates_spend_pre_tax_and_support
            ).toFixed(2),
            managed_service: parseFloat(datas.managed_service).toFixed(2),
            tax: parseFloat(datas.tax).toFixed(2),
            estimated_spend: parseFloat(datas.estimated_spend).toFixed(2),
          };
        });
        console.log(record);
        setForecastdetailrecord(record);
      })
      .catch((err) => {
        // setLForecastStatus(false);
      });
  }, [selectedAccount, budgetdetails, selectedTopdate, selectedCloud]);
  //credit
  useEffect(() => {
    setLCreditStatus(true);
    setLSpendStatus(true);
    const selectedTopdate_new = selectedTopdate;
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };
    dispatch(getCreditAmount({ companyName, selectedTopdate_new, apivalue }))
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery4) {
          setCreditAmount(
            Math.round(Math.abs(data.newfunctionquery4[0].spend))
          );
        } else {
          setCreditAmount(0);
        }
        setLCreditStatus(false);
      })
      .catch((err) => {
        setCreditAmount(0);
        setLCreditStatus(false);
        setLSpendStatus(false);
        console.log(err.message);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
  ]);
  // date
  useEffect(() => {}, [selectedTopdate]);
  //spend
  useEffect(() => {
    setLSpendStatus(true);
    //days
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    const datelist = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
    }
    //api inputs
    const selectedTopdate_new = datelist.toString();
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };
    dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
      .unwrap()
      .then(({ data }) => {
        var spend_list = [];
        var spend_value = 0;
        for (let i = 0; i < 3; i++) {
          let date = new Date(
            lastThreeMonthsDate.getFullYear(),
            lastThreeMonthsDate.getMonth() + i,
            1
          );
          var spend = data.newfunctionquery3.filter((item) => {
            // let spenddate = new Date(item.reportmonth);
            let spenddate = new Date(item.reportmonth);
            if (spenddate.getMonth() + 1 === date.getMonth() + 1) {
              return item;
            }
          });
          if (
            date.getMonth() + 1 ===
            new Date(selectedTopdate).getMonth() + 1
          ) {
            if (spend.length > 0) {
              spend_value = spend[0].spend;
            }
          }
          if (spend.length > 0) {
            spend_list.push(Math.round(spend[0].spend));
          } else {
            spend_list.push(0);
          }
        }

        setSpendDetails({
          withcredit:
            spend_value !== 0 ? Math.round(spend_value) - creditamount : 0,
          withoutcredit: Math.round(spend_value),
          chartvalue: spend_list,
        });

        setLSpendStatus(false);
      })
      .catch((err) => {
        setLSpendStatus(false);
      });
  }, [
    creditamount,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
    spenddate,
  ]);
  // const handleModifiedData = (modifiedData) => {
  //   setSpendDate(modifiedData)

  // };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "account_names",
      key: "account_names",
    },

    {
      title: "Cloud",
      dataIndex: "clouds",
      key: "clouds",
    },
    {
      title: "Billingperiod startdate",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Remaining Days",
      dataIndex: "remaining_days",
      key: "remaining_days",
    },
    {
      title: "Average Dailly spend for last 7 days",
      dataIndex: "average_dailly_spend_for_last_7_days",
      key: "average_dailly_spend_for_last_7_days",
    },
    {
      title: "Actuals spent for the past days of the month",
      dataIndex: "actuals_spent_for_the_past_days_of_the_month",
      key: "actuals_spent_for_the_past_days_of_the_month",
    },
    {
      title: "Estimated Spend for remaining days of  the month",
      dataIndex: "estimated_spend_for_remaining_days_of_the_month ",
      key: "estimated_spend_for_remaining_days_of_the_month",
    },
    {
      title: "Total Estimates Spend Pre Tax and Support",
      dataIndex: "total_estimates_spend_pre_tax_and_support",
      key: "total_estimates_spend_pre_tax_and_support",
    },
    {
      title: "Managed Service",
      dataIndex: "managed_service",
      key: "managed_service",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },

    {
      title: "Estimated Spend",
      dataIndex: "estimated_spend",
      key: "estimated_spend",
    },
  ];
  return (
    <>
      <Row>
        <Col>
          <h1 className="site-color block-title">Budget vs Spend </h1>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-1">
        <Col xxl={9} xl={10} lg={10} md={24} sm={24} xs={24}>
          <Row gutter={[16, 16]}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="plat-card-1 plat-padding">
                <Spin
                  spinning={lspendstatus}
                  wrapperClassName="costimize-loader"
                >
                  {spenddetailsstatus && (
                    <SpendDetailsView
                      setSpendDetailsStatus={setSpendDetailsStatus}
                      spenddetailsstatus={spenddetailsstatus}
                    />
                  )}
                  <div>
                    <h1 className="card-title-1">
                      Usage for the month{" "}
                      {selectedTopdate !== "2023-5-01" && (
                        // <Icon
                        //   style={{ cursor: "pointer", color: "#848484" }}
                        //   icon="streamline:interface-alert-information-circle-information-frame-info-more-help-point-circle"
                        //   onClick={() => setSpendDetailsStatus(true)}
                        // />
                        <Icon
                          icon="iconamoon:information-circle-fill"
                          style={{
                            // marginTop: "7px",
                            fontSize: "19px",
                            color: "#848484",
                            cursor: "pointer",
                          }}
                          onClick={() => setSpendDetailsStatus(true)}
                        />
                      )}
                    </h1>
                  </div>
                  <div className="mt-3">
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate("/costdemaze/assent_spend")}
                    >
                      <h1 className="card-value-1">
                        {/* <Icon
                        icon="streamline:money-cash-search-dollar-search-pay-product-currency-query-magnifying-cash-business-money-glass"
                        className="quick-glance-icon"
                      /> */}
                        ${numberFormat(spenddetails.withoutcredit)}
                      </h1>
                    </span>
                  </div>
                  <div className="mt-2">
                    <h1 className="card-bottom-content">
                      after credits ${numberFormat(spenddetails.withcredit)}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="plat-card-1 plat-padding">
                <Spin
                  spinning={lcreditstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-title-1">Credits Applied</h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-value-1">
                      <Icon
                        icon="streamline:money-safe-vault-saving-combo-payment-safe-money-combination-finance"
                        className="quick-glance-icon"
                      />
                      ${numberFormat(creditamount)}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="plat-card-1  plat-padding">
                <Spin
                  spinning={lforecaststatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-title-1">
                      Spend forecast for {current_month_text}
                      <Tooltip
                        placement="bottom"
                        title="Forecast doesn’t include tax"
                      >
                        {" "}
                        {/* <Icon
                          style={{ cursor: "pointer", color: "#000" }}
                          icon="streamline:interface-alert-information-circle-information-frame-info-more-help-point-circle"
                        /> */}
                        <Icon
                          icon="iconamoon:information-circle-fill"
                          style={{
                            // marginTop: "7px",
                            fontSize: "19px",
                            color: "#848484",
                            cursor: "pointer",
                          }}
                          // onClick={handletableforecast()}
                          onClick={() => setIsModalOpen(true)}
                        />
                      </Tooltip>
                    </h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-value-1">
                      {/* <Icon
                        icon="streamline:money-cash-search-dollar-search-pay-product-currency-query-magnifying-cash-business-money-glass"
                        className="quick-glance-icon"
                      /> */}
                      {forecastdetails.forcast_value === 0
                        ? `$0`
                        : `$${numberFormat(forecastdetails.forcast_value)}`}
                    </h1>
                  </div>
                  <div className="mt-2">
                    <h1 className="card-bottom-content">
                      {forecastdetails.status === "=" ? (
                        <>Same as Budget</>
                      ) : (
                        <>
                          <Icon
                            className={
                              "mb-1 " +
                              (forecastdetails.status === "-"
                                ? "danger-color"
                                : "success-color")
                            }
                            icon={
                              forecastdetails.status === "-"
                                ? "ic:sharp-arrow-drop-up"
                                : "ic:sharp-arrow-drop-down"
                            }
                            style={{ fontSize: "20px" }}
                          />
                          {forecastdetails.diff_value}% vs Budget
                        </>
                      )}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card
                className="plat-card-1 cursor-pointer plat-padding"
                onClick={() => navigate("/costdemaze/budget_planning")}
              >
                <Spin
                  spinning={lbudgetstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-title-1"> Budget for the period</h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-value-1">
                      <Icon
                        icon="solar:money-bag-bold"
                        className="quick-glance-icon"
                      />
                      {budgetdetails.overall_budget === 0
                        ? `$0`
                        : `$${numberFormat(budgetdetails.overall_budget)}`}
                    </h1>
                  </div>
                  <div className="mt-2">
                    <h1 className="card-bottom-content">
                      {budgetdetails.status === "=" ? (
                        <>Same as Previous Month</>
                      ) : (
                        <>
                          <Icon
                            className={
                              "mb-1 " +
                              (budgetdetails.status === "-"
                                ? "danger-color"
                                : "success-color")
                            }
                            icon={
                              budgetdetails.status === "-"
                                ? "ic:sharp-arrow-drop-up"
                                : "ic:sharp-arrow-drop-down"
                            }
                            style={{ fontSize: "20px" }}
                          />
                          {budgetdetails.month_budget}% vs Previous Month
                        </>
                      )}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xxl={7} xl={8} lg={8} md={24} sm={24} xs={24}>
          {/* <Card className="plat-card-1">
            <SpendChart
              chartvalue={spenddetails.chartvalue}
              lspendstatus={lspendstatus}
            />
          </Card> */}
          <Card className="plat-card-2 chart-space pt-3">
            <TopApplicationChart />
          </Card>
        </Col>
        <Col xxl={8} xl={6} lg={6} md={24} sm={24} xs={24}>
          <Card className="plat-card-1">
            <span className="d-flex justify-content-start">
              {/* <Icon
                icon="iconamoon:information-circle-fill"
                style={{ fontSize: "14px", marginTop: "3px" }}
              /> */}
              {/* <h1
                className="spend-day-chart-content"
                style={{
                  marginRight: "10px",
                  marginLeft: "3px",
                  marginTop: "0px",
                  fontSize: "10px",
                }}
              >
                Click bar and view spend by day
              </h1> */}
            </span>
            <SpendVSBudget
              budget={budgetdetails.chartvalue}
              spend={spenddetails.chartvalue}
              lspendstatus={lspendstatus}
              lbudgetstatus={lbudgetstatus}
              setDayChartStatus={setDayChartStatus}
              setSpendDate={setSpendDate}
              spenddate={spenddate}
            />
          </Card>
        </Col>
        {/* {daychartstatus && ( */}
        <SpendServiceChartBody
          spenddate={spenddate}
          setDayChartStatus={setDayChartStatus}
          setSpendDate={setSpendDate}
          // onModifiedData={handleModifiedData}
        />
        {/* )} */}
      </Row>
      <Modal
        footer={false}
        open={isModalOpen}
        onCancel={handleCancel}
        className="plat-card-modal table-forecast"
      >
        {/* <Table className="slm-table" dataSource={forecastdetailsrecord} columns={columns} />; */}
        <Table
          className="slm-table"
          dataSource={forecastdetailsrecord}
          footer={() => (
            <tr style={{ textAlign: "right" }}>
              <td>Total Forecast Value: {forecastdetails.forcast_value}</td>
            </tr>
          )}
        >
          <Column
            title="Account Name"
            dataIndex="account_names"
            key="account_names"
          />
          <Column title="Cloud" dataIndex="clouds" key="clouds" />
          <Column
            title="Billingperiod startdate"
            dataIndex="month"
            key="month"
          />
          <Column title="Customer" dataIndex="customer" key="customer" />
          <ColumnGroup title="a">
            <Column title="Days" dataIndex="days" key="days" />
          </ColumnGroup>
          <ColumnGroup title="b">
            <Column
              title="Remaining Days"
              dataIndex="remaining_days"
              key="remaining_days"
            />
          </ColumnGroup>
          <ColumnGroup title="x">
            <Column
              title="Average Dailly spend for last 7 days"
              dataIndex="average_dailly_spend_for_last_7_days"
              key="average_dailly_spend_for_last_7_days"
            />
          </ColumnGroup>
          <ColumnGroup title="A">
            <Column
              title="Actuals spent for the past days of the month"
              dataIndex="actuals_spent_for_the_past_days_of_the_month"
              key="actuals_spent_for_the_past_days_of_the_month"
            />
          </ColumnGroup>
          <ColumnGroup title="B = b * x">
            <Column
              title="Estimated Spend for remaining days of  the month"
              dataIndex="estimated_spend_for_remaining_days_of_the_month"
              key="estimated_spend_for_remaining_days_of_the_month"
            />
          </ColumnGroup>
          <ColumnGroup title="C = A+ B">
            <Column
              title="Total Estimates Spend Pre Tax and Support"
              dataIndex="total_estimates_spend_pre_tax_and_support"
              key="total_estimates_spend_pre_tax_and_support"
            />
          </ColumnGroup>
          <ColumnGroup title="D = C * % Support">
            <Column
              title="Managed Service"
              dataIndex="managed_service"
              key="managed_service"
            />
          </ColumnGroup>
          <ColumnGroup title="E = (C+D) * 0.18">
            <Column title="Tax" dataIndex="tax" key="tax" />
          </ColumnGroup>
          <ColumnGroup title="F = C+D+E">
            <Column
              title="Estimated Spend"
              dataIndex="estimated_spend"
              key="estimated_spend"
            />
          </ColumnGroup>
        </Table>
      </Modal>
    </>
  );
};

export default memo(BudgetvsSpend);
