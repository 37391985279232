import { Card, Col, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import {
  assessphasewise,
  assessplannedmigration,
  assessplannedmigrationtable,
  assesstobecore,
  assesstobememory,
} from "../../components/action/graphql/a2crover/A2CReducer";
function PlanAndDesign() {
  const dispatch = useDispatch();
  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [assessphase, setAssessphase] = useState([]);
  const [assessplan, setAssessplan] = useState([]);
  const [tablerecord, setTablerecord] = useState([]);
  const [assesscore, setAssesscore] = useState([]);
  const [assessmemory, setAssessmemory] = useState([]);
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const {
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  } = useSelector((store) => store.a2cRover);
  // console.log(selectedEnvironment);
  const input = {
    application: selectedApplication.toString(),
    company: company,
    data_center_name: selecteddc.toString(),
    db: selecteddb.toString(),
    environment: selectedEnvironment.toString(),
    os: selectedos.toString(),
  };
  useEffect(() => {
    dispatch(assessphasewise(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_phase_wise.map((datas) => {
          return {
            phase_level: datas.phase_level,
            resource_count: datas.resource_count,
          };
        });
        console.log(record);
        setAssessphase(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assessplannedmigration(input))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setIsShowSpinner(false);

        const record = data.migrate_resource_by_planned.map((datas) => {
          const date = new Date(datas.date);
          const monthNames = [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ];
          const formattedDate = `${
            monthNames[date.getMonth()]
          }-${date.getDate()}, ${date.getFullYear()}`;

          return {
            date: formattedDate,
            resource_count: datas.resource_count,
          };
        });
        setAssessplan(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assessplannedmigrationtable(input))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "vmtable");
        setIsShowSpinner(false);

        const record = data.assess_to_be_vm_table.map((datas) => {
          // const vm_type = parseInt(datas.vm_type)
          return {
            memory: datas.memory,
            resource_name: datas.resource_name,
            vcpu: datas.vcpu,
            vm_type: datas.vm_type,
          };
        });

        console.log(record);
        setTablerecord(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assesstobecore(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_to_be_core.map((datas) => {
          return {
            to_be_core: datas.to_be_core,
            application: datas.application,
          };
        });
        console.log(record);
        setAssesscore(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  useEffect(() => {
    dispatch(assesstobememory(input))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "asssssgrp");
        setIsShowSpinner(false);

        const record = data.assess_to_be_memory.map((datas) => {
          return {
            total_mem: datas.total_mem,
            application: datas.application,
          };
        });
        console.log(record);
        setAssessmemory(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  const phaseData = assessphase;
  let startValue = 0;
  let endValue = 0;
  const chartData_phase = [];
  phaseData.forEach((phase) => {
    const resourceCount = parseInt(phase.resource_count);
    endValue += resourceCount;
    chartData_phase.push({
      x: phase.phase_level,
      y: [startValue, endValue],
      fill: "#A7EE7C",
    });
    startValue = endValue + 1;
  });
  const result = endValue;
  chartData_phase.push({
    x: "Total",
    y: [0, result],
    fill: "#999999",
    tooltip: {
      content: `Total: ${result}`,
    },
  });

  const datephas = chartData_phase.map((item) => item.x);

  console.log(datephas);

  const chartOptions = {
    chart: {
      type: "treemap",
    },
    series: [
      {
        data: [
          {
            x: "",
            y: 218,
          },
          {
            x: "",
            y: 149,
          },
          {
            x: "",
            y: 184,
          },
          {
            x: "",
            y: 55,
          },
          {
            x: "",
            y: 84,
          },
          {
            x: "",
            y: 31,
          },
          {
            x: "",
            y: 70,
          },
          {
            x: "",
            y: 30,
          },
          {
            x: "",
            y: 44,
          },
          {
            x: "",
            y: 68,
          },
          {
            x: "",
            y: 28,
          },
          {
            x: "",
            y: 19,
          },
          {
            x: "",
            y: 29,
          },
        ],
      },
    ],
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };
  const columns = [
    {
      title: "Source Resource Name",
      dataIndex: "resource_name",
      key: "resource_name",
      align: "left",
      width: "20%",
    },
    {
      title: "Instance Type",
      dataIndex: "vm_type",
      key: "vm_type",
      key: "vcpu",
      align: "left",
      width: "20%",
    },
    {
      title: "Right Sized Core",
      dataIndex: "vcpu",
      key: "vcpu",
      align: "right",
      width: "20%",
    },
    {
      title: "Right Sized RAM (GiB)",
      dataIndex: "memory",
      key: "memory",
      key: "vcpu",
      align: "right",
      width: "10%",
    },
  ];

  const dates = assessplan.map((item) => item.date);
  // const labels_db = dbspread.map((item) => item.database);
  console.log(dates);
  const plannedchartoptions = {
    chart: {
      type: "area",
      height: 350,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    xaxis: {
      categories: dates,
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    yaxis: {
      title: {
        text: "Resource Count",
      },
      labels: {
        style: {
          colors: "white",
        },
      },
    },
  };

  chartOptions.dataLabels = {
    enabled: false,
  };

  chartOptions.stroke = {
    width: 2,

    curve: "smooth",
  };
  const options_phase = {
    chart: {
      type: "rangeBar",
      height: 250,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    xaxis: {
      type: "category",
      categories: datephas,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    tooltip: {
      enabled: false,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    noData: {
      text: "No data ",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#FFF",
        fontSize: "20px",
      },
    },
  };

  const series_phase = [
    {
      name: "Range",
      data: chartData_phase,
      dataLabels: {
        enabled: true,
      },
    },
  ];

  const resourceCounts = assessplan.map((item) =>
    parseInt(item.resource_count)
  );

  const plannedchartData = [
    {
      name: "Resource Count",
      data: resourceCounts,

      fill: {
        type: "gradient",

        gradient: {
          shadeIntensity: 0.3,

          opacityFrom: 0.7,

          opacityTo: 0.9,

          stops: [0, 100],
        },
      },

      stroke: {
        curve: "smooth",

        color: "#FFFF",
      },
    },
  ];
  const chartOptionscore = {
    chart: {
      type: "treemap",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    series: [
      {
        data: assesscore.map((item) => ({
          x: item.application,
          y: parseFloat(item.to_be_core),
        })),
      },
    ],
  };
  const chartOptionsmemory = {
    chart: {
      type: "treemap",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    series: [
      {
        data: assessmemory.map((item) => ({
          x: item.application,
          y: parseFloat(item.total_mem),
        })),
      },
    ],
  };
  return (
    <div>
      <div className="cards6">
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            {/* <Card
              title="Phase wise App Migration"
              bordered={false}
              className="operate-cards"
            >
              {isShowSpinner && chartData_phase.length === 0 ? (
               
                  <div className="d-flex justify-content-center align-items-center spinner-height" style={{ textAlign: "center", marginTop: "15%", color: "#FFF" }}>No Data
                  <Spin />
                </div>
              ) : (
                <ReactApexChart
                  options={options_phase}
                  series={series_phase}
                  type="rangeBar"
                  height={250}
                />
              )}
            </Card> */}
            <Card
              title="Phase Wise App Migration"
              bordered={false}
              className="operate-cards"
            >
              {isShowSpinner && chartData_phase.length === 0 ? (
                <div
                  className="d-flex justify-content-center align-items-center spinner-height"
                  style={{
                    textAlign: "center",
                    marginTop: "15%",
                    color: "#FFF",
                  }}
                >
                  No Data
                  <Spin />
                </div>
              ) : (
                <ReactApexChart
                  options={options_phase}
                  series={series_phase}
                  type="rangeBar"
                  height={250}
                />
              )}
            </Card>
          </Col>

          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Card
              title="Resource count vs Planned Migration Date"
              bordered={false}
              className="operate-cards"
            >
              {isShowSpinner ? (
                <div className=" d-flex justify-content-center align-items-center spinner-height">
                  <Spin />
                </div>
              ) : (
                <ReactApexChart
                  options={plannedchartoptions}
                  series={plannedchartData}
                  type="area"
                  height={250}
                ></ReactApexChart>
              )}
            </Card>
          </Col>
        </Row>
      </div>

      <div className="">
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Card bordered={false} className="operate-cards">
              <div className="">
                <Table
                  className="slm-table"
                  columns={columns}
                  dataSource={tablerecord}
                  loading={{ indicator: <Spin />, spinning: isShowSpinner }}
                  pagination={{ pageSize: 10 }}
                ></Table>
              </div>
            </Card>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
            <Card
              title=" To-Be Core"
              bordered={false}
              className="operate-cards"
            >
              {isShowSpinner ? (
                <div className=" d-flex justify-content-center align-items-center spinner-height">
                  <Spin />
                </div>
              ) : (
                <ReactApexChart
                  options={chartOptionscore}
                  series={chartOptionscore.series}
                  className="piechart"
                  type="treemap"
                  height={715}
                />
              )}
            </Card>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
            <Card title="To-Be RAM" bordered={false} className="operate-cards">
              {isShowSpinner ? (
                <div className=" d-flex justify-content-center align-items-center spinner-height">
                  <Spin />
                </div>
              ) : (
                <ReactApexChart
                  options={chartOptionsmemory}
                  series={chartOptionsmemory.series}
                  className="piechart"
                  type="treemap"
                  height={715}
                />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PlanAndDesign;
