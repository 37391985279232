import { Icon } from "@iconify/react";
import { Button, Form, Input, Popconfirm, Table, Select } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  rightSizingTable,
  dropdownvminstance,
} from "../action/graphql/a2crover/A2CCostAnalysisREducer";

const { Option } = Select;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  // api for right sizing

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            requir4ed: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {/* <Input ref={inputRef} onPressEnter={save} onBlur={save} /> */}
        <Select
          defaultValue="lucy"
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{
            width: 120,
          }}
          // onChange={handleChange}
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "Yiminghe",
              label: "yiminghe",
            },
            {
              value: "disabled",
              label: "Disabled",
              disabled: true,
            },
          ]}
        />
      </Form.Item>
    ) : (
      <div
        // style={{
        //   paddingRight: 24,
        // }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const RightSizingEditablleTable = () => {
  const dispatch = useDispatch();
  let companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  // const dispatch = useDispatch();
  const [rightsizingtable, setRightSizingTable] = useState([]);
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const [editTable, setEditTable] = useState([]);
  const [vmDropdown, setvmDropdown] = useState([]);

  useEffect(() => {
    const input1 = {
      company: "bi_worldwide",
    };
    dispatch(rightSizingTable({ input1 }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setIsShowSpinner(false);

        const record = data.cost_analysis_right_sizing_data.map((datas) => {
          return {
            environment: datas.environment,
            application: datas.application,
            resource_name: datas.resource_name,
            as_is_core: datas.as_is_core,
            to_be_core: datas.to_be_core,
            as_is_ram: datas.as_is_ram,
            to_be_ram: datas.to_be_ram,
            as_is_storage: datas.as_is_storage,
            to_be_storage: datas.to_be_storage,
            instance_type: datas.instance_type,
            storage_type: datas.storage_type,
            egress: datas.egress,
            region: datas.region,
          };
        });
        setRightSizingTable(record);
        console.log(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    const input1 = {
      company: company,
      region: "us-east-1",
    };
    dispatch(dropdownvminstance({ input1 }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setIsShowSpinner(false);

        const record = data.cost_analysis_select_vm.map((datas) => {
          return {
            vm_type: datas.vm_type,
            cpu: datas.cpu,
            ram: datas.ram,
          };
        });
        setvmDropdown(record);
        console.log(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  companyName = "bi_worldwide";
  useEffect(() => {
    dispatch(rightSizingTable(companyName))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getTableValues = (record, index) => {
    console.log("Table Values:", rightsizingtable);
    const input = rightsizingtable[0];
    dispatch(input)
      .unwrap()
      .then(({ data }) => {
        console.log(data, "assessmenttable");
      })
      .catch((err) => {
        console.error("Error in assessmenttable:", err);
      });
    console.log("Row Index:", index);
    console.log("Row Values:", record);
    const updatedTableData = [...rightsizingtable];
    updatedTableData[index] = { ...record };
    setRightSizingTable(updatedTableData);
  };

  const [dataSource, setDataSource] = useState([
    // {
    //   vm_type: "0",
    //   vcpu: "Edward King 0",
    //   memory: "32",
    //   environment: "London, Park Lane no. 0",
    // },
  ]);
  const [count, setCount] = useState(2);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const [editableKeys, setEditableKeys] = useState([]); // State to manage editable rows/keys

  const handleEdit = (key) => {
    setEditableKeys([key]); // Set the key of the row to be edited
  };
  const defaultColumns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      key: "environment",
      align: "left",
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      align: "right",
    },
    {
      title: "Resource Name",
      dataIndex: "resource_name",
      key: "resource_name",

      align: "right",
    },
    {
      title: "As IS-Core",
      dataIndex: "as_is_core",
      key: "as_is_core",
      align: "right",
    },
    {
      title: "To Be-core",
      dataIndex: "to_be_core",
      key: "to_be_core",
      align: "left",
      editable: true,
      render: (_, record) => {
        const isEditable = editableKeys.includes(record.key);

        return isEditable ? (
          <Select
            defaultValue={record.instance_type}
            style={{ width: "100px" }}
          >
            <Option value="Option 1">Option 1</Option>
            <Option value="Option 2">Option 2</Option>
            {/* Add more options as needed */}
          </Select>
        ) : (
          record.instance_type
        );
      },
    },
    {
      title: "As IS-RAM",
      dataIndex: "as_is_ram",
      key: "as_is_ram",
      align: "left",
    },
    {
      title: "TO Be-RAM",
      dataIndex: "to_be_ram",
      key: "to_be_ram",
      align: "left",
      editable: true,
      render: (_, record) => {
        const isEditable = editableKeys.includes(record.key);

        return isEditable ? (
          <Select
            defaultValue={record.instance_type}
            style={{ width: "100px" }}
          >
            <Option value="Option 1">Option 1</Option>
            <Option value="Option 2">Option 2</Option>
            {/* Add more options as needed */}
          </Select>
        ) : (
          record.instance_type
        );
      },
    },
    {
      title: "AS Is-Storage",
      dataIndex: "as_is_storage",
      key: "as_is_storage",

      align: "right",
    },
    {
      title: "To Be-Storage",
      dataIndex: "to_be_storage",
      key: "to_be_storage",

      align: "right",
    },
    {
      title: "Instance Type",
      dataIndex: "instance_type",
      key: "instance_type",
      align: "right",
      editable: true,
      render: (_, record) => {
        const isEditable = editableKeys.includes(record.key);

        return isEditable ? (
          <Select
            defaultValue={record.instance_type}
            style={{ width: "100px" }}
          >
            <Option value="Option 1">Option 1</Option>
            <Option value="Option 2">Option 2</Option>
            {/* Add more options as needed */}
          </Select>
        ) : (
          record.instance_type
        );
      },
    },
    {
      title: "Storage Type",
      dataIndex: "storage_type",
      key: "storage_type",
      align: "right",
      editable: true,
      render: (_, record) => {
        const isEditable = editableKeys.includes(record.key);

        return isEditable ? (
          <Select
            defaultValue={record.instance_type}
            style={{ width: "100px" }}
          >
            <Option value="Option 1">Option 1</Option>
            <Option value="Option 2">Option 2</Option>
            {/* Add more options as needed */}
          </Select>
        ) : (
          record.instance_type
        );
      },
    },

    {
      title: "Egress %",
      dataIndex: "egress",
      key: "egress",

      align: "right",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",

      align: "right",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (record, index) => {
        return (
          <>
            <div
              // onClick={() => showEditRow(index)}
              style={{ cursor: "pointer" }}
            >
              <Icon
                icon="material-symbols:edit"
                color="green"
                onClick={() => handleEdit(record?.key)}
              />
              {/* <Icon icon="material-symbols:edit" /> */}
            </div>
          </>
        );
      },
    },
  ];
  const handleAdd = () => {
    const newData = {
      key: count,
      environment: `Enter Data`,
      application: "Enter data",
      resource_name: `London, Park Lane no. ${count}`,
      as_is_core: "23",
      to_be_core: "45",
      as_is_ram: "67",
      to_be_ram: "54",
      as_is_storage: "76",
      to_be_storage: "73",
      instance_type: "65",
      storage_type: "73",
      egress: "76",
      region: "hsjq",
    };
    setRightSizingTable([...rightsizingtable, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,

        handleSave,
      }),
    };
  });
  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button
          onClick={handleAdd}
          className="yes-button"
          style={{
            marginBottom: 10,
          }}
        >
          <Icon icon="ic:round-plus"></Icon>&nbsp; Add a row
        </Button>
      </div>
      <Table
        // components={components}
        dataSource={rightsizingtable}
        columns={defaultColumns}
        className="slm-table"
        scroll={{ x: 2000 }}
      />
    </div>
  );
};
export default RightSizingEditablleTable;
