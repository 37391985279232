import { memo, useEffect, useState } from "react";
import { Modal, Row, Col } from "antd";
import ReactApexChart from "react-apexcharts";
import Dropdown from "react-bootstrap/Dropdown";
import { Icon } from "@iconify/react";

const AssetSpendTopListChart = (props) => {
  // console.log(props);
  const [infrachart, setInfraChart] = useState({
    chartoption: {},
    chartvalue: [],
  });
  const [applicationchart, setApplicationChart] = useState({
    chartoption: {},
    chartvalue: [],
  });
  const [infracharttype, setInfraChartType] = useState("VM");
  const [applicationcharttype, setApplicationChartType] = useState("VM");
  const handleCancel = () => {
    props.setTopChartStatus(false);
  };
  useEffect(() => {
    var chart_cate = [];
    var chart_value = [];
    // chartspend
    if (applicationcharttype === "VM") {
      chart_cate = props.spendchart.categories;
      chart_value = props.spendchart.value;
    } else if (applicationcharttype === "DBaaS") {
      const records_list = props.chartspend.sort((a, b) =>
        a.db > b.db ? -1 : 1
      );
      var cate_list = [];
      var vmcount = [];
      records_list.map((data, index) => {
        if (index < 5) {
          cate_list.push(data.name);
          vmcount.push(data.db);
        }
      });
      chart_cate = cate_list;
      chart_value = vmcount;
    } else if (applicationcharttype === "Others") {
      const records_list = props.chartspend.sort((a, b) =>
        a.others > b.others ? -1 : 1
      );
      var cate_list = [];
      var vmcount = [];
      records_list.map((data, index) => {
        if (index < 5) {
          cate_list.push(data.name);
          vmcount.push(data.others);
        }
      });
      chart_cate = cate_list;
      chart_value = vmcount;
    }

    setApplicationChart({
      chartoption: {
        chart: {
          type: "bar",
          height: 160,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            columnWidth: "55%",
            endingShape: "rounded",
            dataLabels: {
              orientation: "vertical",
              position: "top", // bottom/center/top
            },
          },
        },
        grid: {
          show: false,
        },
        colors: [
          "#FF6A2F",
          "#A7EE7C",
          "#40A2B8",
          "#646464",
          "#FFFFFF",],
        // colors: ["#549f7a", "#349db4", "#3a8bc2", "#724d8c", "#e17646"],
        xaxis: {
          categories: chart_cate,

          axisBorder: {
            show: true,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            offsetX: 0,
            offsetY: -6,
            style: {
              fontSize: "12px",
              fontWeight: 400,
              colors: "white",
            },
            rotate: 0,
            // wrap: true,
            maxWidth: 1000,
            trim: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            style: {
              fontSize: "10px",
              fontWeight: 400,
              colors: "white",
            },
            formatter: (num) => {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          labels: {
            colors: "white"
          },
        },
        title: {
          text: `Top 5 ${applicationcharttype} Application Spend`,
          align: "center",
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "#fff",
          },
        },
      },
      chartvalue: [
        {
          name: `${applicationcharttype} Spend`,
          data: chart_value,
        },
      ],
    });
  }, [props, applicationcharttype]);

  useEffect(() => {
    var chart_cate = [];
    var chart_value = [];
    // chartspend

    if (infracharttype === "VM") {
      chart_cate = props.infrochart.categories;
      chart_value = props.infrochart.vmlist;
    } else if (infracharttype === "DBaaS") {
      const records_list = props.chartcount.sort((a, b) =>
        a.db > b.db ? -1 : 1
      );
      var cate_list = [];
      var vmcount = [];
      records_list.map((data, index) => {
        if (index < 5) {
          cate_list.push(data.name);
          vmcount.push(data.db);
        }
      });
      chart_cate = cate_list;
      chart_value = vmcount;
    } else if (infracharttype === "Others") {
      const records_list = props.chartcount.sort((a, b) =>
        a.others > b.others ? -1 : 1
      );
      var cate_list = [];
      var vmcount = [];
      records_list.map((data, index) => {
        if (index < 5) {
          cate_list.push(data.name);
          vmcount.push(data.others);
        }
      });
      chart_cate = cate_list;
      chart_value = vmcount;
    }
    setInfraChart({
      chartoption: {
        chart: {
          type: "bar",
          height: 160,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            columnWidth: "55%",
            endingShape: "rounded",
            dataLabels: {
              orientation: "vertical",
              position: "top", // bottom/center/top
            },
          },
        },
        stroke: {
          show: false,
          curve: "smooth",
        },
        grid: {
          show: false,
        },
        colors: [
          "#FF6A2F",
          "#A7EE7C",
          "#40A2B8",
          "#646464",
          "#FFFFFF",],
        // colors: ["#58b", "#6bb", "#a64", "#e94", "#b55"],
        xaxis: {
          categories: chart_cate,
          axisBorder: {
            show: true,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            offsetX: 0,
            offsetY: -6,
            style: {
              fontSize: "12px",
              fontWeight: 400,
              colors: "white",
            },
            rotate: 0,
            // wrap: true,
            maxWidth: 1000,
            trim: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            style: {
              fontSize: "10px",
              fontWeight: 400,
              colors: "white",
            },
            formatter: (num) => {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          labels: {
            colors: "white"
          },
        },
        title: {
          text: `Top 5 ${infracharttype}`,
          align: "center",
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "#fff",
          },
        },
      },
      chartvalue: [
        {
          name: infracharttype,
          data: chart_value,
        },
      ],
    });
  }, [props, infracharttype]);

  const barChartView = (type) => {
    setInfraChartType(type);
  };
  const spendChartView = (type) => {
    setApplicationChartType(type);
  };
  return (
    <>
      <Modal
      className="slmmodalchart"
        title="Analysis"
        open={props.topchartstatus}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center" }}
        width="auto"
      >
        <Row gutter={[16, 16]}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="popup-chart-cart slmpopupchart">
              <ReactApexChart
                options={infrachart.chartoption}
                series={infrachart.chartvalue}
                type="bar"
                height={235}
              />
              <div className="plat-barchart-icon">
                <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                  <Dropdown.Toggle
                    className="plat-three-dort plat-tap-menu plat-box-three-dort"
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    <Icon
                      icon="ph:dots-three-bold"
                      class="plat-expandalt-icon-view"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="plat-box-menu-list">
                    <Dropdown.ItemText
                      onClick={() => barChartView("VM")}
                      className="plat-dropdown-item-text"
                    >
                      <span className="plat-box-item-title list-expand-left">
                        VM
                      </span>
                      <span className="plat-box-item-title list-expand-right">
                        <Icon icon="mdi:cloud-print-outline" />
                      </span>
                    </Dropdown.ItemText>
                    <Dropdown.ItemText
                      onClick={() => barChartView("DBaaS")}
                      className="plat-dropdown-item-text"
                    >
                      <span className="plat-box-item-title list-expand-left">
                        DBaaS
                      </span>
                      <span className="plat-box-item-title list-expand-right">
                        <Icon icon="carbon:ibm-cloud-hyper-protect-dbaas" />
                      </span>
                    </Dropdown.ItemText>
                    <Dropdown.ItemText
                      onClick={() => barChartView("Others")}
                      className="plat-dropdown-item-text"
                    >
                      <span className="plat-box-item-title list-expand-left">
                        Others
                      </span>
                      <span className="plat-box-item-title list-expand-right">
                        <Icon icon="carbon:cloud-logging" />
                      </span>
                    </Dropdown.ItemText>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
          </Row>
          <br></br>
          <Row>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="popup-chart-cart slmpopupchart">
              <ReactApexChart
                options={applicationchart.chartoption}
                series={applicationchart.chartvalue}
                type="bar"
                height={235}
              />
              <div className="plat-barchart-icon">
                <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                  <Dropdown.Toggle
                    className="plat-three-dort plat-tap-menu plat-box-three-dort"
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    <Icon
                      icon="ph:dots-three-bold"
                      class="plat-expandalt-icon-view"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="plat-box-menu-list">
                    <Dropdown.ItemText
                      onClick={() => spendChartView("VM")}
                      className="plat-dropdown-item-text"
                    >
                      <span className="plat-box-item-title list-expand-left">
                        VM
                      </span>
                      <span className="plat-box-item-title list-expand-right">
                        <Icon icon="mdi:cloud-print-outline" />
                      </span>
                    </Dropdown.ItemText>
                    <Dropdown.ItemText
                      onClick={() => spendChartView("DBaaS")}
                      className="plat-dropdown-item-text"
                    >
                      <span className="plat-box-item-title list-expand-left">
                        DBaaS
                      </span>
                      <span className="plat-box-item-title list-expand-right">
                        <Icon icon="carbon:ibm-cloud-hyper-protect-dbaas" />
                      </span>
                    </Dropdown.ItemText>
                    <Dropdown.ItemText
                      onClick={() => spendChartView("Others")}
                      className="plat-dropdown-item-text"
                    >
                      <span className="plat-box-item-title list-expand-left">
                        Others
                      </span>
                      <span className="plat-box-item-title list-expand-right">
                        <Icon icon="carbon:cloud-logging" />
                      </span>
                    </Dropdown.ItemText>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default memo(AssetSpendTopListChart);
