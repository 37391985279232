import { Button, Card, Col, Row, Switch } from "antd";
import apexcharts from "apexcharts";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Companylogo from "../../costimize_v2/WeeklyReport/company_logo.png";
import CHLOGO from "../../costimize_v2/WeeklyReport/onechlogo.jpg";
import "../../costimize_v2/costimize_v2.css";
import Appdata from "./Appdata";
import ApplicationLevelDeatiledView from "./ApplicationLevelDeatiledView";
import ApplicationLevelSummaryUI from "./ApplicationLevelSummaryUI";
import ApplicationLvelSummarySpentMoreUI from "./ApplicationLvelSummarySpentMoreUI";
import EnvironmentDetailedSummary from "./EnvironmentDetailedSummary";
import OverallSummaryUI from "./OverallSummaryUI";
import ProductionTable from "./ProductionTable";
import Rep from "./Rep";
import Reportdata from "./Reportdata";
import ServiceLevelDetaileView from "./ServiceLevelDetaileView";
import ServiceLevelWeeklySpendUI from "./ServiceLevelWeeklySpendUI";
import ThreeMonthsSpend from "./ThreeMonthsSpend";
import ReactApexChart from "react-apexcharts";
import ServiceSummary from "./ServiceSummary";
import { headercustomerlogo } from "../../action/header_logo";
import { DatePicker, Space } from "antd";
import moment from "moment";

function Report() {
  //kamalika
  const currentDate = new Date();
  const date = `${currentDate.getDate() - 3}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;
  console.log(date);
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const companyName = company_name.toUpperCase();
  //API's kamalika
  const [reportmonth, setReportmonth] = useState([]); //integrated
  const [reportweek, setReportweek] = useState([]); //Intergrated
  const [reportweekwise, setReportweekwise] = useState([]);
  // const [reportweekwisesum, setReportweekwisesum] = useState([]);
  const [reportweekwiseappsum, setReportweekwiseappsum] = useState([]);
  const [reportweekwiseappsuminc, setReportweekwiseappsuminc] = useState([]); //Value is coming
  const [reportweekwiseappsumdec, setReportweekwiseappsumdec] = useState([]); // values is coming
  const [reportweekwiseappsumservice, setReportweekwiseappsumservice] =
    useState([]); //values is coming
  const [reportappwise, setReportweekappservice] = useState([]);
  const [reportweekenvironment, setReportweekenvironment] = useState([]); //environemnt week
  const [reportDetailedService, setReportdetailedservice] = useState([]);
  const [overall, setOverall] = useState([]);
  const [company_logo, setCompanyLogo] = useState();
  const [reportApplication, setReportApplication] = useState("");
  const [reportService, setReportService] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chartDatas, setChartData] = useState("");
  const [applicationService, setApplicationService] = useState("");
  const chartData = reportweekwise.map((data) => ({
    environment: data.environment,
    pre_previous_week: parseInt(data.pre_previous_week),
    previous_week: parseInt(data.previous_week),
    current_week: parseInt(data.current_week),
  }));
  const dispatch = useDispatch();
  //kamalika
  const options_Grouped = {
    chart: {
      id: "groupedbar",
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      offsetY: -20,
    },
    xaxis: {
      categories: chartData.map((data) => data.environment),
    },
    title: {
      text: "Spend by Environments & Week ($)",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    legend: {
      position: "top",
      // containerMargin: {
      //   top: 10, // Space between the title and legend
      // },
      // border: {
      //   show: true, // Display the legend border
      //   radius: 5, // Border radius
      //   width: 1, // Border width
      //   strokeColor: "#000", // Border color
      //   offsetX: 0,
      //   offsetY: 0,
      // },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
  };
  const series_Grouped = [
    {
      name: "22/10-28/10",
      data: chartData.map((data) => data.pre_previous_week),
    },
    {
      name: "29/10-4/11",
      data: chartData.map((data) => data.previous_week),
    },
    {
      name: "05/11-11/11",
      data: chartData.map((data) => data.current_week),
    },
  ];
  const chartDataapp = Array.isArray(reportApplication)
    ? reportApplication.map((data) => ({
        application: data.application,
        pre_previous_week: parseInt(data.pre_previous_week),
        previous_week: parseInt(data.previous_week),
        current_week: parseInt(data.current_week),
      }))
    : [];
  const options_WeeklySpend = {
    chart: {
      id: "weeklyspendapp",
      type: "bar",
      height: 250,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      offsetY: -20,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: chartDataapp.map((data) => data.application),
      title: {
        text: "Application",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
    title: {
      text: "Weekly spend by top 5 Application ($)",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    legend: {
      position: "top",
    },
    yaxis: {
      title: {
        text: "Spend",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
  };
  const series_WeeklySpend = [
    {
      name: "22/10-28/10",
      data: chartDataapp.map((data) => data.pre_previous_week),
    },
    {
      name: "29/10-4/11",
      data: chartDataapp.map((data) => data.previous_week),
    },
    {
      name: "05/11-11/11",
      data: chartDataapp.map((data) => data.current_week),
    },
  ];

  const getDataUri = () => {
    apexcharts.exec("threemonthspend", "dataURI").then(({ imgURI }) => {
      setChartImageURI(imgURI);
    });
  };
  const threeWeeks = () => {
    apexcharts.exec("threeweekspend", "dataURI").then(({ imgURI }) => {
      setChartImageweek(imgURI);
    });
  };
  // const groupedBarchart = () => {
  //   apexcharts.exec("groupedbar", "dataURI").then(({ imgURI }) => {
  //     setGroupedbarIamge(imgURI);
  //   });
  // };
  // const weeklyspendapplication = () => {
  //   apexcharts.exec("weeklyspendapp", "dataURI").then(({ imgURI }) => {
  //     setWeeklyspendTopapp(imgURI);
  //   });
  // };

  //kamalika
  const spendService = () => {
    apexcharts.exec("spendbyservice", "dataURI").then(({ imgURI }) => {
      setServiceSpend(imgURI);
    });
  };
  const applicationSpend = () => {
    apexcharts.exec("spendbyapp", "dataURI").then(({ imgURI }) => {
      setapplicationSpend(imgURI);
    });
  };
  const appsummary = () => {
    apexcharts.exec("applicationsummary", "dataURI").then(({ imgURI }) => {
      setappSummary(imgURI);
    });
  };
  const fetchDataAndPassURIs = () => {
    getDataUri();
    threeWeeks();
    spendService();
    applicationSpend();
    appsummary();
    // groupedBarchart();
    // weeklyspendapplication();
  };
  const [display, setDisplay] = useState(false);
  const displayDetailed = () => {
    setDisplay(true);
  };
  const [chartImageURI, setChartImageURI] = useState("");
  const [chartImageweek, setChartImageweek] = useState("");
  const [serviceSpend, setServiceSpend] = useState("");
  const [applicationSpends, setapplicationSpend] = useState("");
  const [reportWeekwiseSum, setReportweekwisesum] = useState("");
  const [appSummary, setappSummary] = useState("");

  let keys = [];
  let seriesData = [];

  const monthdata = reportweek[0];
  if (monthdata && Object.keys(monthdata).length > 0) {
    keys = Object.keys(reportweek[0]);
    seriesData = Object.values(monthdata).map((value) => parseFloat(value));
    //.log(keys);
    //.log(seriesData);
  } else {
    //.log("no data available");
  }
  const formattedData = seriesData.map((value) =>
    value.toLocaleString("en-US")
  );

  //.log(formattedData);
  // const keys = Object.keys(reportweek[0]);
  // const values = Object.values(reportweek[0]);
  // //.log(keys);
  // //.log(values);
  const startDate = new Date(selectedDate);
  const end_Date = new Date(endDate);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(startDate.getTime() - oneWeek);
  const prevWeekEndDate = new Date(end_Date.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(startDate.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(end_Date.getTime() - 2 * oneWeek);
  const threeweekbeforeStart = new Date(startDate.getTime() - 3 * oneWeek);
  const threeweekbeforeEnd = new Date(end_Date.getTime() - 3 * oneWeek);
  const lastMonthSpend = new Date(startDate.getTime() - 7 * oneWeek);
  const lastMonthendSpend = new Date(end_Date.getTime() - 4 * oneWeek);
  // const formatDate = (date) => {
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   return `${day}/${month}`;
  // };

  // const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
  //   prevWeekEndDate
  // )}`;
  // const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
  //   prePrevWeekEndDate
  // )}`;
  // const currentWeekRange = `${formatDate(startDate)}-${formatDate(end_Date)}`;
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };

  const threeweekRange = `${formatDate(threeweekbeforeStart)}-${formatDate(
    threeweekbeforeEnd
  )}`;
  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(startDate)} - ${formatDate(end_Date)}`;

  const options_weeks = {
    chart: {
      id: "threeweekspend",
      type: "bar",
      height: 350,
      stacked: "true",
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        dataLabels: {
          position: "top",
          enabled: true,
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          total: {
            enabled: true,
            formatter: function (w) {
              return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 600,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      position: "bottom",
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      offsetY: -1,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        threeweekRange,
        prePrevWeekRange,
        prevWeekRange,
        currentWeekRange,
      ],
      // categories: keys,
    },
    title: {
      text: "Last Four Weeks Spend ($)",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
    colors: ["#FF6A2F", "#A7EE7C", "#40A2B8"],
    tooltip: {
      enabled: true,
      shared: true, // Enables a shared tooltip
      intersect: false,
      x: {
        formatter: function (val) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      y: {
        formatter: function (value) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    colors: ["#9FDEF1", "#2A5D78"],
  };
  const series_weeks = reportweekwise.map((item) => {
    return {
      name: item.environment,
      data: [
        parseInt(item.t_previous_week),
        parseInt(item.pre_previous_week),
        parseInt(item.previous_week),
        parseInt(item.current_week),
      ],
    };
  });
  const envi = [
    ...new Set(reportweekwiseappsuminc.map((item) => item.environment)),
  ];
  //.log(envi);
  const appli = envi.map((item) => item.application);
  //.log(appli);
  const nonProdData = reportweekwiseappsuminc.filter(
    (item) => item.environment === "Non-Production"
  );
  const prodData = reportweekwiseappsuminc.filter(
    (item) => item.environment === "Production"
  );

  //api

  const environmentData = {};
  reportweekwiseappsuminc.forEach((item) => {
    const { application, environment, current_week } = item;
    if (current_week && current_week !== "null") {
      if (!environmentData[environment]) {
        environmentData[environment] = [];
      }
      environmentData[environment].push({
        application,
        value: parseInt(current_week),
      });
    }
  });

  // Sort applications within each environment based on value (current week)
  Object.keys(environmentData).forEach((env) => {
    environmentData[env].sort((a, b) => b.value - a.value); // Sort by value
  });

  // Extract top 2 applications for each environment
  const series_nonprod = [];
  const labels = [];
  Object.keys(environmentData).forEach((env) => {
    if (environmentData[env].length >= 2) {
      series_nonprod.push(
        environmentData[env].slice(0, 2).map((app) => app.value)
      );
      labels.push(
        `${env}: ${environmentData[env][0].application}, ${environmentData[env][1].application}`
      );
    }
  });

  const options_nonprod = {
    chart: {
      id: "nonprod",
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: labels,
  };

  //api data
  let environments = [];
  if (Array.isArray(reportApplication)) {
    environments = [
      ...new Set(reportApplication.map((item) => item.environment)),
    ];
  }
  // Generate charts for each environment

  const charts = environments.map((env) => {
    // Filter data for the current environment
    const environmentData = reportApplication.filter(
      (item) => item.environment === env && item.current_week !== "null"
    );

    // Extract current week count for series and application names for labels
    const seriesData = environmentData.map((item) =>
      parseInt(item.current_week)
    );
    const labelsData = environmentData.map((item) => item.application);

    // ApexCharts options for the current environment

    const options = {
      chart: {
        type: "donut",
        height: "250",
        width: "500",
        id: "applicationsummary",
      },
      labels: labelsData,
      title: {
        text: env,
        style: {
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#ff6a2f",
        }, // Use environment name as the chart title
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false,
      },
      // Other chart options...
    };
    const legendLabels = labelsData.map((label, index) => {
      const formattedSeries = seriesData[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    options.labels = legendLabels;
    const series = seriesData;

    return (
      <div id="chart" key={env}>
        <Col span={24} key={env}>
          <Card className="report-cards">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width="550"
              height="250"
            />
          </Card>
        </Col>
      </div>
    );
  });

  // const series_nonprod = [44, 55, 41, 17, 15];
  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.platform_logo;
        var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        //.log(err);
      });
  }, []);

  const onChange = (date) => {
    //.log(date);
    const dates = new Date(date.$d); // Convert to Date object
    const formattedDate = `${dates.getFullYear()}-${(dates.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dates.getDate().toString().padStart(2, "0")}`;
    //.log(formattedDate);
    setSelectedDate(formattedDate);
  };

  const onChangeEnd = (date) => {
    //.log(date);
    const dates = new Date(date.$d);

    const formattedDate = `${dates.getFullYear()}-${(dates.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dates.getDate().toString().padStart(2, "0")}`;
    //.log(formattedDate);

    setEndDate(formattedDate);
  };

  // const handleDateChange = (dates, dateStrings) => {
  //   if (dates) {
  //     console.log("Start Date:", dates[0]);
  //     console.log("End Date:", dates[1]);
  //     console.log("Start Date String:", dateStrings[0]);
  //     console.log("End Date String:", dateStrings[1]);
  //     setSelectedDate(dateStrings[0]);
  //     setEndDate(dateStrings[1]);
  //   }
  // };
  const onChanges = (date, dateString) => {
    console.log("Start Date:", dateString);
    setSelectedDate(dateString);
    // Calculate end date by adding 7 days to the selected start date
    const endDate = moment(dateString, "YYYY-MM-DD")
      .add(6, "days")
      .format("YYYY-MM-DD");

    setEndDate(endDate);
    console.log("End Date:", endDate);
  };

  let service_options, service_series;
  if (reportService && Array.isArray(reportService)) {
    const report_service_labels = reportService.map((item) => item.service);
    const report_service_series = reportService.map((item) =>
      parseFloat(item.total_spend)
    );
    console.log(report_service_labels);
    console.log(report_service_series);

    service_options = {
      chart: {
        type: "donut",
        id: "spendbyservice",
      },

      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#fff",
          fontSize: "20px",
          fontFamily: "Poppins",
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false,
        // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //   const label = w.globals.labels[dataPointIndex];
        //   const value = w.globals.series[seriesIndex][dataPointIndex];
        //   return `<div>${label}: ${value.toLocaleString()}</div>`;
        // },
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              value: {
                show: false,
                fontSize: "23px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#ff6a2f",
                offsetY: -5,
                formatter: function (val) {
                  return "";
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: [
        "#9FDEF1",
        "#2A5D78",
        "#F6AA54",
        "#A7EE7C",
        "#F65454",
        "#057EC1",
        "#230B34",
        "#FE632B",
      ],
      labels: report_service_labels,

      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            xaxis: {
              labels: {
                style: {
                  colors: "#000",
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#000",
                },
              },
            },
            // dataLabels: {
            //   formatter: function (val) {
            //     // Remove the percentage symbol from the labels
            //     return val.toFixed(0);
            //   },
            // },
            tooltip: {
              enabled: true,
              theme: "light",
              y: {
                formatter: function (value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
              fillSeriesColor: true, // You can use 'light' or 'dark' theme
            },
            legend: {
              position: "bottom",
              labels: {
                colors: "#ffffff",
              },
            },
          },
        },
      ],
    };
    const legendLabels = report_service_labels.map((label, index) => {
      const formattedSeries = report_service_series[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    service_options.labels = legendLabels;
    service_series = report_service_series;

    // Now use service_options and service_series in your chart component
  } else {
    console.error("reportService is not an array:", reportService);
  }

  const options_servicelastmonth = {
    chart: {
      type: "donut",
      id: "spendbyservicelastmonth",
    },

    // noData: {
    //   text: "No Data",
    //   align: "center",
    //   verticalAlign: "middle",
    //   offsetX: 0,
    //   offsetY: 0,
    //   style: {
    //     color: "#fff",
    //     fontSize: "20px",
    //     fontFamily: "Poppins",
    //   },
    // },
    // tooltip: {
    //   enabled: true,
    //   theme: "light",
    //   y: {
    //     formatter: function (value) {
    //       return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //     },
    //   },
    //   fillSeriesColor: false, // You can use 'light' or 'dark' theme
    // },
    // plotOptions: {
    //   pie: {
    //     donut: {
    //       labels: {
    //         show: false,
    //         value: {
    //           show: false,
    //           fontSize: "23px",
    //           fontFamily: "Helvetica, Arial, sans-serif",
    //           fontWeight: 600,
    //           color: "#ff6a2f",
    //           offsetY: -5,
    //           formatter: function (val) {
    //             return "";
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
    // dataLabels: {
    //   enabled: true,
    // },
    // colors: [
    //   "#9FDEF1",
    //   "#2A5D78",
    //   "#F6AA54",
    //   "#A7EE7C",
    //   "#F65454",
    //   "#057EC1",
    //   "#230B34",
    //   "#FE632B",
    // ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // stroke: {
    //   show: false,
    // },
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200,
    //       },
    //       xaxis: {
    //         labels: {
    //           style: {
    //             colors: "#000",
    //           },
    //         },
    //       },
    //       yaxis: {
    //         labels: {
    //           style: {
    //             colors: "#000",
    //           },
    //         },
    //       },
    //       // dataLabels: {
    //       //   formatter: function (val) {
    //       //     // Remove the percentage symbol from the labels
    //       //     return val.toFixed(0);
    //       //   },
    //       // },
    //       tooltip: {
    //         enabled: true,
    //         theme: "light",
    //         y: {
    //           formatter: function (value) {
    //             return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //           },
    //         },
    //         fillSeriesColor: true, // You can use 'light' or 'dark' theme
    //       },
    //       legend: {
    //         position: "bottom",
    //         labels: {
    //           colors: "#ffffff",
    //         },
    //       },
    //     },
    //   },
    // ],
  };
  const series_lastmonth_service = [44, 55, 41, 17, 15];

  const application_lastmonth_options = {
    chart: {
      type: "donut",
      id: "spendbyservicelastmonth",
    },

    // noData: {
    //   text: "No Data",
    //   align: "center",
    //   verticalAlign: "middle",
    //   offsetX: 0,
    //   offsetY: 0,
    //   style: {
    //     color: "#fff",
    //     fontSize: "20px",
    //     fontFamily: "Poppins",
    //   },
    // },
    // tooltip: {
    //   enabled: true,
    //   theme: "light",
    //   y: {
    //     formatter: function (value) {
    //       return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //     },
    //   },
    //   fillSeriesColor: false, // You can use 'light' or 'dark' theme
    // },
    // plotOptions: {
    //   pie: {
    //     donut: {
    //       labels: {
    //         show: false,
    //         value: {
    //           show: false,
    //           fontSize: "23px",
    //           fontFamily: "Helvetica, Arial, sans-serif",
    //           fontWeight: 600,
    //           color: "#ff6a2f",
    //           offsetY: -5,
    //           formatter: function (val) {
    //             return "";
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
    // dataLabels: {
    //   enabled: true,
    // },
    // colors: [
    //   "#9FDEF1",
    //   "#2A5D78",
    //   "#F6AA54",
    //   "#A7EE7C",
    //   "#F65454",
    //   "#057EC1",
    //   "#230B34",
    //   "#FE632B",
    // ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // stroke: {
    //   show: false,
    // },
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200,
    //       },
    //       xaxis: {
    //         labels: {
    //           style: {
    //             colors: "#000",
    //           },
    //         },
    //       },
    //       yaxis: {
    //         labels: {
    //           style: {
    //             colors: "#000",
    //           },
    //         },
    //       },
    //       // dataLabels: {
    //       //   formatter: function (val) {
    //       //     // Remove the percentage symbol from the labels
    //       //     return val.toFixed(0);
    //       //   },
    //       // },
    //       tooltip: {
    //         enabled: true,
    //         theme: "light",
    //         y: {
    //           formatter: function (value) {
    //             return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //           },
    //         },
    //         fillSeriesColor: true, // You can use 'light' or 'dark' theme
    //       },
    //       legend: {
    //         position: "bottom",
    //         labels: {
    //           colors: "#ffffff",
    //         },
    //       },
    //     },
    //   },
    // ],
  };
  const application_lastmonth_series = [44, 55, 41, 17, 15];
  let application_options, application_series;
  if (applicationService && Array.isArray(applicationService)) {
    const application_labels = applicationService.map(
      (item) => item.application
    );
    const application_series_legend = applicationService.map((item) =>
      parseFloat(item.total_spend)
    );
    application_options = {
      chart: {
        id: "spendbyapp",
        type: "donut",
      },
      // dataLabels: {
      //   formatter: function (val) {
      //     return val.toFixed(0);
      //   },
      // },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#fff",
          fontSize: "20px",
          fontFamily: "Poppins",
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        fillSeriesColor: false, // You can use 'light' or 'dark' theme
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              value: {
                show: false,
                fontSize: "23px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#ff6a2f",
                offsetY: -5,
                formatter: function (val) {
                  return "";
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: [
        "#9FDEF1",
        "#2A5D78",
        "#F6AA54",
        "#A7EE7C",
        "#F65454",
        "#057EC1",
        "#230B34",
        "#FE632B",
      ],
      labels: application_labels,
      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
    const legendLabels = application_labels.map((label, index) => {
      const formattedSeries = application_series_legend[index].toLocaleString(); // Formatting number with commas
      return `${label} ($${formattedSeries})`;
    });

    // Update the 'labels' property in the chart options
    application_options.labels = legendLabels;

    application_series = application_series_legend;
  } else {
    console.error("reportService is not an array:", applicationService);
  }
  const currentDates = new Date();
  const year = currentDates.getFullYear();
  const month = currentDates.getMonth(); // Get the zero-based month index
  const firstDayOfMonth = new Date(year, month, 1);

  // Increment the month by 1 to get the correct date
  const adjustedMonth = firstDayOfMonth.getMonth() + 1;
  const stdate = `${year}-${adjustedMonth.toString().padStart(2, "0")}-01`;
  console.log(date);
  {
    console.log(stdate, "startdate");
  }

  return (
    <div>
      {/* //kamalika */}
      <Reportdata
        setReportmonth={setReportmonth}
        setReportweek={setReportweek}
        setReportweekwise={setReportweekwise}
        setReportweekwisesum={setReportweekwisesum}
        setReportweekwiseappsum={setReportweekwiseappsum}
        setReportweekwiseappsuminc={setReportweekwiseappsuminc}
        setReportweekwiseappsumdec={setReportweekwiseappsumdec}
        setReportweekwiseappsumservice={setReportweekwiseappsumservice}
        setReportweekappservice={setReportweekappservice}
        setReportweekenvironment={setReportweekenvironment}
        setReportdetailedservice={setReportdetailedservice}
        setOverall={setOverall}
        setReportApplication={setReportApplication}
        selectedDate={selectedDate}
        endDate={endDate}
        setReportService={setReportService}
        setApplicationService={setApplicationService}
      />
      {/* kamalika */}
      <div className="white-bg">
        <div class="horizontal-bar"></div>
        <div className="page1">
          <div>
            <div className="headingcenter">
              <Row className="headerrow">
                <Col>
                  <img src={CHLOGO} alt="image" />
                </Col>
              </Row>
              <Row className="subheadingrow">
                <h3 className="subheadingtab">
                  Weekly Consumption Report<br></br>
                  <span className="texts-center">
                    {" "}
                    for <span className="companyname">{companyName}</span>
                  </span>
                </h3>
              </Row>
            </div>
          </div>
        </div>
        {/* kamalika */}
        <div className="page">
          <div class="horizontal-bar"></div>
          <div className="scroll">
            <Row>
              <Col span={16}>
                {company_logo && (
                  <img className="costimize_header_logo" src={company_logo} />
                )}
              </Col>
              <Col span={8}>
                <h6 className="headerheading">
                  Overall Summary & Spend Trend as of {date}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <h5 className="boldheading">Weekly Consumption Report</h5>
                <br></br>

                <pre className="dateheading">
                  Start Date: {selectedDate}
                  <br></br>End Date: &nbsp; &nbsp;{endDate}
                </pre>
              </Col>
              <Col span={8}>
                <OverallSummaryUI spendoverall={overall} stdate={stdate} />
              </Col>
            </Row>
            <hr className="ruler"></hr>
            <Row className="chartsrow">
              <Col span={18}>
                {/* <button
                  className="yes-button"
                  onClick={() => displayDetailed()}
                >
                  Display Detailed Report
                </button> */}
              </Col>

              <Col span={6}>
                <DatePicker onChange={onChanges} className="daterangefilter" />
              </Col>
            </Row>
            <Row className="chartsrow">
              <Col span={12}>
                <h4 className="chartheading">Last Six Months Spend Trend</h4>
              </Col>
              <Col span={12}>
                <h4 className="chartheading">Last Four Weeks Spend Trend</h4>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="chartsrow">
              <Col span={12}>
                <Card className="report-cards">
                  <ThreeMonthsSpend
                    reportformonth={reportmonth}
                    stdate={stdate}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card className="report-cards">
                  <ReactApexChart
                    options={options_weeks}
                    series={series_weeks}
                    height={250}
                    type="bar"
                  />
                </Card>
              </Col>
            </Row>
            <Row className="chartsrow">
              <Col span={12}>
                <h4 className="tableheading">
                  Spend By Service in $ from{" "}
                  {formatDate(threeweekbeforeStart).toString()} -
                  {formatDate(end_Date).toString()}
                </h4>
              </Col>
              <Col span={12}>
                <h4 className="tableheading">
                  Spend By Service in $ from{" "}
                  {formatDate(lastMonthSpend).toString()} -
                  {formatDate(lastMonthendSpend).toString()}
                </h4>
              </Col>
            </Row>

            <Row className="chartsrow" gutter={[16, 16]}>
              <Col span={12}>
                <Card className="report-cards">
                  {service_options && service_series ? ( // Check if both variables are defined
                    <div id="chart">
                      <ReactApexChart
                        options={service_options}
                        series={service_series}
                        type="donut"
                        height="250"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card className="report-cards">
                  <div id="charts">
                    <ReactApexChart
                      options={options_servicelastmonth}
                      series={series_lastmonth_service}
                      type="donut"
                      height="250"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="chartsrow">
              <Col span={12}>
                <h4 className="tableheading">
                  Spend By Application in $ from{" "}
                  {formatDate(threeweekbeforeStart).toString()} -
                  {formatDate(end_Date).toString()}
                </h4>
              </Col>
              <Col span={12}>
                <h4 className="tableheading">
                  Spend By Application in $ from{" "}
                  {formatDate(lastMonthSpend).toString()} -
                  {formatDate(lastMonthendSpend).toString()}
                </h4>
              </Col>
            </Row>

            <Row className="chartsrow" gutter={[16, 16]}>
              <Col span={12}>
                <Card className="report-cards">
                  {application_options && application_series ? (
                    <div id="chart">
                      {" "}
                      <ReactApexChart
                        options={application_options}
                        series={application_series}
                        type="donut"
                        height="250"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card className="report-cards">
                  <div id="charts">
                    <ReactApexChart
                      options={application_lastmonth_options}
                      series={application_lastmonth_series}
                      type="donut"
                      height="250"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="chartsrow">
              <Col span={24}>
                <h4 className="tableheading" id="env">
                  Environment Level Weekly Spend Summary
                </h4>
              </Col>
            </Row>
            {/* <Row gutter={[16, 16]} className="chartsrow" align={"middle"}>
              <Col span={24}>
                <Card className="report-cards">
                  <ReactApexChart
                    options={options_Grouped}
                    series={series_Grouped}
                    height={250}
                    type="bar"
                  />
                </Card>
              </Col>

            </Row> */}
            <Row gutter={[16, 16]} className="chartsrow" align={"middle"}>
              <Col span={24}>
                <ProductionTable
                  envitable={reportWeekwiseSum}
                  selectedDate={selectedDate}
                  endDate={endDate}
                  display={display}
                />
              </Col>
            </Row>
          </div>
        </div>
        <hr className="ruler footerruler"></hr>
        <Row className="footerrow">
          <Col span={12}>
            <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
          </Col>
          <Col span={10}>
            <p>-1-</p>
          </Col>
          <Col span={2}>
            <p>Confidential</p>
          </Col>
        </Row>
        {/* kamalika */}
        <div className="page">
          <div class="horizontal-bar"></div>
          <div className="scroll">
            <Row>
              <Col span={18}>
                {company_logo && (
                  <img className="costimize_header_logo" src={company_logo} />
                )}
              </Col>
              <Col span={6}>
                <h5 className="boldheading">Weekly Consumption Report</h5>
              </Col>
            </Row>
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                <a href="#" className="linktag">
                  Back to Home
                </a>
              </Col>
            </Row>
            <h4 className="subtableheading">
              Application Level Weekly Spend Summary
            </h4>

            <Row className="chartsrow" gutter={[16, 16]}>
              {charts}
            </Row>

            <Row className="chartsrow">
              <Col span={12}>
                <h5 className="subtableheading">
                  Top 5 Increase in Spend By Application ($)
                </h5>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="chartsrow">
              <Col span={24}>
                <ApplicationLvelSummarySpentMoreUI
                  applicationlevelspentmore={reportweekwiseappsuminc}
                  selectedDate={selectedDate}
                  endDate={endDate}
                />
              </Col>
            </Row>

            <Row className="chartsrow">
              <Col span={12}>
                <h5 className="subtableheading">
                  Top 5 Decrease in Spend By Application ($)
                </h5>
              </Col>
              {/* <Col span={12}>
                <h5 className="subtableheading">
                  Application Level Weekly Spend Summary (Spent More) ($)
                </h5>
              </Col> */}
            </Row>
            <Row gutter={[16, 16]} className="chartsrow">
              <Col span={24}>
                {/* <ApplicationLvelSummarySpentMoreUI
                  applicationlevelspentmore={reportweekwiseappsuminc}
                /> */}
                <ApplicationLevelSummaryUI
                  applicationlevelspendless={reportweekwiseappsumdec}
                  selectedDate={selectedDate}
                  endDate={endDate}
                />
              </Col>
              {/* <Col span={12}>
                <ApplicationLvelSummarySpentMoreUI
                  applicationlevelspentmore={reportweekwiseappsuminc}
                />
              </Col> */}
            </Row>
          </div>
        </div>

        <hr className="ruler footerruler"></hr>
        <Row className="footerrow">
          <Col span={12}>
            <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
          </Col>
          <Col span={10}>
            <p>-2-</p>
          </Col>
          <Col span={2}>
            <p>Confidential</p>
          </Col>
        </Row>
        {/* kamalika */}
        <div className="page">
          <div class="horizontal-bar"></div>
          <div className="scroll">
            <Row>
              <Col span={18}>
                <img src={Companylogo} alt="image" />
              </Col>
              <Col span={6}>
                <h5 className="boldheading">Weekly Consumption Report</h5>
              </Col>
            </Row>
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                <a href="#" className="linktag">
                  Back to Home
                </a>
              </Col>
            </Row>
            <h4 className="subtableheading">
              Application Level Weekly Spend Summary
            </h4>

            <Row className="chartsrow">
              <Col span={24}>
                <div id="app">
                  {" "}
                  <Appdata
                    applicationweekly={reportappwise}
                    selectedDate={selectedDate}
                    endDate={endDate}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <hr className="ruler footerruler"></hr>
        <Row className="footerrow">
          <Col span={12}>
            <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
          </Col>
          <Col span={10}>
            <p>-3-</p>
          </Col>
          <Col span={2}>
            <p>Confidential</p>
          </Col>
        </Row>
        {/* kamalika */}
        <div className="page">
          <div class="horizontal-bar"></div>
          <div className="scroll">
            <Row>
              <Col span={18}>
                {company_logo && (
                  <img className="costimize_header_logo" src={company_logo} />
                )}
              </Col>
              <Col span={6}>
                <h5 className="boldheading">Weekly Consumption Report</h5>
              </Col>
            </Row>
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                <a href="#" className="linktag">
                  Back to Home
                </a>
              </Col>
            </Row>
            <h4 className="subtableheading">
              {" "}
              Service Level Weekly Spend Summary
            </h4>
            <Row className="chartsrow" id="service">
              <Col span={24}>
                <ServiceSummary
                  servicelevelWeekly={reportweekwiseappsumservice}
                  selectedDate={selectedDate}
                  endDate={endDate}
                />
              </Col>
            </Row>
            {/* <Row className="chartsrow" id="service">
              <Col span={24}>
                <ServiceLevelWeeklySpendUI
                  servicelevelweekly={reportDetailedService}
                  selectedDate={selectedDate}
                  endDate={endDate}
                />
              </Col>
            </Row> */}
          </div>

          <hr className="ruler footerruler"></hr>
          <Row className="footerrow">
            <Col span={12}>
              <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
            </Col>
            <Col span={10}>
              <p>-5-</p>
            </Col>
            <Col span={2}>
              <p>Confidential</p>
            </Col>
          </Row>
          <Row className="chartsrow">
            <Col span={12}>
              <Button className="yes-button" onClick={() => displayDetailed()}>
                Display Detailed Report
              </Button>
            </Col>
            <Col span={12} className="d-flex justify-content-end">
              <Button
                className="yes-btn "
                onClick={() => fetchDataAndPassURIs()}
              >
                {" "}
                Download PDF
              </Button>
            </Col>
          </Row>
        </div>
        {/* kamalika */}
        {/* //Environment detailed Report */}
        {display ? (
          <div className="page">
            <div class="horizontal-bar"></div>
            <div className="scroll" id="envi">
              <Row>
                <Col span={18}>
                  {company_logo && (
                    <img className="costimize_header_logo" src={company_logo} />
                  )}
                </Col>
                <Col span={6}>
                  <h5 className="boldheading">Weekly Consumption Report</h5>
                </Col>
              </Row>
              <Row>
                <Col span={20}></Col>
                <Col span={4}>
                  <a href="#" className="linktag">
                    Back to Home
                  </a>
                </Col>
              </Row>
              <h4 className="subtableheading">
                {" "}
                Detailed view of VM along with Environment and Spend:
              </h4>
              <br />

              <Row className="chartrow">
                <Col span={24}>
                  <EnvironmentDetailedSummary
                    selectedDate={selectedDate}
                    endDate={endDate}
                  />
                </Col>
              </Row>
            </div>
            <hr className="ruler footerruler"></hr>
            <Row className="footerrow">
              <Col span={12}>
                <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
              </Col>
              <Col span={10}>
                <p>-6-</p>
              </Col>
              <Col span={2}>
                <p>Confidential</p>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        {/* kamalika */}
        {display ? (
          <div className="page">
            <div class="horizontal-bar"></div>
            <div className="scroll" id="appdetail">
              <Row>
                <Col span={18}>
                  {company_logo && (
                    <img className="costimize_header_logo" src={company_logo} />
                  )}
                </Col>
                <Col span={6}>
                  <h5 className="boldheading">Weekly Consumption Report</h5>
                </Col>
              </Row>
              <Row>
                <Col span={20}></Col>
                <Col span={4}>
                  <a href="#" className="linktag">
                    Back to Home
                  </a>
                </Col>
              </Row>
              <h4 className="subtableheading">
                {" "}
                Detailed view of VM along with Application and Spend: <br></br>
              </h4>
              <Row className="chartrow">
                <Col span={24}>
                  <ApplicationLevelDeatiledView
                    applicationweekly={reportappwise}
                    selectedDate={selectedDate}
                    endDate={endDate}
                  />
                </Col>
              </Row>
            </div>
            <hr className="ruler footerruler"></hr>
            <Row className="footerrow">
              <Col span={12}>
                <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
              </Col>
              <Col span={10}>
                <p>-7-</p>
              </Col>
              <Col span={2}>
                <p>Confidential</p>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        {/* kamalika */}
        {display ? (
          <div className="page">
            <div class="horizontal-bar"></div>
            <div className="scroll" id="services">
              <Row>
                <Col span={18}>
                  {company_logo && (
                    <img className="costimize_header_logo" src={company_logo} />
                  )}
                </Col>
                <Col span={6}>
                  <h5 className="boldheading">Weekly Consumption Report</h5>
                </Col>
              </Row>
              <Row>
                <Col span={20}></Col>
                <Col span={4}>
                  <a href="#" className="linktag">
                    Back to Home
                  </a>
                </Col>
              </Row>
              <h4 className="subtableheading">
                {" "}
                Detailed view of along with Service and Spend <br></br>
              </h4>
              <Row className="chartrow">
                <Col span={24}>
                  {/* <ServiceLevelDetaileView
                  servicelevelweekly={reportweekwiseappsumservice}
                /> */}
                  <ServiceLevelDetaileView
                    servicelevelweekly={reportDetailedService}
                    selectedDate={selectedDate}
                    endDate={endDate}
                  />
                </Col>
              </Row>

              {/* //New page */}
            </div>
            <hr className="ruler footerruler"></hr>
            <Row className="footerrow">
              <Col span={12}>
                <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
              </Col>
              <Col span={10}>
                <p>-8-</p>
              </Col>
              <Col span={2}>
                <p>Confidential</p>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        {/* kamalika */}
        <div className="d-flex justify-content-end p-3 mb-5"></div>
        {chartImageURI ? (
          <Rep
            imgURI={chartImageURI}
            imgURIWeek={chartImageweek}
            // imgURIGROUPED={groupedbarImage}
            tableENVIRONMENT={reportWeekwiseSum}
            // imgURIWeeklyTopapp={weeklyspendTopapp}
            imgSpend={serviceSpend}
            imgApplication={applicationSpends}
            tableDATA={reportweekwiseappsumservice}
            tableWeekDec={reportweekwiseappsumdec}
            tableweekINC={reportweekwiseappsuminc}
            applicationData={reportweekwiseappsum}
            applicationweeklydetailed={reportappwise}
            environmentweeklydetailed={reportweekenvironment}
            serviceDetailed={reportDetailedService}
            overallsummary={overall}
            appSummary={appSummary}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Report;
