import { Icon } from "@iconify/react";
import { Col, Form, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ViewCRForm() {
  const devopsStore = useSelector((state) => state.devops);
  const navigate = useNavigate();
  const [crPreviewData, setCRPreviewData] = useState([]);
  useEffect(() => {
    console.log(devopsStore.requestorTableData);
    let arr = [];
    arr.push(devopsStore.requestorTableData);
    setCRPreviewData(arr);
  }, []);
  const removeSpaces = (value) => {
    let str = value.replace(/_/g, " ");
    str = str.charAt(0).toUpperCase() + str.slice(1);
    if (str === "Cx approved by") {
      str = "Admin approved by";
    } else if (str === "Cx comments") {
      str = "Admin comments";
    }
    return str;
  };
  return (
    <div>
      {" "}
      <Form>
        <Row>
          <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
          <Col
            xxl={20}
            xl={20}
            lg={20}
            md={20}
            sm={20}
            xs={20}
            className="mt-5"
          >
            <div className="">
              <div className="arrow-back  d-flex align-items-center">
                <Icon
                  icon="bi:arrow-left"
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={() => navigate("/devops-requestor")}
                />{" "}
                &nbsp; &nbsp;{" "}
                <h4 className="header-title-devops">CR Preview</h4>
              </div>
            </div>
            <br />
            <div className="">
              <div className="basic-details-cr-preview">
                <Row
                  gutter={[16, 16]}
                  className="d-flex justify-content-center"
                >
                  <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                    {crPreviewData.map((items) => {
                      return Object.entries(items).map((item) => {
                        return (
                          <>
                            {item[0] === "Admin password" ? (
                              <>
                                <Row
                                  gutter={[16, 16]}
                                  className="devops-preview-rows"
                                >
                                  <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                    <span className="sub-heading-devops-config">
                                      {removeSpaces(item[0])}
                                    </span>
                                  </Col>
                                  <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                    <span className="sub-heading-devops-config">
                                      {" "}
                                      :
                                    </span>
                                  </Col>
                                  <Col xxl={16} xl={16} lg={16} md={16} sm={24}>
                                    <span className="sub-heading-devops-config">
                                      * * * * * * * * * *
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            ) : item[0] == "created_time" ? (
                              <>
                                <Row
                                  gutter={[16, 16]}
                                  className="devops-preview-rows"
                                >
                                  <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                    <span className="sub-heading-devops-config">
                                      {removeSpaces(item[0])}
                                    </span>
                                  </Col>
                                  <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                    <span className="sub-heading-devops-config">
                                      {" "}
                                      :
                                    </span>
                                  </Col>
                                  <Col xxl={16} xl={16} lg={16} md={16} sm={24}>
                                    <span className="sub-heading-devops-config">
                                      {moment(item[1]).format(
                                        "DD-MMM-YYYY, hh:mm:ss A"
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            ) : item[0] == "__typename" ||
                              item[0] == "rule_name" ||
                              item[0] == "build_number" ||
                              item[0] == "approved_time" ||
                              item[0] == "event_type" ||
                              item[0] == "is_admin_approved" ||
                              item[0] == "is_och_approved" ||
                              item[0] == "job_name" ? (
                              <></>
                            ) : item[0] == "configurations" ? (
                              Object.entries(
                                JSON.parse(JSON.parse(item[1]))
                              ).map((itm) => {
                                return itm[0] === "id" ||
                                  itm[0] == "event_type" ||
                                  itm[0] == "status" ||
                                  itm[0] == "created_by" ? (
                                  <></>
                                ) : itm[0] === "admin_password" ? (
                                  <>
                                    <Row
                                      gutter={[16, 16]}
                                      className="devops-preview-rows"
                                    >
                                      <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                        <span className="sub-heading-devops-config">
                                          {removeSpaces(itm[0])}
                                        </span>
                                      </Col>
                                      <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                        <span className="sub-heading-devops-config">
                                          :
                                        </span>
                                      </Col>
                                      <Col
                                        xxl={16}
                                        xl={16}
                                        lg={16}
                                        md={16}
                                        sm={24}
                                      >
                                        <span className="sub-heading-devops-config">
                                          {" "}
                                          * * * * * * * * * * *
                                        </span>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <Row
                                      gutter={[16, 16]}
                                      className="devops-preview-rows"
                                    >
                                      <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                        <span className="sub-heading-devops-config">
                                          {removeSpaces(itm[0])}
                                        </span>
                                      </Col>
                                      <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                        <span className="sub-heading-devops-config">
                                          {" "}
                                          :
                                        </span>
                                      </Col>
                                      <Col
                                        xxl={16}
                                        xl={16}
                                        lg={16}
                                        md={16}
                                        sm={24}
                                        style={{ wordWrap: "break-word" }}
                                      >
                                        <span className="sub-heading-devops-config">
                                          {itm[1]}
                                        </span>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                {" "}
                                <Row
                                  gutter={[16, 16]}
                                  className="devops-preview-rows"
                                >
                                  <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                    <span className="sub-heading-devops-config">
                                      {removeSpaces(item[0])}
                                    </span>
                                  </Col>
                                  <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                    <span className="sub-heading-devops-config">
                                      :
                                    </span>
                                  </Col>
                                  <Col xxl={16} xl={16} lg={16} md={16} sm={24}>
                                    <span className="sub-heading-devops-config">
                                      {item[1]}
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        );
                      });
                    })}
                  </Col>
                </Row>
              </div>
              <br />
            </div>
          </Col>
          <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
        </Row>
      </Form>
    </div>
  );
}

export default ViewCRForm;
