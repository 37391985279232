import gql from "graphql-tag";

const GET_ASSESS_VM_COUNT = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    assess_VM_resource_count(
      application: $application
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      resource_count
      core
      memory
      storage_as
    }
  }
`;

const GET_SLM_TABLE = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_ticket_table(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      application
      ticket_owner
      ticket_closed_by
      created_time
      closed_time
      category
      sla
      subject
      status
      avg_resolution_time
      first_response_time
      resolution_time
      number_of_reopen
    }
  }
`;
const GET_TOTAL_RESOURCE_TO_BE_MIGRATED = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_total_resource_to_be(
      application: $application
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      application
      resource_count
    }
  }
`;
const GET_TOTAL_RESOURCES_YET_TO_BE_MIGRATED = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_total_resource_yet_to_be(
      application: $application
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      application
      resource_count
    }
  }
`;
const GET_TOTAL_RESOURCE_SPREAD = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_total_resource_under(
      application: $application
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      application
      resource_count
    }
  }
`;
const GET_TOTAL_RESOURCES_MIGRATED = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_total_resource_migrated(
      application: $application
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      application
      resource_count
    }
  }
`;

const ASSESS_OS_SPREAD = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_os_spread(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      operating_system
      os_count
    }
  }
`;
const ASSESS_DB_SPREAD = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_db_spread(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      database
      resource_count
    }
  }
`;
const ASSESS_WAF = gql`
  query MyQuery(
    $application: String!
    $account_name: String!
    $environment: String!
    $company: String!
    $organiation_unit: String!
  ) {
    operate_waf_spread(
      application: $application
      account_name: $account_name
      environment: $environment
      company: $company
      organiation_unit: $organiation_unit
    ) {
      cost_optimization
      operational_excellence
      performance_efficiency
      reliability
      security_level
    }
  }
`;
const ASSSES_ADVIISORY_SCORE = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $environment: String!
  ) {
    assess_waf_methodology(
      application: $application
      company: $company
      data_center_name: $data_center_name
      environment: $environement
    ) {
      cost_optimization
      operational_excellence
      performance_efficiency
      reliability
      security_level
    }
  }
`;

const ASSESS_DC_SPREAD = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_dc_spread(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      data_centre
      resource_count
    }
  }
`;
const ASSESS_CORERAM = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_coreram(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      cpu
      memory
      resource_name
    }
  }
`;
const ASSESS_STORAGE = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_storage(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      resource_name
      storage
    }
  }
`;
const ASSESS_DR = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_dr_implemetation(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      dr_implementation
      resource_count
    }
  }
`;
const ASSESS_MONITOR = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_monitoring_implementation(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      monitoring_status
      application_count
    }
  }
`;

const ASSESS_STORAGE_UTIL = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_storage_util(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      application
      core
    }
  }
`;
const ASSESS_RAM_UTIL = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_Ram_util(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      application
      memory
    }
  }
`;
const TCO_ANALYSIS = gql`
  query MyQuery($company: String!) {
    assess_tco_analysis(company: $company) {
      cost_completion
      onpremises
      on_demand
      ri_1year
      ri_3year
    }
  }
`;

const ASSESS_PHASE_WISE = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_phase_wise(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      phase_level
      resource_count
    }
  }
`;

const ASSESS_PLANNED_MIGRATION = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    migrate_resource_by_planned(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      date
      resource_count
    }
  }
`;
const ASSESS_TO_BE_TABLE = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_to_be_vm_table(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      memory
      resource_name
      vcpu
      vm_type
    }
  }
`;
const ASSESS_TO_BE_CORE = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_to_be_core(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      to_be_core
      application
    }
  }
`;
const ASSESS_TO_BE_MEMORY = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_to_be_memory(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      total_mem
      application
    }
  }
`;
const ASSESS_CLOUD_READINESS = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_cloud_mordenization(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      modernization_type
      resource_count
    }
  }
`;
const ASSESS_MIGRATIONMETHOD = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_migration_methodology(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      migration_methodology
      resource_count
    }
  }
`;
const ASSESS_MIGRATIONCOMP = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_migration_complexity(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      complexity_level
      application_count
    }
  }
`;
const ASSESS_IS = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assessvm_appwise(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      application
      data_center_name
      vm_count
    }
  }
`;
const ASSESS_CORE = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
  ) {
    assess_to_be_vm_appwise(
      application: $application
      company: $company
      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
    ) {
      application
      data_center_name
      vm_count
      region
    }
  }
`;

const GET_TARGET_AND_TOTAL_RESOURCE_COUNT = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_resource_completion_percentage(
      application: $application

      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      target_resorce_count
      total_resource
    }
  }
`;
const GET_TOTAL_CLEANUP_COUNT = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_resource_cleaned_up_percentage(
      application: $application

      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      target_resorce_count
      total_resource
    }
  }
`;
const MIGRATION_COMPLETION_PROCESS = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_resource_completion_progress(
      application: $application

      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      application
      resource_count_completed
      resource_yet_to_migrated
    }
  }
`;

const GET_PLANNED_MIGRATION_BY_DATE = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_resource_by_planned(
      application: $application

      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      date
      resource_count
    }
  }
`;

const GET_ACTUAL_MIGRATION_BY_DATE = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_resource_by_actual(
      application: $application

      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      date
      resource_count
    }
  }
`;

const GET_RESOURCE_STATUS = gql`
  query MyQuery(
    $application: String!
    $data_center_name: String!
    $db: String!
    $environment: String!
    $os: String!
    $company: String!
  ) {
    migrate_resource_by_status(
      application: $application

      data_center_name: $data_center_name
      db: $db
      environment: $environment
      os: $os
      company: $company
    ) {
      migration_status
      resource_count
    }
  }
`;

const TICKET_SEVERITY = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_ticket_severity_count(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      severity
      severity_count
    }
  }
`;

const AVERAGE_RESOLUTION = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_resolution_time(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      month_year
      avg_resolution
      difference_from_prev_month
    }
  }
`;

const AVERAGE_FIRST_RESPONSE = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_response_time(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      month_year
      avg_response
      difference_from_prev_month
    }
  }
`;

const INCIDENET = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_tile_1(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      month_year
      service_request
      service_diff
      incident
      incident_diff
      change_request
      change_diff
    }
  }
`;

const GET_SLM_APPLICATION = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_application_count(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      application
      app_count
    }
  }
`;
const GET_APPLICATION_COUNT = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_ticket_status_count(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      status
      ticket_count
    }
  }
`;

const GET_CATEGORY_COUNT = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_category_count(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      month_year
      ticket_count
      category
    }
  }
`;

const GET_SLA_GRAPH = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $category: String!
    $company: String!
    $organiation_unit: String!
    $sla: String!
    $ticker_owner: String!
    $ticket_closed_by: String!
    $start_date: String!
    $end_date: String!
  ) {
    slm_sla_count(
      account_name: $account_name
      application: $application
      category: $category
      company: $company
      organiation_unit: $organiation_unit
      sla: $sla
      ticker_owner: $ticker_owner
      ticket_closed_by: $ticket_closed_by
      start_date: $start_date
      end_date: $end_date
    ) {
      application
      not_violated
      resolution_time
      response_and_resolution_violation
      response_violation
    }
  }
`;
const GET_ASSESS_FILTER = gql`
  query MyQuery($company: String!) {
    assess_filter(company: $company) {
      application

      data_center_name

      database_name

      environment

      operating_system
    }
  }
`;

const GET_OPERATE_FILTER = gql`
  query MyQuery($company: String!) {
    operate_filter_c(company: $company) {
      application
      account_name
      environment
      organization_unit
    }
  }
`;

const GET_OPERATE_VM_DETAILS = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_vm_details(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      vm_count
      disk_count
      total_cpu
      total_memory
      total_storage
      paas
    }
  }
`;

const GET_OPERATE_OU_COUNT = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_ou_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      ou
      resource_count
    }
  }
`;

const GET_OPERATE_SERVICE_COUNT = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_service_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      service
      resource_count
    }
  }
`;

const GET_OPERATE_OS_COUNT = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_os_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      os
      os_count
    }
  }
`;

const GET_OPERATE_STORAGE_UTIL = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_storage_util(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      ou
      storage
    }
  }
`;

const GET_OPERATE_RAM_UTIL = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_ram_util(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      application
      mem_util
    }
  }
`;

const GET_OPERATE_WAF = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_waf_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      cost_optimization
      operational_excellence
      performance_efficiency
      reliability
      security_level
    }
  }
`;

const GET_OPERATE_TCO = gql`
  query MyQuery(
    $application: String!
    $organization_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_tco_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organization_unit: $organization_unit
    ) {
      ou
      sumofcost
    }
  }
`;

const GET_OPERATE_CMA = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_cma_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      database
      db_count
    }
  }
`;

const GET_OPERATE_MONITORING = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_monitoring_implementation(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      monitoring_status
      application_count
    }
  }
`;
const GET_OPERATE_DR_SPREAD = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_dr_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      dr
      app_count
    }
  }
`;

const GET_SLM_FILTER = gql`
  query MyQuery($company: String!) {
    slm_filter_c(company: $company) {
      account_name
      application
      category
      organization_unit
      sla
      ticket_closed_by
      ticket_owner
    }
  }
`;

const GET_OPERATE_DB_SPREAD = gql`
  query MyQuery(
    $application: String!
    $organiation_unit: String!
    $environment: String!
    $account_name: String!
    $company: String!
  ) {
    operate_db_spread(
      company: $company
      account_name: $account_name
      application: $application
      environment: $environment
      organiation_unit: $organiation_unit
    ) {
      database
      db_count
    }
  }
`;
const GET_APP_MODERNIZATION_IS = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $environment: String!
    $db: String!
    $os: String!
  ) {
    assess_vm_k8s_appwise(
      application: $application
      company: $company
      data_center_name: $data_center_name
      environment: $environment
      db: $db
      os: $os
    ) {
      infra
      application
      vm_count
    }
  }
`;

const GET_APP_MODERNIZATION_TO_BE = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $data_center_name: String!
    $environment: String!
    $db: String!
    $os: String!
  ) {
    assess_to_be_vm_k8s_appwise(
      application: $application
      company: $company
      data_center_name: $data_center_name
      environment: $environment
      db: $db
      os: $os
    ) {
      infra
      application
      vm_count
    }
  }
`;
const GET_KUBERNETES_SPREAD = gql`
  query MyQuery(
    $application: String!
    $company: String!
    $environment: String!
    $organization_unit: String!
    $account_name: String!
  ) {
    operate_k8s_spread(
      application: $application
      company: $company
      environment: $environment
      organization_unit: $organization_unit
      account_name: $account_name
    ) {
      containers
      deployments
      forgate
      namespaces
      nodes
      pods
      resource_count
      services
    }
  }
`;
const GET_ECS = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $company: String!
    $environment: String!
    $organization_unit: String!
  ) {
    operate_ecs_spread_count(
      account_name: $account_name
      application: $application
      company: $company
      environment: $environment
      organization_unit: $organization_unit
    ) {
      container_count
      resource_count
    }
  }
`;

const TOTAL_USAGE_COUNT_KUBERNETES = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $company: String!
    $organiation_unit: String!
  ) {
    slm_k8s_total_usage_count(
      account_name: $account_name
      application: $application
      company: $company
      organiation_unit: $organiation_unit
    ) {
      cpu_used
      memory_used
      total_cpu
      total_filesystem
      total_memory
      filesystem_used
    }
  }
`;

const MEMORY_CPU_COUNT = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $company: String!
    $organiation_unit: String!
    $startdate: String!
  ) {
    slm_k8s_memory_cpu_metrics(
      account_name: $account_name
      application: $application
      company: $company
      organiation_unit: $organiation_unit
      startdate: $startdate
    ) {
      cpu_metrics
      memory_metrics
      transaction_date
    }
  }
`;

const ECS_MEMORY_CPU_COUNT = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $company: String!
    $organiation_unit: String!
    $startdate: String!
  ) {
    slm_ecs_memory_cpu_metrics(
      account_name: $account_name
      application: $application
      company: $company
      organiation_unit: $organiation_unit
      startdate: $startdate
    ) {
      ecs_cpu_metrics
      ecs_memory_metrics
      transaction_date
    }
  }
`;

const ECS_COUNT_TOTAL = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $company: String!
    $organiation_unit: String!
  ) {
    slm_ecs_total_usage_count(
      account_name: $account_name
      application: $application
      company: $company
      organiation_unit: $organiation_unit
    ) {
      cpu_units_used
      memory_ecs_used
      total_cpu_units
      total_memory_ecs
    }
  }
`;

const RIGHTSIZINGQUERY = gql`
  query MyQuery($company: String!) {
    cost_analysis_right_sizing_data(company: $company) {
      environment
      application
      resource_name
      as_is_core
      to_be_core
      as_is_ram
      to_be_ram
      as_is_storage
      to_be_storage
      instance_type
      storage_type
      egress
      region
    }
  }
`;
const CORE_COMPARISON = gql`
  query MyQuery($company: String!) {
    cost_analysis_as_is_to_be_core(company: $company) {
      total_as_is_core
      total_to_be_core
    }
  }
`;
const RAM_COMPARISON = gql`
  query MyQuery($company: String!) {
    cost_analysis_as_is_to_be_ram(company: $company) {
      total_as_is_ram
      total_to_be_ram
    }
  }
`;
const STORAGE_COMPARISON = gql`
  query MyQuery($company: String!) {
    cost_analysis_as_is_to_be_storage(company: $company) {
      total_as_is_storage
      total_to_be_storage
    }
  }
`;
const TCO_TABLE_COSTANALYSIS = gql`
  query MyQuery($company: String!, $os_license: String!) {
    cost_analysis_tco_table(company: $company, os_license: $os_license) {
      application
      environment
      on_demand
      ri_1yr
      ri_3yr
    }
  }
`;
const TO_BE_CORE_CPU = gql`
  query MyQuery($company: String!) {
    cost_analysis_application_wise_cpu(company: $company) {
      application
      total_to_be_cpu
    }
  }
`;

const TO_BE_CORE_RAM = gql`
  query MyQuery($company: String!) {
    cost_analysis_application_wise_ram(company: $company) {
      application
      total_to_be_ram
    }
  }
`;
const TCO_TABLE_SUMMARY = gql`
  query MyQuery($company: String!, $os_license: String) {
    cost_analysis_tco_summary(company: $company, os_license: $os_license) {
      aws_pricing_plan
      estimated_cost
    }
  }
`;
const STORAGE_UPDATE = gql`
  mutation MyMutation(
    $company: String!
    $new_storage_type: String!
    $region_curr: String!
    $resource_name_curr: String!
  ) {
    cost_analysis_update_storage(
      company: $company
      new_storage_type: $new_storage_type
      region_curr: $region_curr
      resource_name_curr: $resource_name_curr
    ) {
      resource_name
      storage_type
    }
  }
`;
const UPDATE_VM = gql`
  mutation MyMutation(
    $company: String!
    $curr_vm_type: String!
    $new_vm_type: String!
    $region_curr: String!
  ) {
    cost_analysis_update_vm(
      company: $company
      curr_vm_type: $curr_vm_type
      new_vm_type: $new_vm_type
      region_curr: $region_curr
    ) {
      resource_name
      vm_type
      to_be_cpu
      to_be_ram
    }
  }
`;
const VM_DROPDOWN = gql`
  query MyQuery($company: String, $region: String) {
    cost_analysis_select_vm(company: $company, region: $region) {
      vm_type
      cpu
      ram
    }
  }
`;
const STORAGE_DROPDOWN = gql`
  query MyQuery($company: String!, $region: String!) {
    cost_analysis_select_disk(company: $company, region: $region) {
      storage_type
    }
  }
`;
const EGRESS_DROPDOWN = gql`
  mutation MyMutation(
    $company: String!
    $egress_new: String!
    $resource_name_curr: String!
  ) {
    cost_analysis_update_egress(
      company: $company
      egress_new: $egress_new
      resource_name_curr: $resource_name_curr
    ) {
      resource_name
      egress
    }
  }
`;

const WAR_DASHBOARD = gql`
  query MyQuery(
    $workload_name: String
    $review_type: String
    $environment: String
    $description: String
    $customer: String
    $created_date: String
    $account_id: String
  ) {
    war_workload(
      workload_name: $workload_name
      review_type: $review_type
      environment: $environment
      description: $description
      customer: $customer
      created_date: $created_date
      account_id: $account_id
    ) {
      workload_name
      review_type
      environment
      description
      customer
      created_date
      account_id
    }
  }
`;

// const WAR_QUESTIONS= gql`
//   query MyQuery(
//     $workload_name: String
//     $review_type: String
//     $environment: String
//     $description: String
//     $customer: String
//     $created_date: String
//     $account_id: String
//   ) {
//     war_workload(
//       workload_name: $workload_name
//       review_type: $review_type
//       environment: $environment
//       description: $description
//       customer: $customer
//       created_date: $created_date
//       account_id: $account_id
//     ) {
//     workload_name
//     review_type
//     environment
//     description
//     customer
//     created_date
//     account_id
//     }
//   }
// `;

const WAR_ASSESSMENT_OPTIONS = gql`
  query MyQuery(
    $workload_name: String
    $updated_date: String
    $question: String
    $piller_type: String
    $customer: String
    $created_date: String
    $answer_options: String
  ) {
    war_assessment(
      workload_name: $workload_name
      updated_date: $updated_date
      question: $question
      piller_type: $piller_type
      customer: $customer
      created_date: $created_date
      answer_options: $answer_options
    ) {
      workload_name
      updated_date
      question
      piller_type
      customer
      created_date
      answer_options
      id
    }
  }
`;

const WAR_DASHBOARD_LIST = gql`
  query MyQuery($customer: String) {
    get_war_workload(customer: $customer) {
      account_id
      created_date
      description
      customer
      environment
      review_type
      workload_name
    }
  }
`;

const GET_WAR_ASSESMENT_LIST = gql`
  query MyQuery($customer: String) {
    get_war_assessment(customer: $customer) {
      answer_options
      created_date
      customer
      piller_type
      question
      updated_date
      workload_name
      id
    }
  }
`;
const WAR_ASSESMENT_UPDATE = gql`
  query MyQuery(
    $answer_options: String
    $id: String
    $customer: String
    $piller_type: String
    $question: String
    $updated_date: String
    $workload_name: String
  ) {
    update_war_assessment(
      answer_options: $answer_options
      id: $id
      customer: $customer
      piller_type: $piller_type
      question: $question
      updated_date: $updated_date
      workload_name: $workload_name
    ) {
      answer_options
      customer
      id
      piller_type
      question
      updated_date
      workload_name
    }
  }
`;
const ASSESSMENT_TABLE = gql`
  mutation MyMutation(
    $application: String!
    $cpu_as_is: String!
    $customer: String!
    $data_center_name: String!
    $database_type: String!
    $dr_implementation: String!
    $environment: String!
    $memory_as_is: String!
    $migration_complexity: String!
    $migration_methodology: String!
    $modernization_type: String!
    $monitoring_stratergy: String!
    $operating_system: String!
    $region: String!
    $resource_name: String!
    $server_role: String!
    $storage_as_is: String!
  ) {
    insert_assess_insert_data(
      application: $application
      cpu_as_is: $cpu_as_is
      customer: $customer
      data_center_name: $data_center_name
      database_type: $database_type
      dr_implementation: $dr_implementation
      environment: $environment
      memory_as_is: $memory_as_is
      migration_complexity: $migration_complexity
      migration_methodology: $migration_methodology
      modernization_type: $modernization_type
      monitoring_stratergy: $monitoring_stratergy
      operating_system: $operating_system
      region: $region
      resource_name: $resource_name
      server_role: $server_role
      storage_as_is: $storage_as_is
    ) {
      application
      cpu_as_is
      customer
      data_center_name
      database_type
      dr_implementation
      environment
      id
      memory_as_is
      migration_complexity
      migration_methodology
      modernization_type
      monitoring_stratergy
      operating_system
      region
      resource_name
      server_role
      storage_as_is
    }
  }
`;
const ASSESSMENT_TABLE_LIST = gql`
  query MyQuery($company: String!) {
    assess_get_data(company: $company) {
      application
      cpu_as_is
      customer
      data_center_name
      database_type
      dr_implementation
      environment
      id
      memory_as_is
      migration_complexity
      migration_methodology
      modernization_type
      monitoring_stratergy
      operating_system
      region
      resource_name
      server_role
      storage_as_is
    }
  }
`;

const ASSESSMENT_TABLE_UPDATE = gql`
  mutation MyMutation(
    $application: String!
    $cpu_as_is: String!
    $customer: String!
    $data_center_name: String!
    $database_type: String!
    $dr_implementation: String!
    $environment: String!
    $memory_as_is: String!
    $migration_complexity: String!
    $migration_methodology: String!
    $modernization_type: String!
    $monitoring_stratergy: String!
    $operating_system: String!
    $region: String!
    $resource_name: String!
    $server_role: String!
    $storage_as_is: String!
    $id: String!
  ) {
    insert_assess_update_data(
      application: $application
      cpu_as_is: $cpu_as_is
      customer: $customer
      data_center_name: $data_center_name
      database_type: $database_type
      dr_implementation: $dr_implementation
      environment: $environment
      memory_as_is: $memory_as_is
      migration_complexity: $migration_complexity
      migration_methodology: $migration_methodology
      modernization_type: $modernization_type
      monitoring_stratergy: $monitoring_stratergy
      operating_system: $operating_system
      region: $region
      resource_name: $resource_name
      server_role: $server_role
      storage_as_is: $storage_as_is
      id: $id
    ) {
      application
      cpu_as_is
      customer
      data_center_name
      database_type
      environment
      id
      memory_as_is
      migration_complexity
      migration_methodology
      modernization_type
      monitoring_stratergy
      operating_system
      region
      resource_name
      server_role
      storage_as_is
    }
  }
`;

const ASSESSMENT_TABLE_DELETE = gql`
  mutation MyMutation($customer: String!, $id: String!) {
    insert_assess_delete_data(customer: $customer, id: $id) {
      application
      cpu_as_is
      customer
      data_center_name
      database_type
      dr_implementation
      environment
      id
      memory_as_is
      migration_complexity
      migration_methodology
      modernization_type
      monitoring_stratergy
      operating_system
      region
      resource_name
      server_role
      storage_as_is
    }
  }
`;

const ASSESSMENT_TABLE_UPLOAD = gql`
  query MyQuery($file: String!) {
    uploadFile(file: $file) {
      bucket
      content
      contentType
      key
      region
      customer
      event
    }
  }
`;

export {
  GET_ASSESS_VM_COUNT,
  GET_SLM_TABLE,
  TICKET_SEVERITY,
  AVERAGE_RESOLUTION,
  AVERAGE_FIRST_RESPONSE,
  INCIDENET,
  GET_SLM_APPLICATION,
  GET_APPLICATION_COUNT,
  GET_CATEGORY_COUNT,
  GET_SLA_GRAPH,
  ASSESS_OS_SPREAD,
  ASSESS_DB_SPREAD,
  ASSESS_DC_SPREAD,
  ASSESS_CORERAM,
  ASSESS_STORAGE,
  ASSESS_DR,
  ASSESS_MONITOR,
  ASSESS_STORAGE_UTIL,
  ASSESS_RAM_UTIL,
  TCO_ANALYSIS,
  ASSESS_PHASE_WISE,
  ASSESS_PLANNED_MIGRATION,
  ASSESS_TO_BE_TABLE,
  ASSESS_TO_BE_CORE,
  ASSESS_TO_BE_MEMORY,
  ASSESS_CLOUD_READINESS,
  ASSESS_MIGRATIONMETHOD,
  ASSESS_MIGRATIONCOMP,
  ASSESS_IS,
  ASSESS_CORE,
  ASSESS_WAF,
  GET_OPERATE_VM_DETAILS,
  GET_OPERATE_RAM_UTIL,
  GET_OPERATE_STORAGE_UTIL,
  GET_OPERATE_CMA,
  GET_OPERATE_DR_SPREAD,
  GET_OPERATE_DB_SPREAD,
  GET_OPERATE_MONITORING,
  GET_OPERATE_TCO,
  GET_OPERATE_WAF,
  GET_OPERATE_OS_COUNT,
  GET_OPERATE_SERVICE_COUNT,
  GET_OPERATE_OU_COUNT,
  GET_TOTAL_RESOURCE_TO_BE_MIGRATED,
  GET_TOTAL_RESOURCES_YET_TO_BE_MIGRATED,
  GET_TOTAL_RESOURCE_SPREAD,
  GET_TOTAL_RESOURCES_MIGRATED,
  GET_TARGET_AND_TOTAL_RESOURCE_COUNT,
  GET_TOTAL_CLEANUP_COUNT,
  MIGRATION_COMPLETION_PROCESS,
  GET_PLANNED_MIGRATION_BY_DATE,
  GET_ACTUAL_MIGRATION_BY_DATE,
  GET_RESOURCE_STATUS,
  GET_ASSESS_FILTER,
  GET_SLM_FILTER,
  GET_OPERATE_FILTER,
  GET_APP_MODERNIZATION_IS,
  GET_APP_MODERNIZATION_TO_BE,
  GET_KUBERNETES_SPREAD,
  GET_ECS,
  TOTAL_USAGE_COUNT_KUBERNETES,
  MEMORY_CPU_COUNT,
  ECS_MEMORY_CPU_COUNT,
  ECS_COUNT_TOTAL,
  ASSSES_ADVIISORY_SCORE,
  RIGHTSIZINGQUERY,
  CORE_COMPARISON,
  RAM_COMPARISON,
  STORAGE_COMPARISON,
  TCO_TABLE_COSTANALYSIS,
  TO_BE_CORE_CPU,
  TO_BE_CORE_RAM,
  TCO_TABLE_SUMMARY,
  STORAGE_UPDATE,
  UPDATE_VM,
  WAR_DASHBOARD,
  // WAR_QUESTIONS
  WAR_ASSESSMENT_OPTIONS,
  WAR_DASHBOARD_LIST,
  GET_WAR_ASSESMENT_LIST,
  WAR_ASSESMENT_UPDATE,
  VM_DROPDOWN,
  STORAGE_DROPDOWN,
  EGRESS_DROPDOWN,
  ASSESSMENT_TABLE,
  ASSESSMENT_TABLE_LIST,
  ASSESSMENT_TABLE_UPDATE,
  ASSESSMENT_TABLE_DELETE,
  ASSESSMENT_TABLE_UPLOAD,
};
