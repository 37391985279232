import { useState } from "react";
import { useEffect } from "react";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";
import { Spin } from "antd";

const TaggingChart = (props) => {
  const [tablerecords, setTableRecords] = useState([]);
  const [chartdetails, setChartDetails] = useState({
    chartcategory: [],
    chartvalue: [],
  });
  useEffect(() => {
    const datalist = props.untaggedresources.list;
    //Mandatory
    const mandatory_tag = datalist?.filter(
      (v, index) => v.tag_category === "Mandatory-tag"
    );

    if (mandatory_tag.length > 0) {
      const services_list = mandatory_tag?.map((v) => v.services);
      const service_set = new Set([...services_list]);
      const services = [...service_set];
      var all_tagging_list = [];
      services.map((service_name) => {
        //tagging count
        const tagged_records = datalist?.filter(
          (v, index) =>
            v.tagging_details === "tagged" && v.services === service_name
        );
        // console.log(tagged_records);
        const tagged_records_count = tagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //partially count
        const partially_records = datalist?.filter(
          (v, index) =>
            v.tagging_details === "partially_tagged" &&
            v.services === service_name
        );
        const partially_records_count = partially_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //untagged
        const untagged_records = datalist?.filter(
          (v, index) =>
            v.tagging_details === "Untagged" && v.services === service_name
        );
        const untagged_records_count = untagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        all_tagging_list.push({
          servicename: service_name,
          taggingcount: tagged_records_count,
          partiallycount: partially_records_count,
          untaggedcount: untagged_records_count,
        });
      });
      const mandatory_list = all_tagging_list.sort(
        (a, b) => b.taggingcount - a.taggingcount
      );
      var chartcat = [];
      var chatvalue = [];
      var new_mandatory_list = [];
      if (mandatory_list.length > 0) {
        var loop_count = 5;
        if (mandatory_list.length < 5) {
          loop_count = mandatory_list.length;
        }
        for (let index = 0; index < loop_count; index++) {
          chartcat.push(mandatory_list[index].servicename);
          new_mandatory_list.push(mandatory_list[index]);
        }
        var tagged_list = [];
        var partially_tagged_list = [];
        var untagged_list = [];
        new_mandatory_list.map((tag_list) => {
          tagged_list.push(tag_list.taggingcount);
          partially_tagged_list.push(tag_list.partiallycount);
          untagged_list.push(tag_list.untaggedcount);
        });
      }
      setChartDetails({
        chartcategory: chartcat,
        chartvalue: [
          {
            name: "Tagged",
            data: tagged_list,
          },
          {
            name: "Partially Tagged",
            data: partially_tagged_list,
          },
          {
            name: "Untagged",
            data: untagged_list,
          },
        ],
      });
    }
  }, [props]);

  const series = chartdetails.chartvalue;

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 0,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: ["#537A5A", "#003f5c", "#975C0E"],

    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "white",
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    xaxis: {
      // categories: ["Automated Tag", "Mandatory Tag", "No Cost Impact Tag"],
      categories: chartdetails.chartcategory,
      labels: {
        show: false,
        style: {
          colors: "white",
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      y: {
        style: {
          color: "rgba(255, 255, 255, 0.40)",
        },
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      labels: {
        colors: "white",
      },
      // colors:"#FFF",
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
  };
  return (
    <>
      <Spin spinning={props.ltaggingstatus} wrapperClassName="costimize-loader">
        <h1 className="spend-chart-title">Top 5 Tagged </h1>
        {/* <h1 className="spend-chart-title">Mandatory Tag</h1> */}
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={200}
        />
      </Spin>
    </>
  );
};

export default memo(TaggingChart);
