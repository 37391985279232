import React from "react";
import { Space, Table, Tag } from "antd";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColumns: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  tableColleft: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCellright: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 8,
    marginRight: 3,
  },
  tableCellleft: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  tableColright: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
  },
});
function EnvironmentWeeklySpend({ tableEnv }) {
  console.log(tableEnv);
  const convertToNumber = (value) => {
    // Convert string to number or return 0 if it's not a valid number
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  };
  const calculateTotals = () => {
    const totals = {
      no_of_application: 0,
      vm_count: 0,
      cpu: 0,
      memory: 0,
      storage: 0,
      pre_previous_week: 0,
      previous_week: 0,
      current_week: 0,
      difference: 0,
    };

    tableEnv.forEach((item) => {
      totals.no_of_application += convertToNumber(item.no_of_application);
      totals.vm_count += convertToNumber(item.vm_count);
      totals.cpu += convertToNumber(item.cpu);
      totals.memory += convertToNumber(item.memory);
      totals.storage += convertToNumber(item.storage);
      totals.pre_previous_week += convertToNumber(item.pre_previous_week);
      totals.previous_week += convertToNumber(item.previous_week);
      totals.current_week += convertToNumber(item.current_week);
      totals.difference += convertToNumber(item.difference);
    });

    return totals;
  };
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const totals = calculateTotals();
  return (
    <div>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Environment</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>No of Applications</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>VM Count</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>CPU</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Memory</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Storage</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>22/10-28/10</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>29/10-04/11</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>05/11-11/11 </Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Difference</Text>
          </View>
        </View>
        {tableEnv.map((item) => {
          return (
            <View style={styles.tableRow}>
              <View style={styles.tableColleft}>
                <Text style={styles.tableCellleft}>{item.environment}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {item.no_of_application}{" "}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.vm_count}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.cpu}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.memory}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.storage}</Text>
              </View>

              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.pre_previous_week)}{" "}
                </Text>
              </View>

              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.previous_week)}{" "}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.current_week)}{" "}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  {item.difference >= 0
                    ? `$${formatNumberWithCommas(item.difference)}`
                    : `-$${formatNumberWithCommas(
                        item.difference.toString().slice(1)
                      )}`}
                </Text>
              </View>
            </View>
          );
        })}
        <View style={styles.tableRow}>
          <View style={styles.tableColleft}>
            <Text style={styles.tableCellleft}>Total</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {totals.no_of_application}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.vm_count}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.cpu}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.memory}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.storage}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              ${formatNumberWithCommas(totals.pre_previous_week)}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {" "}
              ${formatNumberWithCommas(totals.previous_week)}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {" "}
              ${formatNumberWithCommas(totals.current_week)}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {" "}
              {totals.difference >= 0
                ? `$${formatNumberWithCommas(totals.difference)}`
                : `-$${formatNumberWithCommas(
                    totals.difference.toString().slice(1)
                  )}`}
            </Text>
          </View>
        </View>
      </View>
    </div>
  );
}

export default EnvironmentWeeklySpend;
