import { Table } from "antd";
//import Dropdown from "react-bootstrap/Dropdown";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import {
  message,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Switch,
  Row,
  Col,
  Dropdown,
  Modal,
} from "antd";
import { disabledDate } from "../../../custom_hook/CustomHook";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listKeyActivies,
  updateKeyActivities,
  AddKeyActivities,
  deleteKeyActivities,
} from "../../../action/graphql/slm/SLM_reducers";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Icon } from "@iconify/react";

const KeyActivities = () => {
  const { TextArea } = Input;
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [buttonloading, setButtonLoading] = useState(false);
  const [oldRecords, setOldRecords] = useState([]);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const monthFormat = "YYYY-MM-01";
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  useEffect(() => {
    const date = selectedTopdate;
    dispatch(listKeyActivies({ companyName, date }))
      .unwrap()
      .then(({ data }) => {
        console.log("data");
        console.log(data);
        const records = data.newfunctionquery22.map((datas) => {
          return {
            action_token: datas.action_token,
            application: datas.application,
            category: datas.category,
            company: datas.company,
            cost_impact: datas.cost_impact,
            date: datas.date,
            description: datas.description,
            key_id: datas.key_id,
            month: datas.month,
            severity: datas.severity,
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => showEditRow(datas)}
              />
            ),
            type: "data",

            // days_remaining: daysDiff
          };
        });
        setTableData(records);
        console.log(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setTableData([]);
  }, [updatestatus, selectedTopdate]);

  const showEditRow = (data) => {
    setDrawerStatus(true);
    setDeleteStatus(true);
    setOldRecords(data);
    const records = {
      application: data.application,
      date: moment(data.date, "YYYY-MM-DD"),
      category: data.category,
      description: data.description,
      action_token: data.action_token,
      severity: data.severity,
      cost_impact: data.cost_impact,
    };

    form.setFieldsValue(records);
  };
  const addRow = () => {
    //setDrawerStatus(true);
    setModalOpen(true);
    setDeleteStatus(false);
    setOldRecords(null);
    const records = {
      application: "",
      date: "",
      category: "",
      description: "",
      action_token: "",
      severity: "",
      cost_impact: "",
    };

    form.setFieldsValue(records);
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Application Name",
      dataIndex: "application",
      key: "application",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Descriptions",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action Token",
      dataIndex: "action_token",
      key: "action_token",
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
    },
    {
      title: "Cost Impact",
      dataIndex: "cost_impact",
      key: "cost_impact",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const tableProps = {};
  const handleSubmit = (value) => {
    console.log("submit");
    setDrawerStatus(false);

    const input = {
      company_name: companyName,
      action_token: value.action_token,
      application: value.application,
      category: value.category,
      cost_impact: value.cost_impact,
      description: value.description,
      report_date: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-${new Date(value.date).getDate()}`,
      report_month: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-01`,
      severity: value.severity,
    };
    console.log(input);
    console.log(oldRecords);
    if (deletestatus) {
      const key_id = oldRecords.key_id;
      dispatch(updateKeyActivities({ input, key_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(AddKeyActivities({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const rowDelete = () => {
    const key_id = oldRecords.key_id;
    dispatch(deleteKeyActivities({ key_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        setUpdateStatus(!updatestatus);
        setDrawerStatus(false);
        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleFileChange = () => {
    console.log("uploaded");
  };

  const handleFileSubmit = () => {
    console.log("submit");
  };
  const handleCancel = () => {
    setModalOpen(false);
    setIsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen,setModalOpen]=useState(false);
  const uploadClick = () => {
    setIsModalOpen(true);
  };
  const items = [
    {
      key: "1",
      label: "Upload",
      onClick: () => uploadClick(),
    },
    {
      key: "2",
      label: <CSVLink data={tableData}>Download </CSVLink>,
    },
  ];

  return (
    <>
      <Modal
        //drawerstatus={drawerstatus}
        className="slmmodal"
        open={modalOpen}
        onCancel={handleCancel}
        footer={null}
        changeDrawerStatus={changeDrawerStatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        deletestatus={deletestatus}
        rowDelete={rowDelete}
        title="KeyActivities"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  format={monthFormat}
                  picker="month"
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="application"
                label="Application Name"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "changerequest",
                      label: "Change Request",
                    },
                    {
                      value: "changerequest1",
                      label: "Change Request1",
                    },
                    {
                      value: "changerequest2",
                      label: "Change Request2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="action_token"
                label="Action Token"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "1 vm provisioned",
                      label: "1 VM provisioned",
                    },
                    {
                      value: "2 vm provisioned",
                      label: "2 VM provisioned",
                    },
                    {
                      value: "3 vm provisioned",
                      label: "3 VM provisioned",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="severity"
                label="Severity "
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "high",
                      label: "High",
                    },
                    {
                      value: "medium",
                      label: "Medium",
                    },
                    {
                      value: "low",
                      label: "Low",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="cost_impact"
                label="Cost Impact "
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "yes",
                      label: "Yes",
                    },
                    {
                      value: "no",
                      label: "No",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div className="keyActivities-body-screen">
        {/* <keyActivitiesChart /> */}
        <div className="">
          <div className="">
            {/* <h1 className="plat-table-title mt-3">
              Key activities performed this month
            </h1> */}
            <div
              onClick={addRow}
              // onClick={() => setDrawerStatus(true)}
            >
              <button className="table-add-button float-right">
                {/* <Icon icon="ion:add-circle-outline" /> */}
                <Icon icon="material-symbols:add-circle-outline" />
                Add
              </button>
            </div>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <Button className="table-add-button float-right">
                <Icon icon="vscode-icons:file-type-excel" />
              </Button>
            </Dropdown>
            <Modal
              title="Choose File Type"
              open={isModalOpen}
              footer={null}
              onCancel={handleCancel}
            >
              <input type="file" id="fileInput" onChange={handleFileChange} />
              <div className="p-1">
                <button
                  className="btn tagging-upload-button"
                  onClick={handleFileSubmit}
                >
                  Upload
                </button>
              </div>
            </Modal>
            <Table
              {...tableProps}
              className="slm mt-1"
              columns={columns}
              dataSource={tableData}
              pagination={true}
              style={{ overflowX: "scroll" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyActivities;
