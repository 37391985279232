import React, { useState, useEffect } from "react";
import { Table, Row, Col, Spin, DatePicker } from "antd";
import "../spp/spp.css";
import { Icon } from "@iconify/react";
import Billingform from "./Billingform";
import { listbillingclient } from "../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import logo from "../../assets/images/company_logo.png";
import cmacgm_logo from "../../assets/images/cmacgm_logo_new.png";
import AWS from "aws-sdk";
import { saveAs } from "file-saver";
import { headercustomerlogo } from "../action/header_logo";
AWS.config.update({
  accessKeyId: "AKIAUNMDL3MXCBFVA75Z",
  secretAccessKey: "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",
});

const s3 = new AWS.S3();

dayjs.extend(customParseFormat);
const Billingtable = () => {
  const [updatestatus, setUpdateStatus] = useState(true);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [oldrecords, setOldRecords] = useState();
  const [preloader, setPreloader] = useState(true);
  const [tablerecords, setTableRecords] = useState([]);
  const [downloadFile, setDownloadFile] = useState();
  const customer = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const Offer = localStorage.getItem("CognitoIdentityServiceProvider.offering");
  const [company_logo, setCompanyLogo] = useState();
  useEffect(() => {
    if (customer === "cmacgm") {
      setCompanyLogo(cmacgm_logo);
    } else if (customer === "fivestar") {
      setCompanyLogo(logo);
    }
  }, []);
  console.log(Offer);
  const monthFormat = "YYYY-MM";
  const dispatch = useDispatch();
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [filePathURL, setFilePathURL] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("YYYY-MM")
  );
  useEffect(() => {
    const company_name = customer;
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.platform_logo;
        var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const date = `${selectedMonth}-01`;

    dispatch(listbillingclient({ customer, date }))
      .unwrap()
      .then(({ data }) => {
        setPreloader(true);
        console.log(data.newfunctionquery34);
        const records = data.newfunctionquery34.map((datas, index) => {
          return {
            bill_id: datas.bill_id,
            billing_currency: datas.billing_currency,
            billingperiod: datas.billingperiod,
            billtype: datas.billtype,
            due_date: datas.due_date,
            invoiceid: datas.invoiceid,
            issued_date: datas.issued_date,
            platform_cost: datas.platform_cost,
            status: datas.status,
            tax_cost: datas.tax,
            total_invoice_amount: datas.total_invoice_amount,
            usage_cost: datas.usage,
            vendor: datas.vendor,
            record: datas.s3_file_name,
            s3_file_name: datas.s3_file_name,

            report: (
              <div>
                <Icon
                  icon="bi:file-pdf-fill"
                  className="download-btn"
                  onClick={() => handleDownload(records, datas)}
                />
              </div>
            ),
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => {
                  if (Offer === "master" || Offer === "1ch_admin") {
                    showEditPopup(records, datas);
                  }
                }}
              />
            ),

            // action: (
            //     <Icon
            //         icon="material-symbols:edit"
            //         onClick={() => showEditPopup(records, datas)}
            //     ></Icon>
            // ),

            type: "data",
          };
        });

        setTableRecords(records);
        console.log(records);
        console.log(records.vendor);
        setDownloadFile(records.record);
        setPreloader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });
    setTableRecords([]);
  }, [updatestatus, selectedTopdate, selectedMonth]);
  console.log(downloadFile);

  const handleDownload = (records, data) => {
    console.log(records);
    console.log(data);
    console.log(data.s3_file_name);
    const fileName = data.s3_file_name;

    const s3 = new AWS.S3();

    const key = `${customer}/${data.s3_file_name}`;
    console.log(key);

    const params = {
      Bucket: "spp-billing-invoice",
      Key: `${customer}/${data.s3_file_name}`,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        const fileContent = data.Body;
        console.log(data.Body);
        const blob = new Blob([fileContent], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        link.download = `${fileName}`;

        link.click();
      }
    });
  };

  const columns = [
    {
      title: "Issued Date",
      dataIndex: "issued_date",

      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceid",
    },
    {
      title: "Bill Type",
      dataIndex: "billtype",
    },
    {
      title: "Billing Currency",
      dataIndex: "billing_currency",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
    },
    {
      title: "Billing Period",
      dataIndex: "billingperiod",

      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Due Date",
      dataIndex: "due_date",

      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Platform Cost",
      dataIndex: "platform_cost",
      render: (value) => <div className="right-aligned">{value}</div>,
    },
    {
      title: "Usage Cost",
      dataIndex: "usage_cost",
      render: (value) => <div className="right-aligned">{value}</div>,
    },
    {
      title: "Tax",
      dataIndex: "tax_cost",
      render: (value) => <div className="right-aligned">{value}</div>,
    },
    {
      title: "Total Invoice Amount",
      dataIndex: "total_invoice_amount",
      render: (value) => <div className="right-aligned">{value}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Report",
      dataIndex: "report",
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
    },
  ];

  const handleAddClick = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords(null);
  };
  const showEditPopup = (records, data) => {
    console.log(data.s3_file_name);
    const fileName = data.s3_file_name;
    console.log(records);
    setDrawerStatus(true);
    setOldRecords(data);
    const bucketName = "spp-billing-invoice";
    const filePath = s3.getSignedUrl("getObject", {
      Bucket: bucketName,
      Key: `${customer}/${fileName}`,
    });
    console.log("File path URL:", filePath);

    setFilePathURL(filePath);

    setDeleteStatus(true);
  };
  console.log(filePathURL);

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const onChange = (date, dateString) => {
    if (dateString) {
      setSelectedMonth(dateString);
    } else {
      setSelectedMonth(moment(new Date()).format("YYYY-MM"));
    }
    setPreloader(true);
  };
  return (
    <>
      <Billingform
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        setUpdateStatus={setUpdateStatus}
        updatestatus={updatestatus}
        setDrawerStatus={setDrawerStatus}
        dataSource={tablerecords}
        oldrecords={oldrecords}
        deletestatus={deletestatus}
        filePathURL={filePathURL}
      />
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
          <div className="header-title-spp">Billing Service</div>
        </Col>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={24}
          className="text-right mt-3"
        >
          <div className="header-logo">
            {company_logo && <img src={company_logo} />}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={18} offset={6} className="date-picker-container ">
          <DatePicker
            defaultValue={dayjs(selectedMonth, monthFormat)}
            format={monthFormat}
            onChange={onChange}
            className="date-picker"
            picker="month"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div onClick={() => handleAddClick()}>
            {Offer === "master" && (
              <button className="table-add-button float-right">
                <Icon icon="material-symbols:add-circle-outline" />
                Add
              </button>
            )}
            {Offer === "1ch_admin" && (
              <button className="table-add-button float-right">
                <Icon icon="material-symbols:add-circle-outline" />
                Add
              </button>
            )}
            {/* {Offer === 'master' || Offer === '1ch_admin' && (
                            <button className="table-add-button float-right">
                                <Icon icon="material-symbols:add-circle-outline" />
                                Add
                            </button>
                        )} */}
          </div>
        </Col>
      </Row>

      <Row className="spp">
        <Spin spinning={preloader} wrapperClassName="spp-loader">
          <Table
            columns={columns}
            dataSource={tablerecords}
            className="spp_Table"
          />
        </Spin>
      </Row>
    </>
  );
};
export default Billingtable;
