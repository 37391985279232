import React, { memo, useState, useEffect } from "react";
import { Sidebar, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import budgeticon from "../../assets/icons/budget_sidebar_icon.svg";
import budgeticon_white from "../../assets/icons/budget_sidebar_icon_white.svg";
import {
  Menu,
 

  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
function SLMSidebar() {
  const { toggleSidebar } = useProSidebar();
  const [sidebarmenu, setSidebarMenu] = useState(1);
  useEffect(() => {
    if (window.location.pathname === "/slm/compliance") {
      setSidebarMenu(4);
    } else if (window.location.pathname === "/slm/asset-managed") {
      setSidebarMenu(3);
    } else if (window.location.pathname === "/slm/activity-tracker") {
      setSidebarMenu(6);
    } else if (window.location.pathname === "/slm/planned-opened-activity") {
      setSidebarMenu(5);
    } else if (window.location.pathname === "/slm/assets-spend") {
      setSidebarMenu(2);
    } else if (window.location.pathname === "/slm/escalationmatrix") {
      setSidebarMenu(7);
    }else {
      setSidebarMenu(1);
    }
  }, [window.location.pathname]);
  return (
    <div>
      {" "}
      <>
        {window.innerWidth > 780 ? (
          <>
          <div  style={{ display: "flex", height: "100%", minHeight: "400px" }}>
            <Sidebar
                width="200px"
                backgroundColor="#0f0f0f"
                rootStyles={{
                  borderRight: "1px solid rgba(255, 255, 255, 0.20)",
                  ["." + menuClasses.button]: {
                    background: "#0f0f0f",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#202020",
                    },
                  },
                  ["." + menuClasses.label]: {
                    color: "white",
                  },
                  ["." + menuClasses.icon]: {
                    color: "white",
                  },
                }}
                collapsedWidth="200px"
                collapsed={false}
                defaultCollapsed={false}
            >
              <Menu>
                {console.log(sidebarmenu)}
                <MenuItem
                  className={sidebarmenu === 1 && "active"}
                  component={<Link to="/slm/dashboard" />}
                >
                  <Icon icon="ic:round-dashboard" className="sidebaricon"/> Quick Glance
                </MenuItem>
                <MenuItem
                  className={`${sidebarmenu === 2 && "active"} menu-items`}
                  component={<Link to="/slm/assets-spend" />}
                >
                  <Icon icon="fluent-mdl2:fixed-asset-management" className="sidebaricon" />
                  Assets/Spend
                </MenuItem>
                <MenuItem
                  className={sidebarmenu === 3 && "active"}
                  component={<Link to="/slm/asset-managed" />}
                >
                  <Icon icon="material-symbols:verified-user" className="sidebaricon"/><span className="textsidebar">Assets Managed</span> 
                </MenuItem>
                <MenuItem
                  className={sidebarmenu === 4 && "active"}
                  component={<Link to="/slm/compliance" />}
                >
                  <Icon icon="fluent-mdl2:compliance-audit" className="sidebaricon" /> Compliance
                </MenuItem>
                <MenuItem
                  className={sidebarmenu === 5 && "active"}
                  component={<Link to="/slm/planned-opened-activity" />}
                >
                  <Icon icon="carbon:text-link-analysis" className="sidebaricon"/> Planned & Opened Activity
                </MenuItem>
                <MenuItem
                  className={sidebarmenu === 6 && "active"}
                  component={<Link to="/slm/activity-tracker" />}
                >
                  <Icon icon="ic:outline-access-time-filled" className="sidebaricon" />
                  Activity Tracker
                </MenuItem>
                <MenuItem
                  className={sidebarmenu === 7 && "active"}
                  component={<Link to="/slm/escalationmatrix" />}
                >
                  <Icon icon="ic:outline-access-time-filled" className="sidebaricon" /> Escalation Matrix
                </MenuItem>
              </Menu>
            </Sidebar>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ display: "flex", height: "100%", minHeight: "400px" }}
            >
              <Sidebar
                breakPoint="always"
                defaultCollapsed
                collapsedWidth="64px"
                className="slm-sidebar mobile-costimize-sidebar"
              >
                <Menu>
                  {console.log(sidebarmenu)}
                  <MenuItem
                    className={sidebarmenu === 1 && "active"}
                    component={<Link to="/slm/dashboard" />}
                  >
                    <Icon icon="ic:round-dashboard" />
                  </MenuItem>
                  <MenuItem
                    className={sidebarmenu === 2 && "active"}
                    component={<Link to="/slm/assets-spend" />}
                  >
                    <Icon icon="fluent-mdl2:fixed-asset-management" />
                  </MenuItem>
                  <MenuItem
                    className={sidebarmenu === 3 && "active"}
                    component={<Link to="/slm/asset-managed" />}
                  >
                    <Icon icon="material-symbols:verified-user" />
                  </MenuItem>
                  <MenuItem
                    className={sidebarmenu === 4 && "active"}
                    component={<Link to="/slm/compliance" />}
                  >
                    <Icon icon="fluent-mdl2:compliance-audit" />
                  </MenuItem>
                  <MenuItem
                    className={sidebarmenu === 5 && "active"}
                    component={<Link to="/slm/planned-opened-activity" />}
                  >
                    <Icon icon="carbon:text-link-analysis" />
                  </MenuItem>
                  <MenuItem
                    className={sidebarmenu === 6 && "active"}
                    component={<Link to="/slm/activity-tracker" />}
                  >
                    <Icon icon="ic:outline-access-time-filled" />
                  </MenuItem>
                </Menu>
              </Sidebar>
              <main className="sidebar-mobile-main">
                <div>
                  <Icon
                    className="sidemenu-toggle"
                    icon="ic:round-menu"
                    onClick={() => toggleSidebar()}
                  />
                </div>
              </main>
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default SLMSidebar;
