import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";

// import html2canvas from "html2canvas";
// import ApexChartComponent from "./ApexChartComponent";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
function ThreeMonthsSpend({ reportformonth, stdate }) {
  const currentDate = new Date(stdate); // Create a Date object from the string
  const lastSixMonths = [];
  // Check if currentDate is a valid date
  if (!isNaN(currentDate.getTime())) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    for (let i = 0; i < 6; i++) {
      const tempDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      lastSixMonths.push(months[tempDate.getMonth()]);
    }
    console.log("Last Six Months:", lastSixMonths.reverse());
  } else {
    console.error("Invalid date format for startDate:", stdate);
  }
  const aws_consumption = reportformonth.map((item) =>
    parseFloat(item.aws_consumption)
  );
  const credtSpend = reportformonth.map((item) =>
    Math.abs(parseFloat(item.credit_spend))
  );
  const series = [
    {
      // name: "",
      // // data: seriesData,
      // data: reverse,

      name: "Spend after Credit",
      type: "bar",
      data: aws_consumption.reverse(),
    },
    {
      name: "Credits ",
      type: "bar",
      data: credtSpend.reverse(),
    },
    // {
    //   name: "Total Spend",
    //   type: "line",
    //   data: with_credit.reverse(),
    // },
  ];

  const options = {
    chart: {
      id: "threemonthspend",
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        stacked: true,
        dataLabels: {
          align: "top",
          enabled: false,
          style: {
            fontSize: "12px",
            colors: ["#3B3B3B"],
          },
          formatter: function (val) {
            return val.toLocaleString(); // Use toLocaleString for comma-separated values
          },

          total: {
            enabled: true,

            align: "right",
          },
        },
      },
      // line: {
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   grid: {
      //     row: {
      //       colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //       opacity: 0.5,
      //     },
      //   },
      //   stroke: {
      //     curve: "straight",
      //   },
      // },
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      formatter: function (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },

    xaxis: {
      categories: lastSixMonths,
    },
    title: {
      text: "Last Six Months Spend ($)",
      align: "center",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem",
          fontWeight: "500",
        },
      },
    },

    colors: ["#FF6A2F", "#A7EE7C", "#FFFF"],
    tooltip: {
      enabled: true,
      shared: true, // Enables a shared tooltip
      intersect: false,
      x: {
        formatter: function (val) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      y: {
        formatter: function (value) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        height={250}
        type="bar"
      />
    </div>
  );
}

export default ThreeMonthsSpend;
