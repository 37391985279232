import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetSpendVMApplicationRecords } from "../../action/costimizeV2Active";
import { Col, Row } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import { numberFormat, capitalizeFirst } from "../../custom_hook/CustomHook";
import AssetTrendChart from "./AssetTrendChart";
import AssetSpendListRes from "./AssetSpendListRes";

const AssetSpendListApplication = (props) => {
  console.log(props)
  const [isRowClicked, setIsRowClicked] = useState(false);
  const dispatch = useDispatch();
  const [allapplicationlist, setAllApplicationList] = useState([]);
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  useEffect(() => {
    const currentDate = new Date(selectedTopdate);
    const lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    var month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    var first_month = month_list[0];
    var second_month = month_list[1];
    var thired_month = month_list[2];

    var apivalue = {
      environment: props.environment,
      account: selectedAccount.toString(),
      cloud: props.cloud,
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      company_name: companyName,
      first_month: month_list[0],
      second_month: month_list[1],
      thired_month: month_list[2],
    };

    dispatch(
      getAssetSpendVMApplicationRecords({
        companyName,
        selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        // console.log(data.newfunctionquery32,"32")
        if (data.newfunctionquery32.length > 0) {
          const application = data.newfunctionquery32?.map(
            (v) => v.application
          );
          var applicationlist = new Set([...application]);
          applicationlist = [...applicationlist];
          const cloud_all_list = [];
          applicationlist.map((data_map) => {
            const cloud_data_list = data.newfunctionquery32?.filter((datas) => {
              return datas.application.toLowerCase() === data_map.toLowerCase();
            });
            const return_environment = getEnvironmentList(
              data_map,
              cloud_data_list,
              first_month,
              second_month,
              thired_month
            );
            // console.log(return_environment);
            cloud_all_list.push(return_environment);
          });
          // console.log(cloud_all_list);
          setAllApplicationList(cloud_all_list);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props]);

  const getEnvironmentList = (
    enviroment,
    data_list,
    first_month,
    second_month,
    thired_month
  ) => {
    const spendtotal = data_list.reduce((a, v) => (a = +a + +v.spend), 0);
    const totalcount = data_list.reduce((a, v) => (a = +a + +v.count), 0);

    //ram
    const ram_list = data_list.map((data) => {
      if (data) {
        const ram_value = data.ram?.split("GiB");
        if (ram_value?.length > 0) {
          return Number(ram_value[0].trim());
        } else {
          return 0;
        }
      }
    });

    const ram = ram_list.reduce((a, v) => (a = +a + +v), 0);
    const core = data_list.reduce((a, v) => (a = +a + +v.core), 0);
    var storage = data_list.reduce((a, v) => (a = +a + +v.storage), 0);
    if (storage > 1000) {
      storage = storage / 1000;
      storage = `${storage} TB`;
    } else {
      storage = `${storage} GiB`;
    }
    const savings_opportunity = data_list.reduce(
      (a, v) => (a = +a + +v.savings_opportunity),
      0
    );
    //chart
    const third_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.third_month_spend),
      0
    );
    const second_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.second_month_spend),
      0
    );
    const first_month_spend = data_list.reduce(
      (a, v) => (a = +a + +v.first_month_spend),
      0
    );
    const chart = {
      chartlable: [first_month, second_month, thired_month],
      chartvalue: [
        Math.round(third_month_spend),
        Math.round(second_month_spend),
        Math.round(first_month_spend),
      ],
    };
    return {
      title: enviroment,
      spend: Math.round(spendtotal),
      name: "-",
      size: "-",
      ram: ram === 0 ? "-" : Math.round(ram),
      core: core === 0 ? "-" : Math.round(core),
      storage: storage,
      savings_opportunity: !savings_opportunity
        ? 0
        : Math.round(savings_opportunity),
      spend_trend: chart,
      count: totalcount,
    };
  };
  console.log(allapplicationlist)
  const handleClick = () => {
    setIsRowClicked(!isRowClicked)
  }
  useEffect(() => {
  }, [
    isRowClicked
  ])
  return (
    <>
      {allapplicationlist.map((applicationlist, indexa) => {
        const ran_num_cloud = Math.random();
        return (
          <ul className="cd-accordion__sub cd-accordion__sub--l3">
            <li className="cd-accordion__item cd-accordion__item--has-children">
              <input
                className="cd-accordion__input"
                type="checkbox"
                name={"sub-group-level3-" + indexa + "" + ran_num_cloud}
                id={"sub-group-level3-" + indexa + "" + ran_num_cloud}
              // defaultChecked
              />
              <label
                className="cd-accordion__label"
                htmlFor={"sub-group-level3-" + indexa + "" + ran_num_cloud}
                onClick={handleClick}
              >
                <span className="accordion-full-width">
                  <ToggleButtonV2
                    status={true}
                    newclass="application-list-icon-new"
                  >
                    <Row className="plat-table-assets-spend-thead">
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-left-title app-list-title asset-spend-title-vs"
                      >

                        {capitalizeFirst(applicationlist.title)}(
                        {applicationlist.count})
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        ${numberFormat(applicationlist.spend)}
                        <Icon
                          className={
                            "me-1 " +
                            (applicationlist.spend > 0
                              ? "text-danger-icon-custom"
                              : "text-success-icon-custom")
                          }
                          icon={
                            applicationlist.spend > 0
                              ? "material-symbols:arrow-drop-up"
                              : "material-symbols:arrow-drop-down"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {applicationlist.name}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs justify-content-center"
                      >
                        {applicationlist.size}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {applicationlist.ram}
                      </Col>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {applicationlist.core}
                      </Col>
                      {/* {companyName === "fivestar" ||
                                            (companyName === "cmacgm" && ( */}
                      <Col
                        xxl={2}
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        {applicationlist.storage}
                      </Col>
                      {/* ))} */}
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="asset-spend-values-vs plat-text-right"
                      >
                        ${numberFormat(applicationlist.savings_opportunity)}
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="asset-spend-values-vs plat-chart-center"
                      >
                        {console.log(applicationlist)}
                        {console.log(applicationlist.spend_trend)}
                        {console.log(props.cloud)}
                        {console.log(props.environment)}
                        {console.log(props.resname)}
                        {console.log(applicationlist.title)}
                        <AssetTrendChart
                          records={applicationlist.spend_trend}
                          cloud={props.cloud}
                          titles={props.environment}
                          resname={props.resname}
                          application={applicationlist.title}
                        />
                      </Col>
                    </Row>
                    <div className="asset-spend-border-bottom-vs"></div>
                  </ToggleButtonV2>
                </span>
              </label>
              {/* resorces list */}
              {console.log(applicationlist.title)}
              {console.log(props.cloud)}
              {isRowClicked && (
                <AssetSpendListRes
                  environment={props.environment}
                  cloud={props.cloud}
                  application={applicationlist.title}
                  resname={props.resname}
                />
              )}
            </li>
          </ul>
        );
      })}
    </>
  );
};

export default memo(AssetSpendListApplication);
