// userAction.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../apploClient";
import client_new from "./client/dashboardapplonewClinet";
import a2c_httpLink from "./client/A2C_Client";
import { useErrorNavigation } from "../custom_hook/CustomHook";
import {
  ACCOUNT_LIST,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  ACCOUNT_UPDATE,
  ACCOUNT_STATUS_UPDATE,
  ACCOUNT_LIST_NEW,
  ACCOUNT_ENVIRIONMENT_LIST,
  ACCOUNT_ONBOARDING,
  ACCOUNT_ONBOARDING_INSERT,
  TOKEN_VERIFIED,
  ROLE_DROPDOWN,
  ROLE_GET_BY_ID,
  ROLE_INSERT,
  ALL_ROLE_LIST,
  DELETE_ROLE,
  UPDATE_ROLE,
  DOCUMENT_POLICY,
  COMPANY_BASED_ACCOUNT,
  COMPANY_DISTINICT_NAME,
  WEEKLYREPORT_MONTH,
  WEEKLYREPORT_WEEK,
  WEEKLYREPORT_ENV_WEEKWISE,
  WEEKLYREPORT_ENV_WEEKWISE_SUMMARY,
  WEEKLYREPORT_WEEKWISE_SUMMARY,
  WEEKLYREPORT_WEEKWISE_SUMMARY_TOP,
  WEEKLYREPORT_WEEKWISE_SUMMARY_TOP_INCREASE,
  WEEKLYREPORT_WEEKWISE_SUMMARY_SERVICE,
  WEEKLYREPORT_WEEKWISE_APP_SERIVCE,
  ENVIRONMENT_WEEKLY_REPORT,
  WEEKLY_DETAILED_SERVICE_REPORT,
  OVERALL_SUMMARY,
  APPLICATIONLEVELCHART,
  WEEKLYREPORTCONSUMPTION,
  WEEKLYREPORT,
  ENVIRONMENT_MONTHLY_REPORT,
  APPLICATION_WEEKLY_REPORT,
  APPLICATION_MONTHLY_REPORT,
  SERVICE_WEEKLY_REPORT,
  SERVICE_MONTHLY_REPORT,
  ENVIRONMENT_DETAILES,
  SERVICE_TOTAL_SPEND,
  APPLICATION_SUMMARY,
  WEEKLYREPORTFORREPORT,
} from "../Graphql";
import { useNavigate } from "react-router-dom";

export const listAccount = createAsyncThunk(
  // action type string
  "account/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const accList = await client.query({
        query: ACCOUNT_LIST,
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAccountNew = createAsyncThunk(
  // action type string
  "account/new_list",
  // callback function
  async ({}, { rejectWithValue }) => {
    try {
      const accList = await client.query({
        query: ACCOUNT_LIST_NEW,
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const accountOnboarding = createAsyncThunk(
  // action type string
  "account/create",
  // callback function
  async (
    {
      usage_account_id,
      account_name,
      bucket_name,
      iam_arn_role,
      prefix_path,
      report_name,
      environment,
      status,
      cloud_platform,
      customer,
      ec2_regions_list,
      environment_tag,
      application_name_column,
      payer_account_id,
      auto_tag_update,
      event_type,
      external_id,
      files,
      user_id,
    },
    { rejectWithValue }
  ) => {
    //  console.log({  account_id, account_name, bucket_name, iam_arn_role, prefix_path, report_name, environment, status, cloud_type, company_name, ec2_region_list, environment_tag, application_tag, payer_account_id, auto_tag_update,event_type, files });
    console.log(event_type);
    console.log(user_id);
    var query;
    if (event_type === "validate") {
      query = ACCOUNT_ONBOARDING;
    } else {
      query = ACCOUNT_ONBOARDING_INSERT;
    }
    try {
      const account = await client.mutate({
        mutation: query,
        variables: {
          usage_account_id,
          account_name,
          bucket_name,
          iam_arn_role,
          prefix_path,
          report_name,
          environment,
          status: "active",
          cloud_platform,
          customer,
          ec2_regions_list,
          environment_tag,
          application_name_column,
          payer_account_id,
          auto_tag_update,
          event_type,
          external_id,
          files,
          user_id,
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createAccount = createAsyncThunk(
  // action type string
  "account/create",
  // callback function
  async (
    {
      account_id,
      account_name,
      bucket_name,
      iam_arn_role,
      prefix_path,
      report_name,
      environment,
      status,
      cloud_type,
      company_name,
      ec2_region_list,
      environment_tag,
      application_tag,
      payer_account_id,
      auto_tag_update,
    },
    { rejectWithValue }
  ) => {
    try {
      const account = await client.mutate({
        mutation: CREATE_ACCOUNT,
        variables: {
          createaccountconfiginput: {
            account_id,
            account_name,
            bucket_name,
            iam_arn_role,
            prefix_path,
            report_name,
            environment,
            status: "active",
            cloud_type,
            company_name,
            ec2_region_list,
            environment_tag,
            application_tag,
            payer_account_id,
            auto_tag_update,
          },
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAccount = createAsyncThunk(
  // action type string
  "account/delete",
  // callback function
  async ({ account_id, account_name }, { rejectWithValue }) => {
    try {
      const account = await client.mutate({
        mutation: DELETE_ACCOUNT,
        variables: {
          account_id,
          account_name,
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAccountDetails = createAsyncThunk(
  // action type string
  "account/update",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const account = await client.mutate({
        mutation: ACCOUNT_UPDATE,
        variables: {
          account_id: data.account_id,
          account_name: data.account_name,
          bucket_name: data.bucket_name,
          environment: data.environment,
          iam_arn_role: data.iam_arn_role,
          prefix_path: data.prefix_path,
          report_name: data.report_name,
          ec2_region_list: data.ec2_region_list,
          environment_tag: data.environment_tag,
          application_tag: data.application_tag,
          payer_account_id: data.payer_account_id,
          auto_tag_update: data.auto_tag_update,
        },
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAccountStatus = createAsyncThunk(
  // action type string
  "account/status_update",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const account = await client.mutate({
        mutation: ACCOUNT_STATUS_UPDATE,
        variables: {
          account_id: data.account_id,
          account_name: data.account_name,
          status: data.status_value,
        },
      });
      return account;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const accountEnvirionmentList = createAsyncThunk(
  // action type string
  "account/envirionment_list",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const account = await client.mutate({
        mutation: ACCOUNT_ENVIRIONMENT_LIST,
        variables: {},
      });
      return account;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifiedJWTToken = createAsyncThunk(
  // action type string
  "token/verified",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client.query({
        query: TOKEN_VERIFIED,
        variables: {
          token: data.userToken,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const roleDropdown = createAsyncThunk(
  // action type string
  "get/roledropdown",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.mutate({
        mutation: ROLE_DROPDOWN,
        variables: {
          company: data.company_name,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const roleGetByID = createAsyncThunk(
  // action type string
  "get/roledropdown",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: ROLE_GET_BY_ID,
        variables: {
          company: data.company_name,
          role_id: data.role_id,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const newRoleInsert = createAsyncThunk(
  // action type string
  "role/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: ROLE_INSERT,
        variables: {
          customer: data.input.customer,
          role_category: data.input.role_category,
          role_name: data.input.role_name,
          user_id: data.input.user_id,
          event_type: data.input.event_type,
          offering: data.input.offering,
          resources: data.input.resources,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAllRole = createAsyncThunk(
  // action type string
  "role/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.mutate({
        mutation: ALL_ROLE_LIST,
        variables: {
          company_name: data.company_name,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deletedCustomRole = createAsyncThunk(
  // action type string
  "role/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: DELETE_ROLE,
        variables: {
          customer: data.input.customer,
          role_id: data.input.role_id,
          user_id: data.input.user_id,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateRole = createAsyncThunk(
  // action type string
  "role/update",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client_new.query({
        query: UPDATE_ROLE,
        variables: {
          input_new: data.input_new,
          input_old: data.input_old,
          user_id: data.user_id,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const distinictlistAccount = createAsyncThunk(
  // action type string
  "account/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const accList = await client.mutate({
        mutation: COMPANY_DISTINICT_NAME,
        variables: {
          cloud: data.cloud,
          event_type: data.event_type,
        },
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const companybasedlistAccount = createAsyncThunk(
  // action type string
  "account/list",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const accList = await client.mutate({
        mutation: COMPANY_BASED_ACCOUNT,
        variables: {
          company: data.toggleText,
          event_type: data.event_type_company,
        },
      });
      return accList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const rolePolicyDocument = createAsyncThunk(
  // action type string
  "accountonboard/document",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const aasList = await client.mutate({
        mutation: DOCUMENT_POLICY,
        variables: {
          cloud: data.cloud,
          event_type: data.event_type,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// report api
{
  /* kamalika */
}
export const weeklyreportchart = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT,
        variables: {
          // start_date: data.selectedStartDate,
          date: data.date,
          company_name: data.company_name,
          // end_date: data.selectedEndDate,
          // range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const weeklychartforreport = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORTFORREPORT,
        variables: {
          start_date: data.selectedStartDate,
          company_name: data.company_name,
          end_date: data.selectedEndDate,
          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const weeklyreportMonthchart = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_MONTH,
        variables: {
          month: data.selectedStartDate,
          company_name: data.company_name,
          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportweekwisechart = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_WEEK,
        variables: {
          start_date: data.selectedStartDate,
          company_name: data.company_name,
          end_date: data.selectedEndDate,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportenvweekwisechart = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_ENV_WEEKWISE,
        variables: {
          start_date: data.start_date,
          company_name: data.company_name,
          end_date: data.end_date,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportenvweekwisechartsummary = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_ENV_WEEKWISE_SUMMARY,
        variables: {
          company_name: data.company_name,
          start_date: data.start_date,

          end_date: data.end_date,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportweekwisechartsummary = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_WEEKWISE_SUMMARY,
        variables: {
          start_date: data.start_date,
          company_name: data.company_name,
          end_date: data.end_date,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportweekwisechartsummarytop5 = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_WEEKWISE_SUMMARY_TOP,
        variables: {
          start_date: data.start_date,
          company_name: data.company_name,
          end_date: data.end_date,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportweekwisechartsummarytopincrease = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_WEEKWISE_SUMMARY_TOP_INCREASE,
        variables: {
          // start_date: data.selectedStartDate,
          start_date: data.start_date,
          company_name: data.company_name,
          // end_date: data.selectedEndDate,
          end_date: data.end_date,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportweekwisechartsummaryservice = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_WEEKWISE_SUMMARY_SERVICE,
        variables: {
          start_date: data.start_date,
          company_name: data.company_name,
          end_date: data.end_date,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportappsummary = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    console.log(data, "weeklyreportappsummary");
    try {
      const reportrecord = await client_new.query({
        query: WEEKLYREPORT_WEEKWISE_APP_SERIVCE,
        variables: {
          company_name: data.company_name,
          start_date: data.start_date,

          end_date: data.end_date,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportenvironment = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    console.log(data, "weeklyreportappsummary");
    try {
      const environmentcard = await client_new.query({
        query: ENVIRONMENT_DETAILES,
        variables: {
          company_name: data.company_name,
          start_date: data.start_date,

          end_date: data.end_date,
        },
      });
      return environmentcard;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const weeklyreportservicedetailed = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    console.log(data, "weeklyreportservicedetailed");
    try {
      const servicedetailed = await client_new.query({
        query: WEEKLY_DETAILED_SERVICE_REPORT,
        variables: {
          company_name: data.company_name,
          start_date: data.start_date,

          end_date: data.end_date,
        },
      });
      return servicedetailed;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const overallsummarytable = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    console.log(data, "overallsummary");
    try {
      const summaryoverall = await client_new.query({
        query: OVERALL_SUMMARY,
        variables: {
          company_name: data.company_name,
          date: data.date,
        },
      });
      console.log(summaryoverall);
      return summaryoverall;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const applicationlevel = createAsyncThunk(
  "weekly/report",
  async (data, { rejectWithValue }) => {
    console.log(data, "appoverall");
    try {
      const applicationsum = await client_new.query({
        query: APPLICATIONLEVELCHART,
        variables: {
          company_name: data.company_name,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      console.log(applicationsum);
      return applicationsum;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getWeeklyReportConsumptionSavingInitiativeSummary =
  createAsyncThunk("weekly/report", async (data, { rejectWithValue }) => {
    console.log(data, "overallsummary");
    try {
      const summaryoverall = await client_new.query({
        query: WEEKLYREPORTCONSUMPTION,
        variables: {
          company_name: data.company_name,
          month: data.selectedStartDate,
        },
      });
      console.log(summaryoverall);
      return summaryoverall;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  });

// report api
export const getEnvironmentWeeklyReport = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: ENVIRONMENT_WEEKLY_REPORT,
        variables: {
          start_date: data.selectedStartDate,
          company_name: data.company_name,
          end_date: data.selectedEndDate,
          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnvironmentMonthlyReport = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: ENVIRONMENT_MONTHLY_REPORT,
        variables: {
          month: data.selectedStartDate,
          company_name: data.company_name,

          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getApplicationWeeklyReport = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: APPLICATION_WEEKLY_REPORT,
        variables: {
          start_date: data.selectedStartDate,
          company_name: data.company_name,
          end_date: data.selectedEndDate,
          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getApplicationMonthlyReport = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: APPLICATION_MONTHLY_REPORT,
        variables: {
          month: data.selectedStartDate,
          company_name: data.company_name,

          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceWeeklyReport = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: SERVICE_WEEKLY_REPORT,
        variables: {
          start_date: data.selectedStartDate,
          company_name: data.company_name,
          end_date: data.selectedEndDate,
          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceMonthlyReport = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const reportrecord = await client_new.query({
        query: SERVICE_MONTHLY_REPORT,
        variables: {
          month: data.selectedStartDate,
          company_name: data.company_name,

          range: data.selectedRange,
        },
      });
      return reportrecord;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//service spend summary
{
  /* kamalika */
}
export const getservicetotalsummary = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const servicesummarytotal = await client_new.query({
        query: SERVICE_TOTAL_SPEND,
        variables: {
          company_name: data.company_name,
          end_date: data.end_date,
          start_date: data.start_date,
        },
      });
      return servicesummarytotal;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
{
  /* kamalika */
}
export const getapplicationtotal = createAsyncThunk(
  "monthly/report",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const applicationtotal = await client_new.query({
        query: APPLICATION_SUMMARY,
        variables: {
          company_name: data.company_name,
          end_date: data.end_date,
          start_date: data.start_date,
        },
      });
      return applicationtotal;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
