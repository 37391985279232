import { Table } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import ApplicationChart from "./ApplicationChart";
import { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Icon } from "@iconify/react";

const BudgetAllList = (props) => {
  const [chartdrawerstatus, setChartDrawerStatus] = useState(false);
  const [tabledata, setTableData] = useState(props.applicationbudgettable);
  // console.log("tablereacords");
  // console.log(tabledata);
  // console.log(props.applicationbudgettable);

  // const [annualdata, setAnnualData] = useState([63000,63000,63000,63000,63000,63000,63000,63000,63000,63000,63000,63000]);

  useEffect(() => {
    setTableData([
      ...props.applicationbudgettable,
      {
        key: "3",
        environment: "Remaining Budget",
        application: "",
        budget_jan:
          Number(props.annualplannedlist[0]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_jan),
            0
          ),
        budget_feb:
          Number(props.annualplannedlist[1]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_feb),
            0
          ),
        budget_mar:
          Number(props.annualplannedlist[2]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_mar),
            0
          ),
        budget_apr:
          Number(props.annualplannedlist[3]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_apr),
            0
          ),
        budget_may:
          Number(props.annualplannedlist[4]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_may),
            0
          ),
        budget_jun:
          Number(props.annualplannedlist[5]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_jun),
            0
          ),
        budget_jul:
          Number(props.annualplannedlist[6]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_jul),
            0
          ),
        budget_aug:
          Number(props.annualplannedlist[7]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_aug),
            0
          ),
        budget_sept:
          Number(props.annualplannedlist[8]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_sept),
            0
          ),
        budget_oct:
          Number(props.annualplannedlist[9]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_oct),
            0
          ),
        budget_nov:
          Number(props.annualplannedlist[10]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_nov),
            0
          ),
        budget_dec:
          Number(props.annualplannedlist[11]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_dec),
            0
          ),
        annual_total:
          props.annualplannedlist.reduce((a, b) => +a + +b, 0) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.annual_total),
            0
          ),
        action: "",
        type: "footer",
      },
    ]);
  }, [props]);

  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
    },
    {
      title: "Account",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Year",
      dataIndex: "budget_year",
      key: "budget_year",
    },
    {
      title: "Jan",
      dataIndex: "budget_jan",
      key: "budget_jan",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Feb",
      dataIndex: "budget_feb",
      key: "budget_feb",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Mar",
      dataIndex: "budget_mar",
      key: "budget_mar",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Apr",
      dataIndex: "budget_apr",
      key: "budget_apr",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "May",
      dataIndex: "budget_may",
      key: "budget_may",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Jun",
      dataIndex: "budget_jun",
      key: "budget_jun",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Jul",
      dataIndex: "budget_jul",
      key: "budget_jul",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Aug",
      dataIndex: "budget_aug",
      key: "budget_aug",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Sep",
      dataIndex: "budget_sept",
      key: "budget_sept",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Oct",
      dataIndex: "budget_oct",
      key: "budget_oct",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Nov",
      dataIndex: "budget_nov",
      key: "budget_nov",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Dec",
      dataIndex: "budget_dec",
      key: "budget_dec",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Annual Total",
      dataIndex: "annual_total",
      key: "annual_total",
      align: "right",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        if (index.type !== "footer") {
          if (props.rolestatus) {
            if (props.permission.budgetwrite) {
              return (
                <>
                  <Icon
                    onClick={() => props.editYearBudgetDetails(index)}
                    icon="material-symbols:edit"
                  />
                </>
              );
            } else {
              return <></>;
            }
          }
        }
      },
    },
  ];

  const tableProps = {};

  const changeChartDrawerStatus = () => {
    setChartDrawerStatus(false);
  };

  const applicationExport = () => {
    const tablelist = tabledata.map((data) => {
      return {
        Environment: data.environment,
        Application: data.application,
        Account: data.account,
        Jan: data.budget_jan,
        Feb: data.budget_feb,
        Mar: data.budget_mar,
        Apr: data.budget_apr,
        May: data.budget_may,
        Jun: data.budget_jun,
        Jul: data.budget_jul,
        Aug: data.budget_aug,
        Sep: data.budget_sept,
        Oct: data.budget_oct,
        Nov: data.budget_nov,
        Dec: data.budget_dec,
        "Annual Total": data.annual_total,
      };
    });
    const fileName = "Application Budget";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheettml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(tablelist);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <ApplicationChart
        chartdrawerstatus={chartdrawerstatus}
        changeChartDrawerStatus={changeChartDrawerStatus}
        setChartDrawerStatus={setChartDrawerStatus}
        applicationbudgettable={props.applicationbudgettable}
      />
 <div className="budget-search">
      <div className=" budget-float-right" style={{ display: "flex" }}>
        <div className="budget-table-top-icon" onClick={applicationExport}>
          <Icon icon="file-icons:microsoft-excel" style={{ fontSize: "16px", color: "#ff6a2f" }} />
        </div>
        <div
          className="budget-table-top-icon"
          onClick={() => setChartDrawerStatus(true)}
        >
          <Icon
            icon="mdi:chart-bell-curve"
            class="budget-table-chart-icon"
            style={{ fontSize: "16px", color: "#ff6a2f" }}
          ></Icon>
        </div>
        {props.rolestatus && props.permission.budgetwrite ? (
          <>
            <button
              className="table-add-button"
              onClick={() => props.addYearBudgetDetails()}
            >
              {/* <Icon icon="ion:add-circle-outline" /> */}
              <Icon icon="material-symbols:add-circle-outline"></Icon>
              Add
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      </div>

      <div className="costimize-table-width">
        <div className="applicationBudgetTable">
          <Table
            {...tableProps}
            // className="budget-table"
            className="slm"
            columns={columns}
            dataSource={tabledata}
            pagination={false}
          // rowClassName={(record, index) => {
          //     console.log(record);
          //     console.log(index);
          // }}
          />
        </div>
      </div>
    </>
  );
};

export default BudgetAllList;
