import React, { useState, useEffect } from "react";
import { Button, Row, Col, Space, Modal, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import {
  getwardashboardvalue,
  getdashboardlist,
} from "../action/graphql/a2crover/A2CReducer";
import { setSelectedWorkload } from "../slice/a2c_roverSlice";

function Dasboardwar() {
  const [openmodal, setOpenModal] = useState(false);
  const [assessmentData, setAssessmentData] = useState([]);
  const [assessmentName, setAssessmentName] = useState("");
  const [reviewType, setReviewType] = useState("");
  const [accountid, setAccountID] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState("");
  const [description, setDescription] = useState("");
  const [dashboard, setDashboard] = useState([]);
  const [dashboardlist, setDashboardList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setOpenModal(false);
  };
  const onFinish = (value) => {
    // console.log(value)
  };

  const handleworkload = () => {
    setOpenModal(true);
  };
  const { selectedTopdate } = useSelector((store) => store.a2cRover);
  var customer = localStorage.getItem("CognitoIdentityServiceProvider.company");

  useEffect(() => {
    const input = {
      customer: customer,
    };

    dispatch(getdashboardlist({ input }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "dashboardlist");
        // const wardashboardlist = data.get_war_workload.map((datas) => {
        const workloadNames = data.get_war_workload.map(
          (item) => item.workload_name
        );
        setDashboardList(workloadNames, "workloadNames");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); 
  

  const handleAssessmentSubmit = () => {
    const newAssessment = {
      assessmentName,
      reviewType,
      accountid,
      selectedEnvironment,
      description,
      customer,
      selectedTopdate,
    };

    dispatch(getwardashboardvalue(newAssessment))
      .unwrap()
      .then(({ data }) => {
        const updatedAssessmentName = data.assessmentName;

        const wardashboard = data.war_workload.map((datas) => {
          return {
            account_id: datas.account_id,
            created_date: datas.created_date,
            customer: datas.customer,
            description: datas.description,
            environment: datas.environment,
            review_type: datas.review_type,
            workload_name: datas.workload_name,
          };
        });
        setDashboard(wardashboard);
        setDashboardList((prevList) => [...prevList, updatedAssessmentName]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setAssessmentData([...assessmentData, newAssessment]);
        setOpenModal(false);
      });
  };


  const handleview = (e) => {
    console.log(e)
    dispatch(setSelectedWorkload(e))
    navigate("/realability");
  };

  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <A2CSidebar />
      <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
        {" "}
        <div className="cams-content">
          <div className="container-fluid">
            <div className="header-filter d-flex justify-content-between">
              <div className="header-title-a2c-main">WAR</div>
              <span className="d-flex align-items-end">
                {/* <A2cFilter /> */}
              </span>
            </div>
            <div className="">
              <Row justify={"space-between"}>
                <Col>
                  <h4 className="sub-header mt-2">Summary</h4>
                </Col>
                <Col>
                  <Button className="buttonclass" onClick={handleworkload}>
                    <Space>
                      <Icon icon="material-symbols:add-circle-outline" />
                      Create New Assesment
                    </Space>
                  </Button>
                </Col>
              </Row>

              <Modal
                className="costmodal"
                width="40%"
                title="Create New Assesment"
                open={openmodal}
                onCancel={handleCancel}
                footer={null}
              >
                <Form onFinish={onFinish}>
                  <div class="form-group titletab">
                    <label>Assessment name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Unique Identifier"
                      value={assessmentName}
                      onChange={(e) => setAssessmentName(e.target.value)}
                    />
                    {/* <p className="sub-header">
                      You can't change the workload name once you create the
                      workload
                    </p> */}
                  </div>
                  <div class="form-group mt-3">
                    <label className="titletab">Review Types</label>
                    <select
                      className="form-control "
                      placeholder="select Review Type"
                      value={reviewType}
                      onChange={(e) => setReviewType(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Review Type
                      </option>
                      <option value="type1">WAR</option>
                    </select>
                  </div>

                  <div class="form-group titletab mt-3">
                    <label>Account ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account ID"
                      value={accountid}
                      onChange={(e) => setAccountID(e.target.value)}
                    />
                  </div>
                  <div class="form-group mt-3">
                    <label className="titletab">Select Environment</label>
                    <select
                      className="form-control "
                      placeholder="select Review Types"
                      value={selectedEnvironment}
                      onChange={(e) => setSelectedEnvironment(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Select Environment
                      </option>
                      <option>Prod</option>
                      <option>Non-Prod</option>
                    </select>
                  </div>
                  <div class="form-group mt-3">
                    <label className="titletab">Description</label>
                    <textarea
                      class="form-control"
                      placeholder="Please Enter brief description of the Assesment"
                      rows="3"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mt-3 ">
                    {/* <button
                      type="button"
                      class="btn btn-secondary btn-lg btn-block mt-3"
                    >
                      Specify Workload Resource
                    </button> */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="buttonclass mt-3"
                        onClick={handleAssessmentSubmit}
                      >
                        CREATE
                      </Button>
                      <Button className="mt-3 ms-3" onClick={handleCancel}>
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </Form>
              </Modal>
              {dashboardlist.map((workload, index) => (
                <Row gutter={[16, 16]} key={index}>
                  <Col span={18}>
                    <hr />
                    <h6 className="titletab titlefont">{workload}</h6>
                  </Col>
                  <Col span={2}>
                    <h6
                      className="mt-4 titletab titlefont"
                      // onClick={handleview}
                      onClick={()=>handleview(workload)}

                      style={{ cursor: "pointer" }}
                    >
                      <Space>
                        <Icon icon="ep:view"  />
                        {/* <Icon icon="ep:view" /> */}
                        Review <Space>|</Space>
                      </Space>
                    </h6>
                  </Col>
                  <Col span={2}>
                    <h6
                      className="mt-4 ms-2 titletab titlefont"
                      // onClick={handledit}
                      style={{ cursor: "pointer" }}
                    >
                      <Space>
                        <Icon
                          icon="fluent:edit-16-regular"
                          // onClick={handledit}
                        />
                        Edit <Space>|</Space>
                      </Space>
                    </h6>
                  </Col>
                  <Col span={2}>
                    <h6
                      className="mt-4 ms-2 titletab titlefont"
                      style={{ cursor: "pointer" }}
                    >
                      <Space>
                        <Icon
                          style={{ color: "#ff6a2f" }}
                          icon="codicon:trash"
                        />
                        Delete
                      </Space>
                    </h6>
                  </Col>
                </Row>
              ))}
            </div>

            <br />
            {/* Graph */}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Dasboardwar;
