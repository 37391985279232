import React from "react";
import { Table } from "antd";
import "./cost.css";
function SavingTracker() {
    const mainData=[
        {
            key:'1',
            Application:'Archive',
            Environment:'Prod',
            CloudPlatform:'Azure',
      Account:'His-Non PROD',
      TrackingSince:'23',
      AccountId:'21453456',
      TotalSpent:'3786,',
      RealisedSavings:'2367',
      LostOpportunity:'',
      ProjectedAnnualSpend:'23746',
      PotentialAnnualSavings:'3784',








        }
    ];
    
    const expandableData = [
        {
          key: '1',
          address: 'New York',
          email: 'john@example.com',
          phone: '123-456-7890',
        },
        {
          key: '2',
          address: 'Los Angeles',
          email: 'alice@example.com',
          phone: '987-654-3210',
        },
      ]
    const expandableColumns = [
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
      ];
  const columns = [
    {
      title: "Application",
      dataIndex: "Application",
      key: "Application",
    },
    {
      title: "Environment",
      dataIndex: "Environment",
      key: "Environment",
    },
    {
      title: "Cloud Platform",
      dataIndex: "CloudPlatform",
      key: "CloudPlatform",
    },
    {
      title: "Account",
      dataIndex: "Account",
      key: "Account",
    },
    {
      title: "Tracking Since",
      dataIndex: "TrackingSince",
      key: "TrackingSince",
    },

    {
      title: "Account Id",
      dataIndex: "AccountId",
      key: "AccountId",
    },
    {
      title: "Total Spent",
      dataIndex: "TotalSpent",
      key: "TotalSpent",
    },
    {
      title: "Realised Savings",
      dataIndex: "RealisedSavings",
      key: "RealisedSavings",
    },
    {
      title: "Lost Opportunity",
      dataIndex: "LostOpportunity",
      key: "LostOpportunity",
    },
    {
      title: "Projected Annual Spend",
      dataIndex: "ProjectedAnnualSpend",
      key: "ProjectedAnnualSpend",
    },
    {
      title: "Potential Annual Savings",
      dataIndex: "PotentialAnnualSavings",
      key: "PotentialAnnualSavings",
    },
  ];

  return (
    <div>
      <div className="savingtab">
        <Table columns={columns} dataSource={mainData}  expandable={{
        expandedRowRender: (record) => (
          <Table
            dataSource={expandableData.filter((data) => data.key === record.key)}
            columns={expandableColumns}
            pagination={false}
          />
        ),
        rowExpandable: (record) => true,
      }}></Table>
      </div>
    </div>
  );
}

export default SavingTracker;
