import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { listRealizationChart } from "../../action/costimizeV2Active";
import { Spin } from "antd";

var colors = ["#F4D772", "#BB3D9F", "#5228AC"];

const SavingsRealizedChart = () => {
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  //stats
  const [tablerecords, setTableRecords] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [lchartstatus, setLChartStatus] = useState(true);

  useEffect(() => {
    setLChartStatus(true);
    const monthlist = [];
    for (let index = 0; index < 12; index++) {
      monthlist.push(`${new Date().getFullYear()}-${+index + +1}-01`);
    }
    const apivalue = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
      month: monthlist.toString(),
      os: selectedos.toString(),
      db: selecteddb.toString(),
      infra: selectedinfra.toString(),
      service: selectedservices.toString(),
    };
    dispatch(listRealizationChart({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data.newfunctionquery11);
        var chartlist = [];
        for (let index = 0; index < 12; index++) {
          const datalist = data.newfunctionquery11.filter((v) => {
            if (
              `${new Date(v.report_month).getFullYear()}-${
                new Date(v.report_month).getMonth() + 1
              }-01` === `${new Date().getFullYear()}-${+index + +1}-01`
            ) {
              return v;
            }
          });
          if (datalist.length > 0) {
            chartlist.push(Math.round(datalist[0].realised_savings));
          } else {
            chartlist.push(0);
          }
        }
        console.log(chartlist);
        setTableRecords(chartlist);
        setLChartStatus(false);
      })
      .catch((err) => {
        setLChartStatus(false);
        console.log(err.message);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedEnvironment,
    selectedTopdate,
    selectedos,
    selecteddb,
    selectedinfra,
    selectedservices,
  ]);
  const options = {
    chart: {
      type: "area",
      // height: 600,
      // offsetY: 5,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: false,
      curve: "smooth",
    },
    grid: {
      show: false,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    colors: ["#FF6A2F"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisBorder: {
        show: true,
        color: "#78909C",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        offsetX: 0,
        offsetY: 0,
        //   rotate: -45,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          colors: "white",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          fontSize: "8px",
          fontWeight: 400,
          colors: "white",
        },
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Savings Realisation",
      align: "center",
      margin: 0,
      offsetX: 0,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "12px",
        fontWeight: "500",
        color: "#FFF",
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };
  console.log(tablerecords);
  const series = [
    {
      name: "Realised Savings",
      data: tablerecords,
    },
  ];
  return (
    <>
      <Spin spinning={lchartstatus} wrapperClassName="costimize-loader">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={170}
        />
      </Spin>
    </>
  );
};

export default memo(SavingsRealizedChart);
