import { Button, Col, Form, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addConfigureForm,
  addUpdateForm,
  saveUpdateConfigureForm,
  sendEmailStatus,
} from "../../../action/graphql/devops/DevOpsAction";
import { reset, setbackButtonClickAction } from "../../../slice/devopsSlice";
import { loginUser } from "../../../action/userAction";
function PreviewForm(props) {
  const navigateFn = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const devopsStore = useSelector((state) => state.devops);
  const [basicDetailsInfo, setBasicDetailInfo] = useState(
    devopsStore.saveBasicDetailsData.basicDetailsInfo
  );
  const [configDetailsInfo, setConfigDetailsInfo] = useState(
    devopsStore.saveBasicDetailsData.configDetailsInfo
  );
  const [accountDetailsInfo, setAccountDetailsInfo] = useState(
    devopsStore.saveBasicDetailsData.accountInfo
  );
  const [created_by_user, setCreatedByUser] = useState("");
  const getCreatedByUser = () => {
    // to get createdby user
    let user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
    let token = localStorage.getItem("_token");
    let created_user;
    const records = {
      user_id: user_id,
      _token: token,
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        console.log({ data });
        created_user = data.getUsers.email_id;
        setCreatedByUser(created_user);
      });
  };
  useEffect(() => {
    getCreatedByUser();
  }, []);
  const [refreshModalOpen, setrefreshModalOpen] = useState(false);

  const [isShowButtonLoader, setIsShowButtonLoader] = useState(false);

  const handleSubmit = (values) => {
    setIsShowButtonLoader(true);
    console.log(basicDetailsInfo);
    values.created_by = created_by_user;
    const companyName = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    values.customer_name = companyName;
    setrefreshModalOpen(true);
    console.log("testing");
    // setrefreshModalOpen(true);
    if (devopsStore.requestorTableData.id) {
      values.id = devopsStore.requestorTableData.id;
    } else {
      values.id = devopsStore.saveBasicDetailsData.configDetailsInfo.id;
    }
    values.status = "Approval Pending";
    if (devopsStore.requestAction === "addNew") {
      values.event_type = "update_status";
    } else {
      values.event_type = "update_status";
    }
    dispatch(
      sendEmailStatus({
        event_type: "send_approval",
        accountDetailsInfo,
        values,
        basicDetailsInfo,
      })
    )
      .unwrap()
      .then(({ data }) => {
        setIsShowButtonLoader(false);

        // navigateFn("/devops-requestor");
      });
    const jobName = devopsStore.jobName;
    const configurations = devopsStore.saveBasicDetailsData.configDetailsInfo;

    dispatch(
      addConfigureForm({
        values,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log({ data });
        setIsShowButtonLoader(false);
        dispatch(reset());
        setrefreshModalOpen(true);
        // navigateFn("/devops-requestor")
      });
  };
  const handleCancel = () => {
    setrefreshModalOpen(false);
  };
  const handleDashboardClick = () => {
    setrefreshModalOpen(false);
    navigateFn("/devops-requestor");
  };
  const removeSpaces = (value) => {
    let str = value.replace(/_/g, " ");
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  };
  const handleBackButton = () => {
    dispatch(setbackButtonClickAction(true));
    props.setCurrent(props.current - 1);
  };

  return (
    <>
      <Form onFinish={handleSubmit} form={form}>
        <div className="basic-details">
          {/* 
          <div className="getting-cost-details">
            <Row gutter={[16, 16]} className="d-flex justify-content-center">
              <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                Cost details
              </Col>
            </Row>
          </div>
           */}

          <Row gutter={[16, 16]} className="d-flex justify-content-center">
            <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
              {Object.entries(basicDetailsInfo).map((item) => (
                <>
                  {item[0] == "status" || item[0] == "event_type" ? (
                    ""
                  ) : (
                    <>
                      <Row gutter={[16, 16]} className="devops-preview-rows">
                        <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                          <span className="sub-heading-devops-config">
                            {removeSpaces(item[0])}
                          </span>
                        </Col>
                        <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                          <span className="sub-heading-devops-config"> :</span>
                        </Col>
                        <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                          <span className="sub-heading-devops-config">
                            {item[1]}
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ))}
              {Object.entries(configDetailsInfo).map((item) => (
                <>
                  {item[1] && (
                    <>
                      {item[0] == "status" ||
                      item[0] == "event_type" ||
                      item[0] == "id" ? (
                        ""
                      ) : item[0] == "admin_password" ? (
                        <>
                          <Row
                            gutter={[16, 16]}
                            className="devops-preview-rows"
                          >
                            <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                              <span className="sub-heading-devops-config">
                                {removeSpaces(item[0])}
                              </span>
                            </Col>
                            <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                              <span className="sub-heading-devops-config">
                                {" "}
                                :
                              </span>
                            </Col>
                            <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                              <span className="sub-heading-devops-config">
                                * * * * * * * * * *
                              </span>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row
                            gutter={[16, 16]}
                            className="devops-preview-rows"
                          >
                            <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                              <span className="sub-heading-devops-config">
                                {removeSpaces(item[0])}
                              </span>
                            </Col>
                            <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                              <span className="sub-heading-devops-config">
                                :
                              </span>
                            </Col>
                            <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                              <div style={{ wordWrap: "break-word" }}>
                                <span className="sub-heading-devops-config">
                                  {item[1]}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </>
              ))}
            </Col>
          </Row>
        </div>
        <br />
        <Row gutter={[16, 16]} justify="end">
          <Col span={24}>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                htmlType="button"
                className="form-back-button"
                onClick={() => handleBackButton()}
              >
                Back
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="form-button save-form-button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {isShowButtonLoader ? (
                  <>
                    <div className="d-flex">
                      <div className="">
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  " Submit"
                )}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        className="devops-modal"
        open={refreshModalOpen}
        // onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleDashboardClick}
        closable={false}
        okButtonProps={{
          style: {
            background:
              "linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)",
            color: "white",
          },
        }}
        // footer={null}
        width={400}
      >
        <div className="p-4">
          <Row>
            <Col>
              {" "}
              <span className="text-white sub-heading-devops-config">
                Your request has been created Successfully.
              </span>
            </Col>
          </Row>
          <br />
        </div>
      </Modal>
    </>
  );
}

export default PreviewForm;
