import ApplicationLevelDeatiledView from "./ApplicationLevelDeatiledView";
import apexcharts from "apexcharts";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Companylogo from "../../costimize_v2/WeeklyReport/company_logo.png";
import CHLOGO from "../../costimize_v2/WeeklyReport/onechlogo.jpg";
import ServiceLevelDetaileView from "./ServiceLevelDetaileView";
import { Card, Col, Row, Switch } from "antd";
import { headercustomerlogo } from "../../action/header_logo";
import { useLocation } from "react-router-dom";

import Reportdata from "./Reportdata";
function DetailedReport() {
  //   const dispatch = useDispatch();

  //   const [company_logo, setCompanyLogo] = useState();
  //   const [reportDetailedService, setReportdetailedservice] = useState([]);
  //   const [reportappwise, setReportweekappservice] = useState([]);
  //   const company_name = localStorage.getItem(
  //     "CognitoIdentityServiceProvider.company"
  //   );
  //   useEffect(() => {
  //     dispatch(headercustomerlogo({ company_name }))
  //       .unwrap()
  //       .then(({ data }) => {
  //         var encodedStringAtoB = data.getCustomerLogo.platform_logo;
  //         var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
  //         setCompanyLogo(testimg);
  //       })
  //       .catch((err) => {
  //         //.log(err);
  //       });
  //   }, []);
  //   const location = useLocation();
  //   const { startDate, endDate } = location.state || {};
  const [enddate, setenddate] = useState([]);
  const [startDate, setstartdate] = useState([]);
  return (
    // <div>

    // </div>
    // <div>

    //   <div className="page">
    //     <div class="horizontal-bar"></div>
    //     <div className="scroll" id="appdetail">
    //       <Row>
    //         <Col span={18}>
    //           {Companylogo && (
    //             <img className="costimize_header_logo" src={Companylogo} />
    //           )}
    //         </Col>
    //         <Col span={6}>
    //           <h5 className="boldheading">Weekly Consumption Report</h5>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col span={20}></Col>
    //         <Col span={4}>
    //           <a href="#" className="linktag">
    //             Back to Home
    //           </a>
    //         </Col>
    //       </Row>
    //       <h4 className="subtableheading">
    //         {" "}
    //         Detailed view of VM along with Application and Spend: <br></br>
    //       </h4>
    //       <Row className="chartrow">
    //         <Col span={24}>
    //           <ApplicationLevelDeatiledView
    //             applicationweekly={reportappwise}
    //             selectedDate={selectedDate}
    //             endDate={endDate}
    //           />
    //         </Col>
    //       </Row>
    //     </div>
    //     <hr className="ruler footerruler"></hr>
    //     <Row className="footerrow">
    //       <Col span={12}>
    //         <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
    //       </Col>
    //       <Col span={10}>
    //         <p>-1-</p>
    //       </Col>
    //       <Col span={2}>
    //         <p>Confidential</p>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* kamalika */}

    //   <div className="page">
    //     <div class="horizontal-bar"></div>
    //     <div className="scroll" id="services">
    //       <Row>
    //         <Col span={18}>
    //           {company_logo && (
    //             <img className="costimize_header_logo" src={company_logo} />
    //           )}
    //         </Col>
    //         <Col span={6}>
    //           <h5 className="boldheading">Weekly Consumption Report</h5>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col span={20}></Col>
    //         <Col span={4}>
    //           <a href="#" className="linktag">
    //             Back to Home
    //           </a>
    //         </Col>
    //       </Row>
    //       <h4 className="subtableheading">
    //         {" "}
    //         Detailed view of along with Service and Spend <br></br>
    //       </h4>
    //       <Row className="chartrow">
    //         <Col span={24}>
    //           {/* <ServiceLevelDetaileView
    //               servicelevelweekly={reportweekwiseappsumservice}
    //             /> */}
    //           <ServiceLevelDetaileView
    //             servicelevelweekly={reportDetailedService}
    //             selectedDate={selectedDate}
    //             endDate={endDate}
    //           />
    //         </Col>
    //       </Row>

    //       {/* //New page */}
    //     </div>
    //     <hr className="ruler footerruler"></hr>
    //     <Row className="footerrow">
    //       <Col span={12}>
    //         <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
    //       </Col>
    //       <Col span={10}>
    //         <p>-2-</p>
    //       </Col>
    //       <Col span={2}>
    //         <p>Confidential</p>
    //       </Col>
    //     </Row>
    //   </div>
    // </div>

    <div>
      <Reportdata setenddate={setenddate} setstartdate={setstartdate} />
      <h2>Start Date: {startDate}</h2>
      <h2>End Date: {enddate}</h2>
    </div>
  );
}

export default DetailedReport;
