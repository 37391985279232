import { memo, useEffect, useState } from "react";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import MainHead from "../layout/MainHead";
import { Tabs, Row, Col, Input } from "antd";
import { Icon } from "@iconify/react";
import AssetSpendExport from "./AssetSpendExport";
import AssetSpendList_new from "./AssetSpendList_new";
import AssetSpendTopListChart from "./AssetSpendTopListChart";
import AssetSpendListDB from "./AssetSpendListDB";
import AssetSpendListOthers from "./AssetSpendListOthers";
import OldRecords from "./OldRecords";
import Kubernetes_CostDemaze from "./kubernetesCostDemaze/Kubernetes_CostDemaze";

const AssetSpendLayout = () => {
  const [topchartstatus, setTopChartStatus] = useState(false);
  const [records, setRecords] = useState([]);
  const [applicationlist, setApplicationList] = useState([]);
  const [viewStatus, setViewStatus] = useState(false);
  const [infrochart, setInfraChartDetails] = useState({
    categories: [],
    vmlist: [],
    dblist: [],
    others: [],
  });
  const [spendchart, setSpendChartDetails] = useState({
    categories: [],
    value: [],
  });
  const [chartspend, setChartAllSpend] = useState([]);
  const [chartcount, setChartAllCount] = useState([]);
  useEffect(() => {
    // console.log(records);
    // console.log(applicationlist);
    var records_list_count = [];
    var reacords_list_spend = [];
    applicationlist.map((application) => {
      // if(application.toLowerCase() === )
      const list_records = records?.filter((datas) => {
        return datas.application === application.toLowerCase();
      });
      // console.log(list_records);
      const spendtotal = list_records.reduce((a, v) => (a = +a + +v.spend), 0);
      // console.log(spendtotal);

      records_list_count.push({
        label: application,
        value: list_records.length,
      });
      reacords_list_spend.push({
        label: application,
        value: Math.round(spendtotal),
      });
    });
    const records_list = records_list_count.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    const records_list_spend = reacords_list_spend.sort((a, b) =>
      a.value > b.value ? -1 : 1
    );
    // console.log(records_list_spend);
    var cate_list = [];
    var vmcount = [];
    records_list.map((data, index) => {
      if (index < 5) {
        cate_list.push(data.label);
        vmcount.push(data.value);
      }
    });
    setInfraChartDetails({
      categories: cate_list,
      vmlist: vmcount,
      dblist: [0, 0, 0, 0, 0],
      others: [0, 0, 0, 0, 0],
    });

    var spend_cate_list = [];
    var spend_list = [];
    records_list_spend.map((data, index) => {
      if (index < 5) {
        spend_cate_list.push(data.label);
        spend_list.push(data.value);
      }
    });
    setSpendChartDetails({ categories: spend_cate_list, value: spend_list });
    // console.log({ categories: spend_cate_list, value: spend_list });

    // console.log({
    //   categories: cate_list,
    //   vmlist: vmcount,
    //   dblist: [0, 0, 0, 0, 0],
    // others: [0, 0, 0, 0, 0],
    // });
  }, [records]);
  const items = [
    {
      key: "1",
      label: `VM`,
      children: (
        <div className="assetspendslm">
          <AssetSpendList_new
            type="vm"
            className="slm"
            setRecords={setRecords}
            setApplicationList={setApplicationList}
            setViewStatus={setViewStatus}
            viewStatus={viewStatus}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: `DBaas`,
      children: (
        <div className="assetspendslm">
          {" "}
          <AssetSpendListDB
            setChartAllSpend={setChartAllSpend}
            setChartAllCount={setChartAllCount}
            type="db"
          />
        </div>
      ),
    },
    {
      key: "3",
      label: `Kubernetes`,
      children: (
        <div className="assetspendslm">
          <Kubernetes_CostDemaze className="slm" />
        </div>
      ),
    },
    {
      key: "4",
      label: `Others`,
      children: (
        <div className="assetspendslm">
          <AssetSpendListOthers
            setChartAllSpend={setChartAllSpend}
            setChartAllCount={setChartAllCount}
            type="others"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {topchartstatus && (
        <AssetSpendTopListChart
          topchartstatus={topchartstatus}
          setTopChartStatus={setTopChartStatus}
          infrochart={infrochart}
          spendchart={spendchart}
          chartspend={chartspend}
          chartcount={chartcount}
        />
      )}

      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <OldRecords
          setChartAllSpend={setChartAllSpend}
          setChartAllCount={setChartAllCount}
        />
        <CostimizeSidebar />
        <MainHead title="Asset/Spend" />
        <div className="costimize-body-screen">
          <Row style={{ display: "block" }}>
            <Col span={24} className="spend-asset-body">
              <div className="spend-search">
                <div>{/* <AssetSpendExport /> */}</div>
                {/* <div
                  className="plat-icon-bg"
                  onClick={() => setViewStatus(!viewStatus)}
                >
                  <Icon
                    icon="material-symbols:add"
                    style={{ fontSize: "20px" }}
                  />
                </div> */}
                <div
                  className="plat-icon-bg"
                  onClick={() => setTopChartStatus(true)}
                >
                  <Icon
                    icon="mdi:chart-bar-stacked"
                    style={{ fontSize: "20px" }}
                    className="factory-icon"
                  />
                </div>

                {/* <Input
                  placeholder="Search"
                  style={{
                    width: "191px",
                    height: "27px",
                    boxShadow: "0px 4px 4px rgba(217, 217, 217, 0.6)",
                  }}
                /> */}
              </div>
              <div className="spend-tab">
                <Tabs
                  type="line"
                  defaultActiveKey="1"
                  items={items}
                  className="cams-tabs"
                  // className="assetspend-costimize-table-tab"
                  //  className="costimize-table-tab"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default memo(AssetSpendLayout);
