import { Icon } from "@iconify/react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  createIdentifier,
  listEventIdentifier,
  updateIdentifier,
} from "../../../action/graphql/peems/PeemsAPI";
import { useDispatch } from "react-redux";

function EventIdentifierHome() {
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const columns = [
    // {
    //   key: 1,
    //   name: "event_id",
    //   title: "Event Id ",
    //   dataIndex: "rule_id",
    //   fixed: "left",
    // },

    {
      key: 2,
      name: "key",
      title: "Key",
      dataIndex: "key",
      fixed: "left",
    },
    {
      key: 3,
      name: "contains",
      title: "Contains",
      dataIndex: "condition",
      fixed: "left",
    },
    {
      key: 4,
      name: "value",
      title: "Value",
      dataIndex: "value",
      fixed: "left",
    },

    {
      key: 6,
      name: "action",
      title: "",
      dataIndex: "action",
      fixed: "left",
      render: (text, record, index) => {
        console.log(record);
        return (
          <>
            <Icon
              icon="material-symbols:edit"
              onClick={() => handleEditClick(record)}
            />
          </>
        );
      },
    },
  ];

  const handleAddClick = () => {
    form.resetFields();
    setEditStatus(false);
    setDrawerStatus(true);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    setIsShowSpinner(true);
    console.log(values);
    if (editStatus) {
      values.identifier_type = "event";
      values.event_type = "update_identifier";
      dispatch(updateIdentifier({ values, companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          // let response = data.listPEEMSRules.items;
          // setTableData(response);
          setTableData([]);
          getEventIdentifier();
          setIsShowSpinner(false);
          setDrawerStatus(false);
          setEditStatus(false);
        });
    } else {
      values.identifier_type = "event";
      values.event_type = "create_identifier";
      dispatch(createIdentifier({ values, companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          // let response = data.listPEEMSRules.items;
          // setTableData(response);
          setTableData([]);

          getEventIdentifier();
          setIsShowSpinner(false);
          isModalOpen(false);
        });
    }
  };
  const rowDelete = () => {};
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const [editStatus, setEditStatus] = useState(false);
  const handleEditClick = (record) => {
    form.resetFields();
    console.log(record);
    setSelectedRow(record);
    setEditStatus(true);
    form.setFieldsValue(record);
    setDrawerStatus(true);
    setIsModalOpen(true);
  };
  const [selectedRow, setSelectedRow] = useState("");
  const getEventIdentifier = () => {
    dispatch(listEventIdentifier({ companyName }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        let response = data.listPEEMSRules.items;
        setTableData(response);
        setIsShowSpinner(false);
      });
  };
  useEffect(() => {
    getEventIdentifier();
  }, []);
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  return (
    <>
      <Modal
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        centered
        className="bgpopup"
        title=" Event Identifier"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onOk={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="key" label="Key">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="condition" label="Condition">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  options={[
                    {
                      label: "contains",
                      value: "contains",
                    },
                    {
                      label: "equals",
                      value: "equals",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="value" label="Value">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div onClick={() => handleAddClick()}>
        <button className="table-add-button float-right">
          <Icon icon="material-symbols:add-circle-outline" />
          Add
        </button>
      </div>
      <Table
        className="peems"
        columns={columns}
        pagination={true}
        loading={{ indicator: <Spin />, spinning: isShowSpinner }}
        dataSource={tableData}
      />
    </>
  );
}

export default EventIdentifierHome;
