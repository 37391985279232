import { memo, useEffect, useState } from "react";
import { Col, Row, Modal, Tooltip, Spin, Card, Table, Button } from "antd";
import { Icon } from "@iconify/react";
import TaggingPopupUpdated from "./TaggingPopupUpdated";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaginationCount,
  getTagginglist,
} from "../../action/costimizeV2Active";
import { saveAs } from "file-saver";
import CostimizeFilter from "../layout/CostimizeFilter";

import { setSelectedRowList } from "../../slice/costimizeSlice";

const TaggingPopupTable = (props) => {
  const handleCancel = () => {
    props.setTablePopupStatus(false);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedrowvalue, setSelectedRowValue] = useState([]);
  const [editloading, setEditLoading] = useState(false);
  const [deleteloading, setDeleteLoading] = useState(false);
  const [downloadloading, setDownloadLoading] = useState(false);
  const [updatepopupstatus, setUpdatePopupStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const {
    selectedrowlist,
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const [tablerecords, setTableRecords] = useState([]);
  const [loaderstatus, setLoaderStatus] = useState(true);
  const [paginationrecords, setPaginationRecords] = useState({
    page: 1,
    count: 10,
  });
  const [paginationtotal, setPaginationTotal] = useState(0);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    const date = selectedTopdate;
    const tagging_details = props.tabletype.type;
    const service_name = props.tabletype.records.servicename;
    const tag_category = props.tabletype.category;
    const count = paginationrecords.count;
    const environment = selectedEnvironment.toString();
    const account_name = selectedAccount.toString();
    const cloud = selectedCloud.toString();
    const application = selectedApplication.toString();
    const os = selectedos.toString();
    const infra = selectedinfra.toString();
    const db = selecteddb.toString();
    dispatch(
      getPaginationCount({
        tagging_details,
        service_name,
        tag_category,
        companyName,
        date,
        count,
        account_name,
        application,
        cloud,
        environment,
        infra,
        os,
        db,
      })
    )
      .unwrap()
      .then(({ data }) => {
        setPaginationTotal(
          Math.round(data.newfunctionquery9[0].total_pages) *
            paginationrecords.count
        );
        // console.log(
        //   Math.round(data.newfunctionquery9[0].total_pages) *
        //     paginationrecords.count
        // );
      })
      .catch((err) => {
        setDownloadLoading(false);
        console.log(err.message);
      });
  }, [paginationrecords.count]);
  useEffect(() => {
    const date = selectedTopdate;
    const tagging_details = props.tabletype.type;
    const service_name = props.tabletype.records.servicename;
    const tag_category = props.tabletype.category;
    const page = paginationrecords.page;
    const count = paginationrecords.count;
    const environment = selectedEnvironment.toString();
    const account_name = selectedAccount.toString();
    const cloud = selectedCloud.toString();
    const application = selectedApplication.toString();
    const os = selectedos.toString();
    const infra = selectedinfra.toString();
    const db = selecteddb.toString();

    setLoaderStatus(true);
    dispatch(
      getTagginglist({
        tagging_details,
        service_name,
        companyName,
        date,
        page,
        tag_category,
        count,
        account_name,
        application,
        cloud,
        environment,
        infra,
        os,
        db,
      })
    )
      .unwrap()
      .then((taggin_data) => {
        console.log(taggin_data.data.newfunctionquery7);
        const tagging_records = taggin_data.data.newfunctionquery7?.map(
          (data) => {
            return {
              key: makeid(10),
              account_id: data.account_id,
              services: data.services,
              resource_name: data.resourcename,
              resource_id: data.resourceid,
              application: data.application,
              environment: data.environment,
            };
          }
        );
        setTableRecords(tagging_records);
        setLoaderStatus(false);
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log(err.message);
      });
  }, [paginationrecords, updatestatus]);

  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  const editSelectedrow = () => {
    // ajax request after empty completing
    setUpdatePopupStatus(true);
    // setEditLoading(true);

    setTimeout(() => {
      setSelectedRowKeys([]);
      setEditLoading(false);
    }, 1000);
  };

  const deleteSelectedRow = () => {
    setDeleteLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setDeleteLoading(false);
    }, 1000);
  };
  const downloadSelectedRow = () => {
    getPageRecords(5000, true);
  };
  const getPageRecords = (count, recordsliststatus) => {
    setDownloadLoading(true);
    const date = selectedTopdate;
    const tagging_details = props.tabletype.type;
    const service_name = props.tabletype.records.servicename;
    const tag_category = props.tabletype.category;
    const environment = selectedEnvironment.toString();
    const account_name = selectedAccount.toString();
    const cloud = selectedCloud.toString();
    const application = selectedApplication.toString();
    const os = selectedos.toString();
    const infra = selectedinfra.toString();
    const db = selecteddb.toString();
    dispatch(
      getPaginationCount({
        tagging_details,
        service_name,
        tag_category,
        companyName,
        date,
        count,
        account_name,
        application,
        cloud,
        environment,
        infra,
        os,
        db,
      })
    )
      .unwrap()
      .then(({ data }) => {
        const pagecount = Array.from(
          { length: Math.round(data.newfunctionquery9[0].total_pages) },
          (v, k) => k + 1
        );
        if (recordsliststatus) {
          var excelrecords = [];
          const loopResult = pagecount.reduce(async (previous, value) => {
            await previous;
            // Return new promise
            return new Promise((resolve) => {
              const page = value;
              const environment = selectedEnvironment.toString();
              const account_name = selectedAccount.toString();
              const cloud = selectedCloud.toString();
              const application = selectedApplication.toString();
              const os = selectedos.toString();
              const infra = selectedinfra.toString();
              const db = selecteddb.toString();

              dispatch(
                getTagginglist({
                  tagging_details,
                  service_name,
                  companyName,
                  date,
                  page,
                  tag_category,
                  count,
                  account_name,
                  application,
                  cloud,
                  environment,
                  infra,
                  os,
                  db,
                })
              )
                .unwrap()
                .then((taggin_data) => {
                  // console.log(taggin_data);
                  const tagging_records =
                    taggin_data.data.newfunctionquery7.map((data) => {
                      return {
                        AccountID: data.account_id,
                        Services: data.services,
                        ResourceName: data.resourcename,
                        ResourceID: data.resourceid,
                        Application: data.application,
                        Environment: data.environment,
                        TaggingDetails: data.tagging_details,
                      };
                    });
                  var newrecords = excelrecords.concat(tagging_records);
                  // console.log(newrecords);
                  excelrecords = newrecords;
                  resolve();
                })
                .catch((err) => {
                  setDownloadLoading(false);
                  console.log(err.message);
                });
            });
          }, Promise.resolve());

          loopResult.then(() => {
            setDownloadLoading(false);
            // console.log(excelrecords, service_name, tagging_details);
            downloadCSV(excelrecords, service_name, tagging_details);
          });
        }
      })
      .catch((err) => {
        setDownloadLoading(false);
        console.log(err.message);
      });
  };
  function downloadCSV(excelrecords, service_name, tagging_name) {
    const data = excelrecords;
    const filename = `${tagging_name}_${service_name}.csv`;
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
    setDownloadLoading(false);
  }
  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  }
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // console.log(selectedrowlist);
    // console.log(selectedrowvalue);
    setSelectedRowValue(selectedRows);
    // dispatch(setSelectedRowList())
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "Account ID",
      dataIndex: "account_id",
      //   filters: [
      //     {
      //       text: "London",
      //       value: "London",
      //     },
      //     {
      //       text: "New York",
      //       value: "New York",
      //     },
      //   ],
      //   onFilter: (value, record) => record.address.startsWith(value),
      //   filterSearch: true,
    },
    {
      title: "Services",
      dataIndex: "services",
      // render: (record) => {
      //   if (record) {
      //     if (record.length > 10) {
      //       return (
      //         <>
      //           <Tooltip placement="bottom" title={record}>
      //             <li className="tagging-popup-service">
      //               <div> {record.substring(0, 10)}...</div>
      //             </li>
      //           </Tooltip>
      //         </>
      //       );
      //     } else {
      //       return (
      //         <>
      //           <li className="tagging-popup-service">
      //             <div>{record}</div>
      //           </li>
      //         </>
      //       );
      //     }
      //   }
      // },
    },
    {
      title: "Resource Name",
      dataIndex: "resource_name",
      render: (record) => {
        if (record) {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  <li className="tagging-popup-service">
                    <div> {record.substring(0, 10)}...</div>
                  </li>
                </Tooltip>
              </>
            );
          } else {
            return (
              <>
                <li className="tagging-popup-service">
                  <div>{record}</div>
                </li>
              </>
            );
          }
        }
      },
    },
    {
      title: "Resource ID",
      dataIndex: "resource_id",
      render: (record) => {
        if (record) {
          if (record.length > 70) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  <li className="tagging-popup-service">
                    <div> {record.substring(0, 70)}...</div>
                  </li>
                </Tooltip>
              </>
            );
          } else {
            return (
              <>
                <li className="tagging-popup-service">
                  <div>{record}</div>
                </li>
              </>
            );
          }
        }
      },
    },
    {
      title: "Application",
      dataIndex: "application",
      // render: (record) => {
      //   if (record) {
      //     if (record.length > 10) {
      //       return (
      //         <>
      //           <Tooltip placement="bottom" title={record}>
      //             <li className="tagging-popup-service">
      //               <div> {record.substring(0, 10)}...</div>
      //             </li>
      //           </Tooltip>
      //         </>
      //       );
      //     } else {
      //       return (
      //         <>
      //           <li className="tagging-popup-service">
      //             <div>{record}</div>
      //           </li>
      //         </>
      //       );
      //     }
      //   }
      // },
    },
    {
      title: "Environment",
      dataIndex: "environment",
      render: (record) => {
        if (record) {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  <li className="tagging-popup-service">
                    <div> {record.substring(0, 10)}...</div>
                  </li>
                </Tooltip>
              </>
            );
          } else {
            return (
              <>
                <li className="tagging-popup-service">
                  <div>{record}</div>
                </li>
              </>
            );
          }
        }
      },
    },
  ];

  const handlePageChange = (page, size) => {
    setPaginationRecords({
      page: page,
      count: size,
    });
    // setCurrentPage(page);
    // Fetch data for the new page
    // fetchData(page);
  };

  return (
    <>
      {updatepopupstatus && (
        <TaggingPopupUpdated
          updatepopupstatus={updatepopupstatus}
          setUpdatePopupStatus={setUpdatePopupStatus}
          selectedrowvalue={selectedrowvalue}
          setSelectedRowValue={setSelectedRowValue}
          title={props.tabletype.title}
          setUpdateStatus={setUpdateStatus}
          updatestatus={updatestatus}
        />
      )}
      <Modal
      className="costmodal"
        title={props.tabletype.title}
        open={props.tablepopupstatus}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center", padding: "20px" }}
        width="90%"
      >
        <div>
          <div
            style={{
              marginBottom: 16,
            }}
            className="d-flex justify-content-end"
          >
            {/* <span
              style={{
                marginRight: 8,
              }}
            >
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
            </span> */}
            {props.tabletype.type !== "tagged" ? (
              <>
                {companyName.toLowerCase() === "fivestar" ||
                  (companyName.toLowerCase() === "internal1ch" && (
                    <Button
                      onClick={editSelectedrow}
                      disabled={!hasSelected}
                      loading={editloading}
                      className={
                        "taggin-pop-btu " +
                        (hasSelected ? "tagging-popup-button" : "")
                      }
                    >
                      <Icon
                        icon="material-symbols:edit"
                        style={{ fontSize: "25px" }}
                      />
                    </Button>
                  ))}
                {/* <Button
                  onClick={deleteSelectedRow}
                  disabled={!hasSelected}
                  loading={deleteloading}
                  className={
                    "taggin-pop-btu " +
                    (hasSelected ? "popup-delete-button" : "")
                  }
                >
                  <Icon
                    icon="material-symbols:delete"
                    style={{ fontSize: "25px" }}
                  />
                </Button> */}
              </>
            ) : (
              <></>
            )}

            <Button
              //   type="primary"
              onClick={downloadSelectedRow}
              loading={downloadloading}
              className={"taggin-pop-btu "}
            >
              <Icon
                icon="material-symbols:download"
                style={{ fontSize: "25px" }}
              />
            </Button>
          </div>
          <Spin spinning={loaderstatus}>
            {props.tabletype.type !== "tagged" ? (
              <>
                <Table
                  className="slm"
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={tablerecords}
                  pagination={{
                    current: paginationrecords.page,
                    total: paginationtotal,
                    onChange: handlePageChange,
                  }}
                />
                {/* {paginationtotal} */}
              </>
            ) : (
              <Table
                className="slm"
                columns={columns}
                dataSource={tablerecords}
                pagination={{
                  current: paginationrecords.page,
                  total: paginationtotal,
                  onChange: handlePageChange,
                }}
              />
            )}
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default memo(TaggingPopupTable);
